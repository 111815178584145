import { Component, Injector, Input } from "@angular/core";
import { Validators } from "@angular/forms";
import { BaseResourceFormComponent, LoginContabil, NotaExplicativa, NotaExplicativaStorage } from "eddydata-lib";
import { takeUntil } from "rxjs/operators";
import { NotaExplicativaStorageService } from "../service/nota-explicativa-storage.service";
import { NotaExplicativaService } from "../service/nota-explicativa.service";

@Component({
  selector: 'app-nota-explicativa',
  templateUrl: './nota-explicativa.component.html'
})
export class NotaExplicativaComponent extends BaseResourceFormComponent<NotaExplicativa, LoginContabil> {

  @Input() public listaBalancetes: Array<any>;

  public listaArquivos: NotaExplicativaStorage[];
  // public listaArquivos: any[] = [{ nome: 'a', tipo: 'a', tamanho: 123 }, { nome: 'a', tipo: 'a', tamanho: 123 }, { nome: 'a', tipo: 'a', tamanho: 123 }, { nome: 'a', tipo: 'a', tamanho: 123 }, { nome: 'a', tipo: 'a', tamanho: 123 }]
  public balanceteSelect = 'B12';

  constructor(
    protected injector: Injector,
    protected notaExplicativaService: NotaExplicativaService,
    protected storageService: NotaExplicativaStorageService
  ) {
    super(new NotaExplicativa(), injector, NotaExplicativa.converteJson, notaExplicativaService);
  }

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      tipo_relatorio: [null, [Validators.required]],
      nota_explicativa: [null],
    });
  }

  protected parametrosExtras(): {} {
    return {};
  }

  protected afterLoad(): void {
    this.preencherTabelas();
  }

  protected afterInit(): void {
    this.preencherTabelas();
    this.selecionarTipoRelatorio();
  }

  protected beforeSubmit(): void { 

  }

  protected afterSubmit(entidade: NotaExplicativa): void {
    this.entidade = entidade;
  }

  public preencherTabelas() {
    if (this.entidade.id) {
      this.storageService.filtrar(1, -1, { 'nota_explicativa.id': this.entidade.id, orderBy: 'data_cadastro$DESC' })
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.listaArquivos = res.content;
        });
    }
  }

  public selecionarTipoRelatorio() {
    this.notaExplicativaService.filtrar(1, 1, { tipo_relatorio: this.entidadeForm.get('tipo_relatorio').value, relations: 'arquivos' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        const nota: NotaExplicativa = res.content?.[0];
        if (nota) {
          this.entidade = nota;
          this.entidadeForm.get('id').setValue(nota.id)
          this.entidadeForm.get('nota_explicativa').setValue(nota.nota_explicativa);
          this.listaArquivos = nota.arquivos;
        } else {
          this.entidade = null;
          this.entidadeForm.get('nota_explicativa').setValue(null);
          this.listaArquivos = [];
        }
      })
  }

}