import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { EddyAutoComplete, FuncaoService, GrupoEstoque, Login, Produto, ProdutoUnidade, SaldoEstoqueService, SubGrupoEstoque, UnidadeFornecimento } from 'eddydata-lib';
import { AutoComplete } from 'primeng/autocomplete';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { UnidadeFornecimentoService } from '../../unidade-fornecimento/service/unidade-fornecimento.service';
import { ProdutoUnidadeService } from '../service/produto-unidade.service';
import { ProdutoService } from '../service/produto.service';

declare var $: any;

@Component({
  selector: 'lib-produto-un-busca-dlg',
  templateUrl: './produto-un-busca-dlg.component.html'
})
export class ProdutoUnBuscaDlgComponent implements OnInit, OnDestroy {

  @Input() unidade: boolean = true;
  @Input() somenteServico: boolean = false;
  @Input() subelemento: number;
  @Input() login: Login;
  @Input() parametros: {} = {};
  @Input() apenasComSaldo: boolean = false;
  @Input() public nomeDlg: string = 'dialogProdutoUnBusca';
  @Input() public nomeDlgCad: string = 'dialogProdutoCadastro';
  @Output() callback: EventEmitter<Produto | ProdutoUnidade> = new EventEmitter();
  @Output() onCancelar: EventEmitter<void> = new EventEmitter();
  @Output() cancelarImportacoesB: EventEmitter<boolean> = new EventEmitter();
  @Output() cancelarCallback: EventEmitter<boolean> = new EventEmitter();

  // filtros listagem
  public listaGrupos: GrupoEstoque[];
  public grupo: GrupoEstoque;
  public subGrupo: SubGrupoEstoque;
  public servico: boolean = false;
  public codigo: string;
  public nome: string;

  // listagem
  public lista: Produto[];
  public paginaCorrente: number;
  public paginaTotal: number;
  limite: number = 10;

  // cadastro unidade
  @ViewChild('unidade_cad_') campoUnidade: AutoComplete;
  public itemAtual: ProdutoUnidade;
  public unidadeAutoComplete: EddyAutoComplete<UnidadeFornecimento>;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    private unidadeService: UnidadeFornecimentoService,
    private produtoUnService: ProdutoUnidadeService,
    private produtoService: ProdutoService,
    private saldoEstoqueService: SaldoEstoqueService,
  ) { }

  ngOnInit() {
    if (!this.paginaCorrente) {
      this.paginaCorrente = 1;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public buscar() {
    this.paginaCorrente = 1;
    this.preencherGrid();
  }

  private preencherGrid() {
    let parametros = {};
    if (this.parametros)
      parametros = Object.assign({}, this.parametros);
    parametros['relations'] = 'material.sub_grupo.grupo,material.sub_grupo.sub_elementos';
    parametros['orgao_id'] = this.login.orgao.id
    parametros['inativo'] = false;
    parametros['unidades.ativo'] = true;
    if (this.subGrupo) {
      parametros['material.sub_grupo_id'] = this.subGrupo.id;
    } else if (this.grupo) {
      parametros['material.sub_grupo.grupo_id'] = this.grupo.id;
    } else if (this.servico || this.somenteServico) {
      parametros['material.servico'] = true;
    }

    if (this.apenasComSaldo) {
      if (this.codigo) {
        let codigo = this.codigo
          .split('\.').join('')
          .split(',').join('');
        parametros['codigo'] = codigo;
      }
      if (this.nome) {
        parametros['nome'] = `${new FuncaoService().removerAcentos(this.nome)}`;
      }

      const estoque_id = parametros['material.sub_grupo.grupo.estoques.estoque.id']
      if (Object.keys(parametros).length <= 1) {
        toastr.info('Informe os valores de busca');
        return;
      }
      this.saldoEstoqueService.obterProdutosComSaldoEstoquePaginado(this.paginaCorrente, 10, estoque_id, parametros,)
        .pipe(takeUntil(this.unsubscribe)).subscribe(lista => {
          let listaProdutos = []

          for (const l of lista.content) {
            let item = {}
            let unidades = []
            let unidade = { unidade: { nome: l['unidade'] } }

            unidades.push(unidade)

            item = l
            item['unidades'] = unidades

            listaProdutos.push(item)
          }
          this.lista = listaProdutos
          this.paginaTotal = lista.totalPages
        }, error => alert('erro ao retornar lista'))
    } else {
      if (this.codigo) {
        let codigo = this.codigo
          .split('\.').join('')
          .split(',').join('');
        parametros['codigo_comp$like'] = '%' + codigo + '%';
      }
      
      if (this.nome) {
        parametros['nome$like'] = `%${new FuncaoService().removerAcentos(this.nome)}%`;
      }

      if (Object.keys(parametros).length <= 1) {
        toastr.info('Informe os valores de busca');
        return;
      }

      parametros['orderBy'] = 'nome,unidades.unidade.nome';
      parametros['orgao_id'] = this.login.orgao.id;

      console.log(parametros);
      this.produtoService.filtrar(this.paginaCorrente, this.limite, parametros).pipe(takeUntil(this.unsubscribe))
        .subscribe(
          lista => {
            this.lista = lista.content;
            this.paginaTotal = lista.totalPages;
          },
          error => alert('erro ao retornar lista')
        );
    }
  }

  public proximaPagina() {
    this.paginaCorrente = this.paginaCorrente === this.paginaTotal ? this.paginaTotal : this.paginaCorrente + 1;
    this.preencherGrid();
  }

  public paginaAnterior() {
    this.paginaCorrente = this.paginaCorrente === 1 ? 1 : this.paginaCorrente - 1;
    this.preencherGrid();
  }

  public paginaDigitada(pagina: number) {
    this.paginaCorrente =
      +pagina >= this.paginaTotal
        ? this.paginaTotal
        : +pagina;
    this.preencherGrid();
    window.scrollTo(0, 0);
  }

  public mudarLimite(limite: number) {
    this.limite = limite;
    this.paginaDigitada(1);
  }

  public enviarProduto(prod: Produto) {
    this.callback.emit(prod);
    this.fechar();
  }

  public enviarProdutoUn(prodUn: ProdutoUnidade, item: Produto) {
    const prod: Produto = new Produto();
    prod.bec = item.bec;
    prod.codigo = item.codigo;
    prod.descricao = item.descricao;
    prod.id = item.id;
    prod.nome = item.nome;
    prod.material = item.material;

    prodUn.produto = prod;
    this.callback.emit(prodUn);
    this.fechar();
  }

  public fechar() {
    this.codigo = undefined;
    this.nome = undefined;
    this.lista = [];
    $(`#${this.nomeDlg}`).modal('hide');
    this.cancelarCallback.emit(true);
  }

  public abrirCadastroUnidade(item: Produto) {
    if (item) {
      this.unidadeAutoComplete = new EddyAutoComplete(null, this.unidadeService,
        'id', ['nome'], { cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome'] }
      );

      const prod: Produto = new Produto();
      prod.bec = item.bec;
      prod.codigo = item.codigo;
      prod.descricao = item.descricao;
      prod.id = item.id;
      prod.nome = item.nome;

      this.itemAtual = new ProdutoUnidade();
      this.itemAtual.ativo = true;
      this.itemAtual.bec = prod.bec;
      this.itemAtual.produto = prod;

      $(`#${this.nomeDlg}UnCad`).modal('show');
      setTimeout(() => this.campoUnidade ? this.campoUnidade.focusInput() : null, 500);
    }
  }

  public incluirUnidade() {
    this.produtoUnService.inserir(this.itemAtual).pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.itemAtual = res;
        this.fecharUnidade();
        this.lista = undefined;
        this.enviarProdutoUn(this.itemAtual, this.itemAtual.produto);
      },
        error => alert('erro ao retornar lista')
      );
  }

  public fecharUnidade() {
    $(`#${this.nomeDlg}UnCad`).modal('hide');
    this.onCancelar.emit();
  }

  public buscarRegistroIncluido(item: Produto) {
    if (item) {
      this.codigo = undefined;
      this.nome = item.nome;
    }
    this.buscar();
  }

  public podeIncluir(url?: string) {
    if (this.login) {
      if (this.administrador()) {
        return true;
      }
      if (url.lastIndexOf("/") > 0) {
        url = url.substring(0, url.substring(1, url.length).indexOf("/") + 1);
      }
      for (const acesso of this.login.acessos) {
        if (url === acesso.pagina) {
          return acesso.permissao === 2;
        }
      }
    }
    return false;
  }

  administrador() {
    return new FuncaoService().campoJsonToken(this.login.token, "administrador")
  }
}
