import { Component, Input, OnInit } from "@angular/core";
import { GlobalService, LoginContabil, RequisicaoMovimento } from "eddydata-lib";
import { ComprovanteRequisicaoRpt } from "../../relatorio/comprovante-requisicao-rpt/comprovante-requisicao-rpt";

declare var $: any;

@Component({
  selector: 'app-comprovante-requisicao',
  templateUrl: './comprovante-requisicao.component.html'
})
export class ComprovanteRequisicaoComponent implements OnInit {

  @Input() public entidade: RequisicaoMovimento;
  @Input() public login: LoginContabil;

  public listFormato: any[];
  public listOrdenacao: any[];
  public listOrdem: any[];
  public formato = 'Retrato';
  public ordenacao = 'Ordem alfabética';
  public ordem = 'ASC';

  constructor(
    public globalService: GlobalService,
  ) { }

  ngOnInit(): void {
    this.listFormato = ['Retrato', 'Paisagem'];
    this.listOrdenacao = ['Ordem alfabética', 'Ordem por código', 'Ordenação por grupos', 'Quantidade', 'Validade', 'Unidade'];
    this.listOrdem = ['ASC', 'DESC'];

  }

  public emitirComprovante() {
    switch (this.ordenacao) {
      case 'Ordem alfabética':
        if (this.ordem === 'ASC') {
          this.entidade.itens = this.entidade.itens.sort((a, b) => a.produto_unidade.produto.nome.localeCompare(b.produto_unidade.produto.nome))
        } else {
          this.entidade.itens = this.entidade.itens.sort((a, b) => b.produto_unidade.produto.nome.localeCompare(a.produto_unidade.produto.nome))
        }
        break;
      case 'Ordem por código':
        if (this.ordem === 'ASC') {
          this.entidade.itens = this.entidade.itens.sort((a, b) => +a.produto_unidade.produto.codigo - +b.produto_unidade.produto.codigo)
        } else {
          this.entidade.itens = this.entidade.itens.sort((a, b) => +b.produto_unidade.produto.codigo - +a.produto_unidade.produto.codigo)
        }
        break;
      case 'Ordenação por grupos':
        if (this.ordem === 'ASC') {
          this.entidade.itens = this.entidade.itens.sort((a, b) => a.produto_unidade.produto.material.sub_grupo.grupo.nome.localeCompare(b.produto_unidade.produto.material.sub_grupo.grupo.nome))
        } else {
          this.entidade.itens = this.entidade.itens.sort((a, b) => b.produto_unidade.produto.material.sub_grupo.grupo.nome.localeCompare(a.produto_unidade.produto.material.sub_grupo.grupo.nome))
        }
        break;
      case 'Quantidade':
        if (this.ordem === 'ASC') {
          this.entidade.itens = this.entidade.itens.sort((a, b) => +a.qtd_aceita - +b.qtd_aceita)
        } else {
          this.entidade.itens = this.entidade.itens.sort((a, b) => +b.qtd_aceita - +a.qtd_aceita)
        }
        break;
      case 'Validade':
        if (this.ordem === 'ASC') {
          this.entidade.itens = this.entidade.itens.sort((a, b) => new Date(new Date(a.vencimento).toDateString()).getTime() - new Date(new Date(b.vencimento).toDateString()).getTime())
        } else {
          this.entidade.itens = this.entidade.itens.sort((a, b) => new Date(new Date(b.vencimento).toDateString()).getTime() - new Date(new Date(a.vencimento).toDateString()).getTime())
        }
        break;
      case 'Unidade':
        if (this.ordem === 'ASC') {
          this.entidade.itens = this.entidade.itens.sort((a, b) => a.produto_unidade.unidade.nome.localeCompare(b.produto_unidade.unidade.nome))
        } else {
          this.entidade.itens = this.entidade.itens.sort((a, b) => b.produto_unidade.unidade.nome.localeCompare(a.produto_unidade.unidade.nome))
        }
        break;
      default:
        break;
    }

    new ComprovanteRequisicaoRpt(this.login, this.globalService).imprimir(this.entidade, this.formato);

    $('#dlgComprovanteRequisicao').modal('hide');
  }

}