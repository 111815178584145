import { Injectable } from '@angular/core';
import { EspecieAlmoxarifado, Produto, Sistemas } from '../../public-api';
import {
  ComissaoMembroAtribuicao, ComissaoMembroNaturezaCargo, ConservacaoPatrimonio, CriterioDesempatePregao, CriterioJulgamento, CriterioJulgamentoValor, FavorecidoEnquadramento,
  FormatoLicitacao, PrioridadeRCMS, SituacaoImovel, SituacaoLicitacao, SituacaoPatrimonio, SituacaoProponente, SituacaoProposta, TipoAquisicaoVeiculo, TipoComissao, TipoContato,
  TipoContrato, TipoFavorecido, TipoHistoricoTombo, TipoObjetoLicitacao, TipoOcorrenciaContrato, TipoOcorrenciaLicitacao, TipoOcorrenciaVeiculo, TipoOrdemServico, TipoPregao
} from '../components/types';
import { Login } from '../entidade/login/login';
import { StatusRequisicao } from '../entidade/requisicao/status-requisicao.enum';

declare function atualizarMascarasData(): any;
declare function convertEnterParaTab(): any;

export interface Mes {
  id: string;
  nome: string;
}

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  private static SESSIONSTORAGE_NAME = 'login_CLIENTE_SISTEMA';

  // padrao de url para transparencia .../pagina/data1/data2 ou .../pagina/data1/data2/filtro
  public static TRANSPARENCIA_REGEXP: RegExp = /trasparencia\/(.*)\/(\d+)\/\d{4}\-\d{2}\-\d{2}\/\d{4}\-\d{2}\-\d{2}(.*)|(.*)\/(\d+)\/([1-12])\/(\d+)\/\w+(.*)/;

  public static obterSessaoLogin(): Login {
    return JSON.parse(sessionStorage.getItem(GlobalService.SESSIONSTORAGE_NAME));
  }

  public static salvarSessaoLogin(login: Login): void {
    sessionStorage.setItem(GlobalService.SESSIONSTORAGE_NAME, JSON.stringify(login));
  }

  public static limparSessaoLogin(limparTodas?: boolean): void {
    sessionStorage.setItem(GlobalService.SESSIONSTORAGE_NAME, null);
    if (limparTodas) sessionStorage.clear();
  }

  public calendarMascara() {
    atualizarMascarasData();
  }

  public convertEnterParaTab() {
    convertEnterParaTab();
  }

  public obterMes(mes: number, abreviado?: boolean): string {
    switch (mes) {
      case 1: {
        return abreviado ? 'JAN' : 'JANEIRO';
      }
      case 2: {
        return abreviado ? 'FEV' : 'FEVEREIRO';
      }
      case 3: {
        return abreviado ? 'MAR' : 'MARÇO';
      }
      case 4: {
        return abreviado ? 'ABR' : 'ABRIL';
      }
      case 5: {
        return abreviado ? 'MAI' : 'MAIO';
      }
      case 6: {
        return abreviado ? 'JUN' : 'JUNHO';
      }
      case 7: {
        return abreviado ? 'JUL' : 'JULHO';
      }
      case 8: {
        return abreviado ? 'AGO' : 'AGOSTO';
      }
      case 9: {
        return abreviado ? 'SET' : 'SETEMBRO';
      }
      case 10: {
        return abreviado ? 'OUT' : 'OUTUBRO';
      }
      case 11: {
        return abreviado ? 'NOV' : 'NOVEMBRO';
      }
      case 12: {
        return abreviado ? 'DEZ' : 'DEZEMBRO';
      }
      case 0:
        return abreviado ? 'ABE' : 'ABERTURA';
      case 13:
        return abreviado ? 'FEC PAR' : 'FECHAMENTO PARCIAL';
      case 14:
        return abreviado ? 'FEC BAL' : 'FECHAMENTO BALANÇO';
      default: {
        return String(mes);
      }
    }
  }

  public obterListaMeses(): Mes[] {
    return [
      { id: '1', nome: 'JANEIRO' },
      { id: '2', nome: 'FEVEREIRO' },
      { id: '3', nome: 'MARÇO' },
      { id: '4', nome: 'ABRIL' },
      { id: '5', nome: 'MAIO' },
      { id: '6', nome: 'JUNHO' },
      { id: '7', nome: 'JULHO' },
      { id: '8', nome: 'AGOSTO' },
      { id: '9', nome: 'SETEMBRO' },
      { id: '10', nome: 'OUTUBRO' },
      { id: '11', nome: 'NOVEMBRO' },
      { id: '12', nome: 'DEZEMBRO' }
    ];
  }

  public obterDataBR() {
    // atualizarMascarasData();
    const ptBR = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
      monthNames: [
        'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro', 'Fechamento'
      ],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez', 'Fec'],
      today: 'Hoje',
      clear: 'Limpar'
    };

    return ptBR;
  }

  public obterAvaliacoes() {
    return [
      { id: '1', nome: 'EMPENHOS' },
      { id: '2', nome: 'CONTRATOS' },
      { id: '3', nome: 'LICITAÇÕES' },
      { id: '4', nome: 'PATRIMÔNIO' },
      { id: '5', nome: 'ADIANTAMENTOS' },
      { id: '20', nome: 'AUDIÊNCIA PÚBLICA' },
      { id: '21', nome: 'LEI DE DIRETRIZES ORÇAMENTÁRIAS' },
      { id: '22', nome: 'ANEXO DE METAS FISCAIS' },
      { id: '23', nome: 'DISPONIBILIZAÇÃO DOS ESTUDOS E ESTIMATIVAS DA RECEITA' },
      { id: '24', nome: 'CRONOGRAMA MENSAL DE DESEMBOLSOS' },
      { id: '25', nome: 'METAS BIMESTRAIS DE ARRECADAÇÃO' },
      { id: '26', nome: 'ANEXO DE RISCOS FISCAIS' },
      { id: '27', nome: 'LIMITAÇÃO DE EMPENHO E MOVIMENTAÇÃO FINANCEIRA' },
      { id: '28', nome: 'OPERAÇÕES DE CRÉDITO' },
      { id: '29', nome: 'DÍVIDA CONSOLIDADA LÍQUIDA' },
      { id: '30', nome: 'APLICAÇÃO DA RECEITA DE ALIENAÇÃO DE BENS' },
      { id: '31', nome: 'CONSOLIDAÇÃO DAS CONTAS' },
      { id: '32', nome: 'UTILIZAÇÃO DOS RECURSOS VINCULADOS' },
      { id: '33', nome: 'IDENTIFICAÇÃO DE BENEFICIÁRIOS DE PAGAMENTOS DE SENTENÇAS JUDICIAIS' },
      { id: '34', nome: 'RENÚNCIA DE RECEITA' },
      { id: '35', nome: 'PUBLICAÇÃO DO RELATÓRIO RESUMIDO DA EXECUÇÃO ORÇAMENTÁRIA – RREO' },
      { id: '36', nome: 'PUBLICAÇÃO DO RELATÓRIO DE GESTÃO FISCAL – RGF' },
      { id: '37', nome: 'DESPESAS COM PESSOAL' },
      { id: '38', nome: 'INSTITUIÇÃO, PREVISÃO E EFETIVA ARRECADAÇÃO DE TRIBUTOS' }
    ];
  }

  public obterSituacao(idx: string) {
    switch (idx) {
      case '1':
        return 'AGUARDANDO';
      case '2':
        return 'ANDAMENTO';
      case '3':
        return 'FINALIZADO';
      case '4':
        return 'CANCELADO';
      case '99':
        return 'CANCELADO';
      default:
        return '-';
    }
  }

  public obterTipo(idx: number) {
    switch (idx) {
      case 1:
        return 'ADIANTAMENTO';
      case 2:
        return 'CONTRATO';
      case 3:
        return 'LICITAÇÃO';
      case 4:
        return 'PATRIMÔNIO';
      case 5:
        return 'PROTOCOLO';
      case 99:
        return 'OUTROS';
      default:
        return '-';
    }
  }

  public obterPeriodoMerenda(idx: String) {
    switch (idx) {
      case "M":
        return 'MANHÃ';
      case "T":
        return 'TARDE';
      case "N":
        return 'NOITE';
      case "D":
        return 'TOTAL DIÁRIO';
      default:
        return '-';
    }
  }

  public obterEspecie(idx: string) {
    switch (idx) {
      case 'AB1':
        return 'ABERTURA DE EXERCÍCIO CONTA BANCÁRIA - AB1';
      case 'ABE':
        return 'ABERTURA DE EXERCÍCIO - ABE';
      case 'FRP':
        return 'ENCERRAMENTO RESTOS A PAGAR PROCESSADOS - FRP';
      case 'FRN':
        return 'ENCERRAMENTO RESTOS A PAGAR NÃO PROCESSADOS - FRN';
      case 'AB2':
        return 'ABERTURA DE EXERCÍCIO FICHAS EXTRA ORÇAMENTÁRIAS - AB2';
      case 'ALG':
        return 'ALIENAÇÃO DE BENS - GANHO - ALG';
      case 'ALP':
        return 'ALIENAÇÃO DE BENS - PERDA - ALP';
      case 'EOA':
        return 'ANULAÇÃO DE EMPENHOS ORÇAMENTÁRIOS - EOA';
      case 'PGA':
        return 'ANULAÇÃO DE PAGAMENTOS ORÇAMENTÁRIOS - PGA';
      case 'ADI':
        return 'ADIANTAMENTOS CONCEDIDOS - ADI';
      case 'ADD':
        return 'ADIANTAMENTOS DEVOLVIDOS - ADD';
      case 'AAD':
        return 'ADIANTAMENTOS APROPRIAÇÃO DE DESPESA - AAD';
      case 'ADU':
        return 'ADIANTAMENTOS UTILIZADOS - ADU';
      case 'RPE':
        return 'APLICAÇÃO EDUCAÇÃO - RPE';
      case 'ADA':
        return 'ARRECADAÇÃO DIVIDA ATIVA TRIBUTARIAS - CONCOMITANTE - ADA';
      case 'ADN':
        return 'ARRECADAÇÃO DIVIDA ATIVA NAO TRIBUTARIAS - CONCOMITANTE - ADN';
      case 'ACA':
        return 'ADIANTAMENTO CONCEDIDO A APROVAR - ACA';
      case 'AAC':
        return 'ASSINATURA CONVÊNIO AUXÍLIO-CONCESSÃO - AAC';
      case 'SAC':
        return 'ASSINATURA CONVÊNIO SUBVENÇÃO-CONCESSÃO - SAC';
      case 'ARE':
        return 'ASSINATURA CONVÊNIO AUXÍLIO-RECEBIDO ESTADO - ARE';
      case 'AAU':
        return 'ASSINATURA CONVÊNIO AUXÍLIO-RECEBIDO UNIÃO - AAU';
      case 'AAE':
        return 'ASSINATURA CONVÊNIO SUBVENÇÃO-RECEBIDO ESTADO - AAE';
      case 'SRU':
        return 'ASSINATURA CONVÊNIO SUBVENÇÃO-RECEBIDO UNIÃO - SRU';
      case 'SRE':
        return 'ASSINATURA CONVÊNIO CONTRIBUIÇÃO-RECEBIDO ESTADO - SRE';
      case 'CAU':
        return 'ASSINATURA CONVÊNIO CONTRIBUIÇÃO-RECEBIDO UNIÃO - CAU';
      case 'ACT':
        return 'ASSINATURA CONVÊNIO CONCEDIDOS OUTROS - ACT';
      case 'AFA':
        return 'ASSINATURA CONVÊNIO TERMO DE COLABORAÇÃO - AFA';
      case 'ACO':
        return 'ASSINATURA CONVÊNIO ACORDO DE COOPERAÇÃO - ACO';
      case 'ACG':
        return 'ASSINATURA CONVÊNIO CONTRATO DE GESTÃO - ACG';
      case 'ATP':
        return 'ASSINATURA CONVÊNIO TERMO DE PARCERIA ATP';
      case 'ACE':
        return 'ASSINATURA CONVÊNIO RECEBIDOS OUTROS ESTADO - ACE';
      case 'OCC':
        return 'ASSINATURA OUTROS - CONVENIOS CONCEDIDOS OCC';
      case 'CAO':
        return 'ASSINATURA CONTRATOS OUTROS - CAO';
      case 'CAA':
        return 'ASSINATURA CONTRATOS DE ALUGUEL - CAA';
      case 'CAB':
        return 'ASSINATURA CONTRATOS DE FORNECIMENTO DE BENS - CAB';
      case 'CAG':
        return 'ASSINATURA CONTRATOS DE GESTÃO - CAG';
      case 'CAG':
        return 'ASSINATURA CONTRATOS DE GESTÃO - CAG';
      case 'CAS':
        return 'ASSINATURA CONTRATOS DE FORNECIMENTO DE SERVIÇO - CAS';
      case 'CAE':
        return 'ASSINATURA CONTRATOS DE EMPRESTIMOS CONCEDIDOS - CAE';
      case 'CAM':
        return 'ASSINATURA CONTRATOS DE SERVIÇOS E MATERIAIS - CAM';
      case 'CAP':
        return 'ASSINATURA CONTRATOS DE PARCELAMENTO - CAP';
      case 'APC':
        return 'ASSINATURA CONTRATOS PERMISSÃO E CONCESSÃO - APC';
      case 'ASP':
        return 'ASSINATURA PARCERIA PÚBLICO PRIVADA - ASP';
      case 'PPC':
        return 'ASSINATURA CONTRATOS - OBRIGAÇÕES CONTRATUAIS - PPC';
      case 'PPR':
        return 'ASSINATURA CONTRATOS - RISCOS NÃO PROVISIONADOS - PPR';
      case 'PPG':
        return 'ASSINATURA CONTRATOS - GARANTIAS CONCEDIDAS - PPG';
      case 'PPO':
        return 'ASSINATURA CONTRATOS - OUTROS PASSIVOS CONTINGENTES - PPO';
      case 'PP1':
        return 'PREVISÃO PPP ANO 1 - PP1';
      case 'PP2':
        return 'PREVISÃO PPP ANO 2 - PP2';
      case 'PP3':
        return 'PREVISÃO PPP ANO 3 - PP3';
      case 'PP4':
        return 'PREVISÃO PPP ANO 4 - PP4';
      case 'PP5':
        return 'PREVISÃO PPP ANO 5 - PP5';
      case 'PP6':
        return 'PREVISÃO PPP ANO 6 - PP6';
      case 'PP7':
        return 'PREVISÃO PPP ANO 7 - PP7';
      case 'PP8':
        return 'PREVISÃO PPP ANO 8 - PP8';
      case 'PP9':
        return 'PREVISÃO PPP ANO 9 - PP9';
      case 'PP10':
        return 'PREVISÃO PPP ANO 10 - PP10';
      case 'CTA':
        return 'BAIXA CONTRATOS DE ALUGUÉIS - CTA';
      case 'CTE':
        return 'BAIXA CONTRATOS DE EMPRESTIMOS CONCEDIDOS - CTE';
      case 'CTB':
        return 'BAIXA CONTRATOS DE FORNECIMENTO DE BENS - CTB';
      case 'CTS':
        return 'BAIXA CONTRATOS DE FORNECIMENTO DE SERVIÇOS - CTS';
      case 'CTP':
        return 'BAIXA CONTRATOS DE PARCELAMENTOS - CTP';
      case 'CSS':
        return 'BAIXA CONTRATOS DE SEGUROS - CSS';
      case 'BCP':
        return 'BAIXA CONTRATOS - OBRIGAÇÕES CONTRATUAIS - BCP';
      case 'BPR':
        return 'BAIXA CONTRATOS - RISCOS NÃO PROVISIONADOS - BPR';
      case 'BPG':
        return 'BAIXA CONTRATOS - GARANTIAS CONCEDIDAS - BPG';
      case 'BPO':
        return 'BAIXA CONTRATOS - OUTROS PASSIVOS CONTINGENTES - BPO';
      case 'CTO':
        return 'BAIXA CONTRATOS OUTROS - CTO';
      case 'BSP':
        return 'BAIXA CONTRATO PARCERIA PÚBLICO PRIVADA - BSP';
      case 'BPC':
        return 'BAIXA CONTRATOS PERMISSÃO E CONCESSÃO - BPC';
      case 'COS':
        return 'RETENÇÕES EXTRA ORÇAMENTÁRIA - CONCOMITANTE - COS';
      case 'COV':
        return 'BAIXA CONVENIOS AUXILIOS E SUBVENCOES - COV';
      case 'LCT':
        return 'BAIXA TERMO DE COLABORAÇÃO - LCT';
      case 'LTF':
        return 'BAIXA TERMO DE FOMENTO - LTF';
      case 'LCO':
        return 'BAIXA ACORDO DE COOPERAÇÃO - LCO';
      case 'CR1':
        return 'CRÉDITO ADICIONAL SUPLEMENTAR - CR1';
      case 'CR2':
        return 'CRÉDITO ADICIONAL EXCESSO DE ARRECADAÇÃO - CR2';
      case 'CR3':
        return 'CRÉDITO ADICIONAL SUPERÁVIT FINANCEIRO - CR3';
      case 'CR4':
        return 'CRÉDITO ADICIONAL OPERAÇÕES DE CRÉDITO - CR4';
      case 'CR5':
        return 'CRÉDITO ADICIONAL RESERVA DE CONTINGÊNCIA - CR5';
      case 'CR6':
        return 'CRÉDITO ADICIONAL PARAGRAFO 8˚ DO ARTIGO 166 - CR6';
      case 'CR7':
        return 'CRÉDITO ESPECIAL REMANEJAMENTO - CR7';
      case 'CR8':
        return 'CRÉDITO ESPECIAL SUPLEMENTAR - CR8';
      case 'CR9':
        return 'CRÉDITO ESPECIAL EXCESSO DE ARRECADAÇÃO - CR9';
      case 'CR10':
        return 'CRÉDITO ESPECIAL SUPERÁVIT FINANCEIRO - CR10';
      case 'CR11':
        return 'CRÉDITO ESPECIAL OPERAÇÕES DE CRÉDITO - CR11';
      case 'CR12':
        return 'CRÉDITO ESPECIAL RESERVA DE CONTINGÊNCIA - CR12';
      case 'CR13':
        return 'CRÉDITO ESPECIAL PARAGRAFO 8˚ DO ARTIGO 166 - CR13';
      case 'CR14':
        return 'CRÉDITO ESPECIAL REMANEJAMENTO - CR14';
      case 'CR15':
        return 'CRÉDITO EXTRAORDINÁRIO SUPLEMENTAR - CR15';
      case 'CR16':
        return 'CRÉDITO EXTRAORDINÁRIO EXCESSO DE ARRECADAÇÃO - CR16';
      case 'CR17':
        return 'CRÉDITO EXTRAORDINÁRIO SUPERÁVIT FINANCEIRO - CR17';
      case 'CR18':
        return 'CRÉDITO EXTRAORDINÁRIO OPERAÇÕES DE CRÉDITO - CR18';
      case 'CR19':
        return 'CRÉDITO EXTRAORDINÁRIO RESERVA DE CONTINGÊNCIA - CR19';
      case 'CR20':
        return 'CRÉDITO EXTRAORDINÁRIO PARAGRAFO 8˚ DO ARTIGO 166 - CR20';
      case 'CR21':
        return 'CRÉDITO EXTRAORDINÁRIO REMANEJAMENTO - CR21';
      case 'CR90':
        return 'CRÉDITOS ADICIONAIS SUPLEMENTAR CANCELAMENTO DOTAÇÃO - CR90';
      case 'CR91':
        return 'CRÉDITOS ADICIONAIS ESPECIAL CANCELAMENTO DOTAÇÃO - CR91';
      case 'ECC':
        return 'ENCERRAMENTO DAS CONTAS DE CONTROLES DEVEDORES E CREDORES - 14 mês - ECC';
      case 'BLQ':
        return 'BLOQUEIO JUDICIAL - BLQ';
      case 'ARR':
        return 'AJUSTE RECEITA EXTRA PROVENIENTE DE RETENÇÃO - ARR';
      case 'EDA':
        return 'PRÉ-EMPENHO DISPONIBILIDADE EXERCÍCIO ANTERIOR - ANULAÇÃO - EDA';
      case 'ETF':
        return 'ESTORNO DE TERMO DE FOMENTO - ASSINATURA CONVÊNIO - ETF';
      case 'OER':
        return 'APROVAÇÃO OUTROS - CONVENIOS RECEBIDOS ESTADO - OER';
      case 'ETC':
        return 'ESTORNO DE TERMO DE COLABORAÇÃO - ASSINATURA CONVÊNIO - ETC';
      case 'LPA':
        return 'ANULAÇÃO LIQUIDAÇÃO DE PRÉ LIQUIDAÇÃO DE RESTO - LPA';
      case 'TRC':
        return 'DEVOLUÇÃO CÂMARA REF. ANULAÇÃO DE RESTOS A PAGAR - TRC';
      case 'APD':
        return 'AJUSTE PREVISÃO INICIAL DA DESPESA - APD';
      case 'PER':
        return 'RETENÇÃO EXTRA - LIQUIDAÇÃO DE RESTOS COM PRÉ LIQUIDAÇÃO - IPC 011 - PER';
      case 'PDR':
        return 'PAGAMENTO ORÇAMENTO DE DISPONIBILIDADE DO EXERCÍCIO ANTERIOR - PDR';
      case 'PRI':
        return 'PREVISÃO INICIAL DE RESTITUIÇÃO DE RECEITA - PRI';
      case 'PDO':
        return 'PAGAMENTO ORÇAMENTO DE DISPONIBILIDADE DO EXERCÍCIO- PDO';
      case 'IPO':
        return 'IPC 011 - CONCOMITANTE À LIQUIDAÇÃO - RETENÇÃO ORÇAMENTÁRIA - IPO';
      case 'IPR':
        return 'PAGAMENTO DE RETENÇÃO DE LIQUIDAÇÃO DE RP NÃO PROCESSADOS - IPC 011- IPR';
      case 'IPC':
        return 'RETENÇÃO ORÇAMENTÁRIA EMPENHO EXTRA SEM ORIGEM - IPC';
      case 'IPE':
        return 'IPC 011 - CONCOMITANTE À LIQUIDAÇÃO - RETENÇÃO EXTRA- IPE';
      case 'ERO':
        return 'RETENÇÃO ORÇAMENTÁRIAS - EMPENHO EXTRA COM ORIGEM - ERO';
      case 'ARA':
        return 'AJUSTE DE REC AUX/CONT/SUB - RECEBIDO ANT 2014 - ARA';
      case 'ARC':
        return 'AJUSTE DE REC DE CONVENIO ANT 2014 - ARC';
      case 'RPP':
        return 'RESERVA DE DOTAÇÃO ORÇAMENTÁRIA REFORÇO - RPP';
      case 'QUC':
        return 'QUITAÇÃO CONTRIBUIÇÕES - RECEBIMENTOS UNIÃO - QUC';
      case 'CRQ':
        return 'QUITAÇÃO DE CONVENIO - RECEBIDOS UNIÃO - CRQ';
      case 'QES':
        return 'QUITAÇÃO SUBVENÇÕES - RECEBIMENTOS ESTADO - QES';
      case 'QEC':
        return 'QUITAÇÃO CONTRIBUIÇÕES - RECEBIMENTOS ESTADO - QEC';
      case 'QUA':
        return 'QUITAÇÃO AUXÍLIO - RECEBIMENTOS UNIÃO - QUA';
      case 'QUS':
        return 'QUITAÇÃO SUBVENÇÕES - RECEBIMENTOS UNIÃO - QUS';
      case 'CAD':
        return 'CONTROLE DE ADIANTAMENTO - 13 mês - CAD';
      case 'DDC':
        return 'DEVOLUÇÃO DEPÓSITOS E CAUÇÕES - DDC';
      case 'DRP':
        return 'DEVOLUÇÃO DE RECURSOS PREFEITURA - DRP';
      case 'DRC':
        return 'DEVOLUÇÃO DE RECURSOS CAMARA - DRC';
      case 'DRE':
        return 'DEVOLUÇÃO DE RECURSOS CAMARA EXERCÍCIO ANTERIOR - DRE';
      case 'DOM':
        return 'DOMICÍLIO BANCÁRIO TRANSFERÊNCIA FINANCEIRA - DOM';
      case 'ELO':
        return 'EM LIQUIDAÇÃO ORÇAMENTÁRIA - ELO';
      case 'ELR':
        return 'EM LIQUIDAÇÃO RESTOS - ELR';
      case 'EMP':
        return 'EMPENHOS COM PRÉ-EMPENHO - EMP';
      case 'EPA':
        return 'EMPENHOS COM PRÉ-EMPENHO - ANULAÇÃO - EPA';
      case 'EMO':
        return 'EMPENHOS ORÇAMENTÁRIOS - EMO';
      case 'CDR':
        return 'TROCA DE RECURSOS DISPONIBILIDADE - CDR';
      case 'EOA':
        return 'ANULAÇÃO EMPENHOS ORÇAMENTÁRIOS - EOA';
      case 'EMD':
        return 'EMPENHOS ORÇAMENTÁRIOS DISPONIBILIDADE EXERCÍCIO ANTERIOR - EMD';
      case 'EMA':
        return 'ANULAÇÃO EMPENHOS ORÇAMENTÁRIOS DISPONIBILIDADE EXERCÍCIO ANTERIOR - EMA';
      case 'ESP':
        return 'EMPENHOS REFORÇO - SEM PRÉ-EMPENHO - ESP';
      case 'ECP':
        return 'EMPENHOS REFORÇO - COM PRÉ-EMPENHO - ECP';
      case 'ERD':
        return 'EMPENHOS RESERVA DE DOTAÇÃO - ERD';
      case 'CRA':
        return 'EXCESSO DE ARRECADAÇÃO - ANULAÇÃO - CRA';
      case 'FOD':
        return 'FIXAÇÃO ORÇAMENTARIA DA DESPESA - FOD';
      case 'INC':
        return 'INCORPORAÇÃO DE OBRAS E INSTALAÇÕES - INC';
      case 'LEO':
        return 'LIQUIDAÇÃO ORÇAMENTÁRIA - LEO';
      case 'LEA':
        return 'ANULAÇÃO LIQUIDAÇÃO ORÇAMENTÁRIA - LEA';
      case 'PLO':
        return 'LIQUIDAÇÃO DE PRÉ LIQUIDAÇÃO - PLO';
      case 'PLR':
        return 'LIQUIDAÇÃO DE PRÉ LIQUIDAÇÃO DE RESTO - PLR';
      case 'LRP':
        return 'LIQUIDAÇÃO DE RESTOS A PAGAR - PROCESSADOS - LRP';
      case 'LRN':
        return 'LIQUIDAÇÃO DE RESTOS A PAGAR - NÃO PROCESSADOS - LRN';
      case 'LRA':
        return 'LIQUIDAÇÃO DE RESTOS A PAGAR - NÃO PROCESSADOS ESTORNO - LRA';
      case 'CAO':
        return 'CONTRATO ASSINATURA OUTRO TERCEIRO - CAO';
      case 'COR':
        return 'OUTROS CONVENIOS RECEBIDOS - REMUNERAÇÃO DE APLICAÇÃO - COR';
      case 'SAR':
        return 'OUTROS AUXILIOS, SUBVENÇÕES, CONTRIBUIÇÕES RECEBIDOS - REMUNERAÇÃO - SAR';
      case 'COC':
        return 'BAIXA OUTROS CONVÊNIOS CONCEDIDOS - COC';
      case 'OCC':
        return 'OUTROS CONVÊNIOS CONCEDIDOS ASS. C - OCC';
      case 'OCR':
        return 'ASSINATURA CONVÊNIO RECEBIDOS OUTROS UNIÃO - OCR';
      case 'PGO':
        return 'PAGAMENTOS ORÇAMENTÁRIOS - PGO';
      case 'PEE':
        return 'PAGAMENTOS EXTRA ORÇAMENTÁRIOS COM RETENÇÃO- PEE';
      case 'PEG':
        return 'PAGAMENTOS EXTRA ORÇAMENTÁRIOS ORIUNDO DE RETENÇÃO EXTRA - PEG';
      case 'PGE':
        return 'PAGAMENTOS EXTRA ORÇAMENTÁRIOS - PGE';
      case 'BRE':
        return 'BAIXA RETENÇÕES EXTRA ORÇAMENTÁRIAS - BRE';
      case 'BCE':
        return 'BAIXA CONSIGNAÇÕES - BCE';
      case 'TDE':
        return 'PAGAMENTOS EXTRA ORÇAMENTÁRIOS SEM ORIGEM TRANSF DISP. EXTRA - TDE';
      case 'PGR':
        return 'PAGAMENTOS DE RESTOS A PAGAR - PGR';
      case 'PRN':
        return 'PAGAMENTO DE RESTOS A PAGAR NÃO PROCESSADOS - CONCOMITANTE - PRN';
      case 'PRP':
        return 'PAGAMENTO DE RESTOS A PAGAR PROCESSADOS  - CONCOMITANTE - PRP';
      case 'PDL':
        return 'PARCELAMENTO DE DIVIDA - BAIXA - PDL';
      case 'PAD':
        return 'PREVISÃO ADICIONAL DA DESPESA - PAD';
      case 'PED':
        return 'PREVISÃO REDUTORA DA DESPESA - PED';
      case 'PAR':
        return 'PREVISÃO ADICIONAL DA RECEITA - PAR';
      case 'PIR':
        return 'PREVISÃO INICIAL DA RECEITA - PIR';
      case 'PRR':
        return 'PREVISÃO INICIAL REDUTORES DA RECEITA - PRR';
      case 'RRP':
        return 'PREVISÃO INICIAL RENUNCIA DE RECEITA - RRP';
      case 'PRS':
        return 'PREVISÃO INICIAL RESTITUIÇÃO DE RECEITA - PRS';
      case 'PTC':
        return 'PREVISÃO TRANSFERENCIAS A CONCEDER - PTC';
      case 'PTR':
        return 'PREVISÃO TRANSFERENCIAS A RECEBER - PTR';
      case 'PCA':
        return 'PREVISÃO TRANSFERENCIAS CONCEDIDA ADICIONAL - PCA';
      case 'PCE':
        return 'PREVISÃO TRANSFERENCIAS CONCEDIDA REDUÇÃO - PCE';
      case 'PRA':
        return 'PREVISÃO TRANSFERENCIAS RECEBER ADICIONAL - PRA';
      case 'PRE':
        return 'PREVISÃO TRANSFERENCIAS RECEBER REDUÇÃO - PRE';
      case 'QCE':
        return 'QUITAÇÃO DE CONVENIO - RECEBIDOS QCE';
      case 'QCO':
        return 'QUITAÇÃO DE CONVENIO - CONCEDIDOS - QCO';
      case 'QEA':
        return 'QUITAÇÃO DE CONVENIO - AUXILIOS QEA';
      case 'QCR':
        return 'QUITAÇÃO DE CONVENIO - RECEBIMENTOS - QCR';
      case 'QDA':
        return 'QUADRO DETALHAMENTO DESPESA - ACRÉSCIMO - QDA';
      case 'QDD':
        return 'QUADRO DETALHAMENTO DESPESA - DECRÉSCIMOS - QDD';
      case 'QCC':
        return 'QUITAÇÃO DE CONVENIO - CONCESSÃO - QCC';
      case 'QCA':
        return 'QUITAÇÃO AUXÍLIOS CONCEDIDOS - QCA';
      case 'REE':
        return 'RECEITAS EXTRA ORÇAMENTÁRIAS - REE';
      case 'REA':
        return 'RECEITAS EXTRA ORÇAMENTÁRIAS ANULADAS DE RETENÇÕES - REA';
      case 'REO':
        return 'RECEITAS ORÇAMENTÁRIAS - REO';
      case 'ROC':
        return 'RECEITAS ORÇAMENTÁRIAS - RECOLHIDA POR COMPETÊNCIA - ROC';
      case 'ROR':
        return 'RECEITAS ORÇAMENTÁRIAS - REDUTORES - ROR';
      case 'RES':
        return 'RECEITAS ORÇAMENTÁRIAS - RESTITUIÇÃO - RES';
      case 'CON':
        return 'RECEBIMENTO DE CONVENIOS - CON';
      case 'ASR':
        return 'RECEBIMENTO DE AUXÍLIO SUBVENÇÃO CONTRIBUIÇÃO - ASR';
      case 'RDO':
        return 'RESERVA DE DOTAÇÃO ORÇAMENTÁRIA - RDO';
      case 'RDA':
        return 'RESERVA DE DOTAÇÃO ORÇAMENTÁRIA ANULA- RDA';
      case 'LRT':
        return 'RETENÇÕES LIQUIDAÇÃO RESTO A PAGAR EXTRA DOMICILIO - LRT';
      case 'RET':
        return 'RETENÇÕES LIQUIDAÇÃO EXTRA ORÇAMENTÁRIA - RET';
      case 'RTE':
        return 'RETENÇÕES LIQUIDAÇÃO EXTRA ORÇAMENTÁRIA 2022 - RTE';
      case 'RLE':
        return 'RETENÇÕES LIQUIDAÇÃO RESTOS A PAGAR EXTRA ORÇAMENTÁRIA 2022 - RLE';
      case 'RPD':
        return 'RETENÇÕES PAGAMENTO DOMICÍLIO - RPD';
      case 'RPR':
        return 'RETENÇÕES PAGAMENTO RESTO A PAGAR DOMICÍLIO - RPR';
      case 'RPA':
        return 'RETENÇÕES PAGAMENTO DOMICÍLIO ANULAÇÃO - RPA';
      case 'RTA':
        return 'RETENÇÕES LIQUIDAÇÃO EXTRA ORÇAMENTÁRIA ANULAÇÃO - RTA';
      case 'RLA':
        return 'RETENÇÕES LIQUIDAÇÃO ORÇAMENTÁRIA ANULAÇÃO RECURSO ORDINÁRIO - RLA';
      case 'RLR':
        return 'RETENÇÕES RESTOS A PAGAR EXTRA ORÇAMENTÁRIOS - RLR';
      case 'REL':
        return 'RETENÇÕES LIQUIDAÇÃO RESTO RECURSOS ORDINÁRIOS - REL';
      case 'RER':
        return 'RETENÇÕES EMPENHOS EXTRAS - RER';
      case 'REP':
        return 'RETENÇÕES ORÇAMENTÁRIAS CONCOMITANTE - REP';
      case 'RED':
        return 'RETENÇÕES ORÇAMENTÁRIAS BAIXA PAGAMENTO - RED';
      case 'RTD':
        return 'RECEITA TRANSFERÊNCIA DUODÉCIMO - RTD';
      case 'RDD':
        return 'RECEITA TRANSFERÊNCIA DEVOLUCAO DUODÉCIMO - RDD';
      case 'TRS':
        return 'TRANSFERENCIA DE SALDO BANCARIO - TRS';
      case 'TRD':
        return 'TROCA DE RECURSOS DISPONIBILIDADE TRANSFERÊNCIAS - TRD';
      case 'TDP':
        return 'TRANSFERENCIA DUODÉCIMO PREFEITURA - TDP';
      case 'TDC':
        return 'TRANSFERENCIA DUODÉCIMO CÂMARA - TDC';
      case 'TRF':
        return 'TRANSFERENCIA FINANCEIRA - TRF';
      case 'TRN':
        return 'TRANSFERENCIA NORMAL - TRF';
      case 'TER':
        return 'TRANSFERENCIA RETENÇÕES EXTRA ORÇAMENTÁRIAS - TER';
      case 'TIP':
        return 'TRANSFERENCIA - INTERVENIÊNCIA PASSIVA - TIP';
      case 'TIA':
        return 'TRANSFERENCIA - INTERVENIÊNCIA ATIVA - TIA';
      case 'TPP':
        return 'TRANSFERÊNCIAS PARCERIA PUBLICO PRIVADA GARANTIA - TPP';
      case 'TPE':
        return 'TRANSFERÊNCIAS PARCERIA PUBLICO PRIVADA GARANTIA ESTORNO - TPE';
      case 'TPI':
        return 'TPI';
      case 'TRI':
        return 'TRI';
      case 'TRE':
        return 'TRANSFERENCIA DECENDIAL EDUCACAO - TRE';
      case 'TFS':
        return 'TRANSFERENCIA FINANCEIRA SUPERAVIT - TFS';
      case 'TRP':
        return 'TRANSFERÊNCIA RECURSOS PAGAMENTOS - TRP';
      case 'VAR':
        return 'VARIAÇÃO PATRIMONIAL - VAR';
      case 'QCS':
        return 'QUITAÇÃO SUBVENÇÃO - CONCEDIDO - QCS';
      case 'CAC':
        return 'ASSINATURA CONVÊNIO CONTRIBUIÇÃO-CONCESSÃO - CAC';
      case 'ATF':
        return 'ASSINATURA CONVÊNIO TERMO DE FOMENTO - ATF';
      case 'SCA':
        return 'SUPRESSÃO DE AUXÍLIO, CONTRIBUIÇÃO E SUBVENÇÃO CONCEDIDOS - SCA';
      case 'RNP':
        return 'CANCELAMENTO DE RESTOS A PAGAR NÃO PROCESSADO - RNP';
      case 'RNI':
        return 'CANCELAMENTO DE RESTOS A PAGAR NÃO PROCESSADOS POR INSUFICIÊNCIA FINANCEIRA - RNI';
      case 'RNL':
        return 'CANCELAMENTO DE RESTOS A PAGAR NÃO PROCESSADOS LIQUIDADOS - RNL';
      case 'CRP':
        return 'CANCELAMENTO RESTOS A PAGAR PROCESSADO - CRP';
      case 'RPI':
        return 'CANCELAMENTO DE RESTOS A PAGAR PROCESSADO POR INSUFICIÊNCIA FINANCEIRA';
      case 'REV':
        return 'RECEITAS ORÇAMENTÁRIAS - VINCULADAS - REV';
      case 'RSF':
        return 'CRÉDITOS A RECEBER POR REEMBOLSO DE SALÁRIO FAMÍLIA PAGO - RSF';
      case 'CGO':
        return 'PAGAMENTO DE OUTROS CONTRATOS - CGO';
      case 'CGE':
        return 'PAGAMENTO DE OUTROS CONTRATOS NA ÁREA DA EDUCAÇÃO - CGE';
      case 'ACS':
        return 'CONTRATOS DE SEGUROS - ACS';
      case 'AAA':
        return 'ACERTO CONTÁBIL - AAA';
      case 'CGA':
        return 'PAGAMENTO DE OUTROS CONTRATOS NA ÁREA DA ASSISTÊNCIA SOCIAL - CGA';
      case 'CDE':
        return 'RECURSOS DE EXERCÍCIOS ANTERIORES - CDE';
      case 'ROA':
        return 'RECEITA ORÇAMENTÁRIA ANULADA - ROA';
      case 'EDP':
        return 'EMPENHOS COM PRÉ-EMPENHO RECURSO ANTERIOR - EDP';
      case 'CGS':
        return 'PAGAMENTO DE OUTROS CONTRATOS NA ÁREA DA SAÚDE - CGS';
      case 'EAP':
        return 'ANULAÇÃO PRÉ-LIQUIDAÇÃO EXERCÍCIOS ANTERIORES - EAP';
      case 'ARN':
        return 'TRANSFERÊNCIA DE SALDO RESTOS A PAGAR NÃO PROCESSADOS - ARN';
      case 'ARP':
        return 'TRANSFERÊNCIA DE SALDO RESTOS A PAGAR - PROCESSADOS - ARP';
      case 'TS1':
        return 'TRANSFERÊNCIA SUPERÁVITS OU DÉFICITS DO EXERCÍCIO - TS1';
      case 'TS2':
        return 'TRANSFERÊNCIA SUPERÁVITS OU DÉFICITS DO EXERCÍCIO - TS2';
      case 'TS3':
        return 'TRANSFERÊNCIA SUPERÁVITS OU DÉFICITS DO EXERCÍCIO - TS3';
      case 'TS4':
        return 'TRANSFERÊNCIA SUPERÁVITS OU DÉFICITS DO EXERCÍCIO - TS4';
      case 'TS5':
        return 'TRANSFERÊNCIA SUPERÁVITS OU DÉFICITS DO EXERCÍCIO - TS5';
      case 'ELA':
        return 'CRÉDITO EMPENHADO EM LIQUIDAÇÃO - ELA';
      case 'SCC':
        return 'CONVÊNIOS CANCELADOS - SCC';
      case 'LAP':
        return 'CANCELAMENTO DE RESTOS A PAGAR PROCESSADO - LAP';
      case 'FAD':
        return 'FIXAÇÃO ORÇAMENTARIA DA DESPESA - ADICIONAL - FAD';
      case 'FED':
        return 'FIXAÇÃO ORÇAMENTARIA DA DESPESA - EXCLUSÃO - FED';
      case 'PLA':
        return 'ESTORNOS DE EMPENHOS - PLA';
      case 'E14':
        return 'ENCERRAMENTO MÊS 14 - E14';
      case 'EPE':
        return 'ENCERRAMENTO PRÉ-EMPENHO - EPE';
      case 'E13':
        return 'ENCERRAMENTO MÊS 13 - E13';
      case 'CRE':
        return 'CANCELAMENTO DE DOTAÇÕES - CRE';
      case 'TRB':
        return 'TRANSFERÊNCIA BANCÁRIA - TRB';
      case 'RCO':
        return 'RETENÇÕES COMPROMETIDA POR CONSIGNAÇÕES - RCO';
      case 'TAE':
        return 'DEMAIS AJUSTES DE EXERCÍCIOS ANTERIORES - TAE';
      case 'LOA':
        return 'LIQUIDAÇÃO ORÇAMENTÁRIA ANULADA - LOA';
      case 'QCT':
        return 'OUTROS INSTRUMENTOS CONGÊNERES A APROVAR - QCT';
      case 'TAC':
        return 'OUTROS INSTRUMENTOS CONGÊNERES APROVADOS - TAC';
      case 'OMC':
        return 'CONVÊNIOS APROVADOS - OMC';
      case 'AMC':
        return 'OUTROS INSTRUMENTOS CONGÊNERES APROVADOS - AMC';
      case 'STC':
        return 'OUTROS INSTRUMENTOS CONGÊNERES CANCELADOS - STC';
      case 'LXA':
        return 'ANULAÇÃO DE LIQUIDAÇÃO DE RESTO A PAGAR PROCESSADO - LXA';
      case 'QTF':
        return 'OUTROS INSTRUMENTOS CONGÊNERES A APROVAR - QTF';
      case 'RDB':
        return 'RESERVA DE DOTAÇÃO - RDB';
      case 'RCA':
        return 'OUTROS INSTRUMENTOS CONGÊNERES A COMPROVAR - RCA';
      case 'TFA':
        return 'OUTROS INSTRUMENTOS CONGÊNERES APROVADOS - TFA';
      case 'RCC':
        return 'OUTRAS GARANTIAS RECEBIDAS NO PAÍS A EXECUTAR - RCC';
      case 'ELX':
        return 'ANULAÇÃO DE PRE-LIQUIDAÇÕES DE RESTO A PAGAR - ELX';
      case 'ATR':
        return 'ABERTURA E TRANSFERÊNCIA DE RESTOS A PAGAR - ATR';
      default:
        return idx;
    }
  }

  public obterEspecieMeta(idx: string) {
    switch (idx) {
      case 'I':
        return 'INICIAL';
      case 'A':
        return 'ALTERAÇÃO';
      case 'E':
        return 'EXCLUSÃO';
    }
    return '-';
  }

  public obterPrioridade(idx: string) {
    switch (idx) {
      case 'B':
        return 'BAIXA';
      case 'N':
        return 'NORMAL';
      case 'A':
        return 'ALTA';
      case 'U':
        return 'URGENTE';
      default:
        return '-';
    }
  }

  public listaEstados(): Array<any> {
    return [
      { codigo_ibge: 12, uf: 'AC', nome: 'Acre' },
      { codigo_ibge: 27, uf: 'AL', nome: 'Alagoas' },
      { codigo_ibge: 13, uf: 'AM', nome: 'Amazonas' },
      { codigo_ibge: 16, uf: 'AP', nome: 'Amapá' },
      { codigo_ibge: 29, uf: 'BA', nome: 'Bahia' },
      { codigo_ibge: 23, uf: 'CE', nome: 'Ceará' },
      { codigo_ibge: 53, uf: 'DF', nome: 'Distrito Federal' },
      { codigo_ibge: 32, uf: 'ES', nome: 'Espírito Santo' },
      { codigo_ibge: 52, uf: 'GO', nome: 'Goiás' },
      { codigo_ibge: 21, uf: 'MA', nome: 'Maranhão' },
      { codigo_ibge: 31, uf: 'MG', nome: 'Minas Gerais' },
      { codigo_ibge: 50, uf: 'MS', nome: 'Mato Grosso do Sul' },
      { codigo_ibge: 51, uf: 'MT', nome: 'Mato Grosso' },
      { codigo_ibge: 15, uf: 'PA', nome: 'Pará' },
      { codigo_ibge: 25, uf: 'PB', nome: 'Paraíba' },
      { codigo_ibge: 26, uf: 'PE', nome: 'Pernambuco' },
      { codigo_ibge: 22, uf: 'PI', nome: 'Piauí' },
      { codigo_ibge: 41, uf: 'PR', nome: 'Paraná' },
      { codigo_ibge: 33, uf: 'RJ', nome: 'Rio de Janeiro' },
      { codigo_ibge: 24, uf: 'RN', nome: 'Rio Grande do Norte' },
      { codigo_ibge: 11, uf: 'RO', nome: 'Rondônia' },
      { codigo_ibge: 14, uf: 'RR', nome: 'Roraima' },
      { codigo_ibge: 43, uf: 'RS', nome: 'Rio Grande do Sul' },
      { codigo_ibge: 42, uf: 'SC', nome: 'Santa Catarina' },
      { codigo_ibge: 28, uf: 'SE', nome: 'Sergipe' },
      { codigo_ibge: 35, uf: 'SP', nome: 'São Paulo' },
      { codigo_ibge: 17, uf: 'TO', nome: 'Tocantins' }
    ];
  }


  public obterTiposCreditos(idx: string) {
    switch (idx) {
      case '1':
        return 'SUPLEMENTAR';
      case '2':
        return 'ESPECIAL';
      case '3':
        return 'EXTRAORDINÁRIO';
      default:
        return '-';
    }
  }

  public obterTipoEmpenho(idx: string) {
    switch (idx) {
      case 'O':
        return 'ORDINÁRIA';
      case 'E':
        return 'ESTIMATIVA';
      case 'G':
        return 'GLOBAL';
      default:
        return idx;
    }
  }

  public obterListaTiposEmpenhos() {
    return [
      { id: 'O', nome: 'ORDINÁRIA' },
      { id: 'E', nome: 'ESTIMATIVA' },
      { id: 'G', nome: 'GLOBAL' }
    ];
  }

  public obterListaTiposCreditos() {
    return [
      { id: '1', nome: 'SUPLEMENTAR' },
      { id: '2', nome: 'ESPECIAL' },
      { id: '3', nome: 'EXTRAORDINÁRIO' }
    ];
  }

  public obterOcorrenciasCreditos(idx: string) {
    switch (idx) {
      case '1':
        return 'ANULAÇÃO PARCIAL OU TOTAL DE DOTAÇÃO';
      case '2':
        return 'EXCESSO DE ARRECADAÇÃO';
      case '3':
        return 'SUPERÁVIT FINANCEIRO';
      case '4':
        return 'OPERAÇÕES DE CRÉDITO';
      case '5':
        return 'RESERVA DE CONTINGÊNCIA';
      case '6':
        return 'PARAGRAFO 8˚ DO ARTIGO 166 DA CONSTITUIÇÃO FEDERAL';
      case '7':
        return 'REMANEJAMENTO';
      case '8':
        return 'TRANSFERÊNCIAS INTERNAS';
      default:
        return '-';
    }
  }

  public obterListaOcorrenciasCreditos() {
    return [
      { id: '1', nome: 'ANULAÇÃO PARCIAL OU TOTAL DE DOTAÇÃO' },
      { id: '2', nome: 'EXCESSO DE ARRECADAÇÃO' },
      { id: '3', nome: 'SUPERÁVIT FINANCEIRO' },
      { id: '4', nome: 'OPERAÇÕES DE CRÉDITO' },
      { id: '5', nome: 'RESERVA DE CONTINGÊNCIA' },
      { id: '6', nome: 'PARAGRAFO 8˚ DO ARTIGO 166 DA CONSTITUIÇÃO FEDERAL' },
      { id: '7', nome: 'REMANEJAMENTO' },
      { id: '99', nome: 'TRANSFERÊNCIA INTERNA' }
    ];
  }

  public obterListaTiposFichasExtra() {
    return [
      { id: '1', nome: 'RESTOS A PAGAR' },
      { id: '2', nome: 'ANTECIPAÇÃO DE RECEITA' },
      { id: '3', nome: 'CONSIGNAÇÕES' },
      { id: '4', nome: 'RECEITA A CLASSIFICAR' },
      { id: '5', nome: 'CONVÊNIOS' },
      { id: '6', nome: 'TRANSFERÊNCIA DE RECURSOS' }
    ];
  }

  public obterListaContratacao() {
    return [
      { id: '1', nome: 'FORNECIMENTO DE MATERIAL' },
      { id: '2', nome: 'FORNECIMENTO DE SERVIÇOS' },
      { id: '3', nome: 'OBRAS' },
      { id: '4', nome: 'LOCAÇÃO' },
      { id: '5', nome: 'SERVIÇOS DE UTILIDADE PÚBLICA' },
      { id: '6', nome: 'CONTRATO DE GESTÃO' },
      { id: '7', nome: 'TERMO DE PARCERIA' },
      { id: '8', nome: 'PARCERIA PÚBLICO PRIVADA' },
      { id: '9', nome: 'OPERAÇÕES DE CRÉDITO' },
      { id: '10', nome: 'CONSÓRCIOS' },
      { id: '11', nome: 'CONCESSÃO' },
      { id: '12', nome: 'PERMISSÃO' },
      { id: '13', nome: 'FORNECIMENTO MATERIAL E SERVIÇOS' },
      { id: '14', nome: 'OUTROS' },
    ];
  }

  public obterListaTipoContrato() {
    return [
      { id: '1', nome: 'CONCESSÃO' },
      { id: '2', nome: 'PERMISSÃO' },
      { id: '3', nome: 'OUTROS' }
    ];
  }

  public obterListaFinalidade() {
    return [
      { id: '2', nome: 'AUMENTO' },
      { id: '1', nome: 'ESTORNO' },
      { id: '3', nome: 'VIGÊNCIA' },
      { id: '4', nome: 'OUTROS' }
    ];
  }

  public obterFinalidade(idx: number) {
    switch (idx) {
      case 5:
        return 'ASSINATURA';
      case 2:
        return 'AUMENTO';
      case 1:
        return 'ESTORNO';
      case 3:
        return 'VIGÊNCIA';
      case 4:
        return 'QUITAÇÃO';
      case 6:
        return 'APROVAÇÃO';
      case 7:
        return 'PAGAMENTO';
      case 8:
        return 'EDUCAÇÃO CONCESSÃO';
      case 9:
        return 'SAÚDE CONCESSÃO';
      case 10:
        return 'ASSISTÊNCIA CONCESSÃO';
      case 11:
        return 'OUTROS CONCESSÃO';
      case 12:
        return 'EDUCAÇÃO PRESTAÇÃO';
      case 13:
        return 'SAÚDE PRESTAÇÃO';
      case 14:
        return 'ASSISTÊNCIA PRESTAÇÃO';
      case 15:
        return 'OUTROS PRESTAÇÃO';
    }
    return;
  }

  public obterLegislacao() {
    return [
      { id: '1', nome: 'LEI FEDERAL' },
      { id: '2', nome: 'LEI ESTADUAL' },
      { id: '3', nome: 'LEI MUNICIPAL' },
      { id: '4', nome: 'DECRETO FEDERAL' },
      { id: '5', nome: 'DECRETO ESTADUAL' },
      { id: '6', nome: 'DECRETO MUNICIPAL' }
    ];
  }

  public obterListaTipoFornecedores() {
    return [
      { id: '1', nome: 'CNPJ - PESSOA JURÍDICA' },
      { id: '2', nome: 'CPF - PESSOA FÍSICA' },
      { id: '3', nome: 'IDENTIFICAÇÃO ESPECIAL' },
      { id: '4', nome: 'INSCRIÇÃO GENÉRICA-RESTOS A PAGAR-EXERCÍCIOS ANTERIORES' },
      { id: '5', nome: 'INSCRIÇÃO GENÉRICA-SENTENÇAS JUDICIAIS-NATUREZA ALIMENTAR' },
      { id: '6', nome: 'INSCRIÇÃO GENÉRICA-SENTENÇAS JUDICIAIS-OUTROS' },
      { id: '7', nome: 'INSCRIÇÃO GENÉRICA-PRECATÓRIOS-NATUREZA ALIMENTAR' },
      { id: '8', nome: 'INSCRIÇÃO GENÉRICA-PRECATÓRIOS-OUTROS' },
      { id: '9', nome: 'INSCRIÇÃO GENÉRICA-OUTROS' }
    ];
  }

  public obterListaEnquadramentoFornecedores(): { id: FavorecidoEnquadramento, nome: string }[] {
    return [
      { id: 'MEI', nome: 'MEI' },
      { id: 'ME', nome: 'ME' },
      { id: 'EPP', nome: 'EPP' },
      { id: 'EMP_NORMAL', nome: 'EMPRESA NORMAL' },
      { id: 'GRAN_PORTE', nome: 'GRANDE PORTE' },
      { id: 'OUTRA', nome: 'OUTRA' },
      { id: 'S_A', nome: 'S/A' },
      { id: 'PF', nome: 'PESSOA FÍSICA' }
    ];
  }

  public obterListaTipoContasBancarias() {
    return [
      { id: 'M', nome: 'CONTA COMUM/CONTA MOVIMENTO' },
      { id: 'V', nome: 'CONTA VINCULADA' },
      { id: 'A', nome: 'CONTA APLICAÇÃO' },
      { id: 'U', nome: 'CONTA ÚNICA' },
      { id: 'C', nome: 'CAIXA' },
      { id: 'D', nome: 'TRANSFERÊNCIA DUODÉCIMO' },
      { id: 'O', nome: 'TRANSFERÊNCIA ENTRE ORGÃOS' }
    ];
  }

  public obterListaModalidadeRepasses() {
    return [
      { id: 'C', nome: 'CHAMAMENTO' },
      { id: 'D', nome: 'DISPENSA' },
      { id: 'I', nome: 'INEXIGIBILIDADE' },
    ];
  }

  public TipoUnidade() {
    return [
      { id: 'ASSISTENCIA_SOCIAL', nome: 'ASSISTÊNCIA SOCIAL' },
      { id: 'EDUCACAO', nome: 'EDUCAÇÃO' },
      { id: 'SAUDE', nome: 'SAÚDE' },
      { id: 'OUTROS', nome: 'OUTROS' }
    ];
  }

  public obterListaAtoUnidades() {
    return [
      { id: '1', nome: 'LEI' },
      { id: '2', nome: 'DECRETO' },
      { id: '3', nome: 'PORTARIA' },
      { id: '4', nome: 'OUTROS' }
    ];
  }
  public obterListaTiposPpa() {
    return [
      { id: 'I', nome: 'INICIAL' },
      { id: 'A', nome: 'ALTERAÇÃO' },
      { id: 'E', nome: 'EXCLUSÃO' }
    ];
  }

  public obterListaProgramas() {
    return [
      { id: 'I', nome: 'PROGRAMA FINALÍSTICO' },
      { id: 'A', nome: 'PROGRAMA DE APOIO ADMINISTRATIVO' },
      { id: 'E', nome: 'PROGRAMA DE OPERAÇÃO ESPECIAL' }
    ];
  }

  public obterListaTipoLOA() {
    return [
      { id: 'O', nome: 'ORÇAMENTÁRIA' },
      { id: 'S', nome: 'SUPLEMENTAR' },
      { id: 'E', nome: 'EXTRAORDINÁRIA' }
    ];
  }

  public obterListaJulgamentosLicitacao() {
    return [
      { id: '1', nome: 'TÉCNICA E PREÇO' },
      { id: '2', nome: 'MENOR PREÇO' },
      { id: '3', nome: 'MELHOR TÉCNICA OU CONTEÚDO ARTÍSTICO' },
      { id: '4', nome: 'MAIOR LANCE OU OFERTA' },
      { id: '5', nome: 'MAIOR DESCONTO' },
      { id: '6', nome: 'MAIOR OFERTA DE PREÇO' },
      { id: '7', nome: 'MAIOR RETORNO ECONÔMICO' },
      { id: '8', nome: 'MENOR VALOR DA TARIFA DO SERVIÇO PÚBLICO' }
    ];
  }

  public obterListaHomologacaoLicitacao() {
    return [
      { id: '1', nome: 'ADJUDICADA' },
      { id: '2', nome: 'ANULADA' },
      { id: '3', nome: 'DESERTA' },
      { id: '4', nome: 'FRACASSADA' },
      { id: '5', nome: 'HOMOLOGADA PARCIAL' },
      { id: '6', nome: 'HOMOLOGADA' },
      { id: '7', nome: 'HOMOLOGADA REGISTRO DE PREÇO' },
      { id: '8', nome: 'OUTRA' },
      { id: '9', nome: 'REVOGADA' }
    ];
  }

  public obterListaTipoOrgaos() {
    return [
      { id: 'P', nome: 'PREFEITURA MUNICIPAL' },
      { id: 'C', nome: 'CÂMARA MUNICIPAL' },
      { id: 'A', nome: 'AUTARQUIA MUNICIPAL' },
      { id: 'F', nome: 'FUNDAÇÃO MUNICIPAL' },
      { id: 'E', nome: 'EMPRESA MUNICIPAL' },
      { id: 'I', nome: 'INSTITUTO DE PREVIDÊNCIA' }
    ];
  }

  public obterListaSistemas(): { id: Sistemas, nome: string }[] {
    return [
      { id: 'almoxarifado', nome: 'ALMOXARIFADO' },
      { id: 'compras', nome: 'COMPRA' },
      { id: 'contabil', nome: 'CONTABILIDADE' },
      { id: 'controle-interno', nome: 'CONTROLE-INTERNO' },
      { id: 'diario-oficial', nome: 'DIÁRIO OFICIAL' },
      { id: 'fatura', nome: 'FATURA' },
      { id: 'frota', nome: 'FROTA' },
      { id: 'indicador-gestao', nome: 'INDICADOR DE GESTÃO' },
      { id: 'legislativo', nome: 'LEGISLATIVO' },
      { id: 'patrimonio', nome: 'PATRIMÔNIO' },
      { id: 'planejamento', nome: 'PLANEJAMENTO' },
      { id: 'protocolo', nome: 'PROTOCOLO' },
      { id: 'recursos-humanos', nome: 'FOLHA' },
      { id: 'terceiro-setor', nome: 'TERCEIRO SETOR' },
      { id: 'tesouraria', nome: 'TESOURARIA' },
      { id: 'transparencia', nome: 'PORTAL DA TRANSPARÊNCIA' },
      { id: 'licitacao', nome: 'LICITAÇÃO' },
      { id: 'requisicao', nome: 'REQUISIÇÃO' }
    ];
  }

  public obterListaTiposRepasse() {
    return [
      { id: '1', nome: 'AUXÍLIO-CONCESSÃO' },
      { id: '2', nome: 'SUBVENÇÃO-CONCESSÃO' },
      { id: '3', nome: 'CONTRIBUIÇÃO-CONCESSÃO' },
      { id: '4', nome: 'AUXÍLIO-RECEBIDO' },
      { id: '5', nome: 'SUBVENÇÃO-RECEBIDO' },
      { id: '6', nome: 'CONTRIBUIÇÃO-RECEBIDO' },
      { id: '7', nome: 'TERMO DE COLABORAÇÃO' },
      { id: '8', nome: 'TERMO DE FOMENTO' },
      { id: '9', nome: 'OUTROS' },
      { id: '10', nome: 'ACORDO DE COOPERAÇÃO' },
      { id: '11', nome: 'CONTRATO DE GESTÃO' },
      { id: '90', nome: 'TERMO DE PARCERIA' },
      { id: '91', nome: 'OUTROS CONVÊNIOS RECEBIDOS' },
      { id: '92', nome: 'OUTROS - CONVENIOS CONCEDIDOS' }
    ];
  }

  public obterListaSituacaoChamamento() {
    return [
      { nome: 'ABERTO', id: '1' },
      { nome: 'EM ANDAMENTO', id: '2' },
      { nome: 'FINALIZADO', id: '3' },
      { nome: 'CANCELADO', id: '4' }
    ];
  }

  public obterListaNatureza() {
    return [
      { id: '1', nome: '1 - RECURSOS HUMANOS (5)' },
      { id: '2', nome: '2 - RECURSOS HUMANOS (6)' },
      { id: '3', nome: '3 - MEDICAMENTOS' },
      { id: '5', nome: '5 - MATERIAL MÉDICO E HOSPITALAR (*)' },
      { id: '6', nome: '6 - GÊNEROS ALIMENTÍCIOS' },
      { id: '7', nome: '7 - OUTROS MATERIAIS DE CONSUMO' },
      { id: '8', nome: '8 - SERVIÇOS MÉDICOS' },
      { id: '9', nome: '9 - OUTROS SERVIÇOS DE TERCEIROS' },
      { id: '10', nome: '10 - LOCAÇÃO DE IMÓVEIS' },
      { id: '11', nome: '11 - LOCAÇÃO DIVERSAS' },
      { id: '12', nome: '12 - UTILIDADES PÚBLICAS (7)' },
      { id: '13', nome: '13 - COMBUSTÍVEL' },
      { id: '14', nome: '14 - BENS E MATERIAIS PERMANENTES' },
      { id: '15', nome: '15 - OBRAS' },
      { id: '16', nome: '16 - DESPESAS FINANCEIRAS E BANCÁRIAS' },
      { id: '17', nome: '17 - OUTRAS DESPESAS' },
    ];
  }

  // recursos humanos
  public obterEscolaridade() {
    return [
      { nome: '02- Alfabetização', id: '02- Alfabetização' },
      { nome: '03- Ensino Básico', id: '03- Ensino Básico' },
      { nome: '04- Ensino Básico incompleto', id: '04- Ensino Básico incompleto' },
      { nome: '05- Ensino Médio', id: '05- Ensino Médio' },
      { nome: '06- Ensino Técnico', id: '06- Ensino Técnico' },
      { nome: '07- Ensino Superior', id: '07- Ensino Superior' },
      { nome: '08- Especialização', id: '08- Especialização' },
    ];
  }

  public obterAtividade() {
    return [
      { nome: '1- Efetivo', id: '1- Efetivo' },
      { nome: '2- Efetivo em Comissão ', id: '2- Efetivo em Comissão' },
      { nome: '3- Temporário', id: '3- Temporário' },
      { nome: '4- Aposentado', id: '4- Aposentado' },
      { nome: '5- Exclusivamente em Comissão', id: '5- Exclusivamente em Comissão' },
      { nome: '7- Eletivo', id: '7- Eletivo' },
      { nome: '8- Outros', id: '8- Outros' },
      { nome: '9- Outros (não Informado)', id: '8- Outros (não Informa)' },
    ];
  }

  public obterRegimeJuridico() {
    return [
      { nome: '1- CLT', id: '1- CLT' },
      { nome: '2- Estatutário', id: '2- Estatutário' }
    ];
  }

  public obterProvimento() {
    return [
      { nome: '1- Concurso Público', id: '1- Concurso Público' },
      { nome: '2- Tempo Determinado', id: '2- Tempo Determinado' },
      { nome: '3- Livre Provimento', id: '3- Livre Provimento' },
      { nome: '4- Eleição', id: '4- Eleição' },
    ];
  }

  public obterNorma() {
    return [
      { nome: '06- Lei', id: '06- Lei' },
      { nome: '07- Lei complementar', id: '07- Lei complementar' }
    ];
  }

  public listaTipoCalculoFolha() {
    return [
      { id: 'V', nome: 'VALOR' },
      { id: 'P', nome: 'PERCENTUAL' },
      { id: 'H', nome: 'HORA' },
      { id: 'D', nome: 'DIA' }
    ];
  }

  public obterSituacoesLicitacao(): { id: SituacaoLicitacao, nome: string }[] {
    return [
      { id: 'PENDENTE', nome: 'PENDENTE' },
      { id: 'ATIVA', nome: 'ATIVA' }, // 1
      { id: 'ADJUDICADA', nome: 'ADJUDICADA' },
      { id: 'ANULADA', nome: 'ANULADA' }, // 6
      { id: 'SUSPENSA', nome: 'SUSPENSA' }, // 4
      { id: 'DESERTA', nome: 'DESERTA' }, // 2
      { id: 'FRACASSADA', nome: 'FRACASSADA' }, // 3
      { id: 'HOMOLOGADA', nome: 'HOMOLOGADA' }, // 7
      { id: 'HOMOLOGADA – PARCIAL', nome: 'HOMOLOGADA – PARCIAL' },
      { id: 'REVOGADA', nome: 'REVOGADA' }, // 5
      { id: 'REMOVIDO', nome: 'REMOVIDO' },
      { id: 'OUTRA', nome: 'OUTRA' },
    ];
  }

  public obterFormatosLicitacao(): { id: FormatoLicitacao, nome: string }[] {
    return [
      { id: 'REGISTRO_PRECO', nome: 'Registro de Preço' },
      { id: 'RESERVA_VALOR', nome: 'Reserva de Valor' },
    ];
  }

  public obterCriteriosJulgamento(): { id: CriterioJulgamento, nome: string }[] {
    return [
      { id: 'MENOR_PRECO', nome: 'Menor Preço' },
      { id: 'MAIOR_PRECO', nome: 'Maior Preço' },
      { id: 'MENOR_DESCONTO', nome: 'Menor Desconto' },
      { id: 'MAIOR_DESCONTO', nome: 'Maior Desconto' },
    ];
  }

  public obterCriteriosJulgamentoValor(): { id: CriterioJulgamentoValor, nome: string }[] {
    return [
      { id: 'VALOR_UNITARIO', nome: 'Valor Unitário' },
      { id: 'VALOR_TOTAL', nome: 'Valor Total' },
    ];
  }

  public obterTiposContrato(): { id: TipoContrato, nome: string }[] {
    return [
      { id: 'CONCESSAO', nome: 'Concessão' },
      { id: 'PERMISSAO', nome: 'Permissão' },
      { id: 'OUTROS', nome: 'Outros' },
    ];
  }

  public obterAtosPotenciaisPassivos(): { id: string, nome: string }[] {
    return [
      { id: '0', nome: 'Obrigações contratuais' },
      { id: '1', nome: 'Riscos não Provisionados' },
      { id: '2', nome: 'Garantias Concedidas' },
      { id: '3', nome: 'Outros Passivos Contingentes' }
    ]
  }

  public obterTiposPregao(): { id: TipoPregao, nome: string }[] {
    return [
      { id: 'PROCEDIMENTO_COMUM', nome: 'Reserva de Valor' },
      { id: 'REGISTRO_PRECO', nome: 'Registro de Preço' },
    ];
  }

  public obterCriteriosDesempate(): { id: CriterioDesempatePregao, nome: string }[] {
    return [
      { id: 'CREDENCIAMENTO', nome: 'Ordem de Credenciamento' },
      { id: 'SORTEIO', nome: 'Sorteio' },
    ];
  }

  public obterSituacoesProponente(): { id: SituacaoProponente, nome: string, style: string }[] {
    return [
      { id: 'HABILITADO', nome: 'Habilitado', style: 'success' },
      { id: 'INABILITADO', nome: 'Inabilitado', style: 'danger' },
      { id: 'DESCLASSIFICADO', nome: 'Desclassificado', style: 'danger' },
    ];
  }

  public obterSituacoesProposta(): { id: SituacaoProposta, nome: string, style: string, ordem: number }[] {
    return [
      { id: 'VENCEDOR', nome: 'Vencedor', style: 'success', ordem: 0 },
      { id: 'EMPATADO', nome: 'Empatado', style: 'danger', ordem: 0 },
      { id: 'NAO_JULGADO', nome: 'Não Julgado', style: 'secondary', ordem: 0 },

      { id: 'DESISTIDO', nome: 'Desistiu', style: 'danger', ordem: 1 },
      { id: 'DESISTIDO_EPPME', nome: 'Não exerceu Dir. Preferência', style: 'danger', ordem: 1 },
      { id: 'INEXEQUIVEL', nome: 'Inexequível', style: 'danger', ordem: 1 },
      { id: 'EM_NEGOCIACAO', nome: 'Em Negociação', style: 'primary', ordem: 1 },

      { id: 'CLASSIFICADO', nome: 'Classificado', style: 'primary', ordem: 1 },

      { id: 'DECLINADO', nome: 'Declinou', style: 'danger', ordem: 2 },
      { id: 'NAO_CLASSIFICADO', nome: 'Não Classificado', style: 'secondary', ordem: 3 },
      { id: 'FRACASSADO', nome: 'Fracassado', style: 'danger', ordem: 4 },
      { id: 'DESCLASSIFICADO', nome: 'Desclassificado', style: 'danger', ordem: 5 },
      { id: 'INABILITADO', nome: 'Inabilitado', style: 'danger', ordem: 6 },
      { id: 'SUSPENSO', nome: 'Suspenso', style: 'danger', ordem: 7 },
    ];
  }

  public obterTiposComissao(): TipoComissao[] {
    return [
      '1 - PERMANENTE', '2 - ESPECIAL', '3 - SERVIDOR DESIGNADO'
    ];
  }

  public obterAtribuicoesComissaoMembros(): ComissaoMembroAtribuicao[] {
    return [
      '1 - PRESIDENTE', '2 - MEMBRO', '3 - PREGOEIRO', '4 - EQUIPE DE APOIO', '5 - SERVIDOR DESIGNADO',
      '6 - LEILOEIRO', '7 - SECRETÁRIO', '8 - AUTORIDADE PREGÃO', '9 - RESPONSÁVEL', '10 - AUTORIDADE CONVITE',
      '11 - AGENTE DE CONTRATAÇÃO', '12 - COMISSÃO DE CONTRATAÇÃO'
    ];
  }

  public obterNaturezasCargoComissaoMembros(): ComissaoMembroNaturezaCargo[] {
    return [
      '1 - EFETIVO', '2 - COMISSIONADO', '3 - AGENTE POLÍTICO', '4 - EMPREGADO TEMPORÁRIO', '5 - EMPREGADO PÚBLICO', '6 - OUTROS'
    ];
  }

  public obterTiposOcorrenciaLicitacao(): { id: TipoOcorrenciaLicitacao, nome: string }[] {
    return [
      { id: 'ANULACAO', nome: 'Anulação' },
      { id: 'HABILITACAO', nome: 'Habilitação' },
      { id: 'IMPUGNACAO', nome: 'Impugnação' },
      { id: 'MEMORIAL', nome: 'Memorial' },
      { id: 'OUTRO', nome: 'Outro' },
      { id: 'PARECER', nome: 'Parecer da Comissão' },
      { id: 'RECURSO', nome: 'Recurso' },
      { id: 'REVOGACAO', nome: 'Revogação' },
    ];
  }

  public obterTiposOcorrenciaVeiculo(): { id: TipoOcorrenciaVeiculo, nome: string }[] {
    return [
      { id: 'PLACA', nome: 'Placa' },
      { id: 'OUTRO', nome: 'Outro' },
    ];
  }

  public obterTiposOcorrenciaContrato(): { id: TipoOcorrenciaContrato, nome: string }[] {
    return [
      { id: 'PENALIDADE', nome: 'Penalidade' },
      { id: 'REAJUSTE', nome: 'Reajuste' },
      { id: 'ESTORNO', nome: 'Reajuste estorno' },
      { id: 'ADITAMENTO', nome: 'Aditamento' },
      { id: 'OUTRO', nome: 'Outro' }
    ];
  }

  public obterAquisicaoVeiculo(): { id: TipoAquisicaoVeiculo, nome: string }[] {
    return [
      { id: 'CEDIDO', nome: 'Cedido' },
      { id: 'LOCADO', nome: 'Locado' },
      { id: 'PROPRIO', nome: 'Próprio' },
      { id: 'TERCEIRIZADO', nome: 'Terceirizado' },
    ];
  }

  public obterEspeciesAlmoxarifado(tipo?: 'E' | 'S'): { id: EspecieAlmoxarifado, nome: string }[] {
    const list: { id: EspecieAlmoxarifado, nome: string }[] = [
      { id: 'C', nome: 'COMPRA' },
      { id: 'O', nome: 'DOAÇÃO' },
      { id: 'V', nome: 'DEVOLUÇÃO' },
      { id: 'I', nome: 'INVENTÁRIO' },
      { id: 'B', nome: 'BAIXA' },
      { id: 'T', nome: 'TRANSFERÊNCIA' },
      { id: 'E', nome: 'ESTORNO' },
      { id: 'F', nome: 'FRACIONAMENTO' },
      { id: 'R', nome: 'REQUISIÇÃO' },
      { id: 'A', nome: 'REPOSIÇÃO' }
    ];

    if (tipo === 'E') {
      list.splice(list.findIndex((itm) => { return itm.id === 'B' }), 1);
    } else if (tipo === 'S') {
      list.splice(list.findIndex((itm) => { return itm.id === 'C' }), 1);
    }
    list.splice(list.findIndex((itm) => { return itm.id === 'E' }), 1); // estorno não é permitida como opção

    return list;
  }

  public tituloEspecieAlmoxarifado(especie: EspecieAlmoxarifado) {
    switch (especie) {
      case 'B':
        return 'BAIXA';
      case 'C':
        return 'COMPRA';
      case 'I':
        return 'INVENTÁRIO';
      case 'O':
        return 'DOAÇÃO';
      case 'V':
        return 'DEVOLUÇÃO';
      case 'T':
        return 'TRANSFERÊNCIA';
      case 'E':
        return 'ESTORNO';
      case 'F':
        return 'FRACIONAMENTO';
      case 'R':
        return 'REQUISIÇÃO';
      case 'A':
        return 'REPOSIÇÃO';
    }
  }

  public corEspecieAlmoxarifado(especie: EspecieAlmoxarifado) {
    switch (especie) {
      case 'B':
        return 'secondary';
      case 'C':
        return 'primary';
      case 'I':
        return 'dark';
      case 'O':
        return 'info';
      case 'V':
        return 'warning';
      case 'T':
        return 'light';
      case 'E':
        return 'danger';
      case 'F':
        return 'warning';
      case 'A':
        return 'light';
    }
  }

  public tituloEspecieMovimentoEstoque(especie: string) {
    switch (especie) {
      case 'C':
        return 'COMPRA';
      case 'O':
        return 'DOAÇÃO';
      case 'V':
        return 'DEVOLUÇÃO';
      case 'I':
        return 'INVENTÁRIO';
      case 'B':
        return 'BAIXA';
      case 'T':
        return 'TRANSFERÊNCIA';
      case 'E':
        return 'ESTORNO';
      case 'F':
        return 'FRACIONAMENTO';
      case 'R':
        return 'REQUISIÇÃO';
      case 'A':
        return 'REPOSIÇÃO';
    }
  };

  public tituloEspecieMovimentoEstoqueFichaEstoque(especie: string) {
    switch (especie) {
      case 'C':
        return 'COMPRA';
      case 'O':
        return 'DOAÇÃO';
      case 'V':
        return 'DEVOLUÇÃO';
      case 'I':
        return 'INVENTÁRIO';
      case 'B':
        return 'BAIXA';
      case 'T':
        return 'TRANSFERÊNCIA';
      case 'E':
        return 'ESTORNO';
      case 'F':
        return 'FRAC.';
      case 'R':
        return 'REQUISIÇÃO';
      case 'A':
        return 'REPOSIÇÃO';
    }
  };


  public listarSituacaoDocumentoTerceiroSetor() {
    return [
      { nome: 'N', id: 'NÃO ENVIADO' },
      { nome: 'E', id: 'ENVIADO E AGUARDANDO VALIDAÇÃO' },
      { nome: 'A', id: 'APROVADO' }
    ];
  }

  public obterSituacaoDocumentoTerceiroSetor(idx: string) {
    switch (idx) {
      case 'N':
        return 'NÃO ENVIADO';
      case 'E':
        return 'ENVIADO E AGUARDANDO VALIDAÇÃO';
      case 'A':
        return 'APROVADO';
      case 'R':
        return 'REJEITADO';
      case 'D':
        return 'ADEQUAÇÃO';
    }
    return '-';
  }

  public obterStatusPatrimonio(): { id: string, nome: string, style: string }[] {
    return [
      { id: 'PENDENTE', nome: 'Pendente', style: 'secondary' },
      { id: 'PARCIAL', nome: 'Parcial', style: 'info' },
      { id: 'ACEITO', nome: 'Aceito', style: 'success' },
      { id: 'RECUSADO', nome: 'Recusado', style: 'danger' },
      { id: 'ACEITO_PARCIAL', nome: 'Aceito parcialmente', style: 'info' },
      { id: 'EXCLUIDO', nome: 'Excluído', style: 'danger' },
      { id: 'EMPRESTADO', nome: 'Emprestado', style: 'primary' },
      { id: 'EMPRESTADO_PARCIAL', nome: 'Emprestado Parcial', style: 'info' },
      { id: 'DEVOLVIDO', nome: 'Devolvido', style: 'success' },
      { id: 'DEVOLVIDO_PARCIAL', nome: 'Devolvido Parcial', style: 'info' },
      { id: 'NAO_DEVOLVIDO', nome: 'Não Devolvido', style: 'danger' },
      { id: 'EM ANDAMENTO', nome: 'Em Andamento', style: 'primary' },
      { id: 'CONCLUIDO', nome: 'Concluído', style: 'success' },
    ];
  }

  public traduzirSistema(sistema: Sistemas): string {
    switch (sistema) {
      case 'controle-interno':
        return 'Controle Interno';
      case 'diario-oficial':
        return 'Diário Oficial';
      case 'indicador-gestao':
        return 'Indicador de Gestão';
      case 'protocolo-cidadao':
        return 'Protocolo (cidadão)';
      case 'recursos-humanos':
        return 'Recursos Humanos';
      case 'terceiro-setor':
        return 'Terceiro Setor';
      case 'transparencia':
        return 'Transparência Pública';
      case 'site':
        return 'Site Institucional';
      case 'licitacao':
        return 'Licitação';
      case 'comum':
        return 'Geral';
      case 'requisicao':
        return 'Requisição';
      default:
        return sistema.replace(/\w\S*/g, (txt: string) => {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }
  }

  /// status de requisição almoxarifado
  public statusRequisicao = () => [
    StatusRequisicao.AGUARDANDO,
    StatusRequisicao.AGUARDANDO_FORNECEDOR,
    StatusRequisicao.ACEITA,
    StatusRequisicao.ACEITA_PARCIALMENTO,
    StatusRequisicao.ESTORNO,
    StatusRequisicao.RECUSADO
  ];


  public tituloStatusRequisicao(status: StatusRequisicao) {
    switch (status) {
      case StatusRequisicao.AGUARDANDO:
        return 'Aguardando';
      case StatusRequisicao.AGUARDANDO_FORNECEDOR:
        return 'Aguardando fornecedor';
      case StatusRequisicao.ACEITA:
        return 'Aceito';
      case StatusRequisicao.ACEITA_PARCIALMENTO:
        return 'Aceito parcialmente';
      case StatusRequisicao.ESTORNO:
        return 'Estornado';
      case StatusRequisicao.RECUSADO:
        return 'Recusado';
    }
  };

  public corStatusRequisicao(status: StatusRequisicao) {
    switch (status) {
      case StatusRequisicao.AGUARDANDO:
        return 'warning';
      case StatusRequisicao.AGUARDANDO_FORNECEDOR:
        return 'info';
      case StatusRequisicao.ACEITA:
        return 'success';
      case StatusRequisicao.ACEITA_PARCIALMENTO:
        return 'primary';
      case StatusRequisicao.ESTORNO:
        return 'secondary';
      case StatusRequisicao.RECUSADO:
        return 'danger';
    }
  }

  public iconStatusRequisicao(status: StatusRequisicao) {
    switch (status) {
      case StatusRequisicao.AGUARDANDO:
        return 'fa fa-clock-o';
      case StatusRequisicao.AGUARDANDO_FORNECEDOR:
        return 'fa fa-clock-o';
      case StatusRequisicao.ACEITA:
        return 'fa fa-check';
      case StatusRequisicao.ACEITA_PARCIALMENTO:
        return 'fa fa-percent';
      case StatusRequisicao.ESTORNO:
        return 'fa fa-undo';
      case StatusRequisicao.RECUSADO:
        return 'fa fa-times';
    }
  }

  /// tipo de favorecido
  public tiposFavorecido = () => [
    TipoFavorecido.FORNECEDOR,
    TipoFavorecido.CREDOR
  ];

  public tituloTipoFavorecido(tipo: TipoFavorecido) {
    switch (tipo) {
      case TipoFavorecido.FORNECEDOR:
        return 'FORNECEDOR';
      case TipoFavorecido.CREDOR:
        return 'CREDOR';
    }
  };

  public corTipoFavorecido(tipo: TipoFavorecido) {
    switch (tipo) {
      case TipoFavorecido.FORNECEDOR:
        return 'primary';
      case TipoFavorecido.CREDOR:
        return 'danger';
    }
  }


  /// tipo de contatos favorecido
  public tiposContato = () => [
    TipoContato.TELEFONE,
    TipoContato.EMAIL,
    TipoContato.SITE,
    TipoContato.OUTROS
  ];

  public tituloTipoContato(tipo: TipoContato) {
    switch (tipo) {
      case TipoContato.TELEFONE:
        return 'TELEFONE';
      case TipoContato.EMAIL:
        return 'E-MAIL';
      case TipoContato.SITE:
        return 'SITE';
      case TipoContato.OUTROS:
        return 'OUTROS';
    }
  };

  public corTipoContato(tipo: TipoContato) {
    switch (tipo) {
      case TipoContato.TELEFONE:
        return 'success';
      case TipoContato.EMAIL:
        return 'danger';
      case TipoContato.SITE:
        return 'warning';
      case TipoContato.OUTROS:
        return 'primary';
    }
  }

  /// prioridade de rcms
  public prioridadesRCMS = (): PrioridadeRCMS[] => [
    'NORMAL', 'MEDIA', 'URGENTE'
  ];

  public tituloPrioridadeRCMS(prioridade: PrioridadeRCMS) {
    switch (prioridade) {
      case 'NORMAL':
        return 'NORMAL';
      case 'MEDIA':
        return 'MÉDIA';
      case 'URGENTE':
        return 'URGENTE';
    }
  };

  public obterListaEventos() {
    return [
      { id: 'ABE', nome: 'ABERTURA DE EXERCICIO - ABE' },
      { id: 'FRP', nome: 'ENCERRAMENTO RESTOS A PAGAR PROCESSADOS - FRP' },
      { id: 'FRN', nome: 'ENCERRAMENTO RESTOS A PAGAR NÃO PROCESSADOS - FRN' },
      { id: 'AB1', nome: 'ABERTURA DE EXERCICIO CONTAS BANCÁRIAS - AB1' },
      { id: 'AB2', nome: 'ABERTURA DE EXERCICIO FICHAS EXTRA ORÇAMENTÁRIAS - AB2' },
      { id: 'ALG', nome: 'ALIENAÇÃO DE BENS - GANHO - ALG' },
      { id: 'ALP', nome: 'ALIENAÇÃO DE BENS - PERDA - ALP' },
      { id: 'EOA', nome: 'ANULAÇÃO DE EMPENHOS ORÇAMENTÁRIOS - EOA' },
      { id: 'LEA', nome: 'ANULAÇÃO DE LIQUIDAÇÃO EMPENHOS - LEA' },
      { id: 'PGA', nome: 'ANULAÇÃO DE PAGAMENTOS ORÇAMENTÁRIOS - PGA' },
      { id: 'ADI', nome: 'ADIANTAMENTOS CONCEDIDOS - ADI' },
      { id: 'ADD', nome: 'ADIANTAMENTOS DEVOLVIDOS - ADD' },
      { id: 'AAD', nome: 'ADIANTAMENTOS APROPRIAÇÃO DE DESPESA - AAD' },
      { id: 'ADU', nome: 'ADIANTAMENTOS UTILIZADOS - ADU' },
      { id: 'RPE', nome: 'APLICAÇÃO EDUCAÇÃO - RPE' },
      { id: 'ADA', nome: 'ARRECADAÇÃO DIVIDA ATIVA TRIBUTARIAS - CONCOMITANTE - ADA' },
      { id: 'ADN', nome: 'ARRECADAÇÃO DIVIDA ATIVA NAO TRIBUTARIAS - CONCOMITANTE - ADN' },
      { id: 'ACA', nome: 'ADIANTAMENTO CONCEDIDO A APROVAR - ACA' },
      { id: 'CCE', nome: 'INADIMPLÊNCIA EFETIVA AUX/SUB/CONT/CONV CONCEDIDO - CCE' },
      { id: 'CCF', nome: 'INADIMPLÊNCIA CONCLUIDO AUX/SUB/CONT/CONV CONCEDIDO - CCF' },
      { id: 'CCC', nome: 'CANCELADO AUX/SUB/CONT/CONV CONCEDIDO - CCC' },
      { id: 'CCH', nome: 'HOMOLOGADO AUX/SUB/CONT/CONV CONCEDIDO - CCH' },
      { id: 'CAO', nome: 'ASSINATURA CONTRATOS OUTROS - CAO' },
      { id: 'CAA', nome: 'ASSINATURA CONTRATOS DE ALUGUEL - CAA' },
      { id: 'CAE', nome: 'ASSINATURA CONTRATOS DE EMPRESTIMOS CONCEDIDOS - CAE' },
      { id: 'CAB', nome: 'ASSINATURA CONTRATOS DE FORNECIMENTO DE BENS - CAB' },
      { id: 'CAS', nome: 'ASSINATURA CONTRATOS DE FORNECIMENTO DE SERVIÇO - CAS' },
      { id: 'CAG', nome: 'ASSINATURA CONTRATOS DE GESTÃO - CAG' },
      { id: 'CAP', nome: 'ASSINATURA CONTRATOS DE PARCELAMENTO - CAP' },
      { id: 'PPC', nome: 'ASSINATURA CONTRATOS - OBRIGAÇÕES CONTRATUAIS - PPC' },
      { id: 'PPR', nome: 'ASSINATURA CONTRATOS - RISCOS NÃO PROVISIONADOS - PPR' },
      { id: 'PPG', nome: 'ASSINATURA CONTRATOS - GARANTIAS CONCEDIDAS - PPG' },
      { id: 'PPO', nome: 'ASSINATURA CONTRATOS - OUTROS PASSIVOS CONTINGENTES - PPO' },
      { id: 'ASP', nome: 'ASSINATURA CONTRATOS PARCERIA PÚBLICO PRIVADA - ASP' },
      { id: 'APC', nome: 'CONTRATOS ASSINATURA PERMISSÃO E CONCESSÃO - APC' },
      { id: 'CAM', nome: 'ASSINATURA CONTRATOS DE SERVIÇOS E MATERIAIS - CAM' },
      { id: 'AAC', nome: 'ASSINATURA CONVÊNIO AUXÍLIO-CONCESSÃO - AAC' }, // tipo convneio = 1
      { id: 'CAC', nome: 'ASSINATURA CONVÊNIO CONTRIBUIÇÃO-CONCESSÃO - CAC' }, // tipo convenio = 3
      { id: 'ATF', nome: 'ASSINATURA CONVÊNIO TERMO DE FOMENTO - ATF' }, // tipo convenio = ?

      { id: 'SAC', nome: 'ASSINATURA CONVÊNIO SUBVENÇÃO-CONCESSÃO - SAC' }, // tipo convneio = 2
      { id: 'ARE', nome: 'ASSINATURA CONVÊNIO AUXÍLIO-RECEBIDO ESTADO - ARE' }, // tipo convenio = 4
      { id: 'AAU', nome: 'ASSINATURA CONVÊNIO AUXÍLIO-RECEBIDO UNIÃO - AAU' }, // tipo convenio = 4

      { id: 'AAE', nome: 'ASSINATURA CONVÊNIO SUBVENÇÃO-RECEBIDO ESTADO - AAE' }, // tipo convenio = 5
      { id: 'SRU', nome: 'ASSINATURA CONVÊNIO SUBVENÇÃO-RECEBIDO UNIÃO - SRU' }, // tipo convenio = 5

      { id: 'SRE', nome: 'ASSINATURA CONVÊNIO CONTRIBUIÇÃO-RECEBIDO ESTADO - SRE' }, // tipo convenio = 6
      { id: 'CAU', nome: 'ASSINATURA CONVÊNIO CONTRIBUIÇÃO-RECEBIDO UNIÃO - CAU' }, // tipo convenio = 6

      { id: 'ACT', nome: 'ASSINATURA CONVÊNIO CONCEDIDOS OUTROS - ACT' }, // tipo convenio = 7

      { id: 'AFA', nome: 'ASSINATURA CONVÊNIO TERMO DE COLABORAÇÃO - AFA' }, // tipo convenio = 8

      { id: 'OCC', nome: 'ASSINATURA OUTROS - CONVENIOS CONCEDIDOS OCC' }, // tipo convenio = 9

      { id: 'ACO', nome: 'ASSINATURA CONVÊNIO ACORDO DE COOPERAÇÃO - ACO' }, // tipo convenio = 10
      { id: 'ACG', nome: 'ASSINATURA CONVÊNIO CONTRATO DE GESTÃO - ACG' }, // tipo convenio = 11
      { id: 'ATP', nome: 'ASSINATURA CONVÊNIO TERMO DE PARCERIA ATP' }, // tipo convenio = 12

      { id: 'ACE', nome: 'ASSINATURA CONVÊNIO RECEBIDOS OUTROS ESTADO - ACE' }, // tipo convenio = 91
      { id: 'OCR', nome: 'ASSINATURA CONVÊNIO RECEBIDOS OUTROS UNIÃO - OCR' }, // tipo convenio = 91


      { id: 'LCO', nome: 'BAIXA ACORDO DE COOPERAÇÃO - LCO' },
      { id: 'CTA', nome: 'BAIXA CONTRATOS DE ALUGUÉIS - CTA' },
      { id: 'CTE', nome: 'BAIXA CONTRATOS DE EMPRESTIMOS CONCEDIDOS - CTE' },
      { id: 'CTB', nome: 'BAIXA CONTRATOS DE FORNECIMENTO DE BENS - CTB' },
      { id: 'CTS', nome: 'BAIXA CONTRATOS DE FORNECIMENTO DE SERVIÇOS - CTS' },
      { id: 'CTP', nome: 'BAIXA CONTRATOS DE PARCELAMENTOS - CTP' },
      { id: 'LCG', nome: 'BAIXA CONTRATOS DE GESTÃO - LCG' },
      { id: 'CSS', nome: 'BAIXA CONTRATOS DE SEGUROS - CSS' },
      { id: 'CTO', nome: 'BAIXA CONTRATOS OUTROS - CTO' },
      { id: 'BSP', nome: 'BAIXA CONTRATO PARCERIA PÚBLICO PRIVADA - BSP' },
      { id: 'BPC', nome: 'BAIXA CONTRATO PERMISSÃO E CONCESSÃO - BPC' },
      { id: 'BCP', nome: 'BAIXA CONTRATOS - OBRIGAÇÕES CONTRATUAIS - BCP' },
      { id: 'BPR', nome: 'BAIXA CONTRATOS - RISCOS NÃO PROVISIONADOS - BPR' },
      { id: 'BPG', nome: 'BAIXA CONTRATOS - GARANTIAS CONCEDIDAS - BPG' },
      { id: 'BPO', nome: 'BAIXA CONTRATOS - OUTROS PASSIVOS CONTINGENTES - BPO' },

      { id: 'COV', nome: 'BAIXA CONVENIOS AUXILIOS E SUBVENCOES - COV' },
      { id: 'LTF', nome: 'BAIXA TERMO DE FOMENTO - LTF' },
      { id: 'LCT', nome: 'BAIXA TERMO DE COLABORAÇÃO - LCT' },
      { id: 'COC', nome: 'BAIXA OUTROS CONVÊNIOS CONCEDIDOS - COC' },

      { id: 'BRE', nome: 'BAIXA RETENÇÕES EXTRA ORÇAMENTÁRIAS - BRE' },
      { id: 'BCE', nome: 'BAIXA CONSIGNAÇÕES - BCE' },
      { id: 'SCA', nome: 'SUPRESSÃO DE AUXÍLIOS, CONTRIBUIÇÃO E SUBVENÇÕES CONCEDIDOS - SCA' },

      { id: 'CGS', nome: ' CONTRATOS DE GESTÃO EM SAÚDE - CGS' },
      { id: 'CGE', nome: ' CONTRATOS DE GESTÃO EM EDUCAÇÃO - CGE' },
      { id: 'CGA', nome: ' CONTRATOS DE GESTÃO EM ASSISTÊNCIA SOCIAL - CGA' },
      { id: 'CGO', nome: ' CONTRATOS DE GESTÃO EM OUTRAS ÁREAS DE ATUAÇÃO - CGO' },

      { id: 'CR1', nome: 'CRÉDITO ADICIONAL SUPLEMENTAR - CR1' },
      { id: 'CR2', nome: 'CRÉDITO ADICIONAL EXCESSO DE ARRECADAÇÃO - CR2' },
      { id: 'CR3', nome: 'CRÉDITO ADICIONAL SUPERÁVIT FINANCEIRO - CR3' },
      { id: 'CR4', nome: 'CRÉDITO ADICIONAL OPERAÇÕES DE CRÉDITO - CR4' },
      { id: 'CR5', nome: 'CRÉDITO ADICIONAL RESERVA DE CONTINGÊNCIA - CR5' },
      { id: 'CR6', nome: 'CRÉDITO ADICIONAL PARAGRAFO 8˚ DO ARTIGO 166 - CR6' },
      { id: 'CR7', nome: 'CRÉDITO ADICIONAL REMANEJAMENTO - CR7' },
      { id: 'CR8', nome: 'CRÉDITO ADICIONAL SUPLEMENTAR - CR8' },
      { id: 'CR9', nome: 'CRÉDITO ESPECIAL COM EXCESSO DE ARRECADAÇÃO - CR9' },
      { id: 'CR10', nome: 'CRÉDITO ESPECIAL SUPERÁVIT FINANCEIRO - CR10' },
      { id: 'CR11', nome: 'CRÉDITO ESPECIAL OPERAÇÕES DE CRÉDITO - CR11' },
      { id: 'CR12', nome: 'CRÉDITO ESPECIAL RESERVA DE CONTINGÊNCIA - CR12' },
      { id: 'CR13', nome: 'CRÉDITO ESPECIAL PARAGRAFO 8˚ DO ARTIGO 166 - CR13' },
      { id: 'CR14', nome: 'CRÉDITO ESPECIAL REMANEJAMENTO - CR14' },
      { id: 'CR15', nome: 'CRÉDITO EXTRAORDINÁRIO SUPLEMENTAR - CR15' },
      { id: 'CR16', nome: 'CRÉDITO EXTRAORDINÁRIO COM EXCESSO DE ARRECADAÇÃO - CR16' },
      { id: 'CR17', nome: 'CRÉDITO EXTRAORDINÁRIO SUPERÁVIT FINANCEIRO - CR17' },
      { id: 'CR18', nome: 'CRÉDITO EXTRAORDINÁRIO OPERAÇÕES DE CRÉDITO - CR18' },
      { id: 'CR19', nome: 'CRÉDITO EXTRAORDINÁRIO RESERVA DE CONTINGÊNCIA - CR19' },
      { id: 'CR20', nome: 'CRÉDITO EXTRAORDINÁRIO PARAGRAFO 8˚ DO ARTIGO 166 - CR20' },
      { id: 'CR21', nome: 'CRÉDITO EXTRAORDINÁRIO REMANEJAMENTO - CR21' },
      { id: 'CR90', nome: 'CRÉDITO ADICIONAIS SUPLEMENTAR CANCELAMENTO DOTAÇÃO - CR90' },
      { id: 'CR91', nome: 'CRÉDITOS ADICIONAIS ESPECIAL CANCELAMENTO DOTAÇÃO - CR91' },

      { id: 'CAD', nome: 'CONTROLE DE ADIANTAMENTO - 13 mês - CAD' },
      { id: 'ECC', nome: 'ENCERRAMENTO DAS CONTAS DE CONTROLES DEVEDORES E CREDORES - 14 mês - ECC' },
      { id: 'RPP', nome: 'RESERVA DE DOTAÇÃO ORÇAMENTÁRIA REFORÇO - RPP' },
      { id: 'ARA', nome: 'AJUSTE DE REC AUX/CONT/SUB - RECEBIDO ANT 2014 - ARA' },
      { id: 'ARC', nome: 'AJUSTE DE REC DE CONVENIO ANT 2014 - ARC' },

      { id: 'IPO', nome: 'IPC 011 - CONCOMITANTE À LIQUIDAÇÃO - RETENÇÃO ORÇAMENTÁRIA - IPO' },
      { id: 'IPR', nome: 'PAGAMENTO DE RETENÇÃO DE LIQUIDAÇÃO DE RP NÃO PROCESSADOS - IPC 011- IPR' },
      { id: 'IPE', nome: 'IPC 011 - CONCOMITANTE À LIQUIDAÇÃO - RETENÇÃO EXTRA- IPE' },
      { id: 'PER', nome: 'RETENÇÃO EXTRA - LIQUIDAÇÃO DE RESTOS COM PRÉ LIQUIDAÇÃO - IPC 011 - PER' },
      { id: 'PDO', nome: 'PAGAMENTO ORÇAMENTO DE DISPONIBILIDADE DO EXERCÍCIO - PDO' },
      { id: 'PDR', nome: 'PAGAMENTO ORÇAMENTO DE DISPONIBILIDADE DO EXERCÍCIO ANTERIOR - PDR' },
      { id: 'PRI', nome: 'PREVISÃO INICIAL DE RESTITUIÇÃO DE RECEITA - PRI' },
      { id: 'IPC', nome: 'RETENÇÃO ORÇAMENTÁRIA EMPENHO EXTRA SEM ORIGEM - IPC' },
      { id: 'BLQ', nome: 'BLOQUEIO JUDICIAL - BLQ' },
      { id: 'ARR', nome: 'AJUSTE RECEITA EXTRA PROVENIENTE DE RETENÇÃO - ARR' },
      { id: 'APD', nome: 'AJUSTE PREVISÃO INICIAL DA DESPESA - APD' },

      { id: 'DDC', nome: 'DEVOLUÇÃO DEPÓSITOS E CAUÇÕES - DDC' },
      { id: 'DRP', nome: 'DEVOLUÇÃO DE RECURSOS PREFEITURA - DRP' },
      { id: 'DRC', nome: 'DEVOLUÇÃO DE RECURSOS CAMARA - DRC' },
      { id: 'DRE', nome: 'DEVOLUÇÃO DE RECURSOS CAMARA EXERCÍCIO ANTERIOR - DRE' },
      { id: 'DOM', nome: 'DOMICÍLIO BANCÁRIO TRANSFERÊNCIA FINANCEIRA - DOM' },
      { id: 'ELO', nome: 'EM LIQUIDAÇÃO ORÇAMENTÁRIA - ELO' },
      { id: 'ELR', nome: 'EM LIQUIDAÇÃO RESTOS - ELR' },
      { id: 'EMP', nome: 'EMPENHOS COM PRÉ-EMPENHO - EMP' },
      { id: 'EPA', nome: 'EMPENHOS COM PRÉ-EMPENHO - ANULAÇÃO - EPA' },
      { id: 'EMO', nome: 'EMPENHOS ORÇAMENTÁRIOS - EMO' },
      { id: 'EOA', nome: 'EMPENHOS ORÇAMENTÁRIOS ANULAÇÃO - EOA' },
      { id: 'EMD', nome: 'EMPENHOS ORÇAMENTÁRIOS DISPONIBILIDADE EXERCÍCIO ANTERIOR - EMD' },
      { id: 'EMA', nome: 'EMPENHOS ORÇAMENTÁRIOS DISPON. EXERCÍCIO ANTERIOR ANULAÇÃO - EMA' },
      { id: 'CDR', nome: 'TROCA DE RECURSOS DISPONIBILIDADE - CDR' },
      { id: 'ESP', nome: 'EMPENHOS REFORÇO - SEM PRÉ-EMPENHO - ESP' },
      { id: 'ECP', nome: 'EMPENHOS REFORÇO - COM PRÉ-EMPENHO - ECP' },
      { id: 'ERD', nome: 'EMPENHOS RESERVA DE DOTAÇÃO - ERD' },
      { id: 'CRA', nome: 'EXCESSO DE ARRECADAÇÃO - ANULAÇÃO - CRA' },
      { id: 'FOD', nome: 'FIXAÇÃO ORÇAMENTARIA DA DESPESA - FOD' },
      { id: 'INC', nome: 'INCORPORAÇÃO DE OBRAS E INSTALAÇÕES - INC' },
      { id: 'LEO', nome: 'LIQUIDAÇÃO ORÇAMENTÁRIAS - LEO' },
      { id: 'PLO', nome: 'LIQUIDAÇÃO DE PRÉ LIQUIDAÇÃO - PLO' },
      { id: 'PLR', nome: 'LIQUIDAÇÃO DE PRÉ LIQUIDAÇÃO DE RESTO - PLR' },
      { id: 'LRP', nome: 'LIQUIDAÇÃO DE RESTOS A PAGAR - PROCESSADOS - LRP' },
      { id: 'LRN', nome: 'LIQUIDAÇÃO DE RESTOS A PAGAR - NÃO PROCESSADOS - LRN' },
      { id: 'LRA', nome: 'LIQUIDAÇÃO DE RESTOS A PAGAR - NÃO PROCESSADOS ESTORNO - LRA' },
      { id: 'CAO', nome: 'CONTRATO ASSINATURA OUTRO TERCEIRO - CAO' },
      { id: 'COR', nome: 'OUTROS CONVENIOS RECEBIDOS - REMUNERAÇÃO DE APLICAÇÃO - COR' },
      { id: 'SAR', nome: 'OUTROS AUXILIOS, SUBVENÇÕES, CONTRIBUIÇÕES RECEBIDOS - REMUNERAÇÃO - SAR' },
      { id: 'PGO', nome: 'PAGAMENTOS ORÇAMENTÁRIOS - PGO' },
      { id: 'PGE', nome: 'PAGAMENTOS EXTRA ORÇAMENTÁRIOS - PGE' },
      { id: 'PEE', nome: 'PAGAMENTOS EXTRA ORÇAMENTÁRIOS COM RETENÇÃO- PEE' },
      { id: 'PEG', nome: 'PAGAMENTOS EXTRA ORÇAMENTÁRIOS ORIUNDO DE RETENÇÃO EXTRA- PEG' },
      { id: 'TDE', nome: 'PAGAMENTOS EXTRA ORÇAMENTÁRIOS SEM ORIGEM TRANSF DISP. EXTRA - TDE' },
      { id: 'PGR', nome: 'PAGAMENTOS DE RESTOS A PAGAR - PGR' },
      { id: 'PRN', nome: 'PAGAMENTO DE RESTOS A PAGAR NÃO PROCESSADOS - CONCOMITANTE - PRN' },
      { id: 'PRP', nome: 'PAGAMENTO DE RESTOS A PAGAR PROCESSADOS  - CONCOMITANTE - PRP' },
      { id: 'PDL', nome: 'PARCELAMENTO DE DIVIDA - BAIXA - PDL' },
      { id: 'PAD', nome: 'PREVISÃO ADICIONAL DA DESPESA - PAD' },
      { id: 'PED', nome: 'PREVISÃO REDUTORA DA DESPESA - PED' },
      { id: 'PAR', nome: 'PREVISÃO ADICIONAL DA RECEITA - PAR' },
      { id: 'PIR', nome: 'PREVISÃO INICIAL DA RECEITA - PIR' },
      { id: 'PRR', nome: 'PREVISÃO INICIAL REDUTORES DA RECEITA - PRR' },
      { id: 'RRP', nome: 'PREVISÃO INICIAL RENUNCIA DE RECEITA - RRP' },
      { id: 'PRS', nome: 'PREVISÃO INICIAL RESTITUIÇÃO DE RECEITA - PRS' },
      { id: 'PTC', nome: 'PREVISÃO TRANSFERENCIAS A CONCEDER - PTC' },
      { id: 'PTR', nome: 'PREVISÃO TRANSFERENCIAS A RECEBER - PTR' },
      { id: 'PCA', nome: 'PREVISÃO TRANSFERENCIAS CONCEDIDA ADICIONAL - PCA' },
      { id: 'PCE', nome: 'PREVISÃO TRANSFERENCIAS CONCEDIDA REDUÇÃO - PCE' },
      { id: 'PRA', nome: 'PREVISÃO TRANSFERENCIAS RECEBER ADICIONAL - PRA' },
      { id: 'PRE', nome: 'PREVISÃO TRANSFERENCIAS RECEBER REDUÇÃO - PRE' },
      { id: 'QCA', nome: 'QUITAÇÃO AUXÍLIOS CONCEDIDOS - QCA' },
      { id: 'QCC', nome: 'QUITAÇÃO DE CONVENIO - CONCESSÃO - QCC' },
      { id: 'QCE', nome: 'QUITAÇÃO DE CONVENIO - RECEBIDOS QCE' },
      { id: 'QCO', nome: 'QUITAÇÃO DE CONVENIO - CONCEDIDOS - QCO' },
      { id: 'QEA', nome: 'QUITAÇÃO DE CONVENIO - AUXILIOS QEA' },
      { id: 'QCR', nome: 'QUITAÇÃO DE CONVENIO - RECEBIMENTOS - QCR' },
      { id: 'QDA', nome: 'QUADRO DETALHAMENTO DESPESA - ACRÉSCIMO - QDA' },
      { id: 'QDD', nome: 'QUADRO DETALHAMENTO DESPESA - DECRÉSCIMOS - QDD' },
      { id: 'REE', nome: 'RECEITAS EXTRA ORÇAMENTÁRIAS - REE' },
      { id: 'REA', nome: 'RECEITAS EXTRA ORÇAMENTÁRIAS ANULADAS DE RETENÇÕES - REA' },
      { id: 'REO', nome: 'RECEITAS ORÇAMENTÁRIAS - REO' },
      { id: 'ROC', nome: 'RECEITAS ORÇAMENTÁRIAS - RECOLHIDA POR COMPETÊNCIA - ROC' },
      { id: 'ROR', nome: 'RECEITAS ORÇAMENTÁRIAS - REDUTORES - ROR' },
      { id: 'RES', nome: 'RECEITAS ORÇAMENTÁRIAS - RESTITUIÇÃO - RES' },
      { id: 'CON', nome: 'RECEBIMENTO DE CONVENIOS - CON' },
      { id: 'ASR', nome: 'RECEBIMENTO DE AUXÍLIO SUBVENÇÃO CONTRIBUIÇÃO - ASR' },
      { id: 'RDO', nome: 'RESERVA DE DOTAÇÃO ORÇAMENTÁRIA - RDO' },
      { id: 'RDA', nome: 'RESERVA DE DOTAÇÃO ORÇAMENTÁRIA ANULA- RDA' },
      { id: 'RET', nome: 'RETENÇÕES LIQUIDAÇÃO EXTRA ORÇAMENTÁRIA - RET' },
      { id: 'RTE', nome: 'RETENÇÕES LIQUIDAÇÃO EXTRA ORÇAMENTÁRIA 2022 - RTE' },
      { id: 'RLE', nome: 'RETENÇÕES LIQUIDAÇÃO RESTOS A PAGAR EXTRA ORÇAMENTÁRIA 2022 - RLE' },
      { id: 'RPD', nome: 'RETENÇÕES PAGAMENTO DOMICÍLIO - RPD' },
      { id: 'RPR', nome: 'RETENÇÕES PAGAMENTO RESTOS A PAGAR DOMICÍLIO - RPR' },
      { id: 'RPA', nome: 'RETENÇÕES PAGAMENTO DOMICÍLIO ANULAÇÃO - RPA' },
      { id: 'RTA', nome: 'RETENÇÕES LIQUIDAÇÃO EXTRA ORÇAMENTÁRIA ANULAÇÃO - RTA' },
      { id: 'RLA', nome: 'RETENÇÕES LIQUIDAÇÃO ORÇAMENTÁRIA ANULAÇÃO RECURSO ORDINÁRIO - RLA' },
      { id: 'REL', nome: 'RETENÇÕES LIQUIDAÇÃO RECURSOS ORDINÁRIOS - REL' },
      { id: 'RER', nome: 'RETENÇÕES EMPENHOS EXTRAS - RER' },
      { id: 'COS', nome: 'RETENÇÕES BAIXA PAGAMENTO EXTRA - COS' },
      { id: 'REP', nome: 'RETENÇÕES ORÇAMENTÁRIAS CONCOMITANTE - REP' },
      { id: 'RED', nome: 'RETENÇÕES ORÇAMENTÁRIAS BAIXA PAGAMENTO - RED' },
      { id: 'RLR', nome: 'RETENÇÕES RESTOS A PAGAR EXTRA ORÇAMENTÁRIOS - RLR' },
      { id: 'RTD', nome: 'RECEITA TRANSFERÊNCIA DUODÉCIMO - RTD' },
      { id: 'RDD', nome: 'RECEITA TRANSFERÊNCIA DEVOLUCAO DUODÉCIMO - RDD' },
      { id: 'SCA', nome: 'SUPRESSÃO DE AUXÍLIO, CONTRIBUIÇÃO E SUBVENÇÃO CONCEDIDOS - SCA' },

      { id: 'CGS', nome: 'CONTRATOS DE GESTÃO EM SAÚDE - CGS' },
      { id: 'CGE', nome: 'CONTRATOS DE GESTÃO EM EDUCAÇÃO - CGE' },
      { id: 'CGA', nome: 'CONTRATOS DE GESTÃO EM ASSISTÊNCIA SOCIAL - CGA' },
      { id: 'CGO', nome: 'CONTRATOS DE GESTÃO EM OUTRAS ÁREAS DE ATUAÇÃO - CGO' },

      { id: 'TRS', nome: 'TRANSFERENCIA DE SALDO BANCARIO - TRS' },
      { id: 'TRD', nome: 'TROCA DE RECURSOS DISPONIBILIDADE TRANSFERÊNCIAS - TRD' },
      { id: 'TDP', nome: 'TRANSFERENCIA DUODÉCIMO PREFEITURA - TDP' },
      { id: 'TDC', nome: 'TRANSFERENCIA DUODÉCIMO CÂMARA - TDC' },
      { id: 'TRF', nome: 'TRANSFERENCIA FINANCEIRA - TRF' },
      { id: 'TRN', nome: 'TRANSFERENCIA NORMAL - TRN' },
      { id: 'TFS', nome: 'TRANSFERENCIA FINANCEIRA SUPERAVIT - TFS' },
      { id: 'TRE', nome: 'TRANSFERENCIA DECENDIAL EDUCACAO - TRE' },
      { id: 'TRP', nome: 'TRANSFERÊNCIA RECURSOS PAGAMENTOS- TRP' },
      { id: 'TIP', nome: 'TRANSFERENCIA INTERVENIÊNCIA PASSIVA - TIP' },
      { id: 'TIA', nome: 'TRANSFERENCIA INTERVENIÊNCIA ATIVA - TIA' },
      { id: 'TER', nome: 'TRANSFERENCIA RETENÇÕES EXTRA ORÇAMENTÁRIAS - TER' },
      { id: 'TPP', nome: 'TRANSFERÊNCIAS PARCERIA PUBLICO PRIVADA GARANTIA - TPP' },
      { id: 'TPE', nome: 'TRANSFERÊNCIAS PARCERIA PUBLICO PRIVADA GARANTIA - ESTORNO - TPE' },
      { id: 'TPI', nome: 'TPI' },
      { id: 'TRI', nome: 'TRI' },
      { id: 'VAR', nome: 'VARIAÇÃO PATRIMONIAL - VAR' },
      { id: 'QCS', nome: 'QUITAÇÃO SUBVENÇÃO - CONCEDIDO - QCS' },

      { id: 'CRQ', nome: 'QUITAÇÃO DE CONVENIO - RECEBIDOS UNIÃO - CRQ' },
      { id: 'QEC', nome: 'QUITAÇÃO CONTRIBUIÇÕES - RECEBIMENTOS ESTADO - QEC' },
      { id: 'QUA', nome: 'QUITAÇÃO AUXÍLIO - RECEBIMENTOS UNIÃO - QUA' },
      { id: 'QUS', nome: 'QUITAÇÃO SUBVENÇÕES - RECEBIMENTOS UNIÃO - QUS' },
      { id: 'QES', nome: 'QUITAÇÃO SUBVENÇÕES - RECEBIMENTOS ESTADO - QES' },
      { id: 'QUC', nome: 'QUITAÇÃO CONTRIBUIÇÕES - RECEBIMENTOS UNIÃO - QUC' },

      { id: 'CDF', nome: 'M-13 DISPONIBILIDADES FINANCEIRAS ORÇAMENTÁRIA UTILIZADAS - PCM13 - 05 - CDF' },
      { id: 'CAD', nome: 'M-13 BAIXA DE ADIANTAMENTOS - VALOR UTILIZADO PCM13 - 06 - CAD' },
      { id: 'RPC', nome: 'RPC' },
      { id: 'OUC', nome: 'M-13 - OUTROS CONTROLES PCM13 09 AO 15A - OUC' },
      { id: 'DPD', nome: 'M-13 - DISPONBILIDADE POR DESTINAÇÃO PCM13 - 27 - DPD' },
      { id: 'EDR', nome: 'EDR' },
      { id: 'APA', nome: 'M-13 - ATOS POTENCIAIS DO ATIVO PCM13 - 16  AO 18 - APA' },
      { id: 'APP', nome: 'M-13 AVAIS EXECUTADOS PCM13 - 19 - APP' },
      { id: 'IPP', nome: 'M-13 RESTOS A PAGAR PROCESSADOS A PAGAR PCM13 - 22 - A - IPP' },
      { id: 'IPN', nome: 'M-13 RESTOS A PAGAR NÃO PROCESSADOS A LIQUIDAR PCM13 - 23 - IPN' },
      { id: 'IPL', nome: 'M-13 RESTOS A PAGAR NÃO PROCESSADOS EM LIQUIDAÇÃO PCM13 - 24 - IPL' },
      { id: 'RAC', nome: 'M-13 SUPERÁVITS OU DÉFICITS DE EXERCÍCIOS ANTERIORES PCM13 - 25 - RAC' },
      { id: 'FOL', nome: 'M-13 TRANSF. DE CONTAS DO EXERCÍCIO PARA EXERCICIO ANTERIOR - PCM13 - 22 - FOL' },
      { id: 'ASD', nome: 'M-14 SUPERÁVITS OU DÉFICITS DO EXERCÍCIO PCM14 - 01 - ASD' },
      { id: 'AR2', nome: 'M-14 SUPERÁVITS OU DÉFICITS DO EXERCÍCIO PCM14 - 01A  - AR2' },
      { id: 'AR3', nome: 'M-14 SUPERÁVITS OU DÉFICITS DO EXERCÍCIO PCM14 - 01B  - AR3' },
      { id: 'AR4', nome: 'M-14 SUPERÁVITS OU DÉFICITS DO EXERCÍCIO PCM14 - 01C  - AR4' },
      { id: 'AR5', nome: 'M-14 SUPERÁVITS OU DÉFICITS DO EXERCÍCIO PCM14 - 01D - AR5' },
      { id: 'AD1', nome: 'M-14 SUPERÁVITS OU DÉFICITS DO EXERCÍCIO PCM14 - 02 - AD1' },
      { id: 'AD2', nome: 'M-14 SUPERÁVITS OU DÉFICITS DO EXERCÍCIO PCM14 - 02A - AD2' },
      { id: 'AD3', nome: 'M-14 SUPERÁVITS OU DÉFICITS DO EXERCÍCIO PCM14 - 02B - AD3' },
      { id: 'AD4', nome: 'M-14 SUPERÁVITS OU DÉFICITS DO EXERCÍCIO PCM14 - 02C - AD4' },
      { id: 'AD5', nome: 'M-14 SUPERÁVITS OU DÉFICITS DO EXERCÍCIO PCM14 - 2D - AD5' },
      { id: 'ECC', nome: 'M-14 (-) CRONOGRAMA DE DESEMBOLSO A TRANSFERIR - REDUÇÕES PCM14 - ECC' },
      { id: 'EOC', nome: 'EOC' },
      { id: 'CAR', nome: 'M-14 CONTROLE DE RESTOS A PAGAR PCM14 - CAR' },
      { id: 'CARC', nome: 'CARC' },
      { id: 'RPA', nome: 'M-14 PREVISÃO E ARRECADAÇÃO PCM14 - 03 - RPA' },
      { id: 'DFE', nome: 'M-14 CRÉDITO DISPONÍVEL PCM14 - 04 - DFE' },
      { id: 'EDE', nome: 'M-14 EMPENHOS - PCM14 - 05A - EDE' },
      { id: 'EDEA', nome: 'EDEA' },
      { id: 'ESO', nome: 'M-14 CREDITOS ADICIONAIS PMC14 - 03 - ESO' },
      { id: 'PAE', nome: 'M-14 - CRONOGRAMA DESEMBOLSO -  PCM14 - 14   - ENCER. CONTAS P/ S 13 - CONTAS CR - PAE' },
      { id: 'RPD14', nome: 'RPD14' },
      { id: 'EDOA', nome: 'EDOA' },
      { id: 'EDO', nome: 'M-14  DESPESA ORÇAMENTÁRIA - FIXAÇÃO E EXECUÇÃO PCM14 - 04 - EDO' },
      { id: 'ESC', nome: 'ENCERRAMENTO DO SALDO DAS CONTAS DE CONTROLE - PCM14 -  07 - ESC' },
      { id: 'EFCA', nome: 'EFCA' },
      { id: 'EFC', nome: 'ENCERRAMENTO FINAL DO SALDO DAS CONTAS DE CONTROLES - PCM 14 - NÃO ENC ANEXO III - EFC' },

      { id: 'APC', nome: 'CONTRATOS ASSINATURA PERMISSÃO E CONCESSÃO - APC' },

      { id: 'RNP', nome: 'CANCELAMENTO DE RESTOS A PAGAR NÃO PROCESSADO - RNP' },
      { id: 'RNI', nome: 'CANCELAMENTO DE RESTOS A PAGAR NÃO PROCESSADOS POR INSUFICIÊNCIA FINANCEIRA - RNI' },
      { id: 'CRP', nome: 'CANCELAMENTO RESTOS A PAGAR PROCESSADO - CRP' },
      { id: 'RNL', nome: 'CANCELAMENTO DE RESTOS A PAGAR NÃO PROCESSADOS LIQUIDADOS - RNL' },
      { id: 'RPI', nome: 'CANCELAMENTO DE RESTOS A PAGAR PROCESSADO POR INSUFICIÊNCIA FINANCEIRA' },

      { id: 'PP1', nome: 'PREVISÃO PPP ANO 1 - PP1' },
      { id: 'PP2', nome: 'PREVISÃO PPP ANO 2 - PP2' },
      { id: 'PP3', nome: 'PREVISÃO PPP ANO 3 - PP3' },
      { id: 'PP4', nome: 'PREVISÃO PPP ANO 4 - PP4' },
      { id: 'PP5', nome: 'PREVISÃO PPP ANO 5 - PP5' },
      { id: 'PP6', nome: 'PREVISÃO PPP ANO 6 - PP6' },
      { id: 'PP7', nome: 'PREVISÃO PPP ANO 7 - PP7' },
      { id: 'PP8', nome: 'PREVISÃO PPP ANO 8 - PP8' },
      { id: 'PP9', nome: 'PREVISÃO PPP ANO 9 - PP9' },
      { id: 'PP10', nome: 'PREVISÃO PPP ANO 10 - PP10' },
    ];
  }

  public obterTiposObjetoLicitacao(): { id: TipoObjetoLicitacao, nome: string }[] {
    return [
      { id: 'COMPRA_SERVICO', nome: 'Compras e Serviço' },
      { id: 'COMPRA_SERVICO_TI', nome: 'Compras e Serviços de TI' },
      { id: 'ENGENHARIA', nome: 'Obras e Serviços de Engenharia' },
    ]
  }

  public obterObjetoLicitacao(tipo: TipoObjetoLicitacao): { id: number, nome: string }[] {
    if (!tipo) return [];
    switch (tipo) {
      case 'COMPRA_SERVICO':
        return [
          { id: 1, nome: 'Equipamentos e material permanente' },
          { id: 2, nome: 'Material de expediente' },
          { id: 6, nome: 'Medicamentos' },
          { id: 16, nome: 'Outros materiais de consumo' },
          { id: 19, nome: 'Coleta de lixo' },
          { id: 23, nome: 'Limpeza Urbana/Varrição' },
          { id: 24, nome: 'Material Escolar' },
          { id: 25, nome: 'Transporte Escolar' },
          { id: 26, nome: 'Uniforme Escolar' },
          { id: 27, nome: 'Publicidade/Propaganda' },
          { id: 3, nome: 'Gêneros alimentícios' },
          { id: 4, nome: 'Combustíveis e lubrificantes' },
          { id: 5, nome: 'Material hospitalar, ambulatorial ou odontológico' },
          { id: 7, nome: 'Passagens aéreas e outras despesas de locomoção' },
          { id: 8, nome: 'Serviços de consultoria' },
          { id: 10, nome: 'Locação de imóveis' },
          { id: 11, nome: 'Locação de mão de obra' },
          { id: 12, nome: 'Operações de crédito (exceto ARO)' },
          { id: 13, nome: 'Concessão de serviço público' },
          { id: 14, nome: 'Permissão' },
          { id: 17, nome: 'Outras prestações de serviço' },
        ];
      case 'COMPRA_SERVICO_TI':
        return [
          { id: 22, nome: 'Compras de TI' },
          { id: 18, nome: 'Serviços de TI' },
          { id: 32, nome: 'SIAFIC' },
        ];
      case 'ENGENHARIA':
        return [
          { id: 9, nome: 'Obras e serviços de engenharia' },
          { id: 20, nome: 'Implantação de aterro sanitário' },
        ];
    }
  }

  public obterNaturezaLicitacao(): { id: number, nome: string }[] {
    return [
      { id: 6, nome: 'Concessão Pública' },
      { id: 3, nome: 'Credenciamento' },
      { id: 1, nome: 'Normal' },
      { id: 7, nome: 'Permissão Pública' },
      { id: 4, nome: 'PPP Administrativa' },
      { id: 5, nome: 'PPP Patrocinada' },
      { id: 2, nome: 'Registro de Preços' },
      { id: 8, nome: 'Concessão/Permissão de Uso' },
    ]
  }

  public obterTipoLicitacao(): { id: number, nome: string, pncp: boolean }[] {
    return [
      { id: 1, nome: 'Menor preço', pncp: true }, //PNCP 1
      { id: 2, nome: 'Técnica e preço', pncp: true }, // PNCP 4
      { id: 3, nome: 'Melhor técnica ou Conteúdo Artístico', pncp: false }, //NÃO PODE COM O PNCP
      { id: 4, nome: 'Maior Lance ou Oferta', pncp: false }, //NÃO PODE COM O PNCP
      { id: 5, nome: 'Maior Desconto', pncp: true }, // PNCP 2
      { id: 6, nome: 'Maior Oferta de Preço', pncp: false }, //NÃO PODE COM O PNCP
      { id: 7, nome: 'Maior Retorno Econômico', pncp: true }, // PNCP 6
      { id: 8, nome: 'Menor valor da tarifa do serviço público', pncp: false }, //NÃO PODE COM O PNCP
      { id: 9, nome: 'Menor valor da tarifa + melhor técnica', pncp: false }, // NÃO PODE COM O PNCP
      { id: 10, nome: 'Menor valor da contraprestação', pncp: false }, // NÃO PODE COM O PNCP
      { id: 11, nome: 'Menor valor da contraprestação + melhor técnica', pncp: false }, //NÃO PODE COM O PNCP
      { id: 12, nome: 'Melhor destinação de bens alienados (Lei nº 13.303/2016)', pncp: false }, // NÃO PODE COM PNCP
      { id: 13, nome: 'Maior Lance', pncp: true }, // PNCP 5
      { id: 14, nome: 'Não se aplica', pncp: true }, // PNCP 7
      { id: 15, nome: 'Melhor técnica', pncp: true }, // PNCP 8
      { id: 16, nome: 'Conteúdo artístico', pncp: true }, // PNCP 9
    ]
  }

  public obterLei1232006Licitacao(): { id: number, nome: string }[] {
    return [
      { id: 3, nome: 'Tratamento Diferenciado/Simplificado' },
      { id: 1, nome: 'Não se aplica' },
      { id: 2, nome: 'Licitação Exclusiva' },
    ]
  }

  public obterCloudArmazenamento() {
    return [{ id: 'FIREBASE', nome: 'Firebase' }]
  }

  public obterIntegracaoLicitacao() {
    return [
      { id: 'PCP', nome: 'Portal de Compras Públicas' },
      { id: 'BLL', nome: 'BLL Compras' },
      { id: 'BNC', nome: 'BNC Compras' },
      { id: 'NENHUMA', nome: 'Nenhuma' }
    ]
  }

  public obterSituacaoPatrimonio(inventario?: boolean): { id: SituacaoPatrimonio, nome: string, estilo: string }[] {
    if (inventario) {
      return [
        { id: 'ATIVO', nome: 'Em uso', estilo: 'success' },
        { id: 'CESSAO', nome: 'Em cessão de uso', estilo: 'secondary' },
        { id: 'MANUTENCAO', nome: 'Em manutenção', estilo: 'secondary' },
        { id: 'NAO_LOCALIZADO', nome: 'Não localizado', estilo: 'warning' },
      ]
    } else {
      return [
        { id: 'ATIVO', nome: 'Em uso', estilo: 'success' },
        { id: 'BAIXADO', nome: 'Baixado', estilo: 'danger' },
        { id: 'CESSAO', nome: 'Em cessão de uso', estilo: 'secondary' },
        { id: 'EXCLUIDO', nome: 'Excluído', estilo: 'danger' },
        { id: 'MANUTENCAO', nome: 'Em manutenção', estilo: 'secondary' },
        { id: 'NAO_LOCALIZADO', nome: 'Não localizado', estilo: 'warning' },
      ]
    }
  }

  public obterConservacaoPatrimonio(): { id: ConservacaoPatrimonio, nome: string, estilo: string }[] {
    return [
      { id: 'EXCELENTE', nome: 'Excelente', estilo: 'success' },
      { id: 'BOM', nome: 'Bom', estilo: 'primary' },
      { id: 'REGULAR', nome: 'Regular', estilo: 'info' },
      { id: 'RUIM', nome: 'Ruim', estilo: 'warning' },
      { id: 'INSERVIVEL', nome: 'Inservível', estilo: 'danger' },
    ]
  }

  public obterTipoHistoricoPatrimonio(): { id: TipoHistoricoTombo, nome: string, estilo: string }[] {
    return [
      { id: 'BAIXA', nome: 'Baixa', estilo: 'danger' },
      { id: 'MANUTENCAO', nome: 'Manutenção', estilo: 'warning' },
      { id: 'OUTRO', nome: 'Outro', estilo: 'secondary' },
      { id: 'TOMBAMENTO', nome: 'Tombamento', estilo: 'success' },
      { id: 'INVENTARIO', nome: 'Inventário', estilo: 'success' },
      { id: 'TRANSFERENCIA', nome: 'Transferência', estilo: 'primary' },
      { id: 'EMPRESTIMO', nome: 'Empréstimo', estilo: 'primary' },
      { id: 'REATIVACAO', nome: 'Reativação', estilo: 'danger' }
    ]
  }
  /** DE - Doação do Estado, DU - Doação da União, DM - Doação de outros municípios, DA - Doação de Autarquias, DJ - Doação Pessoa Jurídica (CNPJ),  DF - Doação Pessoa Física (CPF) */

  public obterTipoDoacaoPatrimonio(): { id: string, nome: string }[] {
    return [
      { id: 'DE', nome: 'Doação do Estado' },
      { id: 'DU', nome: 'Doação da União' },
      { id: 'DM', nome: 'Doação de Outros Municípios' },
      { id: 'DA', nome: 'Doação de Autarquias' },
      { id: 'DJ', nome: 'Doação Pessoa Jurídica (CNPJ)' },
      { id: 'DF', nome: 'Doação Pessoa Física (CPF)' },
    ]
  }

  public obterTipoOrdemServico(): { id: TipoOrdemServico, nome: string, estilo: string }[] {
    return [
      { id: 'BATERIA', nome: 'Bateria', estilo: 'primary' },
      { id: 'CORREIA', nome: 'Correia Dentada', estilo: 'primary' },
      { id: 'EXTINTOR', nome: 'Extintor', estilo: 'primary' },
      { id: 'OLEO', nome: 'Óleo', estilo: 'primary' },
      { id: 'PECAS', nome: 'Peças', estilo: 'primary' },
      { id: 'PNEU', nome: 'Pneu', estilo: 'primary' },
      { id: 'REVISAO', nome: 'Revisão', estilo: 'primary' },
      { id: 'MANUTENCAO', nome: 'Manutenção', estilo: 'primary' },
    ]
  }

  public obterSituacaoImovel(): { id: SituacaoImovel, nome: string, estilo: string }[] {
    return [
      { id: 'PROPRIO', nome: 'Próprio', estilo: '' },
      { id: 'EM_AQUISICAO', nome: 'Em processo de aquisição', estilo: '' },
      { id: 'CEDIDO_POR', nome: 'Cedido por terceiros', estilo: '' },
      { id: 'CEDIDO_A', nome: 'Cedido a terceiros', estilo: '' },
      { id: 'LOCADO', nome: 'Locado', estilo: '' },
      { id: 'TEM_POSSE', nome: 'Aqueles que se tem a posse', estilo: '' },
      // { id: 'RECEBIDO', nome: 'Recebido', estilo: '' },
      // { id: 'EXCLUIDO', nome: 'Excluído', estilo: '' },
      // { id: 'ALIENACAO', nome: 'Alienação (venda)', estilo: '' },
      // { id: 'COMPRA', nome: 'Aquisição (compra)', estilo: '' },
      // { id: 'OBRAS', nome: 'Obras', estilo: '' },
      // { id: 'CESSAO', nome: 'Cessão de uso (com encargo)', estilo: '' },
      // { id: 'PERMISSAO', nome: 'Permissão de uso', estilo: '' },
      // { id: 'PERMISSAO_ONEROSA', nome: 'Permissão de uso onerosa', estilo: '' },
      // { id: 'PERMISSAO_GRATUITA', nome: 'Permissão de uso gratuita', estilo: '' },
      // { id: 'CONCESSAO', nome: 'Concessão de uso', estilo: '' },
      // { id: 'COMODATO', nome: 'Empréstimo (comodato)', estilo: '' },
      // { id: 'DEVOLUCAO', nome: 'Devolução', estilo: '' },
      // { id: 'INTERESSE', nome: 'Interesse', estilo: '' },
      // { id: 'TRANSFERENCIA', nome: 'Transferência entre órgãos', estilo: '' },
      // { id: 'PERMUTA', nome: 'Permuta', estilo: '' },
      // { id: 'APURACAO', nome: 'Apuração de irregularidade', estilo: '' },
      // { id: 'DEMOLICAO', nome: 'Autorização para demolição', estilo: '' },
      // { id: 'INVASAO', nome: 'Invasão', estilo: '' },
      // { id: 'MANUTENCAO', nome: 'Manutenção da área', estilo: '' },
      // { id: 'DOACAO', nome: 'Recebimento por doação (com encargo)', estilo: '' },
      // { id: 'REGULARIZACAO', nome: 'Regularização de uso', estilo: '' },
      // { id: 'COMPARTILHADO', nome: 'Uso compartilhado', estilo: '' },
      // { id: 'DESAPROPRIACAO', nome: 'Desapropriação', estilo: '' },
      // { id: 'DOACAO_SEM', nome: 'Recebimento por doação (sem encargo)', estilo: '' },
      // { id: 'CESSAO_SEM', nome: 'Cessão de uso (sem encargo)', estilo: '' },
    ]
  }

  public listaPaises(): { nome: string, iso3: string, sigla: string }[] {
    return [
      { nome: 'Afeganistão', iso3: 'AFG', sigla: 'AF' },
      { nome: 'África do Sul', iso3: 'ZAF', sigla: 'ZA' },
      { nome: 'Albânia', iso3: 'ALB', sigla: 'AL' },
      { nome: 'Alemanha', iso3: 'DEU', sigla: 'DE' },
      { nome: 'Andorra', iso3: 'AND', sigla: 'AD' },
      { nome: 'Angola', iso3: 'AGO', sigla: 'AO' },
      { nome: 'Antiga e Barbuda', iso3: 'ATG', sigla: 'AG' },
      { nome: 'Arábia Saudita', iso3: 'SAU', sigla: 'SA' },
      { nome: 'Argélia', iso3: 'DZA', sigla: 'DZ' },
      { nome: 'Argentina', iso3: 'ARG', sigla: 'AR' },
      { nome: 'Arménia', iso3: 'ARM', sigla: 'AM' },
      { nome: 'Austrália', iso3: 'AUS', sigla: 'AU' },
      { nome: 'Áustria', iso3: 'AUT', sigla: 'AT' },
      { nome: 'Azerbaijão', iso3: 'AZE', sigla: 'AZ' },
      { nome: 'Bahamas', iso3: 'BHS', sigla: 'BS' },
      { nome: 'Bangladexe', iso3: 'BGD', sigla: 'BD' },
      { nome: 'Barbados', iso3: 'BRB', sigla: 'BB' },
      { nome: 'Barém', iso3: 'BHR', sigla: 'BH' },
      { nome: 'Bélgica', iso3: 'BEL', sigla: 'BE' },
      { nome: 'Belize', iso3: 'BLZ', sigla: 'BZ' },
      { nome: 'Benim', iso3: 'BEN', sigla: 'BJ' },
      { nome: 'Bielorrússia', iso3: 'BLR', sigla: 'BY' },
      { nome: 'Bolívia', iso3: 'BOL', sigla: 'BO' },
      { nome: 'Bósnia e Herzegovina', iso3: 'BIH', sigla: 'BA' },
      { nome: 'Botsuana', iso3: 'BWA', sigla: 'BW' },
      { nome: 'Brasil', iso3: 'BRA', sigla: 'BR' },
      { nome: 'Brunei', iso3: 'BRN', sigla: 'BN' },
      { nome: 'Bulgária', iso3: 'BGR', sigla: 'BG' },
      { nome: 'Burquina Faso', iso3: 'BFA', sigla: 'BF' },
      { nome: 'Burúndi', iso3: 'BDI', sigla: 'BI' },
      { nome: 'Butão', iso3: 'BTN', sigla: 'BT' },
      { nome: 'Cabo Verde', iso3: 'CPV', sigla: 'CV' },
      { nome: 'Camarões', iso3: 'CMR', sigla: 'CM' },
      { nome: 'Camboja', iso3: 'KHM', sigla: 'KH' },
      { nome: 'Canadá', iso3: 'CAN', sigla: 'CA' },
      { nome: 'Catar', iso3: 'QAT', sigla: 'QA' },
      { nome: 'Cazaquistão', iso3: 'KAZ', sigla: 'KZ' },
      { nome: 'Chade', iso3: 'TCD', sigla: 'TD' },
      { nome: 'Chile', iso3: 'CHL', sigla: 'CL' },
      { nome: 'China', iso3: 'CHN', sigla: 'CN' },
      { nome: 'Chipre', iso3: 'CYP', sigla: 'CY' },
      { nome: 'Colômbia', iso3: 'COL', sigla: 'CO' },
      { nome: 'Comores', iso3: 'COM', sigla: 'KM' },
      { nome: 'Congo-Brazzaville', iso3: 'COG', sigla: 'CG' },
      { nome: 'Coreia do Norte', iso3: 'PRK', sigla: 'KP' },
      { nome: 'Coreia do Sul', iso3: 'KOR', sigla: 'KR' },
      { nome: 'Cosovo', iso3: '-', sigla: '-' },
      { nome: 'Costa do Marfim', iso3: 'CIV', sigla: 'CI' },
      { nome: 'Costa Rica', iso3: 'CRI', sigla: 'CR' },
      { nome: 'Croácia', iso3: 'HRV', sigla: 'HR' },
      { nome: 'Cuaite', iso3: 'KWT', sigla: 'KW' },
      { nome: 'Cuba', iso3: 'CUB', sigla: 'CU' },
      { nome: 'Dinamarca', iso3: 'DNK', sigla: 'DK' },
      { nome: 'Dominica', iso3: 'DMA', sigla: 'DM' },
      { nome: 'Egito', iso3: 'EGY', sigla: 'EG' },
      { nome: 'Emirados Árabes Unidos', iso3: 'ARE', sigla: 'AE' },
      { nome: 'Equador', iso3: 'ECU', sigla: 'EC' },
      { nome: 'Eritreia', iso3: 'ERI', sigla: 'ER' },
      { nome: 'Eslováquia', iso3: 'SVK', sigla: 'SK' },
      { nome: 'Eslovénia', iso3: 'SVN', sigla: 'SI' },
      { nome: 'Espanha', iso3: 'ESP', sigla: 'ES' },
      { nome: 'Essuatíni', iso3: 'SWZ', sigla: 'SZ' },
      { nome: 'Estado da Palestina', iso3: 'PSE', sigla: 'PS' },
      { nome: 'Estados Unidos', iso3: 'USA', sigla: 'US' },
      { nome: 'Estónia', iso3: 'EST', sigla: 'EE' },
      { nome: 'Etiópia', iso3: 'ETH', sigla: 'ET' },
      { nome: 'Fiji', iso3: 'FJI', sigla: 'FJ' },
      { nome: 'Filipinas', iso3: 'PHL', sigla: 'PH' },
      { nome: 'Finlândia', iso3: 'FIN', sigla: 'FI' },
      { nome: 'França', iso3: 'FRA', sigla: 'FR' },
      { nome: 'Gabão', iso3: 'GAB', sigla: 'GA' },
      { nome: 'Gâmbia', iso3: 'GMB', sigla: 'GM' },
      { nome: 'Gana', iso3: 'GHA', sigla: 'GH' },
      { nome: 'Geórgia', iso3: 'GEO', sigla: 'GE' },
      { nome: 'Granada', iso3: 'GRD', sigla: 'GD' },
      { nome: 'Grécia', iso3: 'GRC', sigla: 'GR' },
      { nome: 'Guatemala', iso3: 'GTM', sigla: 'GT' },
      { nome: 'Guiana', iso3: 'GUY', sigla: 'GY' },
      { nome: 'Guiné', iso3: 'GIN', sigla: 'GN' },
      { nome: 'Guiné Equatorial', iso3: 'GNQ', sigla: 'GQ' },
      { nome: 'Guiné-Bissau', iso3: 'GNB', sigla: 'GW' },
      { nome: 'Haiti', iso3: 'HTI', sigla: 'HT' },
      { nome: 'Honduras', iso3: 'HND', sigla: 'HN' },
      { nome: 'Hungria', iso3: 'HUN', sigla: 'HU' },
      { nome: 'Iémen', iso3: 'YEM', sigla: 'YE' },
      { nome: 'Ilhas Marechal', iso3: 'MHL', sigla: 'MH' },
      { nome: 'Índia', iso3: 'IND', sigla: 'IN' },
      { nome: 'Indonésia', iso3: 'IDN', sigla: 'ID' },
      { nome: 'Irão', iso3: 'IRN', sigla: 'IR' },
      { nome: 'Iraque', iso3: 'IRQ', sigla: 'IQ' },
      { nome: 'Irlanda', iso3: 'IRL', sigla: 'IE' },
      { nome: 'Islândia', iso3: 'ISL', sigla: 'IS' },
      { nome: 'Israel', iso3: 'ISR', sigla: 'IL' },
      { nome: 'Itália', iso3: 'ITA', sigla: 'IT' },
      { nome: 'Jamaica', iso3: 'JAM', sigla: 'JM' },
      { nome: 'Japão', iso3: 'JPN', sigla: 'JP' },
      { nome: 'Jibuti', iso3: 'DJI', sigla: 'DJ' },
      { nome: 'Jordânia', iso3: 'JOR', sigla: 'JO' },
      { nome: 'Laus', iso3: 'LAO', sigla: 'LA' },
      { nome: 'Lesoto', iso3: 'LSO', sigla: 'LS' },
      { nome: 'Letónia', iso3: 'LVA', sigla: 'LV' },
      { nome: 'Líbano', iso3: 'LBN', sigla: 'LB' },
      { nome: 'Libéria', iso3: 'LBR', sigla: 'LR' },
      { nome: 'Líbia', iso3: 'LBY', sigla: 'LY' },
      { nome: 'Listenstaine', iso3: 'LIE', sigla: 'LI' },
      { nome: 'Lituânia', iso3: 'LTU', sigla: 'LT' },
      { nome: 'Luxemburgo', iso3: 'LUX', sigla: 'LU' },
      { nome: 'Macedónia do Norte', iso3: 'MKD', sigla: 'MK' },
      { nome: 'Madagáscar', iso3: 'MDG', sigla: 'MG' },
      { nome: 'Malásia', iso3: 'MYS', sigla: 'MY' },
      { nome: 'Maláui', iso3: 'MWI', sigla: 'MW' },
      { nome: 'Maldivas', iso3: 'MDV', sigla: 'MV' },
      { nome: 'Mali', iso3: 'MLI', sigla: 'ML' },
      { nome: 'Malta', iso3: 'MLT', sigla: 'MT' },
      { nome: 'Marrocos', iso3: 'MAR', sigla: 'MA' },
      { nome: 'Maurícia', iso3: 'MUS', sigla: 'MU' },
      { nome: 'Mauritânia', iso3: 'MRT', sigla: 'MR' },
      { nome: 'México', iso3: 'MEX', sigla: 'MX' },
      { nome: 'Mianmar', iso3: 'MMR', sigla: 'MM' },
      { nome: 'Micronésia', iso3: 'FSM', sigla: 'FM' },
      { nome: 'Moçambique', iso3: 'MOZ', sigla: 'MZ' },
      { nome: 'Moldávia', iso3: 'MDA', sigla: 'MD' },
      { nome: 'Mónaco', iso3: 'MCO', sigla: 'MC' },
      { nome: 'Mongólia', iso3: 'MNG', sigla: 'MN' },
      { nome: 'Montenegro', iso3: 'MNE', sigla: 'ME' },
      { nome: 'Namíbia', iso3: 'NAM', sigla: 'NA' },
      { nome: 'Nauru', iso3: 'NRU', sigla: 'NR' },
      { nome: 'Nepal', iso3: 'NPL', sigla: 'NP' },
      { nome: 'Nicarágua', iso3: 'NIC', sigla: 'NI' },
      { nome: 'Níger', iso3: 'NER', sigla: 'NE' },
      { nome: 'Nigéria', iso3: 'NGA', sigla: 'NG' },
      { nome: 'Noruega', iso3: 'NOR', sigla: 'NO' },
      { nome: 'Nova Zelândia', iso3: 'NZL', sigla: 'NZ' },
      { nome: 'Omã', iso3: 'OMN', sigla: 'OM' },
      { nome: 'Países Baixos', iso3: 'NLD', sigla: 'NL' },
      { nome: 'Palau', iso3: 'PLW', sigla: 'PW' },
      { nome: 'Panamá', iso3: 'PAN', sigla: 'PA' },
      { nome: 'Papua Nova Guiné', iso3: 'PNG', sigla: 'PG' },
      { nome: 'Paquistão', iso3: 'PAK', sigla: 'PK' },
      { nome: 'Paraguai', iso3: 'PRY', sigla: 'PY' },
      { nome: 'Peru', iso3: 'PER', sigla: 'PE' },
      { nome: 'Polónia', iso3: 'POL', sigla: 'PL' },
      { nome: 'Portugal', iso3: 'PRT', sigla: 'PT' },
      { nome: 'Quénia', iso3: 'KEN', sigla: 'KE' },
      { nome: 'Quirguistão', iso3: 'KGZ', sigla: 'KG' },
      { nome: 'Quiribáti', iso3: 'KIR', sigla: 'KI' },
      { nome: 'Reino Unido', iso3: 'GBR', sigla: 'GB' },
      { nome: 'República Centro-Africana', iso3: 'CAF', sigla: 'CF' },
      { nome: 'República Checa', iso3: 'CZE', sigla: 'CZ' },
      { nome: 'República Democrática do Congo', iso3: 'COD', sigla: 'CD' },
      { nome: 'República Dominicana', iso3: 'DOM', sigla: 'DO' },
      { nome: 'Roménia', iso3: 'ROU', sigla: 'RO' },
      { nome: 'Ruanda', iso3: 'RWA', sigla: 'RW' },
      { nome: 'Rússia', iso3: 'RUS', sigla: 'RU' },
      { nome: 'Salomão', iso3: 'SLB', sigla: 'SB' },
      { nome: 'Salvador', iso3: 'SLV', sigla: 'SV' },
      { nome: 'Samoa', iso3: 'WSM', sigla: 'WS' },
      { nome: 'Santa Lúcia', iso3: 'LCA', sigla: 'LC' },
      { nome: 'São Cristóvão e Neves', iso3: 'KNA', sigla: 'KN' },
      { nome: 'São Marinho', iso3: 'SMR', sigla: 'SM' },
      { nome: 'São Tomé e Príncipe', iso3: 'STP', sigla: 'ST' },
      { nome: 'São Vicente e Granadinas', iso3: 'VCT', sigla: 'VC' },
      { nome: 'Seicheles', iso3: 'SYC', sigla: 'SC' },
      { nome: 'Senegal', iso3: 'SEN', sigla: 'SN' },
      { nome: 'Serra Leoa', iso3: 'SLE', sigla: 'SL' },
      { nome: 'Sérvia', iso3: 'SRB', sigla: 'RS' },
      { nome: 'Singapura', iso3: 'SGP', sigla: 'SG' },
      { nome: 'Síria', iso3: 'SYR', sigla: 'SY' },
      { nome: 'Somália', iso3: 'SOM', sigla: 'SO' },
      { nome: 'Sri Lanca', iso3: 'LKA', sigla: 'LK' },
      { nome: 'Sudão', iso3: 'SDN', sigla: 'SD' },
      { nome: 'Sudão do Sul', iso3: 'SSD', sigla: 'SS' },
      { nome: 'Suécia', iso3: 'SWE', sigla: 'SE' },
      { nome: 'Suíça', iso3: 'CHE', sigla: 'CH' },
      { nome: 'Suriname', iso3: 'SUR', sigla: 'SR' },
      { nome: 'Tailândia', iso3: 'THA', sigla: 'TH' },
      { nome: 'Taiuã', iso3: 'TWN', sigla: 'TW' },
      { nome: 'Tajiquistão', iso3: 'TJK', sigla: 'TJ' },
      { nome: 'Tanzânia', iso3: 'TZA', sigla: 'TZ' },
      { nome: 'Timor-Leste', iso3: 'TLS', sigla: 'TL' },
      { nome: 'Togo', iso3: 'TGO', sigla: 'TG' },
      { nome: 'Tonga', iso3: 'TON', sigla: 'TO' },
      { nome: 'Trindade e Tobago', iso3: 'TTO', sigla: 'TT' },
      { nome: 'Tunísia', iso3: 'TUN', sigla: 'TN' },
      { nome: 'Turcomenistão', iso3: 'TKM', sigla: 'TM' },
      { nome: 'Turquia', iso3: 'TUR', sigla: 'TR' },
      { nome: 'Tuvalu', iso3: 'TUV', sigla: 'TV' },
      { nome: 'Ucrânia', iso3: 'UKR', sigla: 'UA' },
      { nome: 'Uganda', iso3: 'UGA', sigla: 'UG' },
      { nome: 'Uruguai', iso3: 'URY', sigla: 'UY' },
      { nome: 'Usbequistão', iso3: 'UZB', sigla: 'UZ' },
      { nome: 'Vanuatu', iso3: 'VUT', sigla: 'VU' },
      { nome: 'Vaticano', iso3: 'VAT', sigla: 'VA' },
      { nome: 'Venezuela', iso3: 'VEN', sigla: 'VE' },
      { nome: 'Vietname', iso3: 'VNM', sigla: 'VN' },
      { nome: 'Zâmbia', iso3: 'ZMB', sigla: 'ZM' },
      { nome: 'Zimbábue', iso3: 'ZWE', sigla: 'ZW' },
    ];
  }

  public listaNatJuridica(): { codigo: string, nome: string }[] {
    return [
      { codigo: '0000', nome: 'Natureza Jurídica não informada' },
      { codigo: '1015', nome: 'Órgão Público do Poder Executivo Federal' },
      { codigo: '1023', nome: 'Órgão Público do Poder Executivo Estadual ou do Distrito Federal' },
      { codigo: '1031', nome: 'Órgão Público do Poder Executivo Municipal' },
      { codigo: '1040', nome: 'Órgão Público do Poder Legislativo Federal' },
      { codigo: '1058', nome: 'Órgão Público do Poder Legislativo Estadual ou do Distrito Federal' },
      { codigo: '1066', nome: 'Órgão Público do Poder Legislativo Municipal' },
      { codigo: '1074', nome: 'Órgão Público do Poder Judiciário Federal' },
      { codigo: '1082', nome: 'Órgão Público do Poder Judiciário Estadual' },
      { codigo: '1104', nome: 'Autarquia Federal' },
      { codigo: '1112', nome: 'Autarquia Estadual ou do Distrito Federal' },
      { codigo: '1120', nome: 'Autarquia Municipal' },
      { codigo: '1139', nome: 'Fundação Pública de Direito Público Federal' },
      { codigo: '1147', nome: 'Fundação Pública de Direito Público Estadual ou do Distrito Federal' },
      { codigo: '1155', nome: 'Fundação Pública de Direito Público Municipal' },
      { codigo: '1163', nome: 'Órgão Público Autônomo Federal' },
      { codigo: '1171', nome: 'Órgão Público Autônomo Estadual ou do Distrito Federal' },
      { codigo: '1180', nome: 'Órgão Público Autônomo Municipal' },
      { codigo: '1198', nome: 'Comissão Polinacional' },
      { codigo: '1210', nome: 'Consórcio Público de Direito Público (Associação Pública)' },
      { codigo: '1228', nome: 'Consórcio Público de Direito Privado' },
      { codigo: '1236', nome: 'Estado ou Distrito Federal' },
      { codigo: '1244', nome: 'Município' },
      { codigo: '1252', nome: 'Fundação Pública de Direito Privado Federal' },
      { codigo: '1260', nome: 'Fundação Pública de Direito Privado Estadual ou do Distrito Federal' },
      { codigo: '1279', nome: 'Fundação Pública de Direito Privado Municipal' },
      { codigo: '1287', nome: 'Fundo Público da Administração Indireta Federal' },
      { codigo: '1295', nome: 'Fundo Público da Administração Indireta Estadual ou do Distrito Federal' },
      { codigo: '1309', nome: 'Fundo Público da Administração Indireta Municipal' },
      { codigo: '1317', nome: 'Fundo Público da Administração Direta Federal' },
      { codigo: '1325', nome: 'Fundo Público da Administração Direta Estadual ou do Distrito Federal' },
      { codigo: '1333', nome: 'Fundo Público da Administração Direta Municipal' },
      { codigo: '1341', nome: 'União' },
      { codigo: '2011', nome: 'Empresa Pública' },
      { codigo: '2038', nome: 'Sociedade de Economia Mista' },
      { codigo: '2046', nome: 'Sociedade Anônima Aberta' },
      { codigo: '2054', nome: 'Sociedade Anônima Fechada' },
      { codigo: '2062', nome: 'Sociedade Empresária Limitada' },
      { codigo: '2070', nome: 'Sociedade Empresária em Nome Coletivo' },
      { codigo: '2089', nome: 'Sociedade Empresária em Comandita Simples' },
      { codigo: '2097', nome: 'Sociedade Empresária em Comandita por Ações' },
      { codigo: '2100', nome: 'Sociedade Mercantil de Capital e Indústria' },
      { codigo: '2127', nome: 'Sociedade em Conta de Participação' },
      { codigo: '2135', nome: 'Empresário (Individual)' },
      { codigo: '2143', nome: 'Cooperativa' },
      { codigo: '2151', nome: 'Consórcio de Sociedades' },
      { codigo: '2160', nome: 'Grupo de Sociedades' },
      { codigo: '2178', nome: 'Estabelecimento, no Brasil, de Sociedade Estrangeira' },
      { codigo: '2194', nome: 'Estabelecimento, no Brasil, de Empresa Binacional Argentino-Brasileira' },
      { codigo: '2216', nome: 'Empresa Domiciliada no Exterior' },
      { codigo: '2224', nome: 'Clube/Fundo de Investimento' },
      { codigo: '2232', nome: 'Sociedade Simples Pura' },
      { codigo: '2240', nome: 'Sociedade Simples Limitada' },
      { codigo: '2259', nome: 'Sociedade Simples em Nome Coletivo' },
      { codigo: '2267', nome: 'Sociedade Simples em Comandita Simples' },
      { codigo: '2275', nome: 'Empresa Binacional' },
      { codigo: '2283', nome: 'Consórcio de Empregadores' },
      { codigo: '2291', nome: 'Consórcio Simples' },
      { codigo: '2305', nome: 'Empresa Individual de Responsabilidade Limitada (de Natureza Empresária)' },
      { codigo: '2313', nome: 'Empresa Individual de Responsabilidade Limitada (de Natureza Simples)' },
      { codigo: '2321', nome: 'Sociedade Unipessoal de Advocacia' },
      { codigo: '2330', nome: 'Cooperativas de Consumo' },
      { codigo: '2348', nome: 'Empresa Simples de Inovação - Inova Simples' },
      { codigo: '2356', nome: 'Investidor Não Residente' },
      { codigo: '3034', nome: 'Serviço Notarial e Registral (Cartório)' },
      { codigo: '3069', nome: 'Fundação Privada' },
      { codigo: '3077', nome: 'Serviço Social Autônomo' },
      { codigo: '3085', nome: 'Condomínio Edilício' },
      { codigo: '3107', nome: 'Comissão de Conciliação Prévia' },
      { codigo: '3115', nome: 'Entidade de Mediação e Arbitragem' },
      { codigo: '3131', nome: 'Entidade Sindical' },
      { codigo: '3204', nome: 'Estabelecimento, no Brasil, de Fundação ou Associação Estrangeiras' },
      { codigo: '3212', nome: 'Fundação ou Associação Domiciliada no Exterior' },
      { codigo: '3220', nome: 'Organização Religiosa' },
      { codigo: '3239', nome: 'Comunidade Indígena' },
      { codigo: '3247', nome: 'Fundo Privado' },
      { codigo: '3255', nome: 'Órgão de Direção Nacional de Partido Político' },
      { codigo: '3263', nome: 'Órgão de Direção Regional de Partido Político' },
      { codigo: '3271', nome: 'Órgão de Direção Local de Partido Político' },
      { codigo: '3280', nome: 'Comitê Financeiro de Partido Político' },
      { codigo: '3298', nome: 'Frente Plebiscitária ou Referendária' },
      { codigo: '3301', nome: 'Organização Social (OS)' },
      { codigo: '3328', nome: 'Plano de Benefícios de Previdência Complementar Fechada' },
      { codigo: '3999', nome: 'Associação Privada' },
      { codigo: '4014', nome: 'Empresa Individual Imobiliária' },
      { codigo: '4090', nome: 'Candidato a Cargo Político Eletivo' },
      { codigo: '4120', nome: 'Produtor Rural (Pessoa Física)' },
      { codigo: '5010', nome: 'Organização Internacional' },
      { codigo: '5029', nome: 'Representação Diplomática Estrangeira' },
      { codigo: '5037', nome: 'Outras Instituições Extraterritoriais' },
      { codigo: '8885', nome: 'Natureza Jurídica não informada' }
    ];
  }

  public descricaoProduto(produto: Produto) {
    if (!produto)
      return '';
    if (produto?.material?.sub_grupo?.grupo)
      return `${produto.material.sub_grupo.grupo.codigo}.${produto.material.sub_grupo.codigo}.${produto.material.codigo}.${produto.codigo} - ${produto.nome}`;
    if (produto.codigo)
      return `${produto.codigo} - ${produto.nome}`;
    if (produto?.nome)
      return produto.nome;
    return '';
  }

  public makeid(caracteres: number) {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < caracteres; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

}
