import { Directive, OnDestroy } from '@angular/core';
import { FuncaoService, GlobalService, LoginContabil, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AcaoGovernoService } from '../../../../planejamento/acao-governo/service/acao-governo.service';

@Directive()
export class Anexo6Ldo implements OnDestroy {
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: LoginContabil = new LoginContabil();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected ano: number,
    protected acaoServico: AcaoGovernoService

    ) {
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarRelatorio(orgaos: number[], opt?: number, ano?:number, desconsiderarIndicadoresZeradosExercicio?:boolean) {

    this.acaoServico.anexo3(this.login.ppa.id, orgaos, opt, ano, desconsiderarIndicadoresZeradosExercicio)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        Relatorio.imprimirPersonalizado(
          'ANEXO VI - PLANEJAMENTO ORÇAMENTÁRIO - LDO'
          , this.login.usuario.nome, this.login.usuario.sobrenome,
          this.login.orgao.nome, this.login.brasao,
          this.conteudo(dados, opt),
          'portrait', 'ANEXO VI - LDO',
          {
            linhas: {
              hLineWidth() {
                return 1;
              },
              vLineWidth() {
                return 1;
              },
              hLineColor() {
                return 'black';
              },
              paddingLeft() {
                return 3;
              },
              paddingRight() {
                return 3;
              }
            }
          });
      });
  }

  private conteudo(dados1: any[], opt: number): {}[] {
    if (dados1.length === 0) {
      return;
    }
    // monta relatório
    const registros = [];

    for (let index = 0; index < dados1.length; index++) {
      const item = dados1[index];
      registros.push({
        text: 'UNIDADES EXECUTORAS E AÇÕES VOLTADAS AO DESENVOLVIMENTO DO PROGRAMA GOVERNAMENTAL', alignment: 'center',
        bold: true, fontSize: 10
      });

      registros.push({ text: '', margin: [0, 5] });
      registros.push({ canvas: [{ type: 'line', x1: 1, y1: 1, x2: 530, y2: 1, lineWidth: 0.5 }] });
      registros.push({ text: '', margin: [0, 5] });

      registros.push({
        columns: [{ text: 'TIPO DE AÇÃO: ', width: 100, bold: true },
        this.globalService.obterEspecieMeta(item.ag_tipo)], margin: [0, 2]
      });
      registros.push({ columns: [{ text: 'UNIDADE EXECUTORA: ', width: 100, bold: true }, item.ue_nome], margin: [0, 2] });
      registros.push({ columns: [{ text: 'CÓDIGO DA UNIDADE: ', width: 100, bold: true }, item.ue_codigo], margin: [0, 2] });
      registros.push({
        columns: [{ text: 'FUNÇÃO DE GOVERNO: ', width: 100, bold: true },
        `${item.fu_codigo} ${item.fu_nome}`], margin: [0, 2]
      });
      registros.push({
        columns: [{ text: 'SUB-FUNÇÃO: ', width: 100, bold: true },
        `${item.sf_codigo} ${item.sf_nome}`], margin: [0, 2]
      });
      registros.push({ columns: [{ text: 'PROGRAMA: ', width: 100, bold: true }, item.pr_nome], margin: [0, 2] });
      registros.push({ columns: [{ text: 'CÓDIGO DO PROGRAMA: ', width: 100, bold: true }, item.pr_codigo], margin: [0, 2] });
      registros.push({ columns: [{ text: 'AÇÃO: ', width: 100, bold: true }, item.ac_nome], margin: [0, 2] });
      registros.push({ columns: [{ text: 'CÓDIGO DA AÇÃO: ', width: 100, bold: true }, item.ac_codigo], margin: [0, 2] });
      registros.push({ columns: [{ text: 'INDICADOR: ', width: 100, bold: true }, `${item.in_codigo} ${item.in_nome}`], margin: [0, 0, 0, 20] });

      registros.push({ text: '', margin: [0, 5] });
      registros.push({ canvas: [{ type: 'line', x1: 1, y1: 1, x2: 530, y2: 1, lineWidth: 0.5 }] });
      registros.push({ text: '', margin: [0, 5] });

      const retorno: {}[] = [];
      let custo: number;

      registros.push({
        text: 'METAS FÍSICAS', alignment: 'center',
        bold: true, fontSize: 10, margin: [0, 2]
      });

      retorno.push([
        { text: 'INDICADORES', bold: true, fontSize: 10, alignment: 'center' },
        { text: 'UNIDADE MEDIDA', bold: true, fontSize: 10, alignment: 'center' },
        { text: this.ano, bold: true, fontSize: 10, alignment: 'center' }
      ]);

      let valorMeta: number;

      switch (Number(this.ano)) {
        case this.login.ppa.ppaperiodo.ano1:
          valorMeta = opt == 1 ? item.in_meta1 : item.in_evolucao1;
          custo = item.ag_custo1;
          break;
        case this.login.ppa.ppaperiodo.ano2:
          valorMeta = opt == 1 ? item.in_meta2 : item.in_evolucao2;
          custo = item.ag_custo2;
          break;
        case this.login.ppa.ppaperiodo.ano3:
          valorMeta = opt == 1 ? item.in_meta3 : item.in_evolucao3;
          custo = item.ag_custo3;
          break;
        case this.login.ppa.ppaperiodo.ano4:
          valorMeta = opt == 1 ? item.in_meta4 : item.in_evolucao4;
          custo = item.ag_custo4;
          break;
        default:
          valorMeta = 0;
          custo = 0;
          break;
      }
      retorno.push([
        {
          text: item ? `${item.in_nome}` : '', fontSize: 10
        },
        {
          text: item ? item.in_unidade_medida : '', alignment: 'center',
          fontSize: 10
        },
        {
          text: item ? this.funcaoService.convertToBrNumber(valorMeta) : '', alignment: 'center',
          fontSize: 10
        }
      ]);


      registros.push({
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 2,
          widths: [300, '*', '*'],
          body: retorno
        }, margin: [0, 0, 0, 4]
      });

      registros.push({ text: 'CUSTO FINANCEIRO PARA O EXERCÍCIO', margin: [0, 10, 0, 0], bold: true, fontSize: 10 });
      registros.push({ canvas: [{ type: 'line', x1: 1, y1: 1, x2: 530, y2: 1, lineWidth: 0.5 }] });

      registros.push({
        columns: [{ text: 'R$: ', width: 20, bold: true },
        this.funcaoService.convertToBrNumber(custo)], margin: [0, 10], fontSize: 11, bold: true
      });

      if (index !== dados1.length - 1) {
        registros.push({ text: '', pageBreak: 'after' });
      }
    }
    return registros;
  }


}
