import { Component, Input, OnInit } from '@angular/core';
import { ProgressoService } from '../../components/progresso/service/progresso.service';
import { OrgaoConfiguracao } from '../../entidade/comum/orgao-configuracao.model';
import { Orgao } from '../../entidade/comum/orgao.model';
import { Login } from '../../entidade/login/login';
import { FuncaoService } from '../../util/funcao.service';
import { GlobalService } from '../../util/global.service';
import { PNCPService } from '../../util/pncp.service';
import { OrgaoService } from '../service/orgao.service';

interface SaveTypes {
  lable: string;
  value: string;
}
@Component({
  selector: 'lib-orgao-configuracao-shw',
  templateUrl: './orgao-configuracao-shw.component.html',
  styleUrls: ['./orgao-configuracao-shw.component.css']
})
export class OrgaoConfigracaoShwComponent implements OnInit {

  @Input() configuracao: OrgaoConfiguracao;
  @Input() visualizar: boolean;
  @Input() login: Login

  public saveTypes: SaveTypes[] = [
    { value: 'FIREBASE', lable: 'Firebase' },
    { value: 'SFTP', lable: 'SFTP' },
    { value: 'FTP', lable: 'FTP' },
  ]

  public cloudArmazenamentos: {
    id: string,
    nome: string
  }[] = [];

  private identificadorPncp: string = null;
  public possuiOrgaoPncp: boolean = false;
  public dadosPncp: any = null;
  public listaOrgaos: Orgao[] = []
  public maskedSenhaPncp: string = '';

  constructor(private funcaoService: FuncaoService,
    protected pncpService: PNCPService,
    protected progressoService: ProgressoService,
    private orgaoService: OrgaoService,
    private globalService: GlobalService) { }

  async ngOnInit(): Promise<void> {
    this.cloudArmazenamentos = this.globalService.obterCloudArmazenamento();
    this.listaOrgaos = (await (this.orgaoService.todos().toPromise())).content;
    if (this.configuracao && this.configuracao.senha_pncp) {
      // Substituir cada caractere por um asterisco
      this.maskedSenhaPncp = '*'.repeat(this.configuracao.senha_pncp.length);
    }
  }

  compareFn(c1: any, c2: any): boolean {
    if (c1 && c2) {
      if (c1.id && c2.id) {
        return c1.id === c2.id;
      } else if (c1.chave && c2.chave) {
        return c1.chave == c2.chave;
      } else {
        return c1 === c2;
      }
    } else {
      return false;
    }
  }

  public podeVisualizar() {
    if (this.visualizar === true) {
      return true;
    }
    if (this.visualizar === false) {
      return false;
    }
  }

  loginPncp() {
    this.pncpService.loginPncp(this.login.orgao.id).subscribe((idTransacao) => {
      this.progressoService.show(idTransacao, (retorno) => {
        this.login.token_pncp = retorno.mensagem;
        this.login.validade_pncp = `${new Date().getHours() + 1}h:${new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()}`;
        GlobalService.salvarSessaoLogin(this.login);
        this.identificadorPncp = retorno.identificador;
        this.buscarDadosPncp(this.identificadorPncp);
      });
    }, e => {
      alert('Atenção: ' + e.error.message);
    });
  }

  public buscarDadosPncp(login: string) {
    this.pncpService.consultarUsuarioPncp(login, this.login.token_pncp).subscribe((res) => {
      this.login.dados_pncp = res[0];
      console.log(res);
      GlobalService.salvarSessaoLogin(this.login);
      this.possuiOrgaoPncp = (res[0].entesAutorizados.find(e => e.cnpj == this.login.orgao.cnpj.replace(/\D/g, '')) ? true : false);
      this.dadosPncp = res[0];
      this.filtraOrgaosMunicipio()
    }, e => { toastr.error(e.error.message) });
  }

  public filtraOrgaosMunicipio() {
    let entidadesFiltrada = this.dadosPncp?.entesAutorizados?.filter(entidade => this.listaOrgaos.some(orgao => orgao.cnpj.replace(/\D/g, '') === entidade.cnpj))
    this.dadosPncp.entesAutorizados = entidadesFiltrada
    console.log(entidadesFiltrada)
  }


  public adicionarOrgao() {
    if (this.dadosPncp) {
      this.pncpService.inserirEnteAutorizado(this.dadosPncp.id, this.login.orgao.cnpj.replace(/\D/g, ''), this.login.token_pncp)
        .subscribe((res) => {
          toastr.success('Ente inserido com sucesso!');
          this.buscarDadosPncp(this.identificadorPncp);
        }, e => {
          toastr.error(e.error.message);
        });
    }
  }

  public removerOrgao() {
    if (this.dadosPncp) {
      this.pncpService.removerEnteAutorizado(this.dadosPncp.id, this.login.orgao.cnpj.replace(/\D/g, ''), this.login.token_pncp)
        .subscribe((res) => {
          toastr.success('Ente removido com sucesso!');
          this.buscarDadosPncp(this.identificadorPncp);
        }, e => {
          toastr.error(e.error.message);
        });
    }
  }

  administrador() {
    return (new FuncaoService().campoJsonToken(this.login.token, 'administrador') == true) && this.login.usuario.sistemas_administrador?.includes(this.login.usuario.sistema);
  }
}
