import { Directive, Injector, OnDestroy } from '@angular/core';
import { Coluna, Exercicio, FormatoExportacao, FuncaoService, GlobalService, Login, OrgaoService, Relatorio } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Assinaturas } from '../../../../assinaturas/assinaturas';
import { AnexoLrfFederalService } from '../../../service/anexo-lrf-federal.service';

@Directive()
export class Anexo1RgfLRF implements OnDestroy {
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  private login: Login = new Login();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoFederalServico: AnexoLrfFederalService,
    protected mes: number,
    protected exercicio: Exercicio,
    protected injector: Injector,
    protected assinaturaControleInterno: boolean,
    protected orgaoService: OrgaoService) {
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarRelatorio(orgaos: number[], formato: FormatoExportacao) {
    this.orgaoService.obterPorEspecie('P', this.login.cidade.id).pipe(takeUntil(this.unsubscribe)).subscribe(async orgao => {

      this.anexoFederalServico.obterAnexo1Rgf(this.mes, this.exercicio.id, orgaos)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(async dados => {

          this.anexoFederalServico.obterAnexo3Resuldado(this.mes, this.exercicio.id, orgaos.includes(orgao.id) ? orgaos : orgaos.concat(orgao.id))
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(async resultado => {
              if (formato === 'pdf') {
                Relatorio.imprimirPersonalizado(null, this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
                  await this.conteudo(dados, orgaos, resultado),
                  'landscape', 'RGF ANEXO1 - DEMONSTRATIVO DA DESPESA COM PESSOAL',
                  {
                    linhas: {
                      hLineWidth() { return 1; },
                      vLineWidth() { return 1; },
                      hLineColor() { return 'black'; },
                      paddingLeft() { return 2; },
                      paddingRight() { return 2; }
                    }
                  }, true, false);
              } else {
                this.funcaoService.exportar(formato, this.normalizar(dados, orgaos, resultado), 'RGF ANEXO1 - DEMONSTRATIVO DA DESPESA COM PESSOAL', this.colunas());
              }
            });
        });
    });
  }

  private async conteudo(dados: any[], orgaos: any, resultado: number): Promise<{}[]> {
    const registros: {}[] = [
      [
        { text: this.login.orgao.nome, bold: true, border: [false, false, false, false], colSpan: 15, fontSize: 9, margin: [0, 0, 0, 0] },
        '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
      [
        { text: 'RELATÓRIO DE GESTÃO FISCAL', bold: true, border: [false, false, false, false], colSpan: 15, fontSize: 6, margin: [0, 0, 0, 0] },
        '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
      [
        { text: 'DEMONSTRATIVO DA DESPESA COM PESSOAL ', bold: true, border: [false, false, false, false], colSpan: 15, fontSize: 6, margin: [0, 0, 0, 0] },
        '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
      [
        { text: 'ORÇAMENTOS FISCAL E DA SEGURIDADE SOCIAL', bold: true, border: [false, false, false, false], colSpan: 15, fontSize: 6, margin: [0, 0, 0, 0] },
        '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
      [
        {
          text: `REFERÊNCIA: ${this.globalService.obterDataBR().monthNames[this.mes - 1].toLocaleUpperCase() + '/' + this.exercicio.ano}`,
          bold: true, border: [false, false, false, false], colSpan: 15, fontSize: 6, margin: [0, 0, 0, 0]
        },
        '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
      [
        { text: 'RGF - ANEXO 1 (LRF, art. 55, inciso I, alínea "a")', bold: true, border: [false, false, false, false], colSpan: 15, fontSize: 6, margin: [0, 0, 0, 0] },
        '', '', '', '', '', '', '', '', '', '', '', '', '', ''
      ],
      [
        {
          text: 'ESPECIFICAÇÃO',
          rowSpan: 2, alignment: 'center', bold: true, fontSize: 5
        },
        {
          text: 'DESPESAS EXECUTADAS',
          alignment: 'center',
          bold: true,
          colSpan: 12, fontSize: 5
        }, '', '', '', '', '', '', '', '', '', '', '',
        {
          text: 'Total 12 meses',
          rowSpan: 2, alignment: 'center', bold: true, fontSize: 5
        },
        {
          text: 'INSCRITAS EM RESTOS A PAGAR NÃO PROCESSADOS (b)',
          rowSpan: 2, alignment: 'center', bold: true, fontSize: 5
        }
      ],
      [
        { text: '' },
        { text: this.retornarNomeMes(-11), alignment: 'center', bold: true, fontSize: 6 },
        { text: this.retornarNomeMes(-10), alignment: 'center', bold: true, fontSize: 6 },
        { text: this.retornarNomeMes(-9), alignment: 'center', bold: true, fontSize: 6 },
        { text: this.retornarNomeMes(-8), alignment: 'center', bold: true, fontSize: 6 },
        { text: this.retornarNomeMes(-7), alignment: 'center', bold: true, fontSize: 6 },
        { text: this.retornarNomeMes(-6), alignment: 'center', bold: true, fontSize: 6 },
        { text: this.retornarNomeMes(-5), alignment: 'center', bold: true, fontSize: 6 },
        { text: this.retornarNomeMes(-4), alignment: 'center', bold: true, fontSize: 6 },
        { text: this.retornarNomeMes(-3), alignment: 'center', bold: true, fontSize: 6 },
        { text: this.retornarNomeMes(-2), alignment: 'center', bold: true, fontSize: 6 },
        { text: this.retornarNomeMes(-1), alignment: 'center', bold: true, fontSize: 6 },
        { text: this.retornarNomeMes(0), alignment: 'center', bold: true, fontSize: 6 }
      ]
    ];

    const ass = new Assinaturas(this.login.orgao, this.injector);
    let assinaturas = await ass.dadosAssinatura(null, false, null, this.assinaturaControleInterno);

    const titulos = this.funcaoService.agrupar(dados, ['titulo', 'somador'],
      ['soma0', 'soma1', 'soma2', 'soma3', 'soma4', 'soma5', 'soma6', 'soma7', 'soma8', 'soma9', 'soma10', 'soma11', 'resto']);
    let total0 = 0;
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    let total5 = 0;
    let total6 = 0;
    let total7 = 0;
    let total8 = 0;
    let total9 = 0;
    let total10 = 0;
    let total11 = 0;
    let resto = 0;
    for (const titulo of titulos) {
      registros.push([
        { text: titulo.grupo['titulo'], fontSize: 5, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma11']), alignment: 'right', fontSize: 5, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma10']), alignment: 'right', fontSize: 5, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma9']), alignment: 'right', fontSize: 5, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma8']), alignment: 'right', fontSize: 5, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma7']), alignment: 'right', fontSize: 5, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma6']), alignment: 'right', fontSize: 5, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma5']), alignment: 'right', fontSize: 5, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma4']), alignment: 'right', fontSize: 5, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma3']), alignment: 'right', fontSize: 5, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma2']), alignment: 'right', fontSize: 5, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma1']), alignment: 'right', fontSize: 5, bold: true },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma0']), alignment: 'right', fontSize: 5, bold: true },
        {
          text: this.funcaoService.convertToBrNumber(+titulo.totalizadores['soma0'] + +titulo.totalizadores['soma1'] + +titulo.totalizadores['soma2'] + +titulo.totalizadores['soma3'] +
            +titulo.totalizadores['soma4'] + +titulo.totalizadores['soma5'] + +titulo.totalizadores['soma6'] + +titulo.totalizadores['soma7'] +
            +titulo.totalizadores['soma8'] + +titulo.totalizadores['soma9'] + +titulo.totalizadores['soma10'] + +titulo.totalizadores['soma11']),
          alignment: 'right', fontSize: 5, bold: true
        },
        { text: this.funcaoService.convertToBrNumber(titulo.totalizadores['resto']), alignment: 'right', fontSize: 5, bold: true }
      ]);
      if (titulo.grupo['somador']) {
        total0 += +titulo.totalizadores['soma0'];
        total1 += +titulo.totalizadores['soma1'];
        total2 += +titulo.totalizadores['soma2'];
        total3 += +titulo.totalizadores['soma3'];
        total4 += +titulo.totalizadores['soma4'];
        total5 += +titulo.totalizadores['soma5'];
        total6 += +titulo.totalizadores['soma6'];
        total7 += +titulo.totalizadores['soma7'];
        total8 += +titulo.totalizadores['soma8'];
        total9 += +titulo.totalizadores['soma9'];
        total10 += +titulo.totalizadores['soma10'];
        total11 += +titulo.totalizadores['soma11'];
        resto += +titulo.totalizadores['resto'];
      } else {
        total0 -= +titulo.totalizadores['soma0'];
        total1 -= +titulo.totalizadores['soma1'];
        total2 -= +titulo.totalizadores['soma2'];
        total3 -= +titulo.totalizadores['soma3'];
        total4 -= +titulo.totalizadores['soma4'];
        total5 -= +titulo.totalizadores['soma5'];
        total6 -= +titulo.totalizadores['soma6'];
        total7 -= +titulo.totalizadores['soma7'];
        total8 -= +titulo.totalizadores['soma8'];
        total9 -= +titulo.totalizadores['soma9'];
        total10 -= +titulo.totalizadores['soma10'];
        total11 -= +titulo.totalizadores['soma11'];
        resto -= +titulo.totalizadores['resto'];
      }

      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo',
        ['soma0', 'soma1', 'soma2', 'soma3', 'soma4', 'soma5', 'soma6', 'soma7', 'soma8', 'soma9', 'soma10', 'soma11', 'resto']);
      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          registros.push([
            { text: grupo.grupo, fontSize: 5, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma11']), alignment: 'right', fontSize: 5, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma10']), alignment: 'right', fontSize: 5, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma9']), alignment: 'right', fontSize: 5, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma8']), alignment: 'right', fontSize: 5, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma7']), alignment: 'right', fontSize: 5, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma6']), alignment: 'right', fontSize: 5, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma5']), alignment: 'right', fontSize: 5, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma4']), alignment: 'right', fontSize: 5, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma3']), alignment: 'right', fontSize: 5, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma2']), alignment: 'right', fontSize: 5, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma1']), alignment: 'right', fontSize: 5, bold: true },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma0']), alignment: 'right', fontSize: 5, bold: true },
            {
              text: this.funcaoService.convertToBrNumber(
                +grupo.totalizadores['soma0'] + +grupo.totalizadores['soma1'] + +grupo.totalizadores['soma2'] + +grupo.totalizadores['soma3'] +
                +grupo.totalizadores['soma4'] + +grupo.totalizadores['soma5'] + +grupo.totalizadores['soma6'] + +grupo.totalizadores['soma7'] +
                +grupo.totalizadores['soma8'] + +grupo.totalizadores['soma9'] + +grupo.totalizadores['soma10'] + +grupo.totalizadores['soma11']),
              alignment: 'right', fontSize: 5, bold: true
            },
            { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['resto']), alignment: 'right', fontSize: 5, bold: true }
          ]);


          for (const item of grupo.registros) {
            if (item.nome != '') {
              registros.push([
                {
                  text: item.nome, fontSize: 5, margin: [5, 0, 0, 0]
                },
                { text: this.funcaoService.convertToBrNumber(item.soma11), alignment: 'right', fontSize: 5 },
                { text: this.funcaoService.convertToBrNumber(item.soma10), alignment: 'right', fontSize: 5 },
                { text: this.funcaoService.convertToBrNumber(item.soma9), alignment: 'right', fontSize: 5 },
                { text: this.funcaoService.convertToBrNumber(item.soma8), alignment: 'right', fontSize: 5 },
                { text: this.funcaoService.convertToBrNumber(item.soma7), alignment: 'right', fontSize: 5 },
                { text: this.funcaoService.convertToBrNumber(item.soma6), alignment: 'right', fontSize: 5 },
                { text: this.funcaoService.convertToBrNumber(item.soma5), alignment: 'right', fontSize: 5 },
                { text: this.funcaoService.convertToBrNumber(item.soma4), alignment: 'right', fontSize: 5 },
                { text: this.funcaoService.convertToBrNumber(item.soma3), alignment: 'right', fontSize: 5 },
                { text: this.funcaoService.convertToBrNumber(item.soma2), alignment: 'right', fontSize: 5 },
                { text: this.funcaoService.convertToBrNumber(item.soma1), alignment: 'right', fontSize: 5 },
                { text: this.funcaoService.convertToBrNumber(item.soma0), alignment: 'right', fontSize: 5 },
                {
                  text: this.funcaoService.convertToBrNumber(
                    +item.soma0 + +item.soma1 + +item.soma2 + +item.soma3 + +item.soma4 + +item.soma5 + +item.soma6 +
                    +item.soma7 + +item.soma8 + +item.soma9 + +item.soma10 + +item.soma11
                  ), alignment: 'right', fontSize: 5
                },
                { text: this.funcaoService.convertToBrNumber(item.previsto), alignment: 'right', fontSize: 5 }
              ]);
            }
          }

        }
      }
    }

    registros.push([
      {
        text: 'DESPESA LÍQUIDA COM PESSOAL (III) = (I - II)', fontSize: 5, bold: true
      },
      { text: this.funcaoService.convertToBrNumber(total11), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total10), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total9), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total8), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total7), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total6), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total5), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total4), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total3), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total2), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total1), alignment: 'right', fontSize: 5, bold: true },
      { text: this.funcaoService.convertToBrNumber(total0), alignment: 'right', fontSize: 5, bold: true },
      {
        text: this.funcaoService.convertToBrNumber(
          +total0 + +total1 + +total2 + +total3 + +total4 + +total5 + +total6 + +total7 + +total8 + +total9 + +total10 + +total11),
        alignment: 'right', fontSize: 5, bold: true
      },
      { text: this.funcaoService.convertToBrNumber(resto), alignment: 'right', fontSize: 5, bold: true }
    ]);

    // resultados --------------------------------------
    const total_gasto_pessoal = +total0 + +total1 + +total2 + +total3 + +total4 + +total5 + +total6 + +total7 + +total8 + +total9 + +total10 + +total11;
    const linhas: {}[] = [
      [
        { text: '', bold: true, border: [false, false, false, false], colSpan: 3, fontSize: 9, margin: [5, 5, 5, 5] }, '', ''
      ],
      [
        { text: 'APURAÇÃO DO CUMPRIMENTO DO LIMITE LEGAL', bold: true, fontSize: 6, alignment: 'center' },
        { text: 'VALOR', bold: true, fontSize: 6, alignment: 'center' },
        { text: '% SOBRE A RCL AJUSTADA', bold: true, fontSize: 6, alignment: 'center' }
      ]
    ]
    linhas.push([
      {
        text: 'RECEITA CORRENTE LÍQUIDA - RCL (IV)', fontSize: 6
      },
      { text: this.funcaoService.convertToBrNumber(resultado), alignment: 'right', fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber((+total_gasto_pessoal / resultado) * 100), alignment: 'right', fontSize: 6 }
    ]);



    linhas.push([
      {
        text: '(-) Transferências obrigatórias da União relativas às emendas individuais (art. 166-A, § 1º, da CF) (V) ', fontSize: 6,
      },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 6 }
    ]);
    linhas.push([
      {
        text: '(-) Transferências obrigatórias da União relativas às emendas de bancada (art. 166, § 16 da CF) (VI)', fontSize: 6,
      },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 6 }
    ]);
    linhas.push([
      {
        text: 'RECEITA CORRENTE LÍQUIDA AJUSTADA PARA CÁLCULO DOS LIMITES DA DESPESA COM PESSOAL (VII) = (IV - V - VI)', fontSize: 6,
      },
      { text: this.funcaoService.convertToBrNumber(resultado), alignment: 'right', fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 6 }
    ]);
    linhas.push([
      {
        text: 'DESPESA TOTAL COM PESSOAL - DTP (VIII) = (III a + III b)', fontSize: 6,
      },
      { text: this.funcaoService.convertToBrNumber(+total_gasto_pessoal + +resto), alignment: 'right', fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber((+total_gasto_pessoal + +resto) / resultado), alignment: 'right', fontSize: 6 }
    ]);

    linhas.push([
      {
        text: 'LIMITE MÁXIMO (IX) (incisos I, II e III, art. 20 da LRF) ', fontSize: 6,
      },
      { text: this.funcaoService.convertToBrNumber(resultado * 0.54), alignment: 'right', fontSize: 6 },
      { text: '54,00', alignment: 'right', fontSize: 6 }
    ]);

    linhas.push([
      {
        text: 'LIMITE PRUDENCIAL (X) = (0,95 x IX) (parágrafo único do art. 22 da LRF)', fontSize: 6,
      },
      { text: this.funcaoService.convertToBrNumber((total_gasto_pessoal * 0.54) * 0.95), alignment: 'right', fontSize: 6 },
      { text: '51,30', alignment: 'right', fontSize: 6 }
    ]);
    linhas.push([
      {
        text: 'LIMITE DE ALERTA (XI) = (0,90 x IX) (inciso II do §1º do art. 59 da LRF) ', fontSize: 6,
      },
      { text: this.funcaoService.convertToBrNumber((total_gasto_pessoal * 0.54) * 0.90), alignment: 'right', fontSize: 6 },
      { text: this.funcaoService.convertToBrNumber((((total_gasto_pessoal * 0.54) * 0.90) / resultado) * 100), alignment: 'right', fontSize: 6 }
    ]);

    // -----------------------------------------
    let quadrimestre = '';
    let quadri_1 = '';
    let quadri_2 = '';
    let ano1 = 0;
    let ano2 = 0;
    if (this.mes <= 4) {
      quadrimestre = '1º Quadrimestre';
      quadri_1 = '2º Quadrimestre';
      quadri_2 = '3º Quadrimestre';
      ano1 = this.exercicio.ano;
      ano2 = this.exercicio.ano;
    } else if (this.mes <= 8) {
      quadrimestre = '2º Quadrimestre';
      quadri_1 = '3º Quadrimestre';
      quadri_2 = '1º Quadrimestre';
      ano1 = this.exercicio.ano;
      ano2 = this.exercicio.ano + 1;
    } else {
      quadrimestre = '3º Quadrimestre';
      quadri_1 = '1º Quadrimestre';
      quadri_2 = '2º Quadrimestre';
      ano1 = this.exercicio.ano + 1;
      ano2 = this.exercicio.ano + 1;
    }

    const linhas1: {}[] = [
      [
        { text: '', bold: true, border: [false, false, false, false], colSpan: 9, margin: [5, 5, 5, 5] }, '', '', '', '', '', '', '', ''
      ],
      [
        { text: 'TRAJETÓRIA DE RETORNO AO LIMITE DA DESPESA TOTAL COM PESSOAL', alignment: 'center', bold: true, colSpan: 9, fontSize: 8 }, '', '', '', '', '', '', '', ''
      ],
      [
        { text: this.exercicio.ano, bold: true, fontSize: 6, alignment: 'center', colSpan: 3 },
        '', '',
        { text: ano1, bold: true, fontSize: 6, alignment: 'center', colSpan: 3 },
        '', '',
        { text: ano2, bold: true, fontSize: 6, alignment: 'center', colSpan: 3 },
        '', ''
      ],
      [
        { text: quadrimestre, bold: true, fontSize: 6, alignment: 'center', colSpan: 3 },
        '', '',
        { text: quadri_1, bold: true, fontSize: 6, alignment: 'center', colSpan: 3 },
        '', '',
        { text: quadri_2, bold: true, fontSize: 6, alignment: 'center', colSpan: 3 },
        '', ''
      ],
      [
        { text: 'Limite Máximo', bold: true, fontSize: 6, alignment: 'center', border: [true, false, false, false, true] },
        { text: '% DTP', bold: true, fontSize: 6, alignment: 'center', border: [true, false, false, false, true] },
        { text: '% Excedente', bold: true, fontSize: 6, alignment: 'center', border: [true, false, false, false, false] },
        { text: 'Redutor mínimo de 1/3 do Excedente', bold: true, fontSize: 6, alignment: 'center', border: [true, false, false, false, false] },
        { text: 'Limite', bold: true, fontSize: 6, alignment: 'center', border: [true, false, false, false, false] },
        { text: '% DTP', bold: true, fontSize: 6, alignment: 'center', border: [true, false, false, false, false] },
        { text: 'Redutor Residual', bold: true, fontSize: 6, alignment: 'center', border: [true, false, false, false, false] },
        { text: 'Limite', bold: true, fontSize: 6, alignment: 'center', border: [true, false, false, false, false] },
        { text: '% DTP', bold: true, fontSize: 6, alignment: 'center', border: [true, false, true, false, true] }
      ]
    ];

    const limite = (+total_gasto_pessoal + +resto) / resultado;

    linhas1.push([
      {
        text: '54%', fontSize: 6, alignment: 'center', border: [true, false, false, true, true]
      },
      { text: this.funcaoService.convertToBrNumber(limite), alignment: 'center', fontSize: 6, border: [true, false, false, true, true] },
      { text: this.funcaoService.convertToBrNumber(limite - 54), alignment: 'center', fontSize: 6, border: [true, false, false, true, true] },
      { text: this.funcaoService.convertToBrNumber((limite - 54) / 3), alignment: 'center', fontSize: 6, border: [true, false, false, true, true] },
      { text: this.funcaoService.convertToBrNumber(limite - ((limite - 54) / 3)), alignment: 'center', fontSize: 6, border: [true, false, false, true, true] },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'center', fontSize: 6, border: [true, false, false, true, true] },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'center', fontSize: 6, border: [true, false, false, true, true] },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'center', fontSize: 6, border: [true, false, false, true, true] },
      { text: this.funcaoService.convertToBrNumber(0), alignment: 'center', fontSize: 6, border: [true, false, true, true, true] }
    ]);

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 0,
        widths: ['*', 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 45, 45],
        body: registros
      }
    }, {
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 0,
        widths: ['*', 80, 80],
        body: linhas
      }
    },
    {
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 0,
        widths: ['*', 50, 50, 50, 50, 50, 50, 50, 50],
        body: linhas1
      }
    },
    {
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 0,
        widths: ['*', '*', '*'],
        body: assinaturas
      }
    }
    ];

  }

  retornarNomeMes(diminuir: number) {
    const fator: number = +this.mes + +diminuir;
    if (fator > 0) {
      return ('00' + fator).slice(-2)
        + `/${this.exercicio.ano}`;
    } else {
      const meses: number = +12 + +fator;
      return ('00' + meses).slice(-2) + `/${+this.exercicio.ano - 1}`;
    }
  }

  public normalizar(lista: any[], orgaos: any, resultado: number) {
    const listaExportar = []

    const titulos = this.funcaoService.agrupar(lista, ['titulo', 'somador'],
      ['soma0', 'soma1', 'soma2', 'soma3', 'soma4', 'soma5', 'soma6', 'soma7', 'soma8', 'soma9', 'soma10', 'soma11', 'resto']);
    let total0 = 0;
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    let total5 = 0;
    let total6 = 0;
    let total7 = 0;
    let total8 = 0;
    let total9 = 0;
    let total10 = 0;
    let total11 = 0;
    let resto = 0;

    for (const titulo of titulos) {
      const primeira_linha = {
        titulo: titulo.grupo['titulo'],
        soma11: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma11']),
        soma10: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma10']),
        soma9: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma9']),
        soma8: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma8']),
        soma7: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma7']),
        soma6: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma6']),
        soma5: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma5']),
        soma4: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma4']),
        soma3: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma3']),
        soma2: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma2']),
        soma1: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma1']),
        soma0: this.funcaoService.convertToBrNumber(titulo.totalizadores['soma0']),
        soma_total: this.funcaoService.convertToBrNumber(+titulo.totalizadores['soma0'] + +titulo.totalizadores['soma1'] + +titulo.totalizadores['soma2'] + +titulo.totalizadores['soma3'] +
          +titulo.totalizadores['soma4'] + +titulo.totalizadores['soma5'] + +titulo.totalizadores['soma6'] + +titulo.totalizadores['soma7'] +
          +titulo.totalizadores['soma8'] + +titulo.totalizadores['soma9'] + +titulo.totalizadores['soma10'] + +titulo.totalizadores['soma11']),
        resto: this.funcaoService.convertToBrNumber(titulo.totalizadores['resto'])
      }
      listaExportar.push(primeira_linha)

      if (titulo.grupo['somador']) {
        total0 += +titulo.totalizadores['soma0'];
        total1 += +titulo.totalizadores['soma1'];
        total2 += +titulo.totalizadores['soma2'];
        total3 += +titulo.totalizadores['soma3'];
        total4 += +titulo.totalizadores['soma4'];
        total5 += +titulo.totalizadores['soma5'];
        total6 += +titulo.totalizadores['soma6'];
        total7 += +titulo.totalizadores['soma7'];
        total8 += +titulo.totalizadores['soma8'];
        total9 += +titulo.totalizadores['soma9'];
        total10 += +titulo.totalizadores['soma10'];
        total11 += +titulo.totalizadores['soma11'];
        resto += +titulo.totalizadores['resto'];
      } else {
        total0 -= +titulo.totalizadores['soma0'];
        total1 -= +titulo.totalizadores['soma1'];
        total2 -= +titulo.totalizadores['soma2'];
        total3 -= +titulo.totalizadores['soma3'];
        total4 -= +titulo.totalizadores['soma4'];
        total5 -= +titulo.totalizadores['soma5'];
        total6 -= +titulo.totalizadores['soma6'];
        total7 -= +titulo.totalizadores['soma7'];
        total8 -= +titulo.totalizadores['soma8'];
        total9 -= +titulo.totalizadores['soma9'];
        total10 -= +titulo.totalizadores['soma10'];
        total11 -= +titulo.totalizadores['soma11'];
        resto -= +titulo.totalizadores['resto'];
      }

      const grupos = this.funcaoService.agrupar(titulo.registros, 'grupo',
        ['soma0', 'soma1', 'soma2', 'soma3', 'soma4', 'soma5', 'soma6', 'soma7', 'soma8', 'soma9', 'soma10', 'soma11', 'resto']);
      for (const grupo of grupos) {
        if (grupo.grupo != '') {
          const linha_orgao = {
            titulo: grupo.grupo,
            soma11: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma11']),
            soma10: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma10']),
            soma9: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma9']),
            soma8: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma8']),
            soma7: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma7']),
            soma6: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma6']),
            soma5: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma5']),
            soma4: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma4']),
            soma3: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma3']),
            soma2: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma2']),
            soma1: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma1']),
            soma0: this.funcaoService.convertToBrNumber(grupo.totalizadores['soma0']),
            soma_total: this.funcaoService.convertToBrNumber(+grupo.totalizadores['soma0'] + +grupo.totalizadores['soma1'] + +grupo.totalizadores['soma2'] + +grupo.totalizadores['soma3'] +
              +grupo.totalizadores['soma4'] + +grupo.totalizadores['soma5'] + +grupo.totalizadores['soma6'] + +grupo.totalizadores['soma7'] +
              +grupo.totalizadores['soma8'] + +grupo.totalizadores['soma9'] + +grupo.totalizadores['soma10'] + +grupo.totalizadores['soma11']),
            resto: this.funcaoService.convertToBrNumber(grupo.totalizadores['resto'])
          }
          listaExportar.push(linha_orgao)
        }

        for (const item of grupo.registros) {
          if (item.nome != '') {
            const linha_item = {
              titulo: item.nome,
              soma11: this.funcaoService.convertToBrNumber(item.soma11),
              soma10: this.funcaoService.convertToBrNumber(item.soma10),
              soma9: this.funcaoService.convertToBrNumber(item.soma9),
              soma8: this.funcaoService.convertToBrNumber(item.soma8),
              soma7: this.funcaoService.convertToBrNumber(item.soma7),
              soma6: this.funcaoService.convertToBrNumber(item.soma6),
              soma5: this.funcaoService.convertToBrNumber(item.soma5),
              soma4: this.funcaoService.convertToBrNumber(item.soma4),
              soma3: this.funcaoService.convertToBrNumber(item.soma3),
              soma2: this.funcaoService.convertToBrNumber(item.soma2),
              soma1: this.funcaoService.convertToBrNumber(item.soma1),
              soma0: this.funcaoService.convertToBrNumber(item.soma0),
              soma_total: this.funcaoService.convertToBrNumber(+item.soma0 + +item.soma1 + +item.soma2 + +item.soma3 +
                +item.soma4 + +item.soma5 + +item.soma6 + +item.soma7 +
                +item.soma8 + +item.soma9 + +item.soma10 + +item.soma11),
              resto: this.funcaoService.convertToBrNumber(item.resto)
            }
            listaExportar.push(linha_item)
          }
        }
      }
    }

    const linha_despesa_liquida = {
      titulo: 'DESPESA LÍQUIDA COM PESSOAL (III) = (I - II)',
      soma11: this.funcaoService.convertToBrNumber(total11),
      soma10: this.funcaoService.convertToBrNumber(total10),
      soma9: this.funcaoService.convertToBrNumber(total9),
      soma8: this.funcaoService.convertToBrNumber(total8),
      soma7: this.funcaoService.convertToBrNumber(total7),
      soma6: this.funcaoService.convertToBrNumber(total6),
      soma5: this.funcaoService.convertToBrNumber(total5),
      soma4: this.funcaoService.convertToBrNumber(total4),
      soma3: this.funcaoService.convertToBrNumber(total3),
      soma2: this.funcaoService.convertToBrNumber(total2),
      soma1: this.funcaoService.convertToBrNumber(total1),
      soma0: this.funcaoService.convertToBrNumber(total0),
      soma_total: this.funcaoService.convertToBrNumber(+total0 + +total1 + +total2 + +total3 +
        +total4 + +total5 + +total6 + +total7 +
        +total8 + +total9 + +total10 + +total11),
      resto: this.funcaoService.convertToBrNumber(resto)
    }
    listaExportar.push(linha_despesa_liquida)

    const espaco = {
      receitas: '',
      previsao_atualizada: '',
      previsao_realizada: '',
      saldo_nao_realizado: '',
      despesas_pagas: '',
      despesas_inscritas: '',
      pagamento_resto: '',
      saldo_despesa: ''
    }
    listaExportar.push(espaco)

    const total_gasto_pessoal = +total0 + +total1 + +total2 + +total3 + +total4 + +total5 + +total6 + +total7 + +total8 + +total9 + +total10 + +total11;

    const linha_titulo_apuracao = {
      titulo: 'APURAÇÃO DO CUMPRIMENTO DO LIMITE LEGAL',
      soma11: 'VALOR',
      soma10: '% SOBRE A RCL AJUSTADA',
      soma9: '',
      soma8: '',
      soma7: '',
      soma6: '',
      soma5: '',
      soma4: '',
      soma3: '',
      soma2: '',
      soma1: '',
      soma0: '',
      soma_total: '',
      resto: ''
    }
    listaExportar.push(linha_titulo_apuracao)

    const linha_receita_liquida = {
      titulo: 'RECEITA CORRENTE LÍQUIDA - RCL (IV)',
      soma11: this.funcaoService.convertToBrNumber(resultado),
      soma10: this.funcaoService.convertToBrNumber((+total_gasto_pessoal / resultado) * 100),
      soma9: '',
      soma8: '',
      soma7: '',
      soma6: '',
      soma5: '',
      soma4: '',
      soma3: '',
      soma2: '',
      soma1: '',
      soma0: '',
      soma_total: '',
      resto: ''
    }
    listaExportar.push(linha_receita_liquida)

    const linha_transferencia_individual = {
      titulo: '(-) Transferências obrigatórias da União relativas às emendas individuais (art. 166-A, § 1º, da CF) (V)',
      soma11: this.funcaoService.convertToBrNumber(0),
      soma10: this.funcaoService.convertToBrNumber(0),
      soma9: '',
      soma8: '',
      soma7: '',
      soma6: '',
      soma5: '',
      soma4: '',
      soma3: '',
      soma2: '',
      soma1: '',
      soma0: '',
      soma_total: '',
      resto: ''
    }
    listaExportar.push(linha_transferencia_individual)

    const linha_transferencia_bancaria = {
      titulo: '(-) Transferências obrigatórias da União relativas às emendas de bancada (art. 166, § 16 da CF) (VI)',
      soma11: this.funcaoService.convertToBrNumber(0),
      soma10: this.funcaoService.convertToBrNumber(0),
      soma9: '',
      soma8: '',
      soma7: '',
      soma6: '',
      soma5: '',
      soma4: '',
      soma3: '',
      soma2: '',
      soma1: '',
      soma0: '',
      soma_total: '',
      resto: ''
    }
    listaExportar.push(linha_transferencia_bancaria)

    const linha_receita_ajustada = {
      titulo: 'RECEITA CORRENTE LÍQUIDA AJUSTADA PARA CÁLCULO DOS LIMITES DA DESPESA COM PESSOAL (VII) = (IV - V - VI)',
      soma11: this.funcaoService.convertToBrNumber(resultado),
      soma10: this.funcaoService.convertToBrNumber(0),
      soma9: '',
      soma8: '',
      soma7: '',
      soma6: '',
      soma5: '',
      soma4: '',
      soma3: '',
      soma2: '',
      soma1: '',
      soma0: '',
      soma_total: '',
      resto: ''
    }
    listaExportar.push(linha_receita_ajustada)

    const linha_despesa_total = {
      titulo: 'DESPESA TOTAL COM PESSOAL - DTP (VIII) = (III a + III b)',
      soma11: this.funcaoService.convertToBrNumber(+total_gasto_pessoal + +resto),
      soma10: this.funcaoService.convertToBrNumber((+total_gasto_pessoal + +resto) / resultado),
      soma9: '',
      soma8: '',
      soma7: '',
      soma6: '',
      soma5: '',
      soma4: '',
      soma3: '',
      soma2: '',
      soma1: '',
      soma0: '',
      soma_total: '',
      resto: ''
    }
    listaExportar.push(linha_despesa_total)

    const linha_limite_maximo = {
      titulo: 'LIMITE MÁXIMO (IX) (incisos I, II e III, art. 20 da LRF)',
      soma11: this.funcaoService.convertToBrNumber(resultado * 0.54),
      soma10: '54,00',
      soma9: '',
      soma8: '',
      soma7: '',
      soma6: '',
      soma5: '',
      soma4: '',
      soma3: '',
      soma2: '',
      soma1: '',
      soma0: '',
      soma_total: '',
      resto: ''
    }
    listaExportar.push(linha_limite_maximo)

    const linha_limite_prudencial = {
      titulo: 'LIMITE PRUDENCIAL (X) = (0,95 x IX) (parágrafo único do art. 22 da LRF)',
      soma11: this.funcaoService.convertToBrNumber((total_gasto_pessoal * 0.54) * 0.95),
      soma10: '51,30',
      soma9: '',
      soma8: '',
      soma7: '',
      soma6: '',
      soma5: '',
      soma4: '',
      soma3: '',
      soma2: '',
      soma1: '',
      soma0: '',
      soma_total: '',
      resto: ''
    }
    listaExportar.push(linha_limite_prudencial)

    const linha_limite_alerta = {
      titulo: 'LIMITE DE ALERTA (XI) = (0,90 x IX) (inciso II do §1º do art. 59 da LRF)',
      soma11: this.funcaoService.convertToBrNumber((total_gasto_pessoal * 0.54) * 0.90),
      soma10: this.funcaoService.convertToBrNumber((((total_gasto_pessoal * 0.54) * 0.90) / resultado) * 100),
      soma9: '',
      soma8: '',
      soma7: '',
      soma6: '',
      soma5: '',
      soma4: '',
      soma3: '',
      soma2: '',
      soma1: '',
      soma0: '',
      soma_total: '',
      resto: ''
    }
    listaExportar.push(linha_limite_alerta)

    let quadrimestre = '';
    let quadri_1 = '';
    let quadri_2 = '';
    let ano1 = 0;
    let ano2 = 0;
    if (this.mes <= 4) {
      quadrimestre = '1º Quadrimestre';
      quadri_1 = '2º Quadrimestre';
      quadri_2 = '3º Quadrimestre';
      ano1 = this.exercicio.ano;
      ano2 = this.exercicio.ano;
    } else if (this.mes <= 8) {
      quadrimestre = '2º Quadrimestre';
      quadri_1 = '3º Quadrimestre';
      quadri_2 = '1º Quadrimestre';
      ano1 = this.exercicio.ano;
      ano2 = this.exercicio.ano + 1;
    } else {
      quadrimestre = '3º Quadrimestre';
      quadri_1 = '1º Quadrimestre';
      quadri_2 = '2º Quadrimestre';
      ano1 = this.exercicio.ano + 1;
      ano2 = this.exercicio.ano + 1;
    }

    listaExportar.push(espaco)

    const linha_titulo_trajetoria = {
      titulo: 'TRAJETÓRIA DE RETORNO AO LIMITE DA DESPESA TOTAL COM PESSOAL',
      soma11: '',
      soma10: '',
      soma9: '',
      soma8: '',
      soma7: '',
      soma6: '',
      soma5: '',
      soma4: '',
      soma3: '',
      soma2: '',
      soma1: '',
      soma0: '',
      soma_total: '',
      resto: ''
    }
    listaExportar.push(linha_titulo_trajetoria)

    const linha_exercicio_ano = {
      titulo: this.exercicio.ano,
      soma11: '',
      soma10: '',
      soma9: ano1,
      soma8: '',
      soma7: '',
      soma6: ano2,
      soma5: '',
      soma4: '',
      soma3: '',
      soma2: '',
      soma1: '',
      soma0: '',
      soma_total: '',
      resto: ''
    }
    listaExportar.push(linha_exercicio_ano)

    const linha_quadrimestre = {
      titulo: quadrimestre,
      soma11: '',
      soma10: '',
      soma9: quadri_1,
      soma8: '',
      soma7: '',
      soma6: quadri_2,
      soma5: '',
      soma4: '',
      soma3: '',
      soma2: '',
      soma1: '',
      soma0: '',
      soma_total: '',
      resto: ''
    }
    listaExportar.push(linha_quadrimestre)

    const linha_titulo = {
      titulo: 'Limite Máximo',
      soma11: '% DTP',
      soma10: '% Excedente',
      soma9: 'Redutor mínimo de 1/3 do Excedente',
      soma8: 'Limite',
      soma7: '% DTP',
      soma6: 'Redutor Residual',
      soma5: 'Limite',
      soma4: '% DTP',
      soma3: '',
      soma2: '',
      soma1: '',
      soma0: '',
      soma_total: '',
      resto: ''
    }
    listaExportar.push(linha_titulo)

    const limite = (+total_gasto_pessoal + +resto) / resultado;

    const linha_valores = {
      titulo: '54%',
      soma11: this.funcaoService.convertToBrNumber(limite),
      soma10: this.funcaoService.convertToBrNumber(limite - 54),
      soma9: this.funcaoService.convertToBrNumber((limite - 54) / 3),
      soma8: this.funcaoService.convertToBrNumber(limite - ((limite - 54) / 3)),
      soma7: this.funcaoService.convertToBrNumber(0),
      soma6: this.funcaoService.convertToBrNumber(0),
      soma5: this.funcaoService.convertToBrNumber(0),
      soma4: this.funcaoService.convertToBrNumber(0),
      soma3: '',
      soma2: '',
      soma1: '',
      soma0: '',
      soma_total: '',
      resto: ''
    }
    listaExportar.push(linha_valores)

    return listaExportar
  }

  colunas(): Coluna[] {
    const colunasDefault: Coluna[] = [
      { titulo: 'ESPECIFICAÇÃO', coluna: 'titulo' },
      { titulo: this.retornarNomeMes(-11), coluna: 'soma11' },
      { titulo: this.retornarNomeMes(-10), coluna: 'soma10' },
      { titulo: this.retornarNomeMes(-9), coluna: 'soma9' },
      { titulo: this.retornarNomeMes(-8), coluna: 'soma8' },
      { titulo: this.retornarNomeMes(-7), coluna: 'soma7' },
      { titulo: this.retornarNomeMes(-6), coluna: 'soma6' },
      { titulo: this.retornarNomeMes(-5), coluna: 'soma5' },
      { titulo: this.retornarNomeMes(-4), coluna: 'soma4' },
      { titulo: this.retornarNomeMes(-3), coluna: 'soma3' },
      { titulo: this.retornarNomeMes(-2), coluna: 'soma2' },
      { titulo: this.retornarNomeMes(-1), coluna: 'soma1' },
      { titulo: this.retornarNomeMes(0), coluna: 'soma0' },
      { titulo: 'Total 12 meses', coluna: 'soma_total' },
      { titulo: 'NSCRITAS EM RESTOS A PAGAR NÃO PROCESSADOS (b)', coluna: 'resto' },
    ];
    return colunasDefault;
  }

}
