import { DatePipe } from "@angular/common";
import { Component, ElementRef, EventEmitter, HostListener, Injector, Output, ViewChild } from "@angular/core";
import { Validators } from "@angular/forms";
import { ProdutoService } from "almoxarifado-lib";
import { FichaDespesaService, ReservaDotacaoItemService, ReservaDotacaoService } from "contabil-lib";
import { BaseResourceFormComponent, Contrato, ContratoAditamento, ContratoItem, Convenio, DateFormatPipe, Despesa, EddyAutoComplete, Estoque, EstoqueService, Executora, Exercicio, Favorecido, FavorecidoService, FichaDespesa, FuncaoService, GlobalService, Licitacao, LicitacaoPipe, LoginContabil, Modalidade, Page, ParametroCompra, ParametroCompraService, ParametroRequisicaoService, Pessoa, PessoaService, Prazo, PrioridadeRCMS, Produto, Proposta, Rcms, RcmsCotacao, RcmsFavorecido, RcmsJustificativaDesbloqueioAutomatico, RcmsItem, RcmsStorage, Recurso, ReservaDotacao, ReservaDotacaoItem, Setor, SetorService, Memorial, Veiculo, ParametroRequisicao, ConvenioRecurso } from "eddydata-lib";
import { ConfirmationService } from "primeng/api";
import { takeUntil } from "rxjs/operators";
import * as toastr from "toastr";
import { CompraItemService } from "../../compra/service/compra-item.service";
import { CompraTabelaDesconto } from '../../compra/service/compra-tabela-desconto.service';
import { CompraService } from "../../compra/service/compra.service";
import { ContratoAditamentoService } from '../../contrato/service/contrato-aditamento.service';
import { ContratoItemService } from "../../contrato/service/contrato-item.service";
import { ModalidadeService } from "../../modalidade/service/modalidade.service";
import { PrazoService } from "../../prazo/service/prazo.service";
import { TipoContratacaoService } from "../../tipo-contratacao/service/tipo-contratacao.service";
import { NotaRcmsRpt } from "../nota-rcms-rpt/nota-rcms-rpt.component";
import { RcmsCotacaoComponent } from "../rcms-cotacao/rcms-cotacao.component";
import { RcmsItemComponent } from "../rcms-item/rcms-item.component";
import { RcmsJustificativaDesbloqueioAutomaticoComponent } from "../rcms-justificativa-desbloqueio-automatico/rcms-justificativa-desbloqueio-automatico.component";
import { RcmsFavorecidorService } from "../service/rcms-favorecido.service";
import { RcmsItemService } from "../service/rcms-item.service";
import { RcmsJustificativaDesbloqueioAutomaticoService } from "../service/rcms-justificativa-desbloqueio-automatico.service";
import { RcmsService } from "../service/rcms.service";
import { RcmsStorageService } from "../service/rcms-storage.service";
import { RecursoDisponibilidadeService } from "projects/contabil/src/app/planejamento/recurso-disponibilidade/service/recurso-disponibilidade.service";
import { ContratoService, ConvenioRecursoService, ConvenioService, DespesaService, LicitacaoService, MemorialService, ProponenteService, PropostaService, TipoConvenioService, VeiculoService } from "administrativo-lib";

declare var $: any;

type SaldoFicha = {
  dotacao?: number;
  reservado?: number;
  saldo?: number;
  empenhado?: number;
  compra?: number;
  rcms?: number;
};

// declare var $: any;

@Component({
  selector: "app-rcms-form",
  templateUrl: "./rcms-form.component.html",
})
export class RcmsFormComponent extends BaseResourceFormComponent<Rcms, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  @Output() onAutorizado: EventEmitter<void> = new EventEmitter();
  @Output() onDesbloqueado: EventEmitter<void> = new EventEmitter();

  @ViewChild("cotacao_") cotacaoCon: RcmsCotacaoComponent;
  @ViewChild("processo_") processoInput: ElementRef;
  @ViewChild("contrato_") contratoInput: ElementRef;
  @ViewChild("licitacao_") licitacaoInput: ElementRef;
  @ViewChild("produto_") produtoInput: ElementRef;
  @ViewChild("servico_") servicoInput: ElementRef;
  @ViewChild("rcmsitem_") rcmsItem: RcmsItemComponent;
  @ViewChild("rcmsJustificativaDesbloqueioAutomatico_")

  rcmsJustificativaDesbloqueioAutomatico: RcmsJustificativaDesbloqueioAutomaticoComponent;
  public saldoFicha: SaldoFicha;
  public itens: RcmsItem[];
  public favorecidos: RcmsFavorecido[];
  public oldFavorecidos: RcmsFavorecido[];
  public rcmsAnterior: Rcms;
  public datepipe: DatePipe;
  public visualizarFicha: boolean = false;
  public visualizarExclusao: boolean = false;
  public tab: number = 1;
  public dataUltRcms: Date = new Date();
  public display = false;
  public validSaldoModalidade: boolean = true;
  public validSaldoDispensacao: boolean = true;
  public visualizarSelecionar: boolean = false;
  public recurso: Recurso;
  public aplicacao: Recurso;
  public unidade: Executora;
  public pessoaInEdit: Pessoa;
  public campoPessoa: string;
  public fichaObras: boolean = false;
  public numInicial: number;
  public itensContrato: any;
  public finalidadeObnObrigatorio: boolean = false;
  public naoExisteAditamento = false;
  public visulizarAjusteServico: boolean = false;
  public ajusteSemSubmit: boolean = false;
  public produtosAjuste: any[] = [];
  public route: string;
  public justificativa_aceite = "";
  public visualizarAceite = false;
  public rcmsImprimir: Rcms[] = [];
  public usarFichaDespesa = true;
  public listaPrioridade: PrioridadeRCMS[];
  public listaTipos: Array<any>;
  public selecionarItens: RcmsItem[] = [];
  public tabelasSelecionadas: any[] = [];
  public tabelasDesconto: any[];
  public contratoAtual: Contrato;
  public contratosSelecionar: Contrato[] = [];
  public visualizarContratos: boolean = false;
  public visualizarLicitacoes: boolean = false;
  public licitacoesSelecionar: Licitacao[] = [];
  public processoAtual: string;
  public licitacaoAtual: Licitacao;
  public visualizarFavorecidos: boolean = false;
  public favorecidosSelecionar: Favorecido[] = [];
  public pedidoCompraDireta: boolean = false;
  public tipoContrato: "REGISTRO_PRECO" | "CONTRATO";
  public subelemento: number;
  public bloquearData: any = null;
  public mes: string;
  public fornecedorConvenio: Favorecido;
  public apertouProcesso: boolean = false;
  public apertouContrato: boolean = false;
  public visualizarTabelasDesconto: boolean = false;
  public mostrarDescricao: boolean = false;
  public estoques: Estoque[] = [];
  public listaArquivos: RcmsStorage[];
  public cotacaoComEmpate: Boolean = false
  public visualizarEmpate: Boolean = false
  public subelementoVeiculo: Boolean = false;
  public parametroRequisicao: ParametroRequisicao;
  public parametrosBuscaVeiculo: any = {};

  /**
   * Auto completes
   */
  public pessoaAutoComplete: EddyAutoComplete<Pessoa>;
  public responsavelCotAutoComplete: EddyAutoComplete<Pessoa>;
  public responsavelFiscalizacaoAutoComplete: EddyAutoComplete<Pessoa>;
  public fichaAutoComplete: EddyAutoComplete<FichaDespesa>;
  public subElementoAutoComplete: EddyAutoComplete<Despesa>;
  public licitacaoAutoComplete: EddyAutoComplete<Licitacao>;
  public convenioAutoComplete: EddyAutoComplete<Convenio>;
  public modalidadeAutoComplete: EddyAutoComplete<Modalidade>;
  public setorAutoComplete: EddyAutoComplete<Setor>;
  public prazoEntregaAutoComplete: EddyAutoComplete<Prazo>;
  public prazoPagamentoAutoComplete: EddyAutoComplete<Prazo>;
  public aditamentoAutoComplete: EddyAutoComplete<ContratoAditamento>;
  public veiculoAutoComplete: EddyAutoComplete<Veiculo>;
  public reservaDotacaoList: ReservaDotacao[];
  public reservaDotacaoItens: ReservaDotacaoItem[] = [];
  public reservaSelecionada: ReservaDotacao;
  public numeroConvenio: string;
  public justificaDispensa: boolean = false;
  public desbloqueioAutomaticoEstaHabilitado = false;
  public justificativaDesbloqueioAutomatico: RcmsJustificativaDesbloqueioAutomatico;
  public justificativaDesbloqueioAutomaticoAcaoAoSalvar: {
    codigo: string;
    mensagem: string;
  };

  public saldoConvenio: number;

  public licitacoesImportadas: Licitacao[] = [];

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    public globalService: GlobalService,
    public funcaoService: FuncaoService,
    protected favorecidoService: FavorecidoService,
    protected despesaService: DespesaService,
    protected fichaService: FichaDespesaService,
    protected licitacaoService: LicitacaoService,
    protected contratoService: ContratoService,
    protected convenioService: ConvenioService,
    protected modalidadeService: ModalidadeService,
    protected produtoService: ProdutoService,
    protected pessoaService: PessoaService,
    private setorService: SetorService,
    private prazoService: PrazoService,
    private compraService: CompraService,
    protected rcmsItemService: RcmsItemService,
    protected rcmsFavorecidoService: RcmsFavorecidorService,
    protected rcmsService: RcmsService,
    protected storageService: RcmsStorageService,
    protected veiculoService: VeiculoService,
    private confirmationService: ConfirmationService,
    private contratoItemService: ContratoItemService,
    private parametroCompraService: ParametroCompraService,
    private propostaService: PropostaService,
    private proponenteService: ProponenteService,
    private memorialService: MemorialService,
    protected compraTabelaService: CompraTabelaDesconto,
    private tipoContratacaoService: TipoContratacaoService,
    private reservaDotacaoService: ReservaDotacaoService,
    private reservaDotacaoItemService: ReservaDotacaoItemService,
    private convenioRecursoService: ConvenioRecursoService,
    private aditamentoService: ContratoAditamentoService,
    private compraItemService: CompraItemService,
    private parametroRequisicaoService: ParametroRequisicaoService,
    private estoqueService: EstoqueService,
    private recursoDisponibilidadeService: RecursoDisponibilidadeService,
    private rcmsJustificativaDesbloqueioAutomaticoService: RcmsJustificativaDesbloqueioAutomaticoService,
    private tipoConvenioService: TipoConvenioService
  ) {
    super(new Rcms(), injector, Rcms.converteJson, rcmsService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      data_rcms: [null, [Validators.required]],
      tipo_rcms: ["O", [Validators.required]],
      prazo_entrega: [null],
      prazo_pagamento: [null],
      prioridade: [null],
      numero: [null, [Validators.required]],
      justificativa: [null],
      local_entrega: [null, [Validators.required]],
      observacao: [null],
      documento: [null],
      modalidade: [null, [Validators.required]],
      autorizado: [false],
      ficha: [null],
      orgao: [this.login.orgao, [Validators.required]],
      exercicio: [this.login.exercicio, [Validators.required]],
      subelemento: [null],
      bloqueado: [null],
      setor: [null, [Validators.required]],
      estoque: [null],
      veiculo: [null],
      licitacao: this.fb.group({
        id: [null],
        numero: [null],
        processo: [null],
        tipo_licitacao: [null],
        tabela_desconto: [null],
        natureza: [null],
        modalidade: [null],
        situacao: [null]
      }),
      processo: [null],
      contrato: this.fb.group({
        id: [null],
        numero: [null],
        processo: [null],
        tipo_contratacao: [null],
        saldo_quantidade: [null],
        saldo_valor: [null],
      }),
      convenio: [null],
      operador: [this.login.usuario],
      itens: [null],
      favorecidos: [null],
      requerente: [null, [Validators.required]],
      autorizado_compra: [true],
      somente_produto: [false],
      somente_servico: [false],
      responsavel_cotacao: [null],
      responsavel_fiscalizacao: [null],
      prazo_execucao: [null],
      justificativa_contratacao: [null],
      submitSend: [false],
      reserva_dotacao: [null],
      efetivar_baixa_reserva: [false],
      parametro: [this.login.parametro["requisicao"]],
      usuario: [this.login.usuario],
      finalidade_fundeb: [null],
      adiantamento: [false],
      contrato_aditamento: [null],
      rcmsJustificativa: [null],
      tabelas_desconto: [null],
    });
  }

  protected parametrosExtras(): {} {
    return {
      relations: [
        "setor.unidade",
        "licitacao",
        "contrato.prazo",
        "contrato.tipo_contratacao",
        "contrato.favorecido",
        "contrato.itens",
        "exercicio",
        "orgao",
        "ficha.despesa",
        "subelemento",
        "requerente",
        "modalidade",
        "operador",
        "ficha.executora.unidade",
        "ficha.recurso",
        "ficha.acao",
        "ficha.aplicacao",
        "responsavel_cotacao",
        "responsavel_fiscalizacao",
        "estoque",
        "prazo_entrega",
        "prazo_pagamento",
        "veiculo",
        "contrato_aditamento",
        "rcmsJustificativa",
        "convenio.tipo_convenio"
      ],
    };
  }

  protected afterInit(): void {
    setInterval(() => {
      this.verificarPedidoCompraDireta();
      if (this.produtoInput && this.servicoInput) {
        if (this.itens && this.itens.length > 0) {
          this.produtoInput.nativeElement.disabled = true;
          this.servicoInput.nativeElement.disabled = true;
        } else {
          this.produtoInput.nativeElement.disabled = false;
          this.servicoInput.nativeElement.disabled = false;
        }
      }
    }, 100);

    this.mes = this.globalService.obterMes(this.login.mesReferencia + 1);
    this.datepipe = new DatePipe("pt");

    this.activatedRoute.data.subscribe((data) => {
      this.route = data["route"];
    });

    this.usarFichaDespesa = !this.login.parametro?.requisicao?.nao_usar_ficha_despesa;

    this.listaPrioridade = this.globalService.prioridadesRCMS();
    this.listaTipos = this.globalService.obterListaTiposEmpenhos();
    if (!this.login.estoque) {
      const estoque = new Estoque();
      estoque.id = -1;
      this.login.estoque = estoque;
      this.display = true;
    }
    this.carregarAutoCompletes();
    this.carregarAutoCompleteSubElemento();
    this.bloquearData = this.login.parametro.requisicao.bloquear_data;
    if (this.currentActionRoute === "novo") {
      this.rcmsService
        .proximoNumeroOF(this.login.exercicio.id, this.login.orgao.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.entidadeForm.get("numero").setValue(res);
        });

      this.entidade.data_rcms = new DateFormatPipe().transform(
        this.entidade.data_rcms,
        []
      );
      this.entidade.prazo_execucao = new DateFormatPipe().transform(
        this.entidade.prazo_execucao,
        []
      );
      this.compraService
        .obterDatasPosteriores(this.login.orgao.id, this.login.exercicio.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.entidadeForm
            .get("data_rcms")
            .setValue(new DateFormatPipe().transform(res.ultima, []));
        });
    }
    this.parametroRequisicaoService
      .filtrar(1, -1, { orgao_id: this.login.orgao.id, relations: 'orgao,usuarios_excecao.usuario_excecao,requisicao_subelementos.subelemento' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (res) => {
          this.desbloqueioAutomaticoEstaHabilitado =
            res.content[0].habilitar_desbloqueio_automatico;
          this.parametroRequisicao = res.content[0];
        },
        (error) => this.funcaoService.acaoErro(error)
      );

    this.preencherEstoques();
  }

  public redirecionar() {
    this.router.navigate(["/rcms"]);
  }

  protected campoFoco(): ElementRef {
    return null;
  }

  protected async afterLoad() {
    this.entidade.data_rcms = new DateFormatPipe().transform(
      this.entidade.data_rcms,
      []
    );
    this.entidade.prazo_execucao = new DateFormatPipe().transform(
      this.entidade.prazo_execucao,
      []
    );
    if (this.entidade.operador) {
      this.entidadeForm.get("operador").setValue(this.entidade.operador);
    }

    this.rcmsItemService.filtrar(1, -1, {
      relations: [
        "produto_unidade",
        "produto_unidade.produto.material.aliquota",
        "produto_unidade.produto.aliquota",
        "produto_unidade.unidade",
        "cotacoes",
        "cotacoes.rcmsFavorecido",
        "cotacoes.rcmsFavorecido.favorecido",
        "memorial.licitacao"
      ],
      "rcms.id": this.entidade.id,
      orderBy: ["ordem$ASC", "cotacoes.id$DESC"],
    })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (res) => {
          this.itens = res ? res.content : new Array<RcmsItem>();
          if (!this.entidade?.contrato?.id) {
            this.carregarItens(this.itens);
          }
        },
        (error) => this.funcaoService.acaoErro(error)
      );

    this.rcmsFavorecidoService.filtrar(1, -1, {
      relations: ["favorecido.tipo"],
      "rcms.id": this.entidade.id,
      orderBy: "id$DESC",
    })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (res) => {
          this.favorecidos = res ? res.content : new Array<RcmsFavorecido>();
          if (!this.entidade?.contrato?.id) {
            this.carregarFavorecidos(this.favorecidos);
          }
        },
        (error) => this.funcaoService.acaoErro(error)
      );

    this.carregarAutoCompleteVeiculo();

    this.modalidadeAutoComplete.id = this.entidade?.modalidade?.id;
    this.fichaAutoComplete.id = this.entidade?.ficha?.numero;

    this.subElementoAutoComplete.id = this.entidade?.subelemento?.codigo;
    this.prazoPagamentoAutoComplete.id = this.entidade?.prazo_pagamento?.id;
    this.veiculoAutoComplete.id = this.entidade?.veiculo?.id;

    this.contratoAtual = this.entidade.contrato;
    this.processoAtual = this.entidade.processo;
    this.licitacaoAtual = this.entidade.licitacao;
    this.parametrosBuscaVeiculo['tombamento.setor.id'] = this.entidade?.setor?.id;

    this.rcmsService.filtrar(1, -1, {
      id: this.entidade.id,
      "orgao.id": this.login.orgao.id,
      "exercicio.id": this.login.exercicio.id,
      excluido: false,
      relations:
        "reserva_dotacao.reserva.orgao,reserva_dotacao.reserva.licitacao.modalidade,convenio.tipo_convenio",
    })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((resposta) => {
        if (resposta.content[0]?.convenio)
          this.numeroConvenio = `${resposta.content[0].convenio?.numero}/${resposta.content[0].convenio?.ano}`;
        if (resposta.content[0].reserva_dotacao?.id) {
          if (
            resposta.content[0].reserva_dotacao?.id !== this.reservaSelecionada
          ) {
            this.reservaSelecionada =
              resposta.content[0]?.reserva_dotacao?.reserva.id;
          }
          this.entidadeForm
            .get("reserva_dotacao")
            .setValue(resposta.content[0]?.reserva_dotacao);
          this.carregarFichasReserva();
          this.carregarReservaDotacao(
            resposta.content[0].reserva_dotacao?.reserva.licitacao.id
          );
        } else if (this.entidade?.licitacao) {
          this.carregarReservaDotacao(this.entidade.licitacao?.id);
        }
      });

    this.carregarLicitacao(true);
    this.carregarContrato(this.entidade.contrato, true);
    this.buscarSaldoFicha(true);
    this.preencherTabelas();
    if (this.entidade.id) {
      this.validaFinalidadeFundeb(this.entidade.ficha);
    }

    this.rcmsJustificativaDesbloqueioAutomaticoService
      .filtrar(1, -1, {
        rcms_id: this.entidade.id,
        relations:
          "questao_1_licitacao.modalidade,questao_1_licitacao.exercicio,questao_2_licitacao.modalidade,questao_2_licitacao.exercicio,questao_4_rpl.exercicio",
      })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (data) => {
          if (data.content.length > 0) {
            this.justificativaDesbloqueioAutomatico = data.content[0];

            //const nomeCompleto = `${this.login.usuario.nome} ${this.login.usuario.sobrenome}`;
            // const declaracao =
            //   this.rcmsJustificativaDesbloqueioAutomatico.buscaDeclaracaoDeCiencia(
            //     nomeCompleto,
            //     this.login.usuario.cpf
            //   );
            // const contemDeclaracaoEmObservacao = this.entidadeForm
            //   .get("observacao")
            //   .value.includes(declaracao);

            // if (!contemDeclaracaoEmObservacao) {
            //   this.adicionaDeclaracaoDeCiencia(declaracao);
            //   toastr.warning(
            //     "Há justificativa de desbloqueio automatico, mas a declaração de ciência não está presente no campo observações. Salve a requisição de compras para persistir as alterações."
            //   );
            // }

            this.justificativaDesbloqueioAutomaticoAcaoAoSalvar =
              this.rcmsJustificativaDesbloqueioAutomatico.buscaAcaoASerTomada();
          }
        },
        (error) => this.funcaoService.acaoErro(error)
      );

    await this.carregarConvenio(true);

    const adiantamentos = await this.findAditamentos();

    if (adiantamentos.content.length < 2) {
      this.naoExisteAditamento = true;
    }

    this.licitacoesImportadas = [];
    for (const item of this?.itens) {
      if (item?.memorial && item?.memorial !== 'N' && item?.memorial?.licitacao) {
        if (!this?.licitacoesImportadas?.find(l => l.id === (item?.memorial as Memorial)?.licitacao?.id)) {
          this.licitacoesImportadas?.push(item?.memorial?.licitacao);
        }
      }
    }

    this.preencherEstoques();
  }

  protected async beforeSubmit() {
    const editando = this.itens?.find((c) => c["editavel"]);

    if (editando) {
      if (!editando.id && !editando.produto_unidade.id)
        this.itens.splice(
          this.itens.findIndex((c) => c.id === editando.id),
          1
        );
      else if (!(await this.rcmsItem.salvar(editando))) {
        toastr.warning(
          `Verifique os processos pendentes no cadastro de itens da requisição de compra`
        );
        throw new Error();
      }
    }

    if (
      this.itens &&
      this.itens.filter((i) => !i.produto_unidade?.id).length > 0
    ) {
      toastr.warning(`Foram identificado itens com serviços não cadastrados`);
      this.produtosAjuste = this.itens
        .filter((i) => !i.produto_unidade?.id)
        .map((i) => {
          return { produto: i.produto, unidade: i.unidade };
        });
      this.visulizarAjusteServico = true;
      throw new Error();
    }

    if (
      this.usarFichaDespesa &&
      (this.entidadeForm.get("ficha").value == null ||
        this.entidadeForm.get("subelemento").value == null)
    ) {
      toastr.warning("Ficha de despesa e Subelemento não informados!");
      let errorData = "Ficha de despesa e Subelemento não informados!";
      throw new Error(errorData);
    }

    if (!!this.entidadeForm.get("contrato").value.id) {
      this.itens.map(
        (item) =>
        (item.cota =
          item.cota === "PRINCIPAL" || item.cota === "P" ? "P" : "R")
      );
    }

    if (this.login.parametro?.requisicao?.obrigar_indicar_placa && this.subelementoVeiculo && (!this.entidadeForm.get('veiculo').value || !this.entidadeForm.get('veiculo').value?.id)) {
      let errorData = "Subelemento obriga a indicar um veículo!";
      toastr.warning(errorData);
      throw new Error(errorData);
    }

    this.entidadeForm.get("submitSend").setValue(true);
    this.entidadeForm.get("itens").setValue(
      !this.itens
        ? null
        : this.itens.map((item) => {
          item.cotacoes = !item.cotacoes
            ? null
            : item.cotacoes.map((c) => {
              let cotacao: RcmsCotacao = Object.assign({}, c);
              delete cotacao.rcmsItem;
              delete cotacao.rcmsFavorecido.cotacoes;
              return cotacao;
            });
          return item;
        })
    );

    if (
      this.login.parametro?.requisicao?.exigir_responsavel_cotacao &&
      this.entidadeForm.get("responsavel_cotacao").value == null &&
      this.entidadeForm.get("contrato").value["id"] == null &&
      (this.entidadeForm.get("itens").value
        ? this.entidadeForm.get("itens").value.length > 0
        : false)
    ) {
      toastr.warning("Responsável pela cotação não informado!");
      let errorData = "Responsável pela cotação não informado!";
      throw new Error(errorData);
    }

    //Validação responsável fiscalização
    this.validaFichaObras();

    if (
      this.login.parametro?.requisicao?.exigir_dados_fiscalizacao &&
      (!this.entidadeForm.get("responsavel_fiscalizacao").value ||
        !this.entidadeForm.get("prazo_execucao").value ||
        !this.entidadeForm.get("justificativa_contratacao").value) &&
      this.fichaObras
    ) {
      let campos: string = "";
      campos += !this.entidadeForm.get("responsavel_fiscalizacao").value
        ? "Responsável pela fiscalização|"
        : "";

      campos += !this.entidadeForm.get("prazo_execucao").value
        ? "Prazo de execução|"
        : "";

      campos += !this.entidadeForm.get("justificativa_contratacao").value
        ? "Justificativa de contratação|"
        : "";

      campos = campos.slice(0, -1);
      campos = campos.split("|").join(", ");
      toastr.warning(
        "Dados da fiscalização não informados corretamente! Não informado: " +
        campos
      );
      let errorData =
        "Dados da fiscalização não informados corretamente! Não informado" +
        campos;
      throw new Error(errorData);
    } else if (
      this.login.parametro?.requisicao?.exigir_dados_fiscalizacao &&
      this.entidadeForm.get("responsavel_fiscalizacao").value &&
      (!this.entidadeForm.get("responsavel_fiscalizacao").value["cargo"] ||
        !this.entidadeForm.get("responsavel_fiscalizacao").value["matricula"])
    ) {
      let campos: string = "";

      campos += !this.entidadeForm.get("responsavel_fiscalizacao").value[
        "cargo"
      ]
        ? "Cargo|"
        : "";
      campos += !this.entidadeForm.get("prazo_execucao").value["matricula"]
        ? "Matrícula"
        : "";
      campos = campos.split("|").join(", ");

      toastr.warning(
        "Responsável não possui dados para fiscalização informados corretamente! Não informado: " +
        campos
      );
      let errorData =
        "Responsável não possui dados para fiscalização informados corretamente! Não informado: " +
        campos;
      throw new Error(errorData);
    }

    this.entidadeForm.get("favorecidos").setValue(this.favorecidos);

    let validarAutorizado = false;
    let itens = this.entidadeForm.get("itens").value;
    if (itens && itens.length > 0) {
      for (let item of itens) {
        let temCotacao = item?.cotacoes && item?.cotacoes?.length ? item?.cotacoes.length : 0;
        if (temCotacao > 0 && item.cotacoes.filter((c) => c.valor_unitario > 0).length > 0)
          validarAutorizado = true;
      }
    }
    if (!validarAutorizado)
      this.entidadeForm.get("autorizado_compra").setValue(false);

    const dataRcms: Date = this.entidadeForm.get("data_rcms").value;
    const dataAtual: Date = new Date();

    // if (dataRcms > dataAtual) {
    //   toastr.warning('A Data da Requisição não pode ser maior que a data atual')
    //   throw new Error('A Data da Requisição não pode ser maior que a data atual')
    // }

    this.login.parametro["compras"] = await this.parametroCompraService
      .obterParametro(this.login.orgao.id)
      .toPromise();
    this.login.parametro["compras"].login = {
      email: this.login.usuario.email,
      id: this.login.usuario.id,
    };
    this.login.parametro["compras"].orgao = this.login.orgao;

    let parametros: ParametroCompra = this.login.parametro?.compras;
    let licitacao = this.entidadeForm.get("licitacao").value;
    let contrato = this.entidadeForm.get("contrato").value;
    let processo = this.entidadeForm.get("processo").value;

    let modalidade: Modalidade = this.entidadeForm.get("modalidade").value;

    //validar saldo de modalide
    if (modalidade != null && this.route !== "autorizacao") {
      if (
        this.validSaldoModalidade &&
        modalidade &&
        modalidade.valor_limite &&
        modalidade.valor_limite > 0 &&
        this.entidadeForm.get("autorizado_compra").value
      ) {
        await this.validarModalidade().then((valid) => {
          if (valid) {
            this.validSaldoModalidade = false;
            // setTimeout(() => {
            // this.submitForm(this.limparTela);
            // }, 100);
          }
        });
        throw new Error("validar saldo modalidade");
      }
    }

    //validar saldo de dispensa
    if (
      this.validSaldoDispensacao &&
      modalidade != null &&
      modalidade.codigo === 5 &&
      this.route !== "autorizacao" &&
      // this.entidadeForm.get("autorizado_compra").value &&
      (parametros.dispensacao_favorecido ||
        parametros.dispensacao_subelemento) &&
      (!licitacao || !licitacao.id) &&
      (!contrato || !contrato.id) &&
      !processo
    ) {
      if (parametros.dispensacao_subelemento) {
        await this.validarDispensaSubElemento().then((valid: boolean) => {
          if (valid) {
            this.validSaldoDispensacao = false;
            //this.submitForm(this.limparTela);
          }
          this.validSaldoModalidade = true;
        });
      }
      if (parametros.dispensacao_favorecido) {
        await this.validarDispensaFavorecido().then((valid: boolean) => {
          if (valid) {
            this.validSaldoDispensacao = false;
            //this.submitForm(this.limparTela);
          }
          this.validSaldoModalidade = true;
        });
      }
    }

    const parametrosRequicisao = await this.parametroRequisicaoService.obter(
      Object.assign({}, { 'orgao.id': this.login.orgao.id }, { relations: 'orgao,usuarios_excecao.usuario_excecao,requisicao_subelementos.subelemento' })
    ).toPromise()

    if (parametrosRequicisao.exigir_finalidade_fundeb === true) {
      if (
        this.entidadeForm.get("ficha").value &&
        this.finalidadeObnObrigatorio &&
        !this.entidadeForm.get("finalidade_fundeb").value
      ) {
        toastr.error(
          "Campo finalidade FUNDEB obrigatório para a ficha selecionada"
        );
        throw new Error(
          "Campo Finalidade Fundeb obrigatório para a Ficha de Despesa selecionada"
        );
      }
    }

    this.numInicial = this.entidadeForm.get("numero").value;

    //Verificar estoque
    if (!this.entidadeForm.get('estoque').value) {
      this.entidadeForm.get('estoque').setValue(this.login.estoque);
    }

    await this.verificaFornecedorVencedor()

    if (this.cotacaoComEmpate && await this.resolverEmpateAgora()) {
      throw new Error("Resolvendo empate!");
    }

  }

  public async verificaFornecedorVencedor() {
    const itens: RcmsItem[] = this.entidadeForm.get('itens').value

    itens?.forEach(item => {
      if (item?.cotacoes?.length > 1 && !item?.cotacoes.find(c => c?.vencedor)) {

        // ordeno as cotações pelo menor valor
        item.cotacoes.sort((a, b) => +a.valor_unitario - +b.valor_unitario)

        // define a posição do menor valor sendo ele maior que zero. Se não existir retorna -1
        let idxMenorValor: number = item.cotacoes.findIndex(c => +c.valor_unitario > 0)

        // verifica se a posição do vencedor é valida
        if (idxMenorValor !== -1) {

          // verifico se a posição do menor valor possui cotação depois dele. Se não existe cai no else e o idxMenorValor é o vencedor.
          // se há cotações depois de idxMenorValor verifico se o valor do proximo é igual a ele. Se não for, cai no else e idxMenorValor é o vencedor.
          if (idxMenorValor < (item.cotacoes.length - 1)
            && +item.cotacoes[idxMenorValor].valor_unitario === +item.cotacoes[idxMenorValor + 1].valor_unitario) {

            item.cotacoes.forEach(c => {

              // classifico todas as cotações com o mesmo valor do [idxMenorValor] como empate
              if (+c.valor_unitario === +item.cotacoes[idxMenorValor].valor_unitario) c['empate'] = true
            });

            // sinalizo que o dlg para resolver o empate deve ser aberto
            this.cotacaoComEmpate = true
          } else {
            // se a próxima cotação não tem o mesmo valor da primeira e
            // se o valor unitário não estiver zerado, significa que a primeira é o menor valor e o vencedor
            item.cotacoes[idxMenorValor].vencedor = true
          }
        }
      } else if (item?.cotacoes?.length === 1 && +item?.cotacoes[0]?.valor_unitario > 0) {
        // se há apenas uma cotação e o valor unitário não estiver zerado, então ela é a vencedora
        item.cotacoes[0].vencedor = true
      }
    });
  }

  protected async podeAlterar(entidade: Rcms): Promise<boolean> {
    return !entidade.excluido && !entidade.autorizado && (!entidade['compras_view'] || entidade['compras_view']?.length === 0);
  }

  private async resolverEmpateAgora() {
    return new Promise<boolean>((resolve) => {
      this.confirmationService.confirm({
        header: "Itens com empate nas cotações",
        message: "Esta requisição possui itens com empate nas cotações. Gostaria de definir o vencedor?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        key: "cotacaoEmpate",
        accept: () => {
          this.visualizarEmpate = true
          resolve(true)
        },
        reject: () => {
          resolve(false)
        }
      });
    });
  }

  public resolvidoEmpate() {
    this.cotacaoComEmpate = false
    this.submitForm()
  }

  public preencherTabelas() {
    this.storageService
      .filtrar(1, -1, {
        'rcms.id': this.entidade.id,
        orderBy: 'data_cadastro$DESC',
      })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaArquivos = res.content;
      });
  }

  public async validarModalidade(): Promise<boolean> {
    let modalidade: Modalidade = this.entidadeForm.get("modalidade").value;
    let data_rcms = this.entidadeForm.get("data_rcms").value;
    let saldo = await this.compraService
      .saldoModalidade(
        this.login.exercicio.id,
        this.login.orgao.id,
        modalidade.id,
        new FuncaoService().converteDataSQL(data_rcms),
        this.entidade.id
          ? {
            rcms: this.entidade.id,
          }
          : null
      )
      .toPromise();
    if (
      (saldo &&
        +saldo.saldo_compra +
        +saldo.saldo_rcms_nao_importado +
        this.totalRCSM()) > +modalidade.valor_limite
    ) {
      return await this.confirm(
        `Valor total da requisição excede o limite estabelecido de ${new FuncaoService().convertToBrNumber(
          modalidade.valor_limite
        )} para a modalidade ${modalidade.nome
        }, total já requisitado/comprado ${new FuncaoService().convertToBrNumber(
          +saldo.saldo_compra + +saldo.saldo_rcms_nao_importado
        )}, deseja continuar?`,
        "Limite de Modalidade"
      );
    } else {
      return true;
    }
  }

  private async validarDispensaSubElemento(): Promise<boolean> {
    let parametros: ParametroCompra = this.login.parametro?.compras;

    this.justificaDispensa = false;
    let subelemento: Despesa = this.entidadeForm.get("subelemento").value;
    let data_rcms = this.entidadeForm.get("data_rcms").value;

    if (!parametros.dispensacao_subelemento || !subelemento) return true;

    let valor_limite_dispensacao = subelemento.obras_engenharia
      ? +parametros.valor_limite_dispensacao_engenharia_subelemento
      : +parametros.valor_limite_dispensacao_subelemento;

    if (!valor_limite_dispensacao) return true;

    let saldo = await this.compraService
      .saldoDispensacaoSubElemento(
        this.login.exercicio.id,
        this.login.orgao.id,
        subelemento.codigo,
        new FuncaoService().converteDataSQL(data_rcms),
        this.entidade.id
          ? {
            rcms: this.entidade.id,
          }
          : null
      )
      .toPromise();
    if (
      (saldo &&
        +saldo.saldo_compra +
        +saldo.saldo_rcms_nao_importado +
        this.totalRCSM()) > valor_limite_dispensacao
    ) {
      this.justificaDispensa = true;
      if (this.desbloqueioAutomaticoEstaHabilitado) {
        const existeJustificativaDesbloqueioAutomatico: boolean = !!(
          this.justificativaDesbloqueioAutomatico &&
          this.justificativaDesbloqueioAutomaticoAcaoAoSalvar
        );

        if (!existeJustificativaDesbloqueioAutomatico)
          return await this.devePreencherJustificativaDesbloqueioAutomatico();

        this.entidadeForm.get('rcmsJustificativa').setValue(this.justificativaDesbloqueioAutomatico);

        const { codigo, mensagem } =
          this.justificativaDesbloqueioAutomaticoAcaoAoSalvar;
        if (!this.justificativaDesbloqueioAutomatico.id) {
          switch (codigo) {
            case "NAO_SALVA_REQUISICAO":
              this.entidadeForm.get("bloqueado").setValue(true);
              //toastr.error(mensagem);
              return false;
            case "SALVA_REQUISICAO_BLOQUEADA":
              this.entidadeForm.get("bloqueado").setValue(true);
              this.entidadeForm.get("autorizado_compra").setValue(true);
              toastr.warning(mensagem);
              return true;
            case "SALVA_REQUISICAO_DESBLOQUEADA":
              this.entidadeForm.get("bloqueado").setValue(false);
              this.entidadeForm.get("autorizado_compra").setValue(true);
              toastr.warning(mensagem);
              return true;
            default:
              this.entidadeForm.get("bloqueado").setValue(false);
              this.entidadeForm.get("autorizado_compra").setValue(true);
              toastr.warning(mensagem);
              return true;
          }
        }
      } else {
        let mensagem = `Valor da requisição para o subelemento ${subelemento.nome
          } excede o limite definido para dispensa${subelemento.obras_engenharia ? " para obras e engenharia" : ""
          } - ${new FuncaoService().convertToBrNumber(
            valor_limite_dispensacao
          )}. Total já requisitado/comprado ${new FuncaoService().convertToBrNumber(
            +saldo.saldo_compra + +saldo.saldo_rcms_nao_importado
          )},`;
        if (parametros.autorizar_dispensa) {
          return await this.confirm(
            `${mensagem} A requisição será encaminhada para análise do Setor de Compras, deseja continuar?`,
            "Limite de Dispensa"
          );
        } else {
          toastr.warning(mensagem);
          return false;
        }
      }
    } else {
      return true;
    }
  }

  private async devePreencherJustificativaDesbloqueioAutomatico(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.confirmationService.confirm({
        header: "Atenção",
        message: "Preencha e/ou finalize a justificativa!",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Sim",
        rejectLabel: "OK",
        key: "rcms",
        accept: () => {
          resolve(true);
        },
        reject: () => {

        },
        acceptVisible: false
      });
    });
  }

  private async validarDispensaFavorecido(): Promise<boolean> {
    let parametros: ParametroCompra = this.login.parametro?.compras;

    let subelemento: Despesa = this.entidadeForm.get("subelemento").value;
    let data_rcms = this.entidadeForm.get("data_rcms").value;

    if (!parametros.dispensacao_favorecido || !this.favorecidos) return true;

    let valor_limite_dispensacao = subelemento.obras_engenharia
      ? +parametros.valor_limite_dispensacao_engenharia_favorecido
      : +parametros.valor_limite_dispensacao_favorecido;

    if (!valor_limite_dispensacao) return true;

    for (let favorecido of this.favorecidos) {
      let valor_total = 0;
      let itens = this.entidadeForm.get("itens").value;
      for (let item of itens) {
        if (item.cotacoes && item.cotacoes.length > 0) {
          let menor = item.cotacoes
            .filter((c) => +c.valor_unitario > 0)
            .sort((a, b) => a.valor_unitario - b.valor_unidatario)[0];
          if (
            menor &&
            menor.rcmsFavorecido?.favorecido?.id === favorecido?.favorecido?.id
          )
            valor_total += +menor.valor_unitario;
        }
      }

      let saldo = await this.compraService
        .saldoDispensacaoFavorecido(
          this.login.exercicio.id,
          this.login.orgao.id,
          favorecido.favorecido.id,
          new FuncaoService().converteDataSQL(data_rcms),
          this.entidade.id
            ? {
              rcms: this.entidade.id,
            }
            : null
        )
        .toPromise();
      if (
        (saldo &&
          +saldo.saldo_compra + +saldo.saldo_rcms_nao_importado + valor_total) >
        valor_limite_dispensacao
      ) {
        let mensagem = `Valor da requisição para o fornecedor ${favorecido.favorecido.nome
          } excede o limite definido para dispensa ${subelemento.obras_engenharia ? " para obras e engenharia" : ""
          } - ${new FuncaoService().convertToBrNumber(
            valor_limite_dispensacao
          )}. Total já requisitado/comprado ${new FuncaoService().convertToBrNumber(
            +saldo.saldo_compra + +saldo.saldo_rcms_nao_importado
          )},`;
        if (parametros.autorizar_dispensa) {
          return await this.confirm(
            `${mensagem} A requisição será encaminhada para análise do Setor de Compras, deseja continuar?`,
            "Limite de Dispensa"
          );
        } else {
          toastr.warning(mensagem);
          return false;
        }
      }
    }
    return true;
  }

  protected afterSubmit(ent: Rcms) {
    this.entidadeForm.get('tabelas_desconto').setValue(null);

    this.validSaldoModalidade = true;
    this.validSaldoDispensacao = true;
    if (ent.numero != this.numInicial) {
      toastr.info(
        `Número da requisição alterado para ${ent.numero} durante o salvamento.`
      );
    }
    // if (!this.limparTela) this.loadResource();
  }

  protected acaoErro(error: any): void {
    this.validSaldoModalidade = true;
    this.validSaldoDispensacao = true;
    if (
      error.error?.payload ==
      `duplicate key value violates unique constraint \"UNX_rcms_numero\"`
    ) {
      this.submitForm();
      toastr.warning(`Numeração da requisição foi alterada!`);
    } else {
      super.acaoErro(error);
    }
  }

  public async confirm(mensagem: string, titulo: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.confirmationService.confirm({
        message: mensagem,
        header: titulo,
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Sim",
        rejectLabel: "Não",
        key: "rcms",
        accept: () => {
          resolve(true);
        },
        reject: () => {
          resolve(false);
        },
      });
    });
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  private buscarSaldoFicha(init?: boolean) {
    const exercicio: Exercicio = this.entidadeForm.get("exercicio").value;
    const ficha: FichaDespesa = this.entidadeForm.get("ficha").value
      ? this.entidadeForm.get("ficha").value
      : this.entidade.ficha;
    const dataRcms: Date = this.entidadeForm.get("data_rcms").value
      ? this.entidadeForm.get("data_rcms").value
      : this.entidade.data_rcms;

    if (!ficha || !dataRcms || !exercicio) return;

    this.fichaService
      .saldoAtualFicha(
        ficha.id,
        this.login.orgao.id,
        this.login.exercicio.id,
        String(
          this.funcaoService.converteDataSQL(
            new DateFormatPipe().transform(dataRcms, ["local"])
          )
        ),
        this.entidade.id ? { rcmsId: this.entidade.id } : null,
        true
      )
      .subscribe((data) => {
        if (data) this.carregarSaldoFicha(data, init);
      });
  }

  private carregarSaldoFicha(totalizadores: any, init?: boolean) {
    if (!totalizadores) return;

    // if (totalizadores.saldo_atual <= 0 && !init) {
    //   toastr.warning(`Ficha sem saldo suficiente.`);
    //   this.entidadeForm.get("ficha").setValue(null);
    //   this.fichaAutoComplete.id = null;
    //   this.entidadeForm.get("subelemento").setValue(null);
    //   this.subElementoAutoComplete.id = null;
    //   return;
    // }

    this.saldoFicha = {};

    this.saldoFicha.empenhado = +totalizadores.total_empenhado;
    this.saldoFicha.rcms = +totalizadores.total_rcms_pendente;
    this.saldoFicha.compra = +totalizadores.total_compra_pendente;

    this.saldoFicha.dotacao =
      +totalizadores.total_dotacao + +totalizadores.total_creditado;
    this.saldoFicha.reservado = +totalizadores.total_reservado;
    this.saldoFicha.saldo = +totalizadores.saldo_atual - (totalizadores.cota_reservada ? +totalizadores.cota_reservada : 0);
  }

  private carregarAutoCompletes() {
    // autocomplete para favorecido
    this.pessoaAutoComplete = new EddyAutoComplete(
      this.entidadeForm.get("pessoa"),
      this.pessoaService,
      "id",
      ["nome"],
      {
        cidade_id: this.login.cidade.id,
        // orgao_id: this.login.orgao.id,
        'cpf_cnpj$not_null': true,
        'cpf_cnpj$ne': '',
        orderBy: "nome",
      },
      { number: ['id', 'cpf_cnpj'], text: ['nome', 'cpf_cnpj'] }
    );

    this.responsavelCotAutoComplete = new EddyAutoComplete(
      this.entidadeForm.get("responsavel_cotacao"),
      this.pessoaService,
      "id",
      ["nome"],
      {
        cidade_id: this.login.cidade.id,
        orgao_id: this.login.orgao.id,
        orderBy: "nome",
      },
      { number: ["id", "cpf_cnpj"], text: ["nome"] }
    );

    this.responsavelFiscalizacaoAutoComplete = new EddyAutoComplete(
      this.entidadeForm.get("responsavel_cotacao"),
      this.pessoaService,
      "id",
      ["nome"],
      {
        cidade_id: this.login.cidade.id,
        orgao_id: this.login.orgao.id,
        orderBy: "nome",
      },
      { number: ["id", "cpf_cnpj"], text: ["nome"] }
    );

    this.licitacaoAutoComplete = new EddyAutoComplete(
      this.entidadeForm.get("licitacao"),
      this.licitacaoService,
      "id",
      ["numero"],
      { orgao_id: this.login.orgao.id, orderBy: "numero" },
      { number: ["numero"] }
    );
    this.convenioAutoComplete = new EddyAutoComplete(
      this.entidadeForm.get("convenio"),
      this.convenioService,
      "id",
      ["numero", "ano"],
      { orgao_id: this.login.orgao.id, ativo: true, orderBy: "numero", relations: 'tipo_convenio' },
      { number: ["id", "numero"] }
    );
    this.modalidadeAutoComplete = new EddyAutoComplete(
      this.entidadeForm.get("modalidade"),
      this.modalidadeService,
      "id",
      ["codigo", "nome"],
      { orderBy: "codigo" },
      { number: ["codigo"], text: ["nome"] }
    );

    this.prazoEntregaAutoComplete = new EddyAutoComplete(
      this.entidadeForm.get("prazo_entrega"),
      this.prazoService,
      "id",
      ["nome"],
      { "orgao.id": this.login.orgao.id, entrega: true },
      { text: ["nome"] }
    );

    this.prazoPagamentoAutoComplete = new EddyAutoComplete(
      this.entidadeForm.get("prazo_pagamento"),
      this.prazoService,
      "id",
      ["nome"],
      { "orgao.id": this.login.orgao.id, pagamento: true },
      { text: ["nome"] }
    );

    this.carregarAutoCompleteSetor();
    this.carregarAutoCompleteFicha();

    // Aditamento Autocomplete
    this.carregarAditamentoAutoComplete();
  }

  private carregarAutoCompleteVeiculo() {
    const setor: Setor = this.entidadeForm.get('setor').value ?? this.entidade?.setor;
    if (setor) {
      this.entidadeForm.get('veiculo').setValue(null);
      this.veiculoAutoComplete = new EddyAutoComplete(
        this.entidadeForm.get('veiculo'),
        this.veiculoService,
        'id',
        ['placa_veiculo', 'descricao'],
        {
          'ativo': true,
          'orgao.id': this.login.orgao.id,
          'tombamento.setor.id': setor.id
        },
        { text: ['descricao', 'placa_veiculo'] },
        () => {
          this.alterarVeiculo(false);
        }
      );
    }
  }

  private alterarVeiculo(atualizar: boolean = true) {
    const setor: Setor = this.entidadeForm.get("setor").value;
    if (setor) {
      const subElemento: Despesa = this.entidadeForm.get("subelemento").value;
      this.subelemento = subElemento?.id;
      this.verificarParametroSubelemento(subElemento);
    } else if (atualizar) {
      this.veiculoAutoComplete.id = null;
      this.veiculoAutoComplete.atualizar(false);
    }
  }

  // Autocomplete do aditamento
  private carregarAditamentoAutoComplete(): void {
    let contrato_id = null;
    if (this.entidadeForm.get("contrato").value.id) {
      contrato_id = +this.entidadeForm.get("contrato").value.id;
    } else {
      contrato_id = 0;
    }

    const data = this.entidadeForm.get("data_rcms").value

    const parametro = {
      contrato_id,
      data_inicio$le: this.funcaoService.converteDataSQL(data),
      data_termino$ge: this.funcaoService.converteDataSQL(data),
      orderBy: 'numero$DESC'
    };

    if (this.login.usuario?.sistemas_administrador
      && this.login.usuario?.sistemas_administrador?.includes('requisicao')) {
      delete parametro['data_inicio$le']
    }

    this.aditamentoAutoComplete = new EddyAutoComplete(
      this.entidadeForm.get("contrato_aditamento"),
      this.aditamentoService,
      "id",
      ["numero"],
      parametro,
      {
        text: ["numero"],
      }
    );
  }

  private async findAditamentos(): Promise<Page> {
    let contrato_id = null;
    if (this.entidadeForm.get("contrato").value.id) {
      contrato_id = +this.entidadeForm.get("contrato").value.id;
    } else {
      contrato_id = 0;
    }

    const data = this.entidadeForm.get("data_rcms").value;

    if (!data) {
      return { content: [] } as Page;
    }

    const parametro = {
      contrato_id,
      data_inicio$le: this.funcaoService.converteDataSQL(data),
      data_termino$ge: this.funcaoService.converteDataSQL(data),
      orderBy: 'numero$DESC'
    }

    if (this.login.usuario?.sistemas_administrador
      && this.login.usuario?.sistemas_administrador.includes('requisicao')) {
      delete parametro['data_inicio$le']
    }

    return await this.aditamentoService.filtrar(1, -1, parametro).toPromise();
  }

  public async setAditamento(): Promise<void> {
    try {
      this.entidadeForm.get("contrato_aditamento").setValue(null);
      this.aditamentoAutoComplete.id = null;

      const aditamentos = await this.findAditamentos();

      if (!aditamentos.content.length) {
        this.naoExisteAditamento = true;
      } else if (aditamentos.content.length === 1) {
        this.naoExisteAditamento = true;
        this.carregarAditamentoAutoComplete();
        this.entidadeForm
          .get("contrato_aditamento")
          .setValue(aditamentos.content[0]);
      } else {
        this.naoExisteAditamento = false;
        this.carregarAditamentoAutoComplete();
      }
    } catch (err) {
      if (err instanceof Error) {
        console.error(err.message);
        toastr.error(err.message, "Erro ao buscar aditamentos");
      }
    }
  }

  private carregarAutoCompleteSetor() {
    const parametros = {
      "estoques.estoque.id": this.entidadeForm.get('estoque').value?.id ?? this.login.estoque.id,
      "orgao.id": this.login.orgao.id,
      ativo: true,
      relations: "unidade,executoras.executora",
    };

    const ficha = this.entidadeForm.get("ficha").value;
    if (ficha) parametros["unidade.id"] = ficha.executora?.unidade?.id;

    this.setorAutoComplete = new EddyAutoComplete(
      this.entidadeForm.get("setor"),
      this.setorService,
      "id",
      ["codigo", "nome"],
      parametros,
      { text: ["nome", "codigo"] },
      () => {
        this.carregarAutoCompleteFicha();
        this.carregarAutoCompleteVeiculo();
        this.parametrosBuscaVeiculo['tombamento.setor.id'] = this.entidadeForm.get('setor').value?.id ?? this.entidade.setor?.id;
      }
    );
  }

  private async carregarAutoCompleteFicha() {
    let parametros = {
      "exercicio.id": this.login.exercicio.id,
      "orgao.id": this.login.orgao.id,
      relations: [
        "despesa",
        "recurso",
        "aplicacao",
        "executora.unidade",
        "aplicacao_variavel",
      ],
      orderBy: "despesa.nome",
    };

    const setor: Setor = this.entidadeForm.get("setor").value;
    if (setor?.executoras?.length > 0) {
      parametros["executora.id$in"] = setor.executoras
        .map((e) => e.executora.id)
        .join(",");
    } else {
      if (setor?.unidade?.id) {
        parametros["executora.unidade.id"] = setor?.unidade?.id;
      }
    }

    this.fichaAutoComplete = new EddyAutoComplete(
      this.entidadeForm.get("ficha"),
      this.fichaService,
      "numero",
      ["ficha.despesa.codigo", "ficha.despesa.nome"],
      parametros,
      { number: ["numero"], text: ["despesa.codigo", "despesa.nome"] },
      async () => {
        const ficha: FichaDespesa = this.entidadeForm.get("ficha").value;

        if (!this.fichaAutoComplete.id) {
          this.entidadeForm.get("ficha").setValue(null);
          this.entidadeForm.get("convenio").setValue(null);
          this.numeroConvenio = null;
        }

        if (ficha && !(await this.validarFicha(ficha))) {
          this.entidadeForm.get("ficha").setValue(null);
          this.fichaAutoComplete.id = null;
          this.entidadeForm.get("convenio").setValue(null);
          this.numeroConvenio = null;
        }

        if (
          ficha &&
          (!this.entidade.ficha || this.entidade.ficha.id !== ficha.id)
        ) {
          this.carregarAutoCompleteSubElemento();
        }
        this.alterarSubElemento();
        this.buscarSaldoFicha();
        this.carregarDadosFicha();
        this.carregarAutoCompleteSetor();

        if (ficha?.recurso && ficha?.aplicacao) {
          this.validaFinalidadeFundeb(ficha);
        }
        if (this.login.parametro.contabil.nao_requisitar_01_100) {
          if (ficha && ficha?.aplicacao_variavel?.codigo?.startsWith('01100')) {
            await this.validarDisponibilidadeRecurso(ficha);
          }
        }
        if (ficha?.aplicacao_variavel) await this.carregarConvenio();
      }
    );
  }


  private async validarDisponibilidadeRecurso(ficha: FichaDespesa) {
    let recursoDisponibilidade = await this.recursoDisponibilidadeService.obter({ 'recurso_despesa.codigo': ficha.recurso.codigo, 'aplicacao_despesa.codigo': ficha?.aplicacao?.codigo, 'aplicacao_variavel_despesa.codigo': ficha.aplicacao_variavel.codigo, 'exercicio.id': this.login.exercicio?.id }).toPromise();
    if (!recursoDisponibilidade?.id) {
      this.entidadeForm.get("ficha").setValue(null);
      this.fichaAutoComplete.id = null;
      this.entidadeForm.get("subelemento").setValue(null);
      this.subElementoAutoComplete.id = null;
      this.entidadeForm.get("convenio").setValue(null);
      this.numeroConvenio = null;
      toastr.warning(`Por gentileza cadastre um de-para de disponibilidade de recursos \n ou entre em contato com contador responsável.`)
      return;
    } else if (!recursoDisponibilidade?.ativo && recursoDisponibilidade?.id) {
      this.entidadeForm.get("ficha").setValue(null);
      this.fichaAutoComplete.id = null;
      this.entidadeForm.get("subelemento").setValue(null);
      this.subElementoAutoComplete.id = null;
      this.entidadeForm.get("convenio").setValue(null);
      this.numeroConvenio = null;
      toastr.warning(`Por gentileza verifique o de-para de disponibilidade de recursos \n ou entre em contato com contador responsável.`)
      return;
    }
  }

  private validaFinalidadeFundeb(ficha: FichaDespesa) {
    this.finalidadeObnObrigatorio = true;
    switch (ficha.recurso.codigo + ficha.aplicacao.codigo) {
      case "02260":
        break;
      case "02261":
        break;
      case "02262":
        break;
      case "02263":
        break;
      case "02264":
        break;
      case "02265":
        break;
      case "05260":
        break;
      case "05261":
        break;
      case "05262":
        break;
      case "05263":
        break;
      case "05264":
        break;
      case "05265":
        break;
      default:
        this.finalidadeObnObrigatorio = false;
    }
  }

  public onInputtDataCompra() {
    const data_compra = this.entidadeForm.get("data_rcms").value;
    this.carregarProcesso(false, true);
    if (data_compra) this.fichaAutoComplete.funcaoOnSelect();
  }

  public async validarFicha(ficha: FichaDespesa) {
    if (this.login.parametro?.requisicao?.nao_usar_ficha_despesa) return true;
    if (
      this.itens &&
      this.itens.length > 0 &&
      this.itens.filter((i) => i.produto_unidade?.id).length > 0
    ) {
      let pagina = await this.produtoService
        .filtrar(1, -1, {
          orgao_id: this.login.orgao.id,
          "material.sub_grupo.sub_elementos.sub_elemento.codigo$like": `${ficha.despesa.codigo.substring(
            0,
            6
          )}%`,
          id$in: this.itens
            .filter((i) => i.produto_unidade?.id)
            .map((i) => i.produto_unidade.produto.id)
            .join(","),
        })
        .toPromise();
      if (!pagina || !pagina.content || pagina.content.length === 0) {
        toastr.warning(
          `Produtos vinculado não possui vinculo com ficha ${ficha.numero} - ${ficha.despesa.nome}`
        );
        return false;
      }
      let produtos: Produto[] = pagina.content;
      for (let item of this.itens) {
        let valid = produtos.find(
          (p) => p.id === item.produto_unidade.produto.id
        );
        if (!valid) {
          toastr.warning(
            `Produtos vinculado não possui vinculo com ficha ${ficha.numero} - ${ficha.despesa.nome}`
          );
          return false;
        }
      }
    }

    if (this.favorecidos && this.favorecidos.length > 0) {
      if (ficha.despesa.n4 === "39" || ficha.despesa.n4 === "36") {
        let tipo = ficha.despesa.n4 === "39" ? "01" : "02";
        let favorecido = this.favorecidos.filter(
          (f) => f.favorecido?.tipo?.tce?.toString() !== tipo
        );

        if (favorecido && favorecido.length > 0) {
          toastr.warning(
            `Favorecido vinculado à OF é ${tipo === "01" ? "Pessoa Física" : "Pessoa Jurídica"
            }, portanto não é possível inserir Ficha de ${tipo === "01" ? "Pessoa Jurídica" : "Pessoa Física"
            }`
          );
          return false;
        }
      }
    }
    return true;
  }

  private async carregarAutoCompleteSubElemento() {
    let parametros = {
      exercicio_id: this.login.exercicio.id,
      nivel: 6,
      orderBy: "codigo",
      ativo: true
    };
    let ficha = this.entidadeForm.get("ficha").value;
    if (ficha)
      parametros["codigo$like"] = ficha.despesa.codigo.substring(0, 6) + "%";

    this.subElementoAutoComplete = new EddyAutoComplete(
      this.entidadeForm.get("subelemento"),
      this.despesaService,
      "codigo",
      ["nome"],
      parametros,
      { number: ["id", "codigo"], text: ["nome"] },
      async () => {
        let subelemento = this.entidadeForm.get("subelemento").value;
        if (!this.subElementoAutoComplete.id)
          this.entidadeForm.get("subelemento").setValue(null);
        if (subelemento && !(await this.validarSubElemento(subelemento))) {
          this.entidadeForm.get("subelemento").setValue(null);
        }
        this.alterarSubElemento(false);
      }
    );

    if (!ficha) this.subElementoAutoComplete.disabled = true;
    else this.subElementoAutoComplete.disabled = false;
    this.alterarSubElemento();
  }

  public async validarSubElemento(subelemento: Despesa) {
    if (this.login.parametro?.requisicao?.nao_usar_ficha_despesa) return true;
    if (
      this.itens &&
      this.itens.length > 0 &&
      this.itens.filter((i) => i.produto_unidade?.id).length > 0
    ) {
      let pagina = await this.produtoService
        .filtrar(1, -1, {
          orgao_id: this.login.orgao.id,
          "material.sub_grupo.sub_elementos.sub_elemento.id": subelemento.id,
          id$in: this.itens
            .filter((i) => i.produto_unidade?.id)
            .map((i) => i.produto_unidade.produto.id)
            .join(","),
        })
        .toPromise();

      if (!pagina || !pagina.content || pagina.content.length === 0) {
        toastr.warning(
          `Produtos vinculados não possuem vínculo com sub-elemento ${subelemento.codigo} - ${subelemento.nome}`
        );
        return false;
      }

      let produtos: Produto[] = pagina.content;
      for (let item of this.itens) {
        let valid = produtos.find(
          (p) => p.id === item.produto_unidade.produto.id
        );
        if (!valid) {
          toastr.warning(
            `Produtos vinculados não possuem vínculo com sub-elemento ${subelemento.codigo} - ${subelemento.nome}`
          );
          return false;
        }
      }
    }

    if (!(await this.validarTipoContratacao())) {
      toastr.warning(
        `A ficha informada não é compatível com a contratação informada no contrato. Verifique na divisão de compras se a contratação informada é válida.`
      );
      return false;
    }

    if (!(await this.validarTipoConvenio())) {
      toastr.warning(
        `A ficha informada não é compatível com o tipo de convênio informado. Verifique na divisão de compras se o convênio informado é válido.`
      );
      return false;
    }

    return true;
  }

  private async validarTipoContratacao(): Promise<boolean> {
    let subelemento = this.entidadeForm.get("subelemento").value;
    let contrato = this.entidadeForm.get("contrato").value;
    if (!subelemento || !contrato?.tipo_contratacao?.id)
      //Se o contrato for Outros não valida
      return true;

    const validar = await this.tipoContratacaoService
      .validarDespasa(contrato.tipo_contratacao.id, subelemento.id)
      .toPromise();
    return validar.valido;
  }

  private async validarTipoConvenio(): Promise<boolean> {
    let subelemento = this.entidadeForm.get('subelemento').value;
    let convenio = this.entidadeForm.get('convenio').value;
    if (!subelemento || !convenio?.tipo_convenio?.id) {
      return true;
    }

    const validar = await this.tipoConvenioService.validarDespesa(convenio.tipo_convenio.id, subelemento.id).toPromise();
    return validar.valido;
  }

  private alterarSubElemento(atualizar: boolean = true) {
    const ficha: FichaDespesa = this.entidadeForm.get("ficha").value;
    if (ficha) {
      this.fichaAutoComplete.id = ficha.numero;
      const fichaSubElemento: string = ficha.despesa.codigo.substring(0, 6);
      const subElemento: Despesa = this.entidadeForm.get("subelemento").value;
      this.subelemento = subElemento?.id;
      if (
        !subElemento ||
        fichaSubElemento !== subElemento.codigo.substring(0, 6)
      ) {
        this.subElementoAutoComplete.id = fichaSubElemento;
        this.entidadeForm.get("subelemento").setValue(null);
        this.entidadeForm.get("veiculo").setValue(null);
      } else {
        this.subElementoAutoComplete.id = subElemento.codigo;
        this.verificarParametroSubelemento(subElemento);
      }
    } else if (atualizar) {
      this.subElementoAutoComplete.id = null;
      this.subElementoAutoComplete.atualizar(false);
      this.entidadeForm.get('veiculo').setValue(null);
    }
  }

  private verificarParametroSubelemento(subelemento?: Despesa) {
    const parametroRequisicao = this.parametroRequisicao;
    const subElemento: Despesa = subelemento ?? this.entidadeForm.get("subelemento").value;
    const subelementoVeiculo = parametroRequisicao.requisicao_subelementos.find(sub => sub.subelemento.codigo === subElemento.codigo);
    if (parametroRequisicao.obrigar_indicar_placa) {
      if (subelementoVeiculo) {
        this.subelementoVeiculo = true;
      } else {
        this.subelementoVeiculo = false;
        this.veiculoAutoComplete.id = null;
        this.entidadeForm.get('veiculo').setValue(null);
      }
    } else {
      this.subelementoVeiculo = false;
      this.veiculoAutoComplete.id = null;
      this.entidadeForm.get('veiculo').setValue(null);
    }
  }

  public buscarContrato() {
    let contrato = this.entidadeForm.get("contrato").value;
    if (!contrato.numero) {
      this.contratoAtual = null;
      this.entidadeForm.get("contrato").patchValue({ id: null, numero: null });
      this.carregarProcesso(true);
      return;
    }

    if (this.contratoAtual && this.contratoAtual.numero === contrato.numero)
      return;

    this.entidadeForm.get("modalidade").enable();

    this.carregarLicitacao(true);

    this.contratoService
      .obter({
        orgao_id: this.login.orgao.id,
        numero: contrato.numero,
        orderBy: ["itens.memorial.ordem"],
        relations: [
          "licitacao",
          "modalidade",
          "favorecido.tipo",
          "itens",
          "itens.produto_unidade",
          "itens.produto_unidade.produto.aliquota",
          "itens.produto_unidade.unidade",
          "itens.produto_unidade.produto.material.sub_grupo",
          "itens.produto_unidade.produto.material.aliquota",
          "itens.memorial",
          "tipo_contratacao",
        ],
      })
      .subscribe(
        (data) => {
          if (data) this.carregarContrato(data, false);
          else {
            toastr.warning("Contrato não encontrada!");
            if (this.contratoAtual)
              this.entidadeForm.get("contrato").patchValue(this.contratoAtual);
            else {
              this.entidadeForm
                .get("contrato")
                .patchValue({ id: null, numero: null });
              this.carregarProcesso(true);
            }
            this.funcaoService.focarCampo(this.contratoInput, true);
          }
        },
        (error) => toastr.error(error.error.payload)
      );
  }

  private async carregarContrato(contrato: Contrato, init?: boolean) {
    this.visualizarContratos = false;
    if (!contrato) {
      this.entidadeForm.get("contrato").patchValue({ id: null, numero: null });
      return;
    }
    if (
      contrato.licitacao?.tipo_licitacao !== 5 &&
      !contrato.licitacao?.tabela_desconto
    ) {
      if (!(await this.validarSaldoContrato(contrato)) && !init) {
        toastr.warning(`Contrato não possui saldo para ser importado.`);
        if (this.contratoAtual)
          this.entidadeForm.get("contrato").patchValue(this.contratoAtual);
        else {
          this.entidadeForm
            .get("contrato")
            .patchValue({ id: null, numero: null });
          this.carregarProcesso(true);
        }
        this.funcaoService.focarCampo(this.contratoInput, true);
        return;
      }
    }

    if (contrato.excluido) {
      toastr.warning(`O contrato indicado está removido`);
      this.entidadeForm
        .get("contrato")
        .patchValue({ id: null, numero: null });
      this.entidadeForm
        .get("licitacao")
        .patchValue({ id: null, numero: null });
      this.entidadeForm
        .get("processo")
        .patchValue(null);
      this.carregarProcesso(true);
      this.funcaoService.focarCampo(this.contratoInput, true);
      return;
    }

    if (new Date(this.entidadeForm.get('data_rcms').value) > new Date(contrato.data_termino) && contrato.aditamentos?.length === 0) {
      toastr.warning(`O contrato indicado está vencido`);
      this.entidadeForm
        .get("contrato")
        .patchValue({ id: null, numero: null });
      this.entidadeForm
        .get("licitacao")
        .patchValue({ id: null, numero: null });
      this.entidadeForm
        .get("processo")
        .patchValue(null);
      this.carregarProcesso(true);
      this.funcaoService.focarCampo(this.contratoInput, true);
      return;
    }
    const aditamentosOrdenados = contrato?.aditamentos?.sort((a, b) => {
      return a.data_termino < b.data_termino ? 1 : -1;
    });
    if (aditamentosOrdenados?.length > 0) {
      if (new Date(this.entidadeForm.get('data_rcms').value) > new Date(aditamentosOrdenados[0]?.data_termino)) {
        toastr.warning(`O contrato/aditivo indicado está vencido`);
        this.entidadeForm
          .get("contrato")
          .patchValue({ id: null, numero: null });
        this.entidadeForm
          .get("licitacao")
          .patchValue({ id: null, numero: null });
        this.entidadeForm.get("processo").patchValue(null);
        this.carregarProcesso(true);
        this.funcaoService.focarCampo(this.contratoInput, true);
        return;
      }
    }

    if (
      !this.contratoAtual ||
      (this.contratoAtual && this.contratoAtual.id !== contrato.id)
    )
      this.itens = [];
    this.contratoAtual = contrato;

    this.entidadeForm.get("contrato").patchValue(contrato);
    this.entidadeForm.get("processo").setValue(contrato.processo);
    this.processoAtual = contrato.processo;
    this.tipoContrato =
      contrato.tipo === "REGISTRO_PRECO" ? "REGISTRO_PRECO" : "CONTRATO";

    if (contrato.licitacao) {
      this.entidadeForm.get("licitacao").patchValue(contrato.licitacao);
      this.licitacaoAtual = contrato.licitacao;
      this.carregarReservaDotacao(contrato.licitacao.id);
    }
    if (contrato.modalidade)
      this.entidadeForm.get("modalidade").setValue(contrato.modalidade);
    if (contrato.prazo)
      this.entidadeForm.get("prazo_pagamento").setValue(contrato.prazo);
    if (contrato.entrega)
      this.entidadeForm.get("prazo_entrega").setValue(contrato.entrega);

    if (!init) {
      let favorecido = new RcmsFavorecido();
      let select = !this.favorecidos
        ? null
        : this.favorecidos.find(
          (f) => f.favorecido.id === contrato.favorecido.id
        );
      if (select) favorecido = select;
      favorecido.favorecido = contrato.favorecido;
      if (+favorecido.favorecido.tipo.tce !== 1 && +favorecido.favorecido.tipo.tce !== 2 && +favorecido.favorecido.tipo.tce !== 10) {
        toastr.warning('Verifique a espécie no cadastro de fornecedor');
        this.entidadeForm
          .get("contrato")
          .patchValue({ id: null, numero: null });
        this.entidadeForm
          .get("licitacao")
          .patchValue({ id: null, numero: null });
        this.entidadeForm
          .get("processo")
          .patchValue(null);
        this.carregarProcesso(true);
        this.funcaoService.focarCampo(this.contratoInput, true);
        return;
      }
      this.favorecidos = [favorecido];
    }
    // this.entidadeForm.get('licitacao').disable();
    // this.processoInput.nativeElement.disabled = true;
    this.entidadeForm.get("modalidade").disable();
    this.contratoAtual = contrato;
    this.carregarAutoCompleteFicha();
    this.carregarAutoCompleteSubElemento();
    this.carregarItensContrato(true);
  }

  public retornaCota(itemAtual: ContratoItem, lista: ContratoItem[]) {
    let cota: "P" | "R";
    if (itemAtual.memorial) {
      cota =
        itemAtual.memorial.cota === "PRINCIPAL"
          ? "P"
          : itemAtual.memorial.cota === "NAO_DEFINIDO"
            ? "P"
            : "R";
    } else {
      let itemIgual = lista.find(
        (it) =>
          it.produto_unidade.id === itemAtual.produto_unidade.id &&
          it.id !== itemAtual.id
      );
      cota = !itemIgual
        ? "P"
        : +itemAtual.quantidade > +itemIgual.quantidade
          ? "P"
          : "R";
    }
    return cota;
  }

  public carregarItensContrato(ocultarDlg?: boolean) {

    const dataReferencia = this.funcaoService.converteDataSQL(this.entidadeForm.get('data_rcms').value);
    let contrato = this.entidadeForm.get("contrato").value;
    if (!contrato.numero) return;

    // if (this.selecionarItens.length > 0 && !ocultarDlg) {
    //   this.visualizarSelecionar = true;
    //   return;
    // }

    this.contratoItemService
      .filtrar(1, -1, {
        "contrato.orgao.id": this.login.orgao.id,
        "contrato.numero": contrato.numero,
        "contrato.inativo": false,
        "contrato.excluido": false,
        "OR": `produto_unidade.produto.material.sub_grupo.sub_elementos.sub_elemento.exercicio_id=${this.login.exercicio.id};!;!;produto_unidade.id$null=true`,
        orderBy: ["memorial.ordem"],
        relations: [
          "produto_unidade.produto.material.sub_grupo.sub_elementos.sub_elemento",
          "produto_unidade.unidade",
          "produto_unidade.produto.material.aliquota",
          "produto_unidade.produto.aliquota",
          "memorial",
        ],
      })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        if (!data || !data.content || data.content.length === 0) {
          this.confirmationService.confirm({
            header: "Atenção",
            message: "Nenhum item encontrado! Verifique se há vínculo de sub elemento ao sub grupo para os itens do contrato informado!",
            icon: "pi pi-exclamation-triangle",
            acceptLabel: "OK",
            key: "rcms",
            rejectVisible: false,
            accept: () => {
            },
          });
          return;
        };
        data.content = data.content.filter(
          (c) => c.contrato.tipo === this.tipoContrato
        );
        this.selecionarItens = data.content.map((c) => {
          let item = new RcmsItem();
          item.produto_unidade = c.produto_unidade;
          item.produto = c.descricao;
          item.unidade = c.unidade;
          item.quantidade = c.quantidade;
          item["codigo_item"] = c.memorial ? c.memorial.ordem : "";
          item["cota"] =
            this.retornaCota(c, data.content) === "P"
              ? "PRINCIPAL"
              : "RESERVADO";
          item["contrato_item_id"] = c.id;
          item['bloqueado'] = c.bloqueado;

          let cota = this.retornaCota(c, data.content);

          this.contratoItemService
            .obterSaldo(
              this.login.orgao.id,
              c.id,
              this.entidade.id
                ? {
                  desconRcmsId: this.entidade.id,
                  cota,
                  contrato_id: contrato.id,
                  dataReferencia: dataReferencia
                }
                : { cota, contrato_id: contrato.id, dataReferencia: dataReferencia }
            )
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
              item["saldo_vl"] = +res.saldo_vl;
              item["saldo_qtd"] = +res.saldo_qtd;
              item["quantidade_import"] = +res.saldo_qtd;
              item["valor_unitario"] = +res.valor_unitario;
              //Travado temporariamente para testar apenas em cotas principais, futuramente será estudado testar o inverso como informação.
              if (
                this.login.parametro?.compras?.priorizar_reservado &&
                c.memorial?.cota === "PRINCIPAL"
              ) {
                let licitacao = this.entidadeForm.get("licitacao").value;
                //Quando principal, testa se a reserva é mais favoravel e vice-versa para exibir aviso/trava.
                this.propostaService
                  .obterVencedoresItem(
                    licitacao.id,
                    item.produto_unidade.id,
                    c.memorial.cota == "PRINCIPAL" ? "RESERVADO" : "PRINCIPAL",
                    this.contratoAtual.favorecido.id
                  )
                  .subscribe((res) => {
                    res["content"].forEach((i) => {
                      let p: Proposta = i;
                      if (
                        p.memorial.id != c.memorial.id &&
                        p.proponente.favorecido.id !=
                        this.contratoAtual.favorecido.id
                      ) {
                        //Onde estiver principal, deve se jogar 5%, pois EPP/MEI pode ser até 5% mais caro segundo lei e ainda ser válido como prioridade.
                        let va = c.memorial.cota == "PRINCIPAL" ? 1.05 : 1; //Caso o contrato atual é o principal, testa pelo C.
                        let vb = p.memorial.cota == "PRINCIPAL" ? 1.05 : 1; //Caso o contrato atual é o reserva, testa pelo P.
                        if (item["valor_unitario"] * va > p.valor_final * vb) {
                          //Se for desvantajoso, antes de bloquear verificar se o saldo do outro contrato ainda está disponível.
                          //Busca o contrato atual do fornecedor reservado, para a mesma licitação do Principal:
                          this.contratoService
                            .obter({
                              inativo: false,
                              excluido: false,
                              "orgao.id": this.login.orgao.id,
                              "licitacao.id": licitacao.id,
                              "favorecido.id": p.proponente.favorecido.id,
                            })
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe((contratoReserva) => {
                              //Busca o item atual no contrato:

                              let parametros = {
                                "contrato.orgao.id": this.login.orgao.id,
                                "contrato.numero": contratoReserva.numero,
                                "contrato.inativo": false,
                                "contrato.excluido": false,
                              };

                              if (p.memorial?.produto_unidade?.id)
                                parametros["produto_unidade.id"] =
                                  p.memorial.produto_unidade.id;
                              else {
                                parametros["descricao"] = p.memorial.descricao;
                                parametros["unidade"] = p.memorial.unidade;
                              }

                              this.contratoItemService
                                .filtrar(1, -1, parametros)
                                .pipe(takeUntil(this.unsubscribe))
                                .subscribe((propReserva) => {
                                  //Com o item, se obtem o saldo atual dele:
                                  this.contratoItemService
                                    .obterSaldo(
                                      this.login.orgao.id,
                                      propReserva.content[0].id,
                                      this.entidade.id
                                        ? { desconRcmsId: this.entidade.id, dataReferencia: dataReferencia }
                                        : { dataReferencia: dataReferencia }
                                    )
                                    .pipe(takeUntil(this.unsubscribe))
                                    .subscribe((saldo) => {
                                      if (saldo.saldo_qtd > 0) {
                                        item["vantagem_bloqueio"] =
                                          c.memorial.cota == "PRINCIPAL";
                                        item[
                                          "vantagem"
                                        ] = `Item disponível no Contrato: ${contratoReserva.numero.substring(
                                          0,
                                          4
                                        )}/${contratoReserva.numero.substring(
                                          4
                                        )} - ${p.proponente.favorecido.nome}. ${c.memorial.cota == "PRINCIPAL"
                                          ? "\nDeve ser utilizado por se tratar de um item de COTA RESERVADA com saldo disponível "
                                          : "\nPode ser mais vantajoso na COTA PRINCIPAL com saldo disponível"
                                          } e valor mais vantajoso (Vl. unitário ${Number(
                                            p.valor_final
                                          ).toFixed(2)}).`;
                                      }
                                    });
                                });
                            });
                        } else {
                          item["vantagem_bloqueio"] = false;
                        }
                      }
                    });
                  });
              }
              let select;
              if (this.itens)
                select = this.itens.find(
                  (i) =>
                    i.produto_unidade?.id &&
                    i.produto_unidade.id === item.produto_unidade.id &&
                    i.cota === (item.cota === "PRINCIPAL" ? "P" : "R")
                );
              if (select) {
                item.id = select.id;
                item.cotacoes = select.cotacoes;
                item["selecionado"] = true;
                item["quantidade_import"] = +select.quantidade;
              }

              //validar bloqueio de seleção de item
              let mensagem_bloqueio = this.bloquearItem(item);
              if (mensagem_bloqueio) {
                item["bloqueado"] = true;
                item["mensagem_bloqueio"] = mensagem_bloqueio;
              }
            });

          return item;
        });
        if (!ocultarDlg) this.visualizarSelecionar = true;
      });
  }

  public bloquearItem(item: RcmsItem, propostas?: Proposta[]): string {
    if (!item.produto_unidade?.id) return null;
    if (!this.usarFichaDespesa) {
      if (
        item.produto_unidade?.produto?.material?.servico &&
        this.entidadeForm.get("somente_produto").value
      ) {
        return `Produto ${item.produto_unidade.produto.nome} não pode ser inserido na requisição marcada com somente serviços.`;
      } else if (
        !item.produto_unidade?.produto?.material?.servico &&
        this.entidadeForm.get("somente_servico").value
      ) {
        return `Item ${item.produto_unidade.produto.nome} não pode ser inserido na requisição marcada com somente produtos.`;
      } else {
        return null;
      }
    } else {

      let subelemento = this.entidadeForm.get("subelemento").value;
      if (
        !subelemento ||
        !item.produto_unidade?.produto?.material?.sub_grupo?.sub_elementos ||
        item.produto_unidade.produto.material.sub_grupo.sub_elementos.length ===
        0
      ) {
        return `Produto ${item.produto_unidade.produto.nome} não possui vinculo com sub-elemento informado.`;
      }

      let valid = false;
      let subelementoInativo = false;
      for (let subelemItem of item.produto_unidade.produto.material.sub_grupo.sub_elementos) {
        if (subelemItem.sub_elemento.id === subelemento.id) {
          valid = true;
          if (subelemItem.inativo === true) {
            subelementoInativo = true;
          }
        }
      }

      if (!valid) {
        return `Produto ${item.produto_unidade.produto.nome} não possui vinculo com sub-elemento informado.`;
      }

      if (subelementoInativo) {
        return `Produto ${item.produto_unidade.produto.nome} possui vinculo com o sub-elemento informado, porém o mesmo se encontra inativo no sub-grupo vinculado ao produto!`;
      }

      if (item['bloqueado']) {
        return `Produto ${item.produto_unidade.produto.nome} está bloqueado.`
      }

      return null;
    }
  }

  private async validarSaldoContrato(contrato: Contrato): Promise<boolean> {
    let saldo = await this.contratoService
      .obterSaldo(
        this.login.orgao.id,
        contrato.id,
        this.entidade?.id ? { rcmsId: this.entidade.id } : null,
        contrato.saldo_quantidade
      )
      .toPromise();
    if (!saldo || !saldo.saldo || saldo.saldo <= 0) {
      return false;
    }
    return true;
  }

  public async posSelecionarItens(itens: RcmsItem[]) {
    this.itens = [];
    let cots: RcmsCotacao[] = itens.map((item) => {
      return {
        rcmsFavorecido: this.favorecidos[0].favorecido,
        rcmsItem: item,
        valor_unitario: item.valor_unitario,
      };
    });

    for (let cotacao of cots) {
      if (
        cotacao?.rcmsItem?.produto_unidade?.produto?.id ||
        cotacao?.rcmsItem?.produto_unidade?.unidade?.id
      ) {
        let valor_referencia = await this.compraItemService
          .itemBancoPreco(
            cotacao.rcmsItem.produto_unidade.produto.id,
            cotacao.rcmsItem.produto_unidade.unidade.id,
            this.login.orgao.id
          )
          .toPromise();
        cotacao.valor_referencia = +valor_referencia;
      } else {
        toastr.warning(
          `Não foi possivel carregar o valor referência do item importado!`
        );
      }
    }

    this.favorecidos[0].cotacoes.push(...cots)

    this.itens = itens.map((item) => {
      item.cotacoes = [new RcmsCotacao()];
      item.cotacoes[0].rcmsFavorecido = this.favorecidos[0].favorecido;
      item.cotacoes[0].valor_unitario = item.valor_unitario;
      item.cotacoes[0].rcmsItem = item;
      return item;
    });

    this.itens = itens.map((a, i) => {
      a.ordem = 1 + i;
      a.quantidade = a["quantidade_import"];
      if (this.favorecidos.length > 0) {
        if (!this.favorecidos[0].cotacoes) this.favorecidos[0].cotacoes = [];
        let cotacao = new RcmsCotacao();
        if (a.cotacoes && a.cotacoes.length > 0) {
          for (let c of a.cotacoes) {
            cotacao = c;
          }
        }
        cotacao.rcmsFavorecido = this.favorecidos[0];
        cotacao.valor_unitario = a["valor_unitario"];
        a.cotacoes = [cotacao];
      }
      return a;
    });

    if (this.itens.filter((i) => !i.produto_unidade?.id).length > 0) {
      toastr.warning(`Foi identificado itens com serviços não cadastrados`);
      this.produtosAjuste = this.itens
        .filter((i) => !i.produto_unidade?.id)
        .map((i) => {
          return {
            produto: i.produto,
            unidade: i.unidade,
            id: i["contrato_item_id"],
          };
        });
      this.visulizarAjusteServico = true;
      this.ajusteSemSubmit = true;
    }
  }

  public carregarLicitacao(naoBuscarContrato?: boolean) {
    this.entidadeForm.get("processo").enable();
    let licitacao: Licitacao = this.entidadeForm.get("licitacao").value;
    if (!licitacao.numero) {
      this.entidadeForm.get("licitacao").patchValue({ id: null, numero: null });
      return;
    }

    if (this.licitacaoAtual && this.licitacaoAtual.numero === licitacao.numero)
      return;

    const parametros = {
      numero: licitacao.numero,
      "orgao.id": this.login.orgao.id,
      "itens.propostas.situacao": "VENCEDOR",
      relations: "modalidade",
    }

    if (licitacao?.modalidade?.id) {
      parametros['modalidade_id'] = licitacao?.modalidade?.id
      parametros['situacao'] = licitacao?.situacao
    }

    this.licitacaoService
      .filtrar(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (data) => {
          if (data.content.length > 1) {
            this.licitacoesSelecionar = data.content;
            this.visualizarLicitacoes = true;
          } else {
            this.selecionarLicitacao(data.content[0], naoBuscarContrato);
          }
        },
        (error) => toastr.error(error.error.payload)
      );
  }

  public async carregarProcesso( 
    ignorar?: boolean,
    botaoContrato?: boolean,
    botaoProcesso?: boolean
  ) {
    if (botaoContrato) {
      this.contratoService.filtrar(1, -1, {
        excluido: false,
        "orgao.id": this.login.orgao.id,
        numero: this.entidadeForm.get('contrato.numero').value,
        relations: 'favorecido.tipo,licitacao,modalidade,prazo,entrega,itens,tipo_contratacao,aditamentos'
      })
        .subscribe(
          async (lista) => {
            if (!lista || !lista.content || lista.content.length === 0) {
              toastr.warning(`Contrato '${contrato.numero ? contrato.numero : " "}' não encontrado!`);
              this.entidadeForm.get('contrato').patchValue({ id: null, numero: null });
              return
            } else {
              if (lista.content.length > 1) {
                this.contratosSelecionar = lista.content
                this.apertouContrato = true;
                this.visualizarContratos = true;
              } else {
                if (lista.content[0].inativo) {
                  toastr.warning('Contrato inativado. Entre em contato com o administrador do sistema de licitação.');
                  this.entidadeForm.get('contrato').patchValue({ id: null, numero: null });
                  return
                }
                await this.selecionarContrato(lista.content[0])
              }
            }
          }
        );
    }
    let processo = this.entidadeForm.get("processo").value;
    let contrato = this.entidadeForm.get("contrato").value;
    // let licitacao = this.entidadeForm.get('licitacao').value;
    if (!processo) {
      if (contrato.numero) {
        processo = this.processoAtual;
        this.entidadeForm.get("processo").setValue(processo);
      } else {
        this.processoAtual = null;
        this.entidadeForm.get("licitacao").get("numero").setValue(null);
        this.carregarLicitacao();
      }
    }
    if (
      (!processo ||
        (this.processoAtual &&
          this.funcaoService.removerPontos(this.processoAtual).trim() ===
          this.funcaoService.removerPontos(processo).trim())) &&
      (!ignorar || !processo)
    )
      return;
    if (botaoProcesso) {
      this.apertouProcesso = true;
    }

    this.licitacaoService
      .filtrar(1, -1, {
        OR: `processo=${this.funcaoService
          .removerPontos(processo)
          .trim()};!;!;processo=${processo}`,
        "orgao.id": this.login.orgao.id,
        "itens.propostas.situacao": "VENCEDOR",
        "relations": "modalidade"
      })
      .subscribe(
        async (data) => {
          if (!data || !data.content || data.content.length === 0) {
            toastr.warning(`Processo '${processo}' não encontrado!`);
            this.entidadeForm.get("processo").setValue(null);
            if (this.processoAtual)
              this.entidadeForm.get("processo").setValue(this.processoAtual);
            this.funcaoService.focarCampo(this.processoInput, true);
            return;
          }
          this.carregarReservaDotacao(data.content[0].id);
          let licitacao = data.content[0];
          this.entidadeForm.get("licitacao").patchValue(licitacao);
          this.carregarLicitacao(true);
          if (!(await this.carregarContratosProcesso(processo))) {
            this.entidadeForm
              .get("contrato")
              .patchValue({ id: null, numero: null });
            await this.carregarFavorecidosProcesso(processo);
          }
          this.processoAtual = processo;
        },
        (error) => toastr.error(error.error.payload)
      );
  }

  private async carregarReservaDotacao(licitacao_id: any) {
    this.reservaDotacaoService
      .filtrar(1, -1, {
        "licitacao.id": licitacao_id,
        "orgao.id": this.login.orgao.id,
        relations: "licitacao.modalidade",
        "exercicio.id": this.login.exercicio.id,
      })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response) => {
        this.reservaDotacaoList = response.content;
      });
  }

  private async carregarContratosProcesso(processo: string): Promise<boolean> {
    let pagina = await this.contratoService
      .filtrar(1, -1, {
        OR: `processo=${this.funcaoService
          .removerPontos(processo)
          .trim()};!;!;processo=${processo}`,
        inativo: false,
        excluido: false,
        orgao_id: this.login.orgao.id,
        orderBy: ["numero", "itens.memorial.ordem"],
        relations: [
          "licitacao",
          "modalidade",
          "favorecido.tipo",
          "itens",
          "itens.produto_unidade",
          "itens.produto_unidade.produto",
          "itens.produto_unidade.unidade",
          "itens.produto_unidade.produto.material.aliquota",
          "itens.produto_unidade.produto.aliquota",
          "itens.produto_unidade.produto.material.sub_grupo",
          "itens.memorial",
          "tipo_contratacao",
        ],
      })
      .toPromise();
    if (!pagina || !pagina.content || pagina.content.length === 0) {
      return false;
    }
    let contratos: Contrato[] = [];
    for (let contrato of pagina.content) {
      if ((await this.validarSaldoContrato(contrato)).valueOf()) {
        contratos.push(contrato);
      }
    }
    if (contratos.length === 0) return false;
    this.contratosSelecionar = contratos;
    this.visualizarContratos = true;
    this.apertouProcesso = true;
    return true;
  }

  private async carregarFavorecidosProcesso(processo: string) {
    let pagina = await this.proponenteService
      .filtrar(1, -1, {
        'licitacao.processo': processo,
        'propostas.situacao': "VENCEDOR",
        // OR: `licitacao.processo=${this.funcaoService
        //   .removerPontos(processo)
        //   .trim()};!;!;licitacao.processo=${processo}`,
        // "propostas.situacao": "VENCEDOR",
        relations: "favorecido.tipo,propostas",
      })
      .toPromise();
    if (!pagina || !pagina.content || pagina.content.length === 0) {
      return;
    }
    this.favorecidosSelecionar = pagina.content.map((p) => p.favorecido);
    console.log(this.favorecidosSelecionar)
    this.visualizarFavorecidos = true;
    return true;
  }

  public carregarItensProcesso() {
    let processo = this.entidadeForm.get("processo").value;
    let contrato = this.entidadeForm.get("contrato").value;
    if (
      contrato.numero ||
      !processo ||
      !this.favorecidos ||
      this.favorecidos.length === 0
    )
      return;
    this.memorialService
      .filtrar(1, -1, {
        OR: `licitacao.processo=${this.funcaoService
          .removerPontos(processo)
          .trim()};!;!;licitacao.processo=${processo}`,
        "propostas.situacao": "VENCEDOR",
        "propostas.proponente.favorecido.id": this.favorecidos[0].favorecido.id,
        "produto_unidade.produto.material.sub_grupo.sub_elementos.sub_elemento.exercicio_id": this.login.exercicio.id,
        relations:
          "produto_unidade.produto.aliquota,produto_unidade.produto.material.aliquota,produto_unidade.produto.material.sub_grupo.sub_elementos.sub_elemento,produto_unidade.unidade,propostas.proponente.favorecido",
      })
      .subscribe((data) => {
        if (!data || !data.content || data.content.length === 0) return;
        this.selecionarItens = data.content.map((i) => {
          let item = new RcmsItem();
          item.produto_unidade = i.produto_unidade;
          item.produto = i.descricao;
          item.unidade = i.unidade;
          item.quantidade = +i.quantidade;
          item["saldo_qtd"] = +i.quantidade;
          item["valor_unitario"] = +i.propostas[0].valor_unitario;
          item["saldo_vl"] = +i.quantidade * +i.propostas[0].valor_unitario;
          item["quantidade_import"] = +i.quantidade;
          item["codigo_item"] = i.ordem;
          item["cota"] = i.cota;
          let select;
          if (this.itens)
            select = this.itens.find(
              (it) => it.produto_unidade.id === i.produto_unidade.id
            );
          if (select) {
            item.id = select.id;
            item.cotacoes = select.cotacoes;
            item["selecionado"] = true;
            item["quantidade_import"] = +select.quantidade;
          }

          //validar bloqueio de seleção de item
          let mensagem_bloqueio = this.bloquearItem(item, i.propostas);
          if (mensagem_bloqueio) {
            item["bloqueado"] = true;
            item["mensagem_bloqueio"] = mensagem_bloqueio;
          }
          return item;
        });
        this.visualizarSelecionar = true;
      });
  }

  public async selecionarContrato(contrato: Contrato) {
    this.entidadeForm.get("contrato").patchValue(contrato);
    this.carregarContrato(contrato);
    await this.setAditamento();
  }


  public async selecionarLicitacao(data: Licitacao, naoBuscarContrato?: boolean) {
    if (data) {
      let processo = this.entidadeForm.get("processo").value;
      if (
        processo &&
        this.funcaoService.removerPontos(data.processo).trim() !==
        this.funcaoService.removerPontos(processo).trim()
      ) {
        toastr.warning(
          `Licitação de número '${this.funcaoService.mascarar(
            "#####/####",
            data.numero
          )}' não pertencente ao processo '${processo}'`
        );
        if (this.licitacaoAtual)
          this.entidadeForm
            .get("licitacao")
            .patchValue(this.licitacaoAtual);
        else {
        }
        // this.funcaoService.focarCampo(this.licitacaoInput, true);
        return;
      }
      this.entidadeForm.get("licitacao").patchValue(data);
      this.entidadeForm.get("processo").setValue(data.processo);
      if (data.modalidade)
        this.entidadeForm.get("modalidade").setValue(data.modalidade);
      this.entidadeForm.get("modalidade").disable();
      // this.processoInput.nativeElement.disabled = true;
      let contrato = this.entidadeForm.get("contrato").value;
      if (!naoBuscarContrato && !contrato.id) this.carregarProcesso();
      this.licitacaoAtual = data;
    } else {
      if (this.licitacaoAtual)
        this.entidadeForm
          .get("licitacao")
          .patchValue(this.licitacaoAtual);
      else
        this.entidadeForm
          .get("licitacao")
          .patchValue({ id: null, numero: null });
      // this.funcaoService.focarCampo(this.licitacaoInput, true);
      toastr.warning("Licitação não encontrada!");
    }
  }

  public selecionarFavorecido(favorecido: Favorecido) {
    if (
      this.favorecidos &&
      this.favorecidos.length > 0 &&
      this.favorecidos[0].favorecido.id !== favorecido.id
    )
      this.itens = [];
    let rcmsFavorecido = new RcmsFavorecido();
    let select = !this.favorecidos
      ? null
      : this.favorecidos.find((f) => (f.favorecido.id = favorecido.id));
    if (select) rcmsFavorecido = select;
    rcmsFavorecido.favorecido = favorecido;
    this.favorecidos = [rcmsFavorecido];
    this.carregarAutoCompleteFicha();
    this.carregarAutoCompleteSubElemento();
  }

  public verificarPedidoCompraDireta() {
    const licitacao = this.entidadeForm.get("licitacao").value;
    const processo = this.entidadeForm.get("processo").value;
    const contrato = this.entidadeForm.get("contrato").value;
    const modalidade: Modalidade = this.entidadeForm.get("modalidade").value;

    if (
      (!licitacao || !licitacao.id) &&
      (!contrato || !contrato.id) &&
      !processo &&
      modalidade?.codigo === 5
    )
      this.pedidoCompraDireta = true;
    else this.pedidoCompraDireta = false;
  }

  public aposAjustarProdutos(produtos: any[]) {
    const contrato = this.entidadeForm.get("contrato").value;
    const licitacao = this.entidadeForm.get("licitacao").value;

    if (!produtos) return;
    if (produtos.filter((p) => !p.produto_unidade?.id).length > 0) {
      toastr.warning(`Alguns serviços não foram ajustados`);
      return;
    }
    for (let item of this.itens) {
      if (item.produto_unidade?.id) continue;
      let produto_unidade = produtos.find(
        (p) =>
          p.produto === item.produto_unidade.produto.nome &&
          p.unidade === item.produto_unidade.unidade.nome
      );
      if (produto_unidade) {
        item.produto_unidade = produto_unidade.produto_unidade;

        if (contrato.id) {
          this.contratoItemService
            .atualizarProdutoUnidadeId(
              produto_unidade.produto_unidade,
              item["contrato_item_id"]
            )
            .subscribe((ci2) => {
              toastr.success(
                `Ajustado item ${item.produto} - ${item.unidade
                } do contrato ${new LicitacaoPipe().transform(contrato.numero)}`
              );
              item.produto = null;
              item.unidade = null;
            });
        }
        if (licitacao.id) {
          this.memorialService
            .atualizarProdutoUnidade(produto_unidade.produto_unidade, {
              licitacao_id: licitacao.id,
              produto: item.produto,
              unidade: item.unidade,
            })
            .subscribe((ci2) => {
              toastr.success(
                `Ajustado item ${item.produto} - ${item.unidade
                } da licitação ${new LicitacaoPipe().transform(
                  licitacao.numero
                )}`
              );
              item.produto = null;
              item.unidade = null;
            });
        }
      }
    }
    this.carregarItens(this.itens);
    if (!this.ajusteSemSubmit) this.submitForm(this.limparTela);
    this.ajusteSemSubmit = false;
  }

  public cancelarAjuste() {
    if (this.ajusteSemSubmit) {
      this.itens = [];
      this.carregarItens(this.itens);
    }
    this.visulizarAjusteServico = false;
  }

  public parametrosProdutos(): {} {
    let subelemento = this.entidadeForm.get("subelemento").value;
    let somente_produto = this.entidadeForm.get("somente_produto").value;
    let somente_servico = this.entidadeForm.get("somente_servico").value;
    // let processo = this.entidadeForm.get("processo").value;

    let parametros = {};
    if (subelemento && subelemento.codigo) {
      parametros["valid_sub_elemento$like"] = `%${subelemento.id}%`;
    } else if (somente_produto || somente_servico) {
      parametros["material.servico"] = somente_servico;
    }
    // if (processo)
    //   parametros['unidades.memoriais.licitacao.processo'] = this.funcaoService.removerPontos(processo).trim();
    return parametros;
  }

  public totalRCSM() {
    let saldo = 0.0;
    if (!this.itens) return saldo;
    let itensAnulados = this.itens.filter((c) => c.cotacoes && c.cotacoes.find((d) => +d.valor_unitario < 0));
    for (let item of this.itens) {
      if (!item.cotacoes) continue;
      let menor = item.cotacoes
        .filter((c) => c.valor_unitario > 0)
        .sort((a, b) => a.valor_unitario - b.valor_unitario)[0];
      if (menor) {
        saldo += menor.valor_unitario * item.quantidade;
        if (itensAnulados) {
          for (let anulado of itensAnulados) {
            for (let cotacaoAnulada of anulado.cotacoes) {
              if (cotacaoAnulada.rcmsFavorecido.id === menor.rcmsFavorecido.id) {
                saldo += +cotacaoAnulada.valor_unitario;
              }
            }
          }
        }
      }
    }
    return this.funcaoService.arredondar(saldo, 2);
  }

  public menorPreco() {
    if (!this.itens) return 0.0;
    return this.itens
      .map((i): number => {
        if (!i.cotacoes || !i.quantidade) return 0.0;
        let menor = i.cotacoes.sort((a, b) =>
          a.valor_unitario === 0.0
            ? 1
            : +i.quantidade * +a.valor_unitario -
            +i.quantidade * +b.valor_unitario
        )[0];
        if (!menor) return 0;
        return +menor.valor_unitario * +i.quantidade;
      })
      .sort((a, b) => (a === 0.0 ? 1 : a - b))[0];
  }

  public desbloquearRcms() {
    if (!this.justificativa_aceite) {
      toastr.warning("Informe a justificativa");
      return;
    }
    this.visualizarAceite = false;
    // this.submitForm(false, (entidade) => {
    this.rcmsService
      .desbloquearRcms(this.entidade.id, this.justificativa_aceite)
      .subscribe(
        (data) => {
          toastr.success(`Requisição desbloqueada`);
          if (this.component) this.onDesbloqueado.emit();
          this.loadResource();
        },
        (error) => this.funcaoService.acaoErro(error)
      );
    //});
  }

  public autorizarRcms() {
    // this.submitForm(false, (entidade) => {
    this.rcmsService.autorizarRcms(this.entidade.id).subscribe(
      (data) => {
        toastr.success(`Requisição autorizada`);
        if (this.component) this.onAutorizado.emit();
        this.loadResource();
      },
      (error) => this.funcaoService.acaoErro(error)
    );
    //});
  }

  public carregarPessoa(pessoa: Pessoa) {
    this.entidadeForm.get(this.campoPessoa).setValue(pessoa);
  }

  public selecionarTab(tab: number, event) {
    this.validaFichaObras();

    if (this.tab === 2 && this.itens) {
      for (let item of this.itens) {
        if (item.editavel) {
          this.itens.splice(this.itens.indexOf(item), 1);
        }
      }
    }
    if (this.entidadeForm.invalid && tab > 1) {
      event.stopPropagation();
      const invalidos: string[] = [];
      let controls = this.entidadeForm.controls;
      Object.entries(controls).forEach(([key, value]) => {
        if (value.invalid) invalidos.push(key);
      });
      toastr.warning(
        invalidos
          .map((c) => {
            if (this.camposClasse) {
              let campo = this.camposClasse.find(
                (cl) => (cl.campo === c && !cl.classe) || cl.classe === c
              );
              return campo
                ? campo.classe
                  ? campo.nomeClasseSimples
                  : campo.nome
                : c;
            }
          })
          .join(", "),
        "Há campos obrigatórios não informados ou inválidos!"
      );
      return;
    }

    if (!this.entidade.id) {
      if (
        !this.usarFichaDespesa &&
        !this.entidadeForm.get("somente_produto").value &&
        !this.entidadeForm.get("somente_servico").value
      ) {
        event.stopPropagation();
        toastr.warning("Tipo de itens habilitados não foi selecionado!");
        return;
      } else if (
        this.usarFichaDespesa &&
        (!this.entidadeForm.get("ficha").value ||
          !this.entidadeForm.get("subelemento").value)
      ) {
        event.stopPropagation();
        toastr.warning("Ficha de despesa ou Subelemento não informados!");
      }
    }

    if (this.cotacaoCon && tab === 4) this.cotacaoCon.carregarCotacoes(true);
    this.tab = tab;
  }

  public tabSelecionado(tab: number) {
    return this.tab === tab;
  }

  public carregarDadosFicha(): void {
    this.recurso = (
      this.entidadeForm.get("ficha").value as FichaDespesa
    )?.recurso;
    this.aplicacao = (
      this.entidadeForm.get("ficha").value as FichaDespesa
    )?.aplicacao;
    this.unidade = (
      this.entidadeForm.get("ficha").value as FichaDespesa
    )?.executora;
  }

  public imprimir(nota: NotaRcmsRpt) {
    let p = new Promise<void>((resolve, reject) => {
      if (
        this.login.parametro?.requisicao?.minimo_tres_cotacoes &&
        !(this.login.usuario.administrador && this.login.usuario.sistemas_administrador?.includes('compras')) &&
        this.entidadeForm.get("processo").value == null &&
        this.entidadeForm.get("licitacao").value["id"] == null &&
        this.entidadeForm.get("contrato").value["id"] == null
      ) {
        let parametros = {
          "orgao.id": this.login.orgao.id,
          id: this.entidade.id,
          minimo_tres_cotacoes: true,
        };
        this.rcmsService
          .filtrar(1, -1, parametros)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            if (res.content.length === 0) {
              reject();
            } else {
              resolve();
            }
          });
      } else {
        resolve();
      }
    });
    p.then(() => {
      this.rcmsService.obter({
        relations: [
          "setor",
          "licitacao",
          "contrato",
          "contrato_aditamento",
          "exercicio",
          "orgao",
          "ficha",
          "ficha.despesa",
          "subelemento",
          "requerente",
          "modalidade",
          "operador",
          "ficha.executora.unidade",
          "ficha.recurso",
          "ficha.acao",
          "ficha.aplicacao",
          "responsavel_cotacao",
          "responsavel_fiscalizacao",
          "estoque",
        ],
        "exercicio.id": this.login.exercicio.id,
        "orgao.id": this.login.orgao.id,
        id: this.entidade.id,
      })
        .subscribe((data) => {
          this.rcmsItemService.filtrar(1, -1, {
            relations: [
              "produto_unidade",
              "produto_unidade.produto.aliquota",
              "produto_unidade.produto.material.aliquota",
              "produto_unidade.unidade",
              "cotacoes",
              "cotacoes.rcmsFavorecido",
              "cotacoes.rcmsFavorecido.favorecido",
            ],
            "rcms.id": this.entidade.id,
            orderBy: ["ordem$ASC", "cotacoes.id$DESC"],
          })
            .subscribe((itens) => {
              this.rcmsFavorecidoService.filtrar(1, -1, {
                relations: ["favorecido,favorecido.tipo"],
                "rcms.id": this.entidade.id,
                orderBy: "id$DESC",
              })
                .subscribe(
                  (favorecidos) => {
                    data.itens = itens.content;
                    data.favorecidos = favorecidos.content;
                    this.rcmsImprimir = [data];
                    nota.show();
                  },
                  (error) => this.funcaoService.acaoErro(error)
                );
            });
        });
    }).catch(() => {
      toastr.info("Requisição com menos de 3 cotações, impressão cancelada!");
    });
  }

  public async carregarItens(itens: RcmsItem[], atualizar?: boolean) {
    itens.sort((a, b) => a.ordem - b.ordem);
    this.itens = itens;
    await this.carregarAutoCompleteFicha();
    await this.carregarAutoCompleteSubElemento();
    if (this.cotacaoCon) {
      this.cotacaoCon.itens = this.itens;
      this.cotacaoCon.carregarCotacoes(true, true);
    }
  }

  public carregarFavorecidos(favorecidos) {
    this.favorecidos = favorecidos;
    if (this.cotacaoCon) {
      this.cotacaoCon.favorecidos = this.favorecidos;
      this.cotacaoCon.carregarCotacoes(true, true);
    }
  }

  public carregarFicha(ficha: FichaDespesa) {
    this.entidadeForm.get("ficha").setValue(ficha);
    this.fichaAutoComplete.atualizar(true);
  }

  alternaCheck(tipo: string) {
    if (tipo === "s") {
      this.entidadeForm.get("somente_produto").setValue(false);
    } else if (tipo === "p") {
      this.entidadeForm.get("somente_servico").setValue(false);
    }
  }

  setPessoaInEdit(campo: string) {
    this.campoPessoa = campo;
    if (this.entidadeForm.get(this.campoPessoa).value) {
      this.pessoaInEdit = this.entidadeForm.get(this.campoPessoa)?.value;
    } else {
      this.pessoaInEdit = new Pessoa();
    }
  }

  validaFichaObras() {
    if (this.entidadeForm.get("ficha").value) {
      this.fichaObras =
        this.entidadeForm
          .get("ficha")
          .value["despesa"].codigo.substring(0, 6) == "449051";
    }
  }

  async importarRequisicao(rcms: Rcms) {
    this.fichaAutoComplete.id = rcms.ficha.numero;
    this.subElementoAutoComplete.id = rcms.subelemento.codigo;

    this.itens = Object.assign([], rcms.itens);
    this.favorecidos = Object.assign([], rcms.favorecidos)

    this.itens.forEach(item => {
      item.id = null
      item.data_alteracao = null
      item.data_cadastro = null
      item.cotacoes.forEach(cotacao => {
        cotacao.id = null
        cotacao.data_alteracao = null
        cotacao.data_cadastro = null
        cotacao.rcmsFavorecido.id = null
      });
    });

    this.favorecidos.forEach(favorec => {
      favorec.id = null
      favorec.data_alteracao = null
      favorec.data_cadastro = null
    });

    this.entidadeForm.patchValue({
      contrato: rcms.contrato ? rcms.contrato : null,
      processo: rcms.processo ? rcms.processo : null,
      licitacao: rcms.licitacao ? rcms.licitacao : null,
      requerente: rcms.requerente ? rcms.requerente : null,
      prioridade: rcms.prioridade ? rcms.prioridade : null,
      tipo_rcms: rcms.tipo_rcms ? rcms.tipo_rcms : null,
      modalidade: rcms.modalidade ? rcms.modalidade : null,
      setor: rcms.setor ? rcms.setor : null,
      reserva_dotacao: rcms.reserva_dotacao ? rcms.reserva_dotacao : null,
      prazo_entrega: rcms.prazo_entrega ? rcms.prazo_entrega : null,
      prazo_pagamento: rcms.prazo_pagamento ? rcms.prazo_pagamento : null,
      compra_direta: rcms.compra_direta ? rcms.compra_direta : null,
      autorizado_compra: rcms.autorizado_compra ? rcms.autorizado_compra : null,
      convenio: rcms.convenio ? rcms.convenio : null,
      ficha: rcms.ficha ? rcms.ficha : null,
      subelemento: rcms.subelemento ? rcms.subelemento : null,
      justificativa: rcms.justificativa ? rcms.justificativa : null,
      observacao: rcms.observacao ? rcms.observacao : null,
      local_entrega: rcms.local_entrega ? rcms.local_entrega : null,
      itens: this.itens ? this.itens : [],
      favorecidos: this.favorecidos ? this.favorecidos : [],
      responsavel_cotacao: rcms.responsavel_cotacao ? rcms.responsavel_cotacao : null
    });
    this.numeroConvenio = rcms.convenio ? rcms.convenio?.numero + '/' + rcms.convenio?.ano : null;
    if (rcms.convenio?.id) {
      this.saldoConvenio = await this.convenioService.buscarSaldoConvenio(
        rcms.convenio?.id,
        this.funcaoService.converteDataSQL(this.entidadeForm.get('data_rcms').value),
        this?.login?.orgao?.id).toPromise();
    }

    this.setPessoaInEdit("responsavel_cotacao");
    this.carregarItens(this.itens);
    this.buscarSaldoFicha();
  }

  permitirEditar(): boolean {
    const contrato: Contrato = this.entidadeForm.get("contrato").value;
    const licitacao: Licitacao = this.entidadeForm.get("licitacao").value;
    const processo: string = this.entidadeForm.get("processo").value;
    const convenio: Convenio = this.entidadeForm.get("convenio").value;

    if (licitacao?.tipo_licitacao === 5 || licitacao?.natureza === 3)
      return false; // licitação julgada por taxa permite edição
    if (contrato?.id) return true;
    if (processo) return true;
    if (convenio?.id && convenio.tipo_fornecedor === 'C' && !contrato?.id) {
      return false
    }

    return false;
  }

  public atualizarQuantidade(event: any) {
    for (let item of this.itens) {
      this.entidadeForm.get("itens").setValue(item);
    }
  }

  public async salvarRcms() {
    if (
      this.funcaoService.podeIncluir(this.login, "/baixa-automatica-reserva") &&
      this.entidadeForm.get("reserva_dotacao").value
    ) {
      let mensagem1: string;
      if (this.entidadeForm.get("processo").value.includes("/")) {
        mensagem1 = `${this.entidadeForm.get("processo").value}`;
      } else {
        mensagem1 = `${this.funcaoService.mascarar(
          "#####/####",
          this.entidadeForm.get("processo").value
        )}`;
      }
      const primeiraConfirmacao = await this.confirm(
        `Esta é a última requisição do processo ${mensagem1} para esta ficha?`,
        "Confirmação"
      );
      if (primeiraConfirmacao) {
        const segundaConfirmacao = await this.confirm(
          `Confirma a baixa da reserva?`,
          `Confirmação`
        );
        if (segundaConfirmacao) {
          this.entidadeForm.get("efetivar_baixa_reserva").setValue(true);
        }
      }
      this.submitForm();
    } else {
      const licitacao = this.entidadeForm.get('licitacao').value;
      const contrato = this.entidadeForm.get('contrato').value
      if (licitacao && licitacao.tabela_desconto && (licitacao.tipo_licitacao === 5 || licitacao.natureza === 3)) {
        let tabelas = []
        if (this.entidade?.id) {
          this.compraTabelaService.filtrar(1, -1, { 'rcms.id': this.entidade?.id, relations: 'compra.itens,memorial' })
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
              tabelas = tabelas.concat(response.content)
              if (tabelas.length > 0) {
                this.submitForm()
              } else {
                this.contratoItemService.filtrar(1, -1, { 'contrato.id': contrato.id, relations: 'memorial,memorial.produto_unidade,memorial.produto_unidade.produto.aliquota,memorial.produto_unidade.produto.material.aliquota', orderBy: 'memorial.ordem' })
                  .pipe(takeUntil(this.unsubscribe))
                  .subscribe((itens) => {
                    this.tabelasDesconto = itens.content.map((c) => {
                      let item = new ContratoItem();
                      item.memorial = c.memorial;
                      item.descricao = c.descricao;
                      item.produto_unidade = c.produto_unidade;

                      //validar bloqueio de seleção de item
                      let mensagem_bloqueio = c.bloqueado ? `Produto ${item.produto_unidade.produto.nome} está bloqueado.` : null;
                      if (mensagem_bloqueio) {
                        item.bloqueado = true;
                        item["mensagem_bloqueio"] = mensagem_bloqueio;
                      }
                      return item;
                    });
                  });
                this.visualizarTabelasDesconto = true
              }
            });
        } else {
          this.contratoItemService.filtrar(1, -1, { 'contrato.id': contrato.id, relations: 'memorial,memorial.produto_unidade,memorial.produto_unidade.produto.aliquota,memorial.produto_unidade.produto.material.aliquota', orderBy: 'memorial.ordem' })
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((itens) => {
              this.tabelasDesconto = itens.content.map((c) => {
                let item = new ContratoItem();
                item.memorial = c.memorial;
                item.descricao = c.descricao;
                item.produto_unidade = c.produto_unidade;

                //validar bloqueio de seleção de item
                let mensagem_bloqueio = c.bloqueado ? `Produto ${item.produto_unidade.produto.nome} está bloqueado.` : null;
                if (mensagem_bloqueio) {
                  item.bloqueado = true;
                  item["mensagem_bloqueio"] = mensagem_bloqueio;
                }
                return item;
              });
            });
          this.visualizarTabelasDesconto = true
        }
      } else {
        this.submitForm();
      }
    }
  }

  public carregarFichasReserva() {
    this.reservaDotacaoItens = [];
    if (this.reservaSelecionada) {
      this.reservaDotacaoItemService.filtrar(1, -1, {
        'reserva.id': this.reservaSelecionada, 'ativo': true,
        'data_reserva$le': new DateFormatPipe().transform(this.entidadeForm.get('data_rcms').value, ["local"]),
        'relations': 'ficha.despesa,ficha.aplicacao,ficha.recurso,ficha.executora.unidade,contrato_aditamento.contrato'
      })
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((resposta) => {
          if (resposta.content.length === 0) {
            return toastr.warning(
              `Reserva informada não possui fichas com a data da requisição(${new DateFormatPipe().transform(
                this.entidadeForm.get("data_rcms").value,
                ["local"]
              )})!`
            );
          } else {
            this.reservaDotacaoItens = resposta.content;
          }
        });
    } else {
      this.entidadeForm.get("reserva_dotacao").setValue(null);
    }
  }

  public callBackDlgReservaDotacao(reserva: any) {
    this.entidadeForm.get("reserva_dotacao").setValue(reserva);
    this.carregarAutoCompleteFicha();
    this.entidadeForm.get("ficha").setValue(reserva.ficha);
    this.fichaAutoComplete.id = reserva.ficha.id;
    this.fichaAutoComplete.atualizar(true);
  }

  public async carregarConvenio(onLoad?: boolean) {
    let ficha: FichaDespesa;
    if (onLoad) {
      ficha = (
        await this.fichaService
          .filtrar(1, 1, {
            id: this.entidade.ficha.id,
            relations: "aplicacao_variavel,recurso,aplicacao",
          })
          .toPromise()
      ).content[0];
    } else {
      if (!this.entidadeForm.get("ficha").value) {
        throw new Error(`sem ficha`);
      }
      ficha = this.entidadeForm.get("ficha").value;
    }

    const parametros = {
      'OR_1': `recurso_id=${ficha?.recurso?.id};!;!;recurso_contra_id=${ficha?.recurso?.id}`,
      'OR_2': `aplicacao_id=${ficha?.aplicacao?.id};!;!;aplicacao_contra_id=${ficha?.aplicacao?.id}`,
      relations: 'convenio.favorecido.tipo,convenio.aditamento,convenio.tipo_convenio'
    }

    if (ficha?.aplicacao_variavel?.id) {
      parametros['OR_3'] = `aplicacao_variavel_id=${ficha?.aplicacao_variavel?.id};!;!;aplicacao_variavel_contra_id=${ficha?.aplicacao_variavel?.id}`;
    }

    parametros['convenio.ativo'] = true;

    // const convenio: Convenio = await this.convenioRecursoService.obterConvenio(ficha.id, this.login.orgao.id, this.entidadeForm.get('data_rcms').value).toPromise();

    const convenioRecurso: ConvenioRecurso = await this.convenioRecursoService.obter(parametros).toPromise();

    const contrato = this.entidadeForm.get('contrato')?.value?.id

    if (convenioRecurso?.convenio) {
      const convenio: Convenio = convenioRecurso.convenio;
      this.entidadeForm.get("convenio").setValue(convenio);
      this.numeroConvenio = `${convenio.numero}/${convenio.ano}`;

      this.saldoConvenio = await this.convenioService.buscarSaldoConvenio(
        convenio?.id,
        this.funcaoService.converteDataSQL(this.entidadeForm.get('data_rcms').value),
        this?.login?.orgao?.id

      ).toPromise();

      if (convenio?.tipo_fornecedor === 'F' && convenio?.favorecido) {
        //Se convenio for do tipo 'FORNECEDOR', traz o fornecedor do convenio.
        this.fornecedorConvenio = this.entidadeForm.get("convenio")?.value?.favorecido;
        const rcmsFavorecido = new RcmsFavorecido(null, this.fornecedorConvenio, null, null);
        this.oldFavorecidos = this.favorecidos ? [...this.favorecidos] : [];
        let favorecido = this.favorecidos?.find(c => c.favorecido.id == rcmsFavorecido.favorecido.id);
        if (!favorecido) {
          this.favorecidos = [rcmsFavorecido];
        }
      } else if (convenio?.tipo_fornecedor === 'C' && convenio?.favorecido && !contrato) {
        this.fornecedorConvenio = null;
      } else {
        this.fornecedorConvenio = null;
        this.favorecidos = this.oldFavorecidos ? [...this.oldFavorecidos] : (this.favorecidos ? this.favorecidos : [])
      }
    } else {
      this.fornecedorConvenio = null;
      this.favorecidos = this.oldFavorecidos ? [...this.oldFavorecidos] : this.favorecidos;
    }
  }

  public justificativaDesbloqueioAutomaticoFinalizada(evento: {
    acao: any,
    declaracaoDeCiencia: string,
    justificativa: RcmsJustificativaDesbloqueioAutomatico
  }) {
    const { acao, declaracaoDeCiencia, justificativa } = evento;

    this.justificativaDesbloqueioAutomaticoAcaoAoSalvar = acao;
    this.justificativaDesbloqueioAutomatico = justificativa;

    this.adicionaDeclaracaoDeCiencia(declaracaoDeCiencia.trim())
  }

  private adicionaDeclaracaoDeCiencia(declaracaoDeCiencia: string) {
    let observacaoAtual: string = !this.entidade.id
      ? this.entidadeForm.get("observacao").value
      : this.entidade.observacao;

    const novaObservacao = observacaoAtual ? observacaoAtual + ' ' + declaracaoDeCiencia : declaracaoDeCiencia;

    if (!this.entidade.id) {
      this.entidadeForm.get("observacao").setValue(novaObservacao);
    } else {
      this.entidade.observacao = novaObservacao;
    }
  }

  public salvarTabelas(tabelasSelecionadas?: any[]) {
    this.visualizarTabelasDesconto = false;
    this.tabelasSelecionadas = tabelasSelecionadas;
    this.entidadeForm.get('tabelas_desconto').setValue(this.tabelasSelecionadas);
    this.submitForm();
  }

  public preencherEstoques() {
    this.estoqueService.estoquesUsuarios(this.login.usuario.id, this.login.orgao.id).subscribe((data) => {
      if (!data || !data.content || data.content.length === 0) {
        return;
      }
      this.estoques = data.content;
    });
  }

  public validaAditivoVigente(rcms: Rcms) {
    if (rcms.contrato_aditamento) {
      const dataHoje = new Date()
      const DataInicioAditamento = new Date(rcms.contrato_aditamento.data_inicio)

      return dataHoje < DataInicioAditamento
    } else {
      return false
    }
  }

  @HostListener('window:keydown.control.p', ['$event'])
  public abrirConsultaVeiculo(event: KeyboardEvent) {
    event.preventDefault();
    $('#dialogVeiculoBusca').modal('show');
  }

  public callbackVeiculo(veiculo: Veiculo) {
    this.entidadeForm.get('veiculo').setValue(veiculo);
    this.veiculoAutoComplete.id = veiculo.id;
  }

}

