import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ConvenioReajuste, FuncaoService, GlobalService, Login } from 'eddydata-lib';
import { Subject } from 'rxjs';
import * as toastr from 'toastr';
import { ConvenioReajusteService } from '../service/convenio-reajuste.service';
import { ConfirmationService } from 'primeng/api';

declare var $: any;

@Component({
  selector: 'app-convenio-reajuste-dlg',
  templateUrl: './convenio-reajuste-dlg.component.html'
})
export class ConvenioReajusteDlgComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @Input() visualizar_item: boolean;
  @Input() reajuste: ConvenioReajuste;
  @Input() login: Login;
  @Output() callback: EventEmitter<void> = new EventEmitter();
  @Input() inclusao: boolean;
  @Output() inclusaoChange: EventEmitter<boolean> = new EventEmitter();

  public itens: ConvenioReajuste[];
  public listaEspecies: { id: string, nome: string }[];
  public listaTipos: { id: string, nome: string }[];
  public ptBR: any;
  protected datepipe: DatePipe;

  protected unsubscribe: Subject<void> = new Subject();

  public imaskQtd = {
    mask: Number,
    scale: 0,
    signed: false
  };

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    public funcaoService: FuncaoService,
    public reajusteService: ConvenioReajusteService,
    private globalService: GlobalService,
    protected confirmationService: ConfirmationService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes['inclusao'] && this.inclusao) {
        this.inclusao = false;
        this.inclusaoChange.emit(false);
      }
    }
  }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    setInterval(() => new GlobalService().calendarMascara(), 500);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
    this.globalService.calendarMascara();
    this.listaEspecies = this.obterEspecies();
    this.listaTipos = this.obterTipos();
  }

  public incluirReajuste() {
    this.confirmationService.confirm({
      message: 'Essa ação irá alterar o saldo do convênio. Deseja prosseguir?',
      header: 'Atenção!',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      key: 'ajuste_saldo',
      accept: () => {
      }, reject: () => {
        this.reajuste.ajuste_saldo = false;
      },
    });
  }


  public visualizarItem(visualizar_item) {
    if (visualizar_item === true) {
      return true
    } else {
      return false
    }
  }

  public async salvar() {
    if (!this.funcaoService.podeAlterarAudesp(this.reajuste.data_contabilizacao, this.login)) {
      toastr.error('Data de contabilização inválida, período na contabilidade já está fechado, entre em contato com o contador.');
    }

    if (!this.reajuste.especie) {
      toastr.error('Campo espécie não preenchido!')
      return;
    }
    if (!this.reajuste.data_reajuste) {
      toastr.error('Campo data do reajuste não preenchido!')
      return;
    }
    if (!this.reajuste.data_contabilizacao) {
      toastr.error('Campo data de contabilização não preenchida!')
      return;
    }
    if (!this.reajuste.observacao) {
      toastr.error('Campo observação não preenchido!')
      return;
    }

    if (this.reajuste.id) { // alteração
      this.reajuste.usuario = this.login.usuario;
      this.reajusteService.atualizar(this.reajuste).subscribe(res => {
        toastr.success('Reajuste realizado com sucesso!');

        this.callback.emit();
        $('#dialogReajuste').modal('hide');
      }, error => this.funcaoService.acaoErro(error));
    } else {
      this.reajusteService.inserir(this.reajuste).subscribe(res => {
        toastr.success('Reajuste realizado com sucesso!');
        this.callback.emit();
        $('#dialogReajuste').modal('hide');
      }, error => this.funcaoService.acaoErro(error));

    }
  }

  public obterEspecies(): { id: string, nome: string }[] {
    return [
      { id: 'R', nome: 'REAJUSTE' },
      { id: 'E', nome: 'ESTORNO' }
    ];
  }

  public obterTipos(): { id: string, nome: string }[] {
    return [
      { id: 'CONCEDENTE', nome: 'CONCEDENTE' },
      { id: 'CONTRAPARTIDA', nome: 'CONTRAPARTIDA' }
    ];
  }

}
