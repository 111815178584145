import { Component, Injector, Input, OnInit } from '@angular/core';
import { ContratoAditamentoService, ContratoRescisaoService, ContratoStorageService } from 'compra-lib';
import { ConfirmationService } from 'primeng/api';
import * as toastr from 'toastr';
import { ProgressoService } from '../../../components/progresso/service/progresso.service';
import { ContratoAditamento } from '../../../entidade/compra/contrato-aditamento.model';
import { ContratoPncp } from '../../../entidade/compra/contrato-pncp.model';
import { ContratoRescisao } from '../../../entidade/compra/contrato-rescisao.model';
import { ContratoStorage } from '../../../entidade/compra/contrato-storage.model';
import { Contrato } from '../../../entidade/compra/contrato.model';
import { Login } from '../../../entidade/login/login';
import { FuncaoService } from '../../../util/funcao.service';
import { PNCPService } from '../../../util/pncp.service';
import { ContratoPncpService } from '../../service/contrato-pncp.service';
import { PncpContratoService } from '../../service/contrato.service';
import { RegistroPncpService } from '../../service/registro-pncp.service';

declare var $: any;
@Component({
  selector: 'app-pncp-contrato-cadastro',
  templateUrl: './pncp-contrato-cadastro.component.html',
  styleUrls: ['./pncp-contrato-cadastro.component.css']
})
export class PncpContratoCadastroComponent implements OnInit {

  @Input() login: Login;
  @Input() contrato: ContratoPncp;
  historicoCompra: Array<any> = new Array<any>();
  arquivoApagando: ContratoStorage = new ContratoStorage();

  resultados: Array<{ item: string, sucesso: boolean, mensagem: string }> = new Array<{ item: string, sucesso: boolean, mensagem: string }>();

  /**
   * Construtor com as injeções de dependencias
   */

  constructor(
    protected injector: Injector,
    protected contratoPncpService: ContratoPncpService,
    protected contratoService: PncpContratoService,
    protected confirmationService: ConfirmationService,
    private pncpService: PNCPService,
    private storage: ContratoStorageService,
    protected progressoService: ProgressoService,
    private funcaoService: FuncaoService,
    private aditamentoService: ContratoAditamentoService,
    private rescisaoService: ContratoRescisaoService,
    private service: RegistroPncpService) {
  }

  ngOnInit(): void {
  }

  abrirPncp(tipo: 'C' | 'L') {
    const ano = (tipo == 'L' ? this.contrato.contrato.licitacao.exercicio.ano : new Date(this.contrato.contrato.data_assinatura).getFullYear());
    const sequencial = (tipo == 'L' ? this.contrato.contrato.licitacao.sequencial_pncp : this.contrato.contrato.sequencial_pncp);

    window.open(this.pncpService.abrirPortalPNCP(this.contrato.contrato.orgao.cnpj, ano, sequencial, tipo == 'L' ? 'E' : 'C'), '_blank');
  }

  idPncp(tipo: 'C' | 'L'): string {
    if (tipo == 'L') {
      return `${this.login.orgao.cnpj.replace(/\D/g, '')}-1-${("000000" + this.contrato.contrato.licitacao.sequencial_pncp).slice((this.contrato.contrato.licitacao.sequencial_pncp + '').length)}/${this.contrato.contrato.licitacao.exercicio.ano}`;
    } else {
      return `${this.login.orgao.cnpj.replace(/\D/g, '')}-1-${("000000" + this.contrato.contrato.sequencial_pncp).slice((this.contrato.contrato.sequencial_pncp + '').length)}/${new Date(this.contrato.contrato.data_assinatura).getFullYear()}`;
    }
  }

  apagarContrato() {
    this.confirmationService.confirm({
      header: 'Apagar Contrato',
      message: `<pre>Confirma a exclusão desse contrato? Essa ação NÃO pode ser desfeita.</pre>.`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, confirmo exclusão.',
      rejectLabel: 'Não',
      accept: async () => {
        this.pncpService.excluirContrato(this.contrato.contrato).subscribe(async (res) => {
          const relations = 'favorecido,orgao,orgao.cidade,prazo,entrega,tipo_contratacao,licitacao,'
            + 'modalidade,licitacao_audesp,fiscais,licitacao.exercicio';
          const cont: Contrato = await this.contratoService.obter({ relations: relations, id: this.contrato.contrato.id, 'orgao.id': this.login.orgao.id }).toPromise();
          cont.excluido_pncp = true;
          cont.sequencial_pncp = null;
          cont.cadastrado_pncp = false;
          cont['ignoreContabilizacao'] = true;
          await this.contratoService.atualizar(cont).toPromise();
          window.location.reload();
        }, (e) => {
          this.funcaoService.acaoErro(e);
        });
      }
    });
  }

  enviarArquivoConfirma(item: ContratoStorage) {
    if (!item.tipo_documento) {
      toastr.warning('Tipo de documento para o PNCP não informado');
      return;
    }
    this.confirmationService.confirm({
      header: 'Envio de documento',
      message: `<pre>Confirma o envio do arquivo?</pre>.`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, confirmo envio.',
      rejectLabel: 'Não',
      accept: async () => {
        this.enviarArquivo(item);
      }
    });
  }

  enviarTodos() {
    this.confirmationService.confirm({
      header: 'Envio de documento',
      message: `<pre>Confirma o envio de todos documentos não enviados?</pre>.`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, confirmo envio.',
      rejectLabel: 'Não',
      accept: async () => {
        for (const a of this.contrato.contrato.arquivos) {
          if (a.tipo_documento == 12 || a.tipo_documento == 16 || !a.sequencial_pncp) {
            this.enviarArquivo(a);
          }
        }
      }
    });

  }

  enviarArquivo(item: ContratoStorage) {
    const aux = Object.assign({}, this.contrato.contrato);
    aux.arquivos = [];
    item.contrato = aux;
    this.pncpService.enviarArquivoContrato(item).subscribe((idTransacao) => {
      this.progressoService.show(idTransacao, async (retorno) => {
        this.resultados = retorno;
        if (this.resultados[0].sucesso) {
          const index = this.contrato.contrato.arquivos.indexOf(item);
          item.sequencial_pncp = +this.resultados[0].mensagem.split(':')[1];
          this.contrato.contrato.arquivos[index] = item;
        }
      });
    }, e => {
      this.funcaoService.acaoErro(e);
    });
  }

  confirmaArquivoTermo(item: ContratoStorage) {
    if (!item.vinculo_id) {
      toastr.warning('Necessário informar o vínculo do documento');
      return;
    }
    this.confirmationService.confirm({
      header: 'Envio de documento',
      message: `<pre>Confirma o envio do arquivo?</pre>.`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, confirmo envio.',
      rejectLabel: 'Não',
      accept: async () => {
        this.enviarArquivoTermo(item)
      }
    });
  }

  enviarArquivoTermo(item: ContratoStorage) {
    const aux = Object.assign({}, this.contrato.contrato);
    aux.arquivos = [];
    item.contrato = aux;
    this.pncpService.enviarArquivoTermo(item).subscribe((idTransacao) => {
      this.progressoService.show(idTransacao, async (retorno) => {
        this.resultados = retorno;
        if (this.resultados[0].sucesso) {
          const index = this.contrato.contrato.arquivos.indexOf(item);
          item.sequencial_pncp = +this.resultados[0].mensagem.split(':')[1];
          this.contrato.contrato.arquivos[index] = item;
        }
      });
    }, e => {
      this.funcaoService.acaoErro(e);
    });
  }

  enviarTodosTermos() {
    this.confirmationService.confirm({
      header: 'Envio de documento',
      message: `<pre>Confirma o envio dos documentos pendentes?</pre>.`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, confirmo envio.',
      rejectLabel: 'Não',
      accept: async () => {
        for (const a of this.contrato.contrato.arquivos) {
          if ((a.tipo_documento != 12 && a.tipo_documento != 16) || !a.tipo_documento) {
            if (!a.vinculo_id) {
              toastr.warning('Documento sem vinculo informado, não enviado');
              continue;
            }

            this.enviarArquivoTermo(a);
          }
        }
      }
    });
  }

  apagarArquivo(item: ContratoStorage) {
    this.confirmationService.confirm({
      header: 'Apagar documento',
      message: `<pre>Confirma a remoção do documento?</pre>.`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, confirmo exclusão.',
      rejectLabel: 'Não',
      accept: async () => {
        const aux = Object.assign({}, this.contrato.contrato);
        aux.arquivos = [];
        item.contrato = aux;
        this.pncpService.apagarArquivoContrato(item).subscribe(async (res) => {
          const index = this.contrato.contrato.arquivos.indexOf(item);
          const seq = item.sequencial_pncp;
          item.sequencial_pncp = null;
          await this.storage.atualizar(item).toPromise();
          this.contrato.contrato.arquivos[index] = item;
          this.fecharDlgApagarArquivo();
          toastr.success('Arquivo removido com sucesso do pncp!');
        }, e => {
          if (e.error?.message) {
            toastr.error(e.error.message);
          } else {
            this.funcaoService.acaoErro(e);
          }
        });
      }
    });
  }

  async apagarArquivoTermo(item: ContratoStorage) {
    let aditivo: ContratoAditamento;
    let rescicao: ContratoRescisao;

    if (item.tipo_documento == 14) {
      aditivo = await this.aditamentoService.obterId(item.vinculo_id).toPromise();
    }
    if (item.tipo_documento == 13) {
      rescicao = await this.rescisaoService.obterId(item.vinculo_id).toPromise();
    }

    this.confirmationService.confirm({
      header: 'Apagar documento',
      message: `<pre>Confirma a remoção do documento?</pre>.`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, confirmo exclusão.',
      rejectLabel: 'Não',
      accept: async () => {
        const aux = Object.assign({}, this.contrato.contrato);
        aux.arquivos = [];
        item.contrato = aux;
        this.pncpService.apagarArquivoTermo(item, (aditivo ? aditivo.sequencial_pncp : rescicao.sequencial_pncp)).subscribe(async (res) => {
          const index = this.contrato.contrato.arquivos.indexOf(item);
          const seq = item.sequencial_pncp;
          item.sequencial_pncp = null;
          await this.storage.atualizar(item).toPromise();
          this.contrato.contrato.arquivos[index] = item;
          this.fecharDlgApagarArquivo();
          toastr.success('Arquivo removido com sucesso do pncp!');
        }, e => {
          if (e.error?.message) {
            toastr.error(e.error.message);
          } else {
            this.funcaoService.acaoErro(e);
          }
        });
      }
    });
  }

  dlgApagarArquivo(item: ContratoStorage) {
    this.arquivoApagando = item;
    $('#dlgApagarItem').modal('show');
  }

  fecharDlgApagarArquivo() {
    this.arquivoApagando = new ContratoStorage();
    $('#dlgApagarItem').modal('hide');
  }

  confirmarEnvioAditivo(tipo: 'A' | 'R') {
    let texto = '';
    const envios = [];
    if (tipo == 'A') {
      for (const a of this.contrato.contrato.aditamentos) {
        if (a['enviar']) {
          texto += '\n' + a.numero + ' - ' + a.finalidade + (` (${a.sequencial_pncp ? 'Retificação' : 'Novo'})`);
          envios.push(a.id);
        }
      }
    } else if (tipo == 'R') {
      for (const a of this.contrato.contrato.rescisoes) {
        if (a['enviar']) {
          texto += '\n' + a.id + ' - ' + a.motivo + (` (${a.sequencial_pncp ? 'Retificação' : 'Novo'})`);
          envios.push(a.id);
        }
      }
    }

    this.confirmationService.confirm({
      header: 'Envio de Termos do Contrato',
      message: `<pre>Confirma o envio dos sequinte itens? ${texto}</pre>.`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, confirmo envios.',
      rejectLabel: 'Não',
      accept: async () => {
        this.pncpService.enviarTermos((tipo == 'A' ? envios : null), (tipo == 'R' ? envios : null)).subscribe((idTransacao) => {
          this.progressoService.show(idTransacao, async (retorno) => {
            this.resultados = retorno;
          });
        }, e => {
          this.funcaoService.acaoErro(e);
        });
      }
    });
  }

  apagarAditivo(item, tipo: 'A' | 'R') {
    this.confirmationService.confirm({
      header: 'Apagar Termo',
      message: `<pre>Confirma a remoção do Termo de contrato dos registros do PNCP?</pre>.`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, confirmo exclusão.',
      rejectLabel: 'Não',
      accept: async () => {
        this.pncpService.apagarTermo(this.contrato.contrato, item.sequencial_pncp).subscribe(async (res) => {
          if (tipo == 'A') {
            const adt = await this.aditamentoService.obter({ id: item.id, relations: 'contrato,contrato.orgao,exercicio,usuario,tipo' }).toPromise();
            adt.cadastrado_pncp = false;
            adt.sequencial_pncp = null;
            adt['pular_escrituracao'] = true;
            this.aditamentoService.atualizar(adt).subscribe(() => {
              item.cadastrado_pncp = false;
              item.sequencial_pncp = null;
              toastr.success('Sucesso ao remover registro do pncp');
            }, (e) => {
              this.funcaoService.acaoErro(e);
            });
          } else {
            const res = await this.rescisaoService.obter({ id: item.id, relations: 'usuario,contrato' }).toPromise();
            res.cadastrado_pncp = false;
            res.sequencial_pncp = null;
            this.rescisaoService.atualizar(res).subscribe((res) => {
              item.cadastrado_pncp = false;
              item.sequencial_pncp = null;
              toastr.success('Sucesso ao remover registro do pncp');
            }, (e) => {
              this.funcaoService.acaoErro(e);
            });
          }
        }, e => {
          if (e.error?.message) {
            toastr.error(e.error.message);
          } else {
            this.funcaoService.acaoErro(e);
          }
        });
      }
    });

  }

  async voltarEnvios(tipo: 'A' | 'R') {
    const atualiza = this.resultados.find(r => r.sucesso);
    this.resultados = new Array<{ item: string, sucesso: boolean, mensagem: string }>();
    if (tipo == 'A' && atualiza) {
      this.contrato.contrato.aditamentos = await (await this.aditamentoService.filtrar(0, -1, { 'contrato.id': this.contrato.contrato.id }).toPromise()).content;
    } else if (tipo == 'R' && atualiza) {
      this.contrato.contrato.rescisoes = await (await this.rescisaoService.filtrar(0, -1, { 'contrato.id': this.contrato.contrato.id }).toPromise()).content;
    }
  }

  async buscarHistoricoContrato() {
    this.historicoCompra = await this.pncpService.consultarHistoricoContrato(this.login.orgao.cnpj.replace(/[./-]/g, ''), this.contrato.contrato.ano, this.contrato.contrato.sequencial_pncp).toPromise();
  }
}
