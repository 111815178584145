import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { Acao, BaseResourceFormComponent, FuncaoService, GlobalService, LoginContabil } from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { AcaoService } from '../service/acao.service';

@Component({
  selector: 'app-acao-form',
  templateUrl: './acao-form.component.html'
})
export class AcaoFormComponent extends BaseResourceFormComponent<Acao, LoginContabil> implements OnInit {

  /**
   * Declaração de variáveis
   */
  @ViewChild('nome') inputField: ElementRef;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private messageService: MessageService,
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected acaoService: AcaoService) {
    super(new Acao(), injector, Acao.converteJson, acaoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected async podeAlterar(_entidade: Acao): Promise<boolean> {
    return true
  }

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      codigo: [null, [Validators.required]],
      nome: [null, [Validators.required]],
      ppa: [this.login.ppa, [Validators.required]],
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'ppa' };
  }

  protected afterLoad() {
  }

  protected afterInit(): void {
  }

  protected campoFoco(): ElementRef {
    return this.inputField;
  }

  protected async beforeSubmit() {
    try {
      if (this.entidadeForm.get('nome').value === '') {
        throw new Error('Informe o nome da acao!');
      }
      this.entidadeForm.get('ppa').setValue(this.login.ppa);
      const param = {
        'OR': `codigo=${this.entidadeForm.get('codigo').value};!;!;nome$likeraw=${this.entidadeForm.get('nome').value.trim()}`,
        'ppa.id': this.entidadeForm.get('ppa').value.id
      }
      if (this.entidadeForm.get('id').value || this.entidade?.id) {
        param['id$ne'] = this.entidadeForm.get('id').value
      }

      let reg = await this.acaoService.obter(param).toPromise()
      if (reg) {
        throw ('Já existe ação com este código e/ou nome')
      }
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  protected afterSubmit(ent: Acao) {
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================


}
