import { Component, Injector, Input, OnInit } from '@angular/core';
import { Login } from '../../entidade/login/login';
import { ContratoPncpService } from '../service/contrato-pncp.service';
import { RegistroPncpService } from '../service/registro-pncp.service';
import * as toastr from 'toastr';
import { PncpContratoService } from '../service/contrato.service';
import { FuncaoService } from '../../util/funcao.service';
import { ContratoPncp } from '../../entidade/compra/contrato-pncp.model';
import { ConfirmationService } from 'primeng/api';
import { PNCPService } from '../../util/pncp.service';
import { Contrato } from '../../entidade/compra/contrato.model';
import { ProgressoService } from '../../components/progresso/service/progresso.service';
import { PncpRegistro } from '../../entidade/comum/registro-pncp.model';
import { ContratoStorage } from '../../entidade/compra/contrato-storage.model';
import { ContratoAditamentoService, ContratoRescisaoService, ContratoStorageService } from 'compra-lib';
import { ContratoAditamento } from '../../entidade/compra/contrato-aditamento.model';
import { ContratoRescisao } from '../../entidade/compra/contrato-rescisao.model';

declare var $: any;
@Component({
  selector: 'app-pncp-contrato',
  templateUrl: './pncp-contrato.component.html',
  styleUrls: ['./pncp-contrato.component.css']
})
export class PncpContratoComponent implements OnInit {

  @Input() login: Login;
  lista: Array<ContratoPncp> = new Array<ContratoPncp>();
  tipoFiltro: number = 0;
  todos: boolean = false;
  numeroContrato: string;
  pendentes: boolean = false;
  historicoCompra: Array<any> = new Array<any>();
  relationsContrato: string = 'contrato.favorecido,contrato.tipo_contratacao,'
    + 'contrato.modalidade,contrato.licitacao.exercicio,'
    + 'contrato.aditamentos,contrato.rescisoes,contrato.licitacao.setor.unidade';

  contrato: ContratoPncp;
  arquivoApagando: ContratoStorage = new ContratoStorage();
  resultados: Array<{ item: string, sucesso: boolean, mensagem: string }> = new Array<{ item: string, sucesso: boolean, mensagem: string }>();
  public colunaOrdenacao: string;
  public ascendente = true;

  /**
   * Construtor com as injeções de dependencias
   */

  constructor(
    protected injector: Injector,
    protected contratoPncpService: ContratoPncpService,
    protected storageService: ContratoStorageService,
    protected contratoService: PncpContratoService,
    protected confirmationService: ConfirmationService,
    private pncpService: PNCPService,
    protected progressoService: ProgressoService,
    private funcaoService: FuncaoService) {
  }

  ngOnInit(): void {
    this.filtrarContratos();
  }

  filtrarContratos() {
    const parametros = {};

    parametros['contrato.tipo'] = 'CONTRATO';
    parametros['contrato.orgao.id'] = this.login.orgao.id;
    parametros['contrato.excluido'] = false;
    parametros['relations'] = this.relationsContrato;
    parametros['contrato.licitacao.sequencial_pncp$not_null'] = true;//Apenas que licitação foram enviadas
    parametros['contrato.licitacao.cadastrado_pncp'] = true;//E estão cadastradas no PNCP
    parametros['contrato.licitacao.excluido_pncp'] = false;//E não foram excluidas
    parametros['contrato.licitacao.situacao_pncp'] = 1;//Na situação atual de "divulgada";
    parametros['ignorar_pncp'] = false;
    if (this.tipoFiltro == 0) {
      parametros['contrato.cadastrado_pncp'] = false;
    } else if (this.tipoFiltro == 1) {
      parametros['contrato.sequencial_pncp$null'] = true;
      parametros['contrato.cadastrado_pncp'] = false;
    } else if (this.tipoFiltro == 2) {
      parametros['contrato.sequencial_pncp$not_null'] = true;
      parametros['contrato.cadastrado_pncp'] = false;
    } else if (this.tipoFiltro < 0) {
      parametros['contrato.sequencial_pncp$not_null'] = true;
      parametros['contrato.cadastrado_pncp'] = true;
    }

    this.contratoPncpService.filtrar(0, this.tipoFiltro < 0 ? 100 : -1, parametros).subscribe((res) => {
      this.lista = res.content;
    }, () => {
      toastr.error('Erro ao filtrar contratos para o PNCP');
    });
  }

  public podeIncluir(url?: string) {
    if (this.login) {
      if (new FuncaoService().campoJsonToken(this.login.token, "administrador") == true) {
        return true;
      }

      return new FuncaoService().podeIncluir(this.login, url)
    }
    return false;
  }

  marcarTodos() {
    this.lista.forEach(i => {
      i['enviar'] = this.todos;
    });
  }

  confirmarEnvio() {
    const envios: Array<number> = new Array<number>();
    let texto: string = '';
    for (const i of this.lista) {
      if (i['enviar']) {
        envios.push(i.id);
        texto += '\n' + i.contrato.numero.replace(/(\d{4})(\d{4})/g, '\$1\/\$2') + ` (${i.contrato.sequencial_pncp ? 'Retificação' : 'Novo'})`;
      }
    }

    if (envios.length == 0) {
      toastr.info('Nenhum item selecionado para envio');
      return;
    }

    this.confirmationService.confirm({
      header: 'Confirmar Envio',
      message: `<pre>Confirma o envio dos contratos a seguir para o PNCP? Alterações posteriores apenas via retificação ou exclusão: \n${texto}</pre>.`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, confirmo envios.',
      rejectLabel: 'Não',
      accept: async () => {
        this.enviar(envios);
      }
    });
  }

  private enviar(lista: Array<number>) {
    this.pncpService.enviarContratos(lista).subscribe((idTransacao) => {
      this.progressoService.show(idTransacao, (retorno) => {
        this.resultados = retorno;
        this.filtrarContratos();
      });
    }, e => {
      this.funcaoService.acaoErro(e);
    });
  }

  voltarListagemContratos() {
    this.resultados = [];
    this.filtrarContratos();
  }

  buscarContrato(item: ContratoPncp) {
    let p = new Promise<void>(async (resolve, reject) => {
      if (item) {
        this.contrato = item;
        //Carregamento de arquivos e memorial retirado da base, por sobrecarga em cenários com +1000
        const arqs = await this.storageService.filtrar(0, -1, { id: item.contrato.id }).toPromise();
        this.contrato.contrato.arquivos = [].concat(arqs.content);

        resolve();
      } else {
        if (!this.numeroContrato || this.numeroContrato == '') {
          if (!this.pendentes) {
            this.filtrarContratos();
          } else {
            this.filtrarPendentes();
          }

          reject();
        }

        let contratos = await this.contratoPncpService.filtrar(1, -1,
          {
            'contrato.orgao.id': this.login.orgao.id,
            relations: this.relationsContrato,
            'contrato.cadastrado_pncp': true,
            'contrato.numero': this.numeroContrato
          }).toPromise();

        if (contratos.content.length > 1) {
          this.lista = contratos.content;
          toastr.info('Mais de uma contrato encontrado')
          reject();
        } else {
          const contrato = contratos.content[0];
          if (contrato && contrato.contrato.cadastrado_pncp) {
            this.buscarContrato(contrato);
            reject();
          } else {
            toastr.info('Contrato não encontrada ou não cadastrada no PNCP')
            reject();
          }
        }
      }
    });

    p.then(() => {
      const validos = this.contrato.contrato.arquivos.filter(a => a.tipo_documento);
      this.contrato.contrato.arquivos = validos;
    });
  }

  filtrarPendentes() {
    if (!this.pendentes) {
      this.contratoPncpService.buscarPendenciasPncp(this.login.orgao.id, this.login.exercicio.id).subscribe((res) => {
        this.lista = res.content;
      }, () => {
        toastr.error('Erro ao filtrar contratos para o PNCP');
      });
    } else {
      this.tipoFiltro = -1;
      this.filtrarContratos();
    }
  }

  cancelarEdicao() {
    this.contrato = null;
    this.resultados = [];
  }

  async buscarHistoricoContrato() {
    this.historicoCompra = await this.pncpService.consultarHistoricoContrato(this.login.orgao.cnpj.replace(/[./-]/g, ''), this.contrato.contrato.ano, this.contrato.contrato.sequencial_pncp).toPromise();
  }

  public reordenar(coluna: string, toggle: boolean, lista: any) {
    if (!coluna) return;

    if (!toggle) this.ascendente = true;
    else this.ascendente = !this.ascendente;

    for (const item of lista) {
      if (!item.contrato.cadastrado_pncp && !item.contrato.sequencial_pncp) {
        item.situacao = 'PENDENTE'
      }

      if (item.contrato.cadastrado_pncp && item.contrato.sequencial_pncp) {
        item.situacao = 'ENVIADO'
      }

      if (!item.contrato.cadastrado_pncp && item.contrato.sequencial_pncp) {
        item.situacao = 'RETIFICAÇÃO'
      }
    }

    this.colunaOrdenacao = coluna;
    if (this.colunaOrdenacao === 'contrato.numero') {
      lista.sort((a, b) => (this.ascendente ? a.contrato.numero - b.contrato.numero : b.contrato.numero - a.contrato.numero))
    }
    if (this.colunaOrdenacao === 'contrato.favorecido.nome') {
      lista.sort((a, b) => {
        if (this.ascendente) {
          return a.contrato.favorecido.nome.localeCompare(b.contrato.favorecido.nome);
        } else {
          return b.contrato.favorecido.nome.localeCompare(a.contrato.favorecido.nome);
        }
      });
    }
    if (this.colunaOrdenacao === 'contrato.tipo_contratacao.nome') {
      lista.sort((a, b) => {
        if (this.ascendente) {
          return a.contrato.tipo_contratacao.nome.localeCompare(b.contrato.tipo_contratacao.nome);
        } else {
          return b.contrato.tipo_contratacao.nome.localeCompare(a.contrato.tipo_contratacao.nome);
        }
      });
    }
    if (this.colunaOrdenacao === 'situacao') {
      lista.sort((a, b) => {
        if (this.ascendente) {
          return a.situacao.localeCompare(b.situacao);
        } else {
          return b.situacao.localeCompare(a.situacao);
        }
      });
    }
  }
}
