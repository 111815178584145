import { Injectable, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FuncaoService, LoginContabil, Exercicio, Relatorio, GlobalService } from 'eddydata-lib';
import { FichaDespesaService } from 'contabil-lib';

@Injectable({
  providedIn: 'root'
})
export class PrevisaoMensalDespesaInicialLoa implements OnDestroy {

  protected funcaoService: FuncaoService;
  private login: LoginContabil = new LoginContabil();
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected anexoServico: FichaDespesaService
  ) {
    this.funcaoService = new FuncaoService();
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public montarRelatorio(exercicio: Exercicio, orgaos: number[], executora?: String, unidade?: String) {
    this.anexoServico.planilhaPrevisao(1, 999999, exercicio.id, orgaos.join(), true, true, executora, unidade)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {

        Relatorio.imprimirPersonalizado(
          '', this.login.usuario.nome, this.login.usuario.sobrenome,
          this.login.orgao.nome, this.login.brasao,
          this.conteudo(dados.content, exercicio), 'landscape',
          'DEMONSTRAÇÃO DA PREVISÃO MENSAL DA DESPESA (INICIAL)',
          {
            linhas: {
              hLineWidth() { return 1; },
              vLineWidth() { return 1; },
              hLineColor() { return 'black'; },
              paddingLeft() { return 3; },
              paddingRight() { return 3; }
            }
          }
        );
      });
  }

  private conteudo(dados: any[], exercicio: Exercicio): {}[] {
    const registros: {}[] = [
      [
        {
          text: `RELATÓRIO GERENCIAL - DEMONSTRAÇÃO DA PREVISÃO MENSAL DA DESPESA (INICIAL) - EXERCÍCIO: ${exercicio.ano}`,
          alignment: 'center', bold: true, colSpan: 14, border: [false, false, false, false], fontSize: 11
        }, '', '', '', '', '', '', '', '', '', '', '', '', ''
      ], [
        { text: 'ESPECIFICAÇÃO', alignment: 'left', fontSize: 7, margin: [0, 5, 0, 5] },
        { text: 'JANEIRO', alignment: 'center', fontSize: 7, margin: [0, 5, 0, 5] },
        { text: 'FEVEREIRO', alignment: 'center', fontSize: 7, margin: [0, 5, 0, 5] },
        { text: 'MARÇO', alignment: 'center', fontSize: 7, margin: [0, 5, 0, 5] },
        { text: 'ABRIL', alignment: 'center', fontSize: 7, margin: [0, 5, 0, 5] },
        { text: 'MAIO', alignment: 'center', fontSize: 7, margin: [0, 5, 0, 5] },
        { text: 'JUNHO', alignment: 'center', fontSize: 7, margin: [0, 5, 0, 5] },
        { text: 'JULHO', alignment: 'center', fontSize: 7, margin: [0, 5, 0, 5] },
        { text: 'AGOSTO', alignment: 'center', fontSize: 7, margin: [0, 5, 0, 5] },
        { text: 'SETEMBRO', alignment: 'center', fontSize: 7, margin: [0, 5, 0, 5] },
        { text: 'OUTUBRO', alignment: 'center', fontSize: 7, margin: [0, 5, 0, 5] },
        { text: 'NOVEMBRO', alignment: 'center', fontSize: 7, margin: [0, 5, 0, 5] },
        { text: 'DEZEMBRO', alignment: 'center', fontSize: 7, margin: [0, 5, 0, 5] },
        { text: 'TOTAL', alignment: 'center', fontSize: 7, margin: [0, 5, 0, 5] }
      ]
    ];

    const totais: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    for (const item of dados) {
      registros.push([
        { text: `${this.funcaoService.mascarar('#.#.##.##', item['r_codigo'])} ${item['r_nome']}`, fontSize: 6, border: [false, false, false, false] },
        { text: this.funcaoService.convertToBrNumber(item['previsto_jan']), alignment: 'right', fontSize: 6, border: [false, false, false, false] },
        { text: this.funcaoService.convertToBrNumber(item['previsto_fev']), alignment: 'right', fontSize: 6, border: [false, false, false, false] },
        { text: this.funcaoService.convertToBrNumber(item['previsto_mar']), alignment: 'right', fontSize: 6, border: [false, false, false, false] },
        { text: this.funcaoService.convertToBrNumber(item['previsto_abr']), alignment: 'right', fontSize: 6, border: [false, false, false, false] },
        { text: this.funcaoService.convertToBrNumber(item['previsto_mai']), alignment: 'right', fontSize: 6, border: [false, false, false, false] },
        { text: this.funcaoService.convertToBrNumber(item['previsto_jun']), alignment: 'right', fontSize: 6, border: [false, false, false, false] },
        { text: this.funcaoService.convertToBrNumber(item['previsto_jul']), alignment: 'right', fontSize: 6, border: [false, false, false, false] },
        { text: this.funcaoService.convertToBrNumber(item['previsto_ago']), alignment: 'right', fontSize: 6, border: [false, false, false, false] },
        { text: this.funcaoService.convertToBrNumber(item['previsto_set']), alignment: 'right', fontSize: 6, border: [false, false, false, false] },
        { text: this.funcaoService.convertToBrNumber(item['previsto_out']), alignment: 'right', fontSize: 6, border: [false, false, false, false] },
        { text: this.funcaoService.convertToBrNumber(item['previsto_nov']), alignment: 'right', fontSize: 6, border: [false, false, false, false] },
        { text: this.funcaoService.convertToBrNumber(item['previsto_dez']), alignment: 'right', fontSize: 6, border: [false, false, false, false] },
        { text: this.funcaoService.convertToBrNumber(item['previsto_total']), alignment: 'right', fontSize: 6, border: [false, false, false, false] },
      ]);

      totais[0] += item['previsto_jan'];
      totais[1] += item['previsto_fev'];
      totais[2] += item['previsto_mar'];
      totais[3] += item['previsto_abr'];
      totais[4] += item['previsto_mai'];
      totais[5] += item['previsto_jun'];
      totais[6] += item['previsto_jul'];
      totais[7] += item['previsto_ago'];
      totais[8] += item['previsto_set'];
      totais[9] += item['previsto_out'];
      totais[10] += item['previsto_nov'];
      totais[11] += item['previsto_dez'];
      totais[12] += item['previsto_total'];
    }

    registros.push([
      { text: 'TOTAL...', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(totais[0]), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(totais[1]), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(totais[2]), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(totais[3]), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(totais[4]), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(totais[5]), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(totais[6]), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(totais[7]), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(totais[8]), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(totais[9]), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(totais[10]), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(totais[11]), alignment: 'right', fontSize: 6, bold: true },
      { text: this.funcaoService.convertToBrNumber(totais[12]), alignment: 'right', fontSize: 6, bold: true }
    ]);

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
        body: registros
      }
    }];
  }
}