import * as extenso from 'extenso';
import { FuncaoService, Login, Relatorio, GlobalService, OrgaoAssinaturaService, Pagamento } from 'eddydata-lib';
import JsBarcode from 'jsbarcode';

export class NotaPagamento {

  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;

  private log: Login;
  private ordenador_despesa: string;
  private cargo_ordenador_despesa: string;
  private ordenador_despesa_funcao_08: string;
  private cargo_ordenador_despesa_funcao_08: string;
  private ordenador_despesa_funcao_10: string;
  private cargo_ordenador_despesa_funcao_10: string;
  private ordenador_despesa_funcao_12: string;
  private cargo_ordenador_despesa_funcao_12: string;
  private ordenador_pagto: string;
  private cargo_ordenador_pagto: string;
  private contador: string;
  private cargo_contador: string;
  private tesoureiro: string;
  private cargo_tesoureiro: string;
  private anulacao: boolean;

  constructor(protected assinaturaService: OrgaoAssinaturaService) {
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
  }

  public async imprimir(model: Pagamento[], login: Login) {
    this.log = login;

    const assinatura = await this.assinaturaService.obter({
      orgao_id: this.log.orgao.id,
      'data_limite$ge': String(this.funcaoService.converteDataSQL(new Date().toLocaleDateString()))
    }).toPromise();
    if (assinatura) {
      this.ordenador_despesa = assinatura.ordenador_despesa;
      this.cargo_ordenador_despesa = assinatura.cargo_ordenador_despesa;

      this.ordenador_despesa_funcao_08 = assinatura.ordenador_despesa_funcao_08;
      this.cargo_ordenador_despesa_funcao_08 = assinatura.cargo_ordenador_despesa_funcao_08;

      this.ordenador_despesa_funcao_10 = assinatura.ordenador_despesa_funcao_10;
      this.cargo_ordenador_despesa_funcao_10 = assinatura.cargo_ordenador_despesa_funcao_10;

      this.ordenador_despesa_funcao_12 = assinatura.ordenador_despesa_funcao_12;
      this.cargo_ordenador_despesa_funcao_12 = assinatura.cargo_ordenador_despesa_funcao_12;

      this.ordenador_pagto = assinatura.ordenador_pagto;
      this.cargo_ordenador_pagto = assinatura.cargo_ordenador_pagto;

      this.tesoureiro = assinatura.tesoureiro;
      this.cargo_tesoureiro = assinatura.cargo_tesoureiro;

      this.contador = assinatura.contador;
      this.cargo_contador = assinatura.cargo_contador;
    }

    Relatorio.imprimirPersonalizado('NOTA DE PAGAMENTO', login.usuario.nome, login.usuario.sobrenome,
      login.orgao.nome, login.brasao,
      this.montarConteudo(model),
      'portrait', 'NOTA PAGAMENTO',
      {
        linhas: {
          hLineWidth() {
            return 1;
          },
          vLineWidth() {
            return 1;
          },
          hLineColor() {
            return 'black';
          },
          paddingLeft() {
            return 3;
          },
          paddingRight() {
            return 3;
          }
        }
      }, true, true);
  }

  private montarConteudo(lista: Pagamento[]) {

    const conteudo = [];

    for (const entidade of lista) {
      if (conteudo.length > 0) {
        conteudo.push([{ text: '', pageBreak: 'after' }]);
      }
      const canvas = document.createElement('CANVAS') as HTMLCanvasElement;
      const barCode = `018170${this.funcaoService.strZero((+entidade.liquidacao.empenho?.valor_empenho).toFixed(2), 12).split('.').join('')}` +
        `${this.log.orgao.codigo.substring(0, 4)}${this.funcaoService.converteDataSQL(new Date(entidade.liquidacao.empenho?.data_empenho)).split('-').join('')}` +
        `${this.funcaoService.strZero(entidade.liquidacao.empenho?.numero, 10)}0000090`;


      JsBarcode(canvas, String(barCode), { displayValue: false, height: 70, margin: 0 });
      conteudo.push(this.dadosCabecalho(this.log, entidade)
        .concat(this.dadosEmpenho(entidade))
        .concat(this.dadosClassificacao(entidade))
        .concat(this.dadosContrato(entidade))
        .concat(this.dadosFavorecido(entidade))
        .concat(this.dadosHistorico(entidade))
        .concat(this.dadosAutorizacao(entidade))
        .concat(this.dadosAssinatura(this.log))
        .concat(this.dadosRecibo()).concat([{
          image: canvas.toDataURL(),
          fit: [250, 70], margin: [10, 5]//, absolutePosition: { x: 280, y: -90 }
        }]));
    }
    return conteudo;
  }

  private dadosCabecalho(log: Login, entidade: Pagamento): {}[] {
    this.anulacao = entidade.anulacao === true;
    const anulado = this.anulacao;

    let brasaoImage: {};
    if (log.brasao) {
      brasaoImage = {
        image: log.brasao,
        width: 40,
        alignment: 'center',
        margin: [0, 0, 0, 0],
        border: [true, true, true, false]
      };
    } else {
      brasaoImage = { margin: [0, 10, 0, 0], text: '', border: [true, true, true, false] };
    }

    const conteudo = [
      [brasaoImage],
      [{ text: log.orgao.nome, bold: true, alignment: 'center', fontSize: 13, border: [true, false, true, false] }],
      [{ text: `${log.orgao.endereco} ${log.cidade?.nome} ${log.cidade?.estado?.nome} CNPJ: ${log.orgao.cnpj}`, alignment: 'center', fontSize: 8, border: [true, false, true, false] }],
      [{
        text: !anulado ? `PAGAMENTO ORÇAMENTÁRIO - ${log.exercicio.ano}` : `ANULAÇÃO DE PAGAMENTO ORÇAMENTÁRIO - ${log.exercicio.ano}`,
        bold: true, alignment: 'center', fontSize: 12, border: [true, false, true, false]
      }]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: ['*'],
        body: conteudo
      }
    }];
  }

  private dadosEmpenho(dados: Pagamento): {}[] {
    const tipoEmpenho = this.globalService.obterListaTiposEmpenhos().find(x => x.id === dados.liquidacao.empenho.tipo_empenho);
    const conteudo = [
      [
        {
          text: 'EMPENHO Nº ' + this.funcaoService.strZero(dados.liquidacao.empenho?.numero, 4),
          rowSpan: 2, fontSize: 11, alignment: 'left', bold: true, margin: [0, 10, 10, 10]
        },
        { text: 'Ficha', fontSize: 7, border: [true, true, false, false] },
        { text: 'Tipo', fontSize: 7, border: [true, true, false, false] },
        { text: 'Data', fontSize: 7, border: [true, true, false, false] },
        {
          text: 'Prazo Pagto', fontSize: 7, border: [true, true, false, false],
        },
        {
          text: 'Licitação', fontSize: 7, border: [true, true, false, false],
        },
        {
          text: 'Processo', fontSize: 7, border: [true, true, false, false],
        },
        {
          text: 'Modalidade',
          alignment: 'center', fontSize: 7, bold: true, border: [true, true, true, false],
        }
      ], [
        { text: '' },
        { text: `${dados.liquidacao.empenho?.ficha.numero ? dados.liquidacao.empenho?.ficha.numero : ''}`, alignment: 'center', bold: true, border: [false, false, false, true] },
        {
          text: tipoEmpenho ? `${tipoEmpenho?.nome}` : '-',
          alignment: 'center', bold: true, border: [true, false, false, true]
        },
        {
          text: this.funcaoService.converteDataBR(dados.liquidacao.empenho?.data_empenho),
          alignment: 'center', bold: true, border: [true, false, false, true]
        },
        {
          text: `${this.funcaoService.converteDataBR(dados.liquidacao.data_vencimento) ? this.funcaoService.converteDataBR(dados.liquidacao.data_vencimento) : ''}`,
          alignment: 'center', bold: true, border: [true, false, false, true]
        },
        {
          text: `${dados.liquidacao.empenho?.licitacao?.numero ? dados.liquidacao.empenho?.licitacao?.numero : ''}`,
          alignment: 'center', bold: true, border: [true, false, false, true]
        },
        { text: `${dados.liquidacao.empenho?.processo ? dados.liquidacao.empenho?.processo : ''}`, alignment: 'center', bold: true, border: [true, false, false, true] },
        {
          text: `${dados.liquidacao.empenho?.modalidade?.nome ? dados.liquidacao.empenho?.modalidade?.nome : ''}`,
          alignment: 'center', bold: true, border: [true, false, true, true]
        },
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: [110, 25, 55, 50, 50, 50, 40, '*'],
        body: conteudo
      }
    }];
  }

  private dadosClassificacao(dados: Pagamento): {}[] {
    const dotacao = (+dados.liquidacao.empenho?.total_dotacao_ficha + +dados.liquidacao.empenho?.total_creditado_ficha) - +dados.liquidacao.empenho?.total_reservado;
    const anterior = +dotacao - +dados.liquidacao.empenho?.total_empenhado_ficha_anterior;
    let saldo = 0;
    if (dados.especie === 'EMO') {
      saldo = +anterior - +dados.liquidacao.empenho?.valor_empenho;
    } else {
      saldo = +dados.valor_pago + +dados.liquidacao.empenho?.valor_empenho;
    }

    const conteudo = [
      [
        { text: 'UNIDADE', border: [true, false, false, false] },
        {
          text: `${dados.liquidacao.empenho?.ficha?.executora?.unidade?.codigo} ${dados.liquidacao.empenho?.ficha?.executora?.unidade?.nome}`,
          border: [false, false, true, false]
        },
        { text: this.anulacao === false ? 'DOTAÇÃO' : '', border: [true, false, false, false] },
        {
          text: this.anulacao === false ? this.funcaoService.convertToBrNumber(dotacao) : '',
          border: [false, false, true, false], alignment: 'right'
        },
      ], [
        { text: 'EXECUTORA', border: [true, false, false, false] },
        { text: `${dados.liquidacao.empenho?.ficha?.executora?.codigo} ${dados.liquidacao.empenho?.ficha?.executora?.nome} `, border: [false, false, true, false] },
        { text: this.anulacao === false ? 'ANTERIOR' : 'EMPENHADO', border: [true, false, false, false] },
        {
          text: this.anulacao === false ?
            this.funcaoService.convertToBrNumber(anterior) : this.funcaoService.convertToBrNumber(+dados.liquidacao.empenho.valor_empenho),
          border: [false, false, true, false], alignment: 'right'
        },
      ], [
        { text: 'NATUREZA', border: [true, false, false, false] },
        // { text: `${dados.ficha?.despesa?.codigo} ${dados.ficha?.despesa?.nome} `, border: [false, false, true, false] },
        { text: `${dados.liquidacao.empenho?.subelemento.codigo.substring(0, 8)} ${dados.liquidacao.empenho?.subelemento?.nome}`, border: [false, false, true, false] },
        { text: this.anulacao === false ? 'EMPENHADO' : 'ANULADO', border: [true, false, false, false] },
        {
          text: this.funcaoService.convertToBrNumber(+dados.valor_pago),
          border: [false, false, true, false], alignment: 'right'
        },
      ], [
        { text: 'SUBELEMENTO', border: [true, false, false, false] },
        { text: `${dados.liquidacao.empenho?.subelemento?.codigo} ${dados.liquidacao.empenho?.subelemento?.nome} `, border: [false, false, true, false] },
        { text: 'SALDO', border: [true, false, false, false] },
        { text: this.funcaoService.convertToBrNumber(saldo), border: [false, false, true, false], alignment: 'right' },
      ], [
        { text: 'FUNCIONAL', border: [true, false, false, false] },
        {
          // tslint:disable-next-line: max-line-length
          text: `${dados.liquidacao.empenho?.ficha?.funcao?.codigo}.${dados.liquidacao.empenho?.ficha?.subfuncao?.codigo}.${dados.liquidacao.empenho?.ficha?.programa?.codigo} ${dados.liquidacao.empenho?.ficha?.programa?.nome} `,
          border: [false, false, true, false]
        },
        { text: '', border: [true, false, false, false] },
        { text: '', border: [false, false, true, false] },
      ], [
        { text: 'PROJ/ATIVID.', border: [true, false, false, false] },
        { text: `${dados.liquidacao.empenho?.ficha?.acao?.codigo} ${dados.liquidacao.empenho?.ficha?.acao?.nome} `, border: [false, false, true, false] },
        { text: '', border: [true, false, false, false] },
        { text: '', border: [false, false, true, false] },
      ], [
        { text: 'RECURSO', border: [true, false, false, true] },
        {
          text: `${dados.liquidacao.empenho?.ficha?.aplicacao_variavel?.codigo ? dados.liquidacao.empenho?.ficha?.aplicacao_variavel?.codigo : dados.liquidacao.empenho?.ficha?.recurso?.codigo + ' ' + dados.liquidacao.empenho?.ficha?.aplicacao?.codigo} ${dados.liquidacao.empenho?.ficha?.aplicacao_variavel?.codigo ? dados.liquidacao.empenho?.ficha?.aplicacao_variavel?.nome : dados.liquidacao.empenho?.ficha?.aplicacao?.nome}`,
          border: [false, false, false, true]
        },
        { text: '', border: [true, false, false, true] },
        { text: '', border: [false, false, true, true] },
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: [60, '*', 60, 50],
        body: conteudo
      }
    }];
  }

  private dadosFavorecido(dados: Pagamento): {}[] {
    let bancoPrincipal = dados.liquidacao.empenho?.favorecido.contas?.find((e) => e.principal);
    const conteudo = [
      [
        { text: 'Beneficiário:', colSpan: 3, border: [true, false, false, false] },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, true, false] },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, true, false] },
      ], [
        {
          text: `${dados.liquidacao.empenho?.favorecido?.nome}`, colSpan: 3,
          fontSize: 11, bold: true, border: [true, false, false, false]
        },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, false, false] },
        { text: `CÓDIGO: ${dados.liquidacao.empenho?.favorecido?.id}`, bold: true, border: [false, false, true, false] },
      ], [
        { text: 'CPF/CNPJ:', border: [true, false, false, false] },
        { text: `${dados.liquidacao.empenho?.favorecido?.cpf_cnpj}`, border: [false, false, false, false] },
        { text: 'TIPO PESSOA:', border: [false, false, false, false] },
        {
          text: `${dados.liquidacao.empenho?.favorecido?.tipo?.nome}`,
          border: [false, false, false, false]
        },
        { text: `BANCO: ${bancoPrincipal?.banco.nome ? bancoPrincipal?.banco.nome : ''}`, border: [false, false, true, false] },
      ], [
        { text: 'ENDEREÇO:', border: [true, false, false, false] },
        {
          text: `${dados.liquidacao.empenho?.favorecido?.endereco}, ${dados.liquidacao.empenho?.favorecido?.num_endereco} `,
          border: [false, false, false, false]
        },
        { text: 'BAIRRO:', border: [false, false, false, false] },
        { text: `${dados.liquidacao.empenho?.favorecido?.bairro}`, border: [false, false, false, false] },
        { text: `AGÊNCIA: ${bancoPrincipal?.agencia ? bancoPrincipal?.agencia : ''}`, border: [false, false, true, false] },
      ], [
        { text: 'CIDADE:', border: [true, false, false, true] },
        { text: `${dados.liquidacao.empenho?.favorecido?.municipio}, ${dados.liquidacao.empenho?.favorecido?.uf} `, border: [false, false, false, true] },
        { text: 'TELEFONE:', border: [false, false, false, true] },
        { text: `(${dados.liquidacao.empenho?.favorecido?.ddd_fone ? dados.liquidacao.empenho?.favorecido?.ddd_fone : ''}) ${dados.liquidacao.empenho?.favorecido?.telefone}     `, border: [false, false, false, true] },
        { text: `C/C: ${bancoPrincipal?.numero_conta ? bancoPrincipal?.numero_conta : ''}`, border: [false, false, true, true] },
      ],
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: [60, '*', 60, 150, '*'],
        body: conteudo
      }
    }];
  }

  private dadosContrato(dados: Pagamento): {}[] {
    const conteudo = [
      [
        { text: 'Tipo de Meta.', fontSize: 7, border: [true, false, false, false] },
        { text: 'Convênio', fontSize: 7, border: [true, false, false, false] },
        { text: 'Fontes de Recurso', fontSize: 7, border: [true, false, false, false] },
        { text: 'Data Início', fontSize: 7, border: [true, false, false, false] },
        { text: 'Data Término', fontSize: 7, border: [true, false, false, false] },
        { text: 'Processo', fontSize: 7, border: [true, false, false, false] },
        { text: 'Data Assinatura', fontSize: 7, border: [true, false, true, false] },
      ], [
        {// tipo meta
          text: `${dados.liquidacao.empenho?.contrato ? dados.liquidacao.empenho?.contrato?.numero : '-'}`,
          alignment: 'center', bold: true, border: [true, false, false, true]
        },
        {// convenio
          text: dados.liquidacao.empenho?.convenio?.numero ? `${dados.liquidacao.empenho.convenio.numero}` : '-',
          alignment: 'center', bold: true, border: [true, false, true, true]
        },
        {// fonte de recurso
          text: dados.liquidacao.empenho?.ficha.recurso.nome ? `${dados.liquidacao.empenho.ficha.recurso.nome}` : '-',
          alignment: 'center', border: [true, false, false, true]
        },
        {//data inicio
          text: dados.liquidacao.empenho?.data_empenho ? `${this.funcaoService.converteDataBR(dados.liquidacao.empenho.data_empenho)}` : '-',
          alignment: 'center', border: [true, false, false, true]
        },
        {// data termino
          text: dados.liquidacao.data_vencimento ? `${this.funcaoService.converteDataBR(dados.liquidacao.data_vencimento)}` : '-',
          alignment: 'center', border: [true, false, false, true]
        },
        {// processo
          text: dados.liquidacao.empenho?.processo ? `${dados.liquidacao.empenho.processo}` : '-',
          alignment: 'center', border: [true, false, false, true]
        },
        {// data assinatura
          text: dados.liquidacao.empenho?.contrato?.data_assinatura ? this.funcaoService.converteDataBR(dados.liquidacao.empenho.contrato.data_assinatura) : '-',
          alignment: 'center', border: [true, false, true, true]
        }
      ]];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: [110, '*', 85, '*', '*', '*', '*'],
        body: conteudo
      }
    }];
  }

  private dadosHistorico(dados: Pagamento): {}[] {
    // Abreviação da observação caso seja gigante.
    let partialObservation = '';
    if (dados.liquidacao.empenho?.compra?.rcms?.observacao) {
      const { observacao } = dados.liquidacao.empenho?.compra.rcms;
      partialObservation = observacao ? observacao.length > 220 ? `${observacao.substring(0, 220)}...` : observacao : '';
    }

    const conteudo = [
      [
        { text: 'Histórico:', border: [true, false, true, false] },
        { text: 'Observações', fontSize: 6, border: [true, false, true, false], colSpan: 2 },
        { text: ''/*, border: [false, false, true, false]*/ }
      ], [
        { text: `${(dados.historico.length > 500 ? dados.historico.substring(0, 450) + '...' : dados.historico)} `, border: [true, false, true, false] },
        { text: partialObservation, fontSize: 7, bold: true, border: [true, false, true, false], margin: [0, -5, 0, 0], colSpan: 2 },
        { text: ''/*, border: [false, false, true, false]*/ }
      ], [
        { text: '', border: [true, false, true, false] },
        { text: 'RCMS:  ' + `${dados.liquidacao.empenho?.compra?.rcms?.numero || ''}` + ' | ' + 'OF.:  ' + `${dados.liquidacao.empenho?.compra?.numero || ''}`, fontSize: 7, border: [true, false, true, false], colSpan: 2 },
        { text: '', border: [false, false, true, false] },
      ], [
        { text: '', border: [true, false, true, false] },
        { text: 'REQ.:  ' + `${dados.liquidacao.empenho?.compra?.rcms?.requerente?.nome || ''}`, fontSize: 7, border: [true, false, true, false], colSpan: 2 },
        { text: '', border: [false, false, true, false] },
      ], [
        { text: '', border: [true, false, true, false] },
        { text: 'Prazo:  ' + `${dados.liquidacao.empenho?.compra?.rcms?.prazo?.intervalo_qtd || ''} - ${dados.liquidacao.empenho?.compra?.rcms?.prazo?.intervalo || ''}`, fontSize: 7, border: [true, false, false, false] },
        { text: '', border: [false, false, true, false] },
      ], [
        { text: '', border: [true, false, true, false] },
        { text: 'VALOR....R$', fontSize: 8, bold: true, border: [true, true, false, false] },
        { text: `${dados.especie === 'EMO' ? this.funcaoService.convertToBrNumber(dados.liquidacao.empenho?.valor_empenho || 0) : this.funcaoService.convertToBrNumber(dados.valor_pago || 0)}`, fontSize: 9, bold: true, alignment: 'right', border: [false, true, true, false] },
      ], [
        { text: '', border: [true, false, true, false] },
        { text: 'RETENÇÃO..R$', fontSize: 8, bold: true, border: [true, false, false, false] },
        { text: `${this.funcaoService.convertToBrNumber(dados.valor_retido || 0)}`, fontSize: 9, bold: true, alignment: 'right', border: [false, false, true, false] },
      ], [
        { text: '', border: [true, false, true, true] },
        { text: 'LIQUIDO....R$', fontSize: 8, bold: true, border: [true, false, false, true] },
        { text: `${dados.especie === 'EMO' ? this.funcaoService.convertToBrNumber(dados.liquidacao.empenho?.valor_empenho || 0) : this.funcaoService.convertToBrNumber(dados.valor_pago - dados.valor_retido || 0)}`, fontSize: 9, bold: true, alignment: 'right', border: [false, false, true, true] },
      ], [
        { text: 'Fica anulado a importância de: ', border: [true, false, false, false], colSpan: 2 },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, true, false] }
      ], [
        {
          text: extenso(dados.especie === 'EMO' ? this.funcaoService.convertToBrNumber(dados.liquidacao.empenho?.valor_empenho) : this.funcaoService.convertToBrNumber(dados.valor_pago),
            { mode: 'currency', currency: { type: 'BRL' } }).toUpperCase(),
          border: [true, false, false, false], margin: [0, 0, 0, 0]
        },
        { text: 'VALOR R$:', bold: true, fontSize: 11, border: [false, false, false, false], alignment: 'right' },
        {
          text: dados.especie === 'EMO' ? this.funcaoService.convertToBrNumber(dados.liquidacao.empenho?.valor_empenho) : this.funcaoService.convertToBrNumber(dados.valor_pago),
          alignment: 'center', bold: true, fontSize: 13, border: [false, false, true, false], margin: [0, 0, 0, 5]
        },
      ],
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        heights: [5, 5],
        widths: ['*', 60, 100],
        body: conteudo
      }
    }];
  }

  private dadosAutorizacao(dados: Pagamento): {}[] {
    let ordenador = '';
    let cargoOrdenador = '';
    const funcao = dados.liquidacao?.empenho?.ficha?.funcao?.codigo;
    const data_liquidacao = dados.liquidacao.empenho?.liquidacoes?.length == 1 ?
      dados.liquidacao.empenho?.liquidacoes[0]?.data_liquidacao : dados.liquidacao.empenho?.liquidacoes[dados.liquidacao.empenho?.liquidacoes?.length - 1]?.data_liquidacao;

    switch (funcao) {
      case '12':
        ordenador = this.ordenador_despesa_funcao_12;
        cargoOrdenador = this.cargo_ordenador_despesa_funcao_12;
        break;
      case '10':
        ordenador = this.ordenador_despesa_funcao_10;
        cargoOrdenador = this.cargo_ordenador_despesa_funcao_10;
        break;
      case '08':
        ordenador = this.ordenador_despesa_funcao_08;
        cargoOrdenador = this.cargo_ordenador_despesa_funcao_08;
        break;
      default:
        ordenador = this.ordenador_despesa;
        cargoOrdenador = this.cargo_ordenador_despesa;
    }

    if (!ordenador || !cargoOrdenador) {
      ordenador = this.ordenador_despesa;
      cargoOrdenador = this.cargo_ordenador_despesa;
    }

    const conteudo = [
      [
        {
          text: 'AUTORIZAÇÃO EMPENHO',
          bold: true, border: [true, true, true, false], margin: [0, 0, 0, 10]
        },
        { text: 'CONTABILIZAÇÃO', border: [false, true, true, false], bold: true, },
        { text: 'LIQUIDAÇÃO', border: [false, true, true, false] }
      ], [
        { text: '_____________________________________', alignment: 'center', border: [true, false, true, false] },
        { text: '_____________________________________', alignment: 'center', border: [false, false, true, false] },
        { text: 'Documento:', border: [false, false, true, false] }
      ], [
        { text: ordenador, fontSize: 7, alignment: 'center', border: [true, false, true, false] },
        { text: this.contador ? this.contador : '', fontSize: 7, alignment: 'center', border: [false, false, true, false] },
        { text: dados.liquidacao.empenho?.liquidacoes?.length == 1 ? `${dados.liquidacao.empenho?.liquidacoes[0]?.documento}` : `${dados.liquidacao.empenho?.liquidacoes[dados.liquidacao.empenho?.liquidacoes?.length - 1]?.documento}`, border: [false, false, true, false], margin: [0, 5, 0, 5] }
      ], [
        { text: cargoOrdenador, fontSize: 7, alignment: 'center', border: [true, false, true, false], margin: [0, -7, 0, 0] },
        { text: this.cargo_contador ? this.cargo_contador : '', fontSize: 7, alignment: 'center', border: [false, false, true, false], margin: [0, -7, 0, 0] },
        { text: '', border: [false, false, true, false], margin: [0, 5, 0, 5] }
      ], [
        { text: 'Data: _____/_____/________', border: [true, false, true, true], margin: [0, -5, 0, 0] },
        { text: 'Data: _____/_____/________', border: [false, false, true, true], margin: [0, -5, 0, 0] },
        { text: data_liquidacao ? `Data: ${this.funcaoService.converteDataBR(data_liquidacao)}` : 'Data: _____/_____/________', border: [false, false, true, true], margin: [0, -5, 0, 0] }
      ],
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: ['*', '*', '*'],
        body: conteudo
      }
    }];
  }

  private dadosAssinatura(log: Login): {}[] {
    const conteudo = [
      [
        { text: 'AUTORIZAÇÃO PAGAMENTO', border: [true, false, false, false], colSpan: 2 },
        { text: '', border: [true, false, false, false], },
        { text: '______________________________________', border: [false, false, false, false], margin: [20, 30, -20, 0] },
        { text: '______________________________________', border: [false, false, false, false], margin: [40, 30, -30, 0] },
        { text: '', border: [false, false, true, false] },
      ], [
        { text: 'BANCO:_______________', border: [true, false, false, false] },
        { text: '', border: [false, false, false, false], },
        { text: this.ordenador_pagto ? this.ordenador_pagto : '', fontSize: 7, alignment: 'center', border: [false, false, false, false], margin: [10, 0, -20, 0] },
        { text: this.tesoureiro ? this.tesoureiro : '', fontSize: 7, alignment: 'center', border: [false, false, false, false], margin: [50, 0, -20, 0] },
        { text: '', border: [false, false, true, false], },
      ], [
        { text: 'RECURSO:_____________ CHEQUE:______________', border: [true, false, false, false] },
        { text: '', border: [false, false, false, false], },
        { text: this.cargo_ordenador_pagto ? this.cargo_ordenador_pagto : '', alignment: 'center', fontSize: 7, border: [false, false, false, false], margin: [15, 0, -15, 10] },
        { text: this.cargo_tesoureiro ? this.cargo_tesoureiro : '', alignment: 'center', fontSize: 7, border: [false, false, false, false], margin: [50, 0, -15, 10] },
        { text: '', border: [false, false, true, false], },
      ], [
        { text: 'Data: _____/_____/________', border: [true, false, false, true], colSpan: 2 },
        { text: '', border: [false, false, false, false], },
        { text: '', border: [false, false, false, true], margin: [0, 0, 0, 10] },
        { text: '', border: [false, false, false, true], margin: [0, 0, 0, 10] },
        { text: '', border: [false, false, true, true] },
      ],
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        heights: [10, 0],
        widths: [100, 0, 150, 150, '*'],
        body: conteudo
      }
    }];
  }

  private dadosRecibo(): {}[] {
    const conteudo = [
      [
        { text: 'RECIBO E QUITAÇÃO', border: [true, false, false, false], colSpan: 2 },
        { text: '', border: [false, false, false, false], },
        { text: '', border: [false, false, false, false], },
        { text: '', border: [false, false, true, false], },
      ], [
        {
          text: 'Atestamos que o valor desse documento foi cancelado e volta à dotação orçamentária.',
          border: [true, false, false, false], colSpan: 2, margin: [0, 0, 20, 0]
        },
        { text: '', border: [false, false, false, false], },
        { text: 'Assinatura', border: [false, false, false, false], },
        { text: 'Documento', border: [false, false, true, false], },
      ], [
        { text: 'Data: _____/_____/________', border: [true, false, false, true], colSpan: 2 },
        { text: '', border: [false, false, false, true], },
        { text: '__________________________', border: [false, false, false, true], },
        { text: '__________________________', border: [false, false, true, true], },
      ],
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        heights: [10, 0],
        widths: ['*', '*', '*', '*'],
        body: conteudo
      }
    }];
  }

}

