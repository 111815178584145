import { Component, OnInit, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { Login, GlobalService, ConvenioAditamento, FuncaoService } from 'eddydata-lib';

declare var $: any;

@Component({
  selector: 'app-convenio-aditamento-dlg',
  templateUrl: './convenio-aditamento-dlg.component.html'
})
export class ConvenioAditamentoDlgComponent implements OnInit, AfterViewInit, OnDestroy {

  protected datepipe: DatePipe;
  public ptBR: any;
  public listaFinalidade: Array<any>;
  protected unsubscribe: Subject<void> = new Subject();

  @Input() entidade: ConvenioAditamento;
  @Input() lista: Array<any>;
  @Input() login: Login;

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    protected messageService: MessageService,
    private funcaoService: FuncaoService,
    private globalService: GlobalService
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.listaFinalidade = this.globalService.obterListaFinalidade();
    if (!this.entidade?.id) this.entidade.data_contabilizacao = new Date()
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
    this.globalService.calendarMascara();
  }

  public salvar() {
    try {
      if (!this.entidade.data_aditamento) {
        throw new Error('Informe a data de aditamento!');
      }
      if (!this.entidade.data_inicio) {
        throw new Error('Informe a data de início!');
      }
      if (!this.entidade.data_termino) {
        throw new Error('Informe a data de término!');
      }
      if (!this.entidade.finalidade) {
        throw new Error('Informe a finalidade do aditamento!');
      }
      if (!this.funcaoService.podeAlterarAudesp(this.entidade.data_contabilizacao, this.login)) {
        throw new Error('Data de contabilização inválida, período na contabilidade já está fechado, entre em contato com o contador.');
      }
      // envia a entidade para ser salva no banco -----
      this.entidade.usuario = this.login.usuario;
      this.entidade.editavel = false;
      if (!this.lista) {
        this.lista = new Array();
      }
      this.entidade.ordem = this.contarOrdemAdiantamento(this.lista);
      if (!this.entidade.id) {
        this.lista.unshift(this.entidade);
      }
      $('#dialogAditamento').modal('hide');
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  private contarOrdemAdiantamento(lista: ConvenioAditamento[]): number {
    if (!lista?.length)
      return 1;

    const convenio = lista.reduce((prev, current) => {
      return prev.ordem > current.ordem ? prev : current;
    });

    const obj = { ...convenio };

    return ++obj.ordem;
  }

  compareFn(c1: any, c2: any): boolean {
    if (c1 && c2) {
      if (c1.id && c2.id) {
        return c1.id === c2.id;
      } else if (c1.chave && c2.chave) {
        return c1.chave == c2.chave;
      } else {
        return c1 === c2;
      }
    } else {
      return false;
    }
  }

}
