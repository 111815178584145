import { Component, ViewChild, ElementRef, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import { AdiantamentoDocumentoService } from '../service/adiantamento-documento.service';
import { NotaAdiantamento } from '../relatorio/nota-adiantamento';
import {
  Adiantamento, AdiantamentoDocumento, AdiantamentoStorage, BaseResourceFormComponent, DateFormatPipe,
  EddyAutoComplete, Empenho, FuncaoService, GlobalService, HEREService, LoginContabil, OrgaoAssinaturaService,
  OrgaoAssinatura, ParecerControleInterno, Relatorio, ParametroContabilService, ParametroContabil
} from 'eddydata-lib';
import { EmpenhoService, AdiantamentoService, PagamentoService } from 'administrativo-lib';
import { AdiantamentoStorageService } from '../service/adiantamento-storage.service';
import * as toastr from 'toastr';
import { ParecerControleInternoService } from 'projects/contabil/src/app/parecer-controle-interno/service/parecer-controle-interno.service';

@Component({
  selector: 'lib-adiantamento-form',
  templateUrl: './adiantamento-form.component.html'
})
export class AdiantamentoFormComponent extends BaseResourceFormComponent<Adiantamento, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  @ViewChild('oficio') inputField: ElementRef;

  public listaSituacoes: Array<any>;
  public listaEspecies: Array<any>;
  public listaTipos: Array<any>;
  public empenhoAutoComplete: EddyAutoComplete<Empenho>;
  public listaPareceres: Array<any>;
  public saldo: number;
  public vlEfetivado: number;
  public vlAnulado: number;
  public listaDocumentos: Array<AdiantamentoDocumento>;
  // public vpd: string = 'NENHUM'
  public ptBR: any;
  public parametro: ParametroContabil;
  //destino
  public destinoKm: String = '---';
  public destinoHr: String = '---';
  public listProrrogacao: String[];
  public destinoGM: String = '---'; //abrir rota no google maps
  public destinoShow: boolean = false;
  public coordenadaOrigem: String = '';
  public coordenadaDestino: String = '';
  public ibgeUF: String = '';
  public ibgeCidade: String = '';
  public total_geral: number;
  public listaArquivos: AdiantamentoStorage[];
  public listaFotos: AdiantamentoStorage[];
  public assinatura: OrgaoAssinatura
  public parecer: ParecerControleInterno
  public prestarContas: boolean = false;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    private messageService: MessageService,
    private funcaoService: FuncaoService,
    protected empenhoService: EmpenhoService,
    protected pagtoService: PagamentoService,
    protected assinaturaService: OrgaoAssinaturaService,
    protected documentoService: AdiantamentoDocumentoService,
    protected adiantamentoService: AdiantamentoService,
    protected hereService: HEREService,
    protected storageService: AdiantamentoStorageService,
    private parametroContabilService: ParametroContabilService,
    public orgaoAssinaturaService: OrgaoAssinaturaService,
    public parecerService: ParecerControleInternoService) {
    super(new Adiantamento(), injector, Adiantamento.converteJson, adiantamentoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected async podeAlterar(_entidade: Adiantamento): Promise<boolean> {
    return (!_entidade.data_termino || !_entidade.data_prestacao) && this.login.sistema != 'controle-interno'
  }

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      numero: [null, [Validators.required]],
      mes: [null],
      data_adiantamento: [null, [Validators.required]],
      data_vencimento: [null, [Validators.required]],
      data_liberacao: [null],
      data_prestacao: [null],
      data_termino: [null],
      data_partida: [null],
      data_chegada: [null],
      data_aplicacao: [null],
      data_prazo_prestacao: [null],
      parecer: [null],
      descricao_parecer: [null],
      especie: [null, [Validators.required]],
      situacao: [null, [Validators.required]],
      valor_adiantado: [null, [Validators.required]],
      valor_complemento: [0],
      oficio: ['', [Validators.required]],
      motivo: [null, [Validators.required]],
      motivacao_viagem: [null],
      resumo_atividades: [null],
      resultados_viagem: [null],
      numero_conta: [null],
      prorrogacao_adiantamento: [null],
      agencia: [null],
      banco: [null],
      beneficiario: [null, [Validators.required]],
      tipo_beneficiario: [null, [Validators.required]],
      cargo_beneficiario: [null, [Validators.required]],
      destino: [null],
      empenho: [null, [Validators.required]],
      orgao: [this.login.orgao, [Validators.required]],
      exercicio: [this.login.exercicio],
      aux: [],
      vpd: ['NENHUM'],
      naoEscriturar: false,
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'empenho.liquidacoes.pagamentos,empenho.exercicio,exercicio,orgao,empenho.ficha.despesa.vpd,empenho.subelemento.vpd' };
  }

  protected async afterInit(): Promise<void> {
    this.listaSituacoes = [
      { id: 'A', nome: 'ATIVO' },
      { id: 'I', nome: 'INATIVO' }
    ];
    this.listaTipos = [
      { id: 'S', nome: 'SERVIDOR PÚBLICO' },
      { id: 'A', nome: 'AGENTE POLÍTICO' },
      { id: 'V', nome: 'VEREADOR' },
      { id: 'O', nome: 'OUTRO' }
    ];
    this.listaEspecies = [
      { id: 'N', nome: 'NUMERARIAS' },
      { id: 'D', nome: 'DIÁRIAS' }
    ];
    this.listaPareceres = [
      { id: 'F', nome: 'FAVORÁVEL' },
      { id: 'D', nome: 'DESFAVORÁVEL' }
    ];

    this.carregarAutoCompletes();
    this.antesInserir();
    await this.parametroContabil();
    this.preencherDatas(this.entidade.empenho);

    // if (this.parametro?.puxar_data_automatica) {
    //   this.entidadeForm.get('data_liberacao').disable();
    // }

    this.assinatura = await this.orgaoAssinaturaService.obter({ orgao_id: this.login.orgao.id, 'data_limite$ge': this.funcaoService.converteDataSQL(new Date()), 'orderBy': 'data_limite$DESC' }).toPromise();

    this.parecer = await this.parecerService.obter({ orgao_id: this.login.orgao.id, exercicio_id: this.login.exercicio.id, orderBy: 'id$DESC' }).toPromise()
  }

  protected campoFoco(): ElementRef {
    return this.inputField;
  }

  protected afterLoad() {
    // if (!this.podeAlterarAudesp(this.entidade.data_adiantamento)) {
    // this.entidadeForm.disable();
    // this.router.navigate(['/adiantamentos']);
    // toastr.warning('Não é possível alterar. Prazo esgotado!');
    // return;
    // }
    this.entidade.data_adiantamento = new DateFormatPipe().transform(this.entidade.data_adiantamento, []);
    this.entidade.data_vencimento = new DateFormatPipe().transform(this.entidade.data_vencimento, []);
    this.entidade.data_liberacao = new DateFormatPipe().transform(this.entidade.data_liberacao, []);
    this.entidade.data_prestacao = new DateFormatPipe().transform(this.entidade.data_prestacao, []);
    this.entidade.data_aplicacao = new DateFormatPipe().transform(this.entidade.data_aplicacao, []);
    this.entidade.data_prazo_prestacao = new DateFormatPipe().transform(this.entidade.data_prazo_prestacao, []);
    this.entidade.data_termino = new DateFormatPipe().transform(this.entidade.data_termino, []);
    this.entidade.data_partida = new DateFormatPipe().transform(this.entidade.data_partida, []);
    this.entidade.data_chegada = new DateFormatPipe().transform(this.entidade.data_chegada, []);
    this.entidade.parecer ?
      this.entidadeForm.get('parecer').disable() :
      this.entidadeForm.get('parecer').enable();
    this.calcularSaldo();
    this.preencherDocumento();
    this.empenhoAutoComplete.id = this.entidade.empenho ? this.entidade.empenho.id : null;
    if (this.entidade.id) {
      this.buscaCidades();
    }
    this.bloquearAdiantamento();

    this.listProrrogacao = this.entidade?.prorrogacao_adiantamento?.split(";");

    this.storageService.filtrar(1, -1, { 'adiantamento.id': this.entidade.id, foto: false, orderBy: 'data_cadastro$DESC' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaArquivos = res.content;
      });
    this.storageService.filtrar(1, -1, { 'adiantamento.id': this.entidade.id, foto: true, orderBy: 'data_cadastro$DESC' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaFotos = res.content;
      });
  }

  protected beforeSubmit() {
    try {
      this.entidadeForm.enable();
      if (!this.entidadeForm.get('valor_adiantado') || Number(this.entidadeForm.get('valor_adiantado').value) === 0) {
        toastr.error('O valor do adiantamento tem que ser maior que zero!');
        throw new Error('O valor do adiantamento tem que ser maior que zero!');
      }
      this.entidadeForm.get('aux').setValue(0);
      this.entidadeForm.get('data_adiantamento').enable();
      this.entidadeForm.get('data_prestacao').enable();
      this.entidadeForm.get('data_termino').enable();
      this.entidadeForm.get('valor_adiantado').enable();
      this.entidadeForm.get('parecer').enable();
      const dtAdiantamento: Date = this.entidadeForm.get('data_adiantamento').value;
      const dtVencimento: Date = this.entidadeForm.get('data_vencimento').value;
      const dtLiberacao: Date = this.entidadeForm.get('data_liberacao').value;
      const dtPartida: Date = this.entidadeForm.get('data_partida').value;
      const vpd = this.entidadeForm.get('empenho').value?.subelemento?.vpd?.nome ? this.entidadeForm.get('empenho').value?.subelemento?.vpd?.nome : 'NENHUM'
      if (vpd === 'NENHUM') {
        toastr.error('VPD é campo obrigatório quando o adiantamento tem origem de um Empenho.\nAjuste o subelemento em Planejamento > Cadastros > Despesas orçamentárias');
        throw new Error('VPD é campo obrigatório quando o adiantamento tem origem de um Empenho.\nAjuste o subelemento em Planejamento > Cadastros > Despesas orçamentárias')
      }
      if (!dtLiberacao && !this.parametro?.puxar_data_automatica) {
        toastr.error('Preencha corretamente a data de liberação!');
        throw new Error('Preencha corretamente a data de liberação!');
      }
      if (!dtAdiantamento || !dtVencimento) {
        toastr.error('Preencha corretamente a data de adiantamento e vencimento!');
        throw new Error('Preencha corretamente a data de adiantamento e vencimento!')
      }
      if (dtAdiantamento.getFullYear() !== this.login.exercicio.ano) {
        toastr.error('O ano da data do adiantamento está diferente do exercício logado');
        throw new Error('O ano da data do adiantamento está diferente do exercício logado');
      }
      if (dtVencimento.getTime() < dtAdiantamento.getTime()) {
        toastr.error('A data de vencimento não pode ser menor que a data do adiantamento.');
        throw new Error('A data de vencimento não pode ser menor que a data do adiantamento.');
      }
      if (dtPartida && (dtPartida.getTime() < dtAdiantamento.getTime())) {
        toastr.error('A data de partida não pode ser menor que a data do adiantamento.');
        throw new Error('A data de partida não pode ser menor que a data do adiantamento.');
      }
      this.entidadeForm.get('mes').setValue(+this.funcaoService.converteDataSQL(dtAdiantamento)?.split('-')?.[1]);

      // DATA TERMINO: nao podera ser menor que a DATA PRESTACAO
      if (this.funcaoService.converteDataSQL(this.entidadeForm.get('data_termino').value) && (this.funcaoService.converteDataSQL(this.entidadeForm.get('data_termino').value) < this.funcaoService.converteDataSQL(this.entidadeForm.get('data_prestacao').value))) {
        this.entidadeForm.get('data_termino').setValue(null);
        toastr.error('O campo Data de término deve ser igual ou superior a data de prestação!');
        throw new Error(`O campo Data de término deve ser igual ou superior a data de prestação!`);
      }
      // DATA TERMINO: nao pode ser superior a data atual
      if (this.funcaoService.converteDataSQL(this.entidadeForm.get('data_termino').value) && (this.funcaoService.converteDataSQL(this.entidadeForm.get('data_termino').value) > this.funcaoService.converteDataSQL(new Date()))) {
        this.entidadeForm.get('data_termino').setValue(null);
        toastr.error('O campo Data de término não pode ser superior a data de hoje!');
        throw new Error(`O campo Data de término não pode ser superior a data de hoje!`);
      }
      // DATA PRESTACAO: nao podera ser superior a data de atual
      if (this.funcaoService.converteDataSQL(this.entidadeForm.get('data_prestacao').value) && (this.funcaoService.converteDataSQL(this.entidadeForm.get('data_prestacao').value) > this.funcaoService.converteDataSQL(new Date()))) {
        toastr.error('O campo Data de Prestação não pode ser superior a data de hoje!');
        throw new Error(`O campo Data de Prestação não pode ser superior a data de hoje!`);
      }
      if (dtVencimento !== this.entidade.data_vencimento) {
        this.entidadeForm.get('prorrogacao_adiantamento').setValue((this.entidade.prorrogacao_adiantamento ? this.entidade.prorrogacao_adiantamento + ";" : "") + "Adiantamento prorrogado de " + this.funcaoService.converteDataBR(this.entidade.data_vencimento) + " para " + this.funcaoService.converteDataBR(dtVencimento));
      }
      if (this.entidade.id) {
        if (dtVencimento !== this.entidade.data_vencimento) {
          this.entidadeForm.get('prorrogacao_adiantamento').setValue((this.entidade.prorrogacao_adiantamento ? this.entidade.prorrogacao_adiantamento + ";" : "") + "Adiantamento prorrogado de " + this.funcaoService.converteDataBR(this.entidade.data_vencimento) + " para " + this.funcaoService.converteDataBR(dtVencimento));
        }
      }
      if (this.entidadeForm.get('motivacao_viagem').value?.length > 1000) {
        toastr.error('O campo "Motivação da viagem" excedeu o limite de mil caracteres!');
        throw new Error(`O campo "Motivação da viagem" excedeu o limite de mil caracteres!`)
      }
      if (this.entidadeForm.get('resumo_atividades').value?.length > 1000) {
        toastr.error('O campo "Resumo das atividades" excedeu o limite de mil caracteres!');
        throw new Error(`O campo "Resumo das atividades" excedeu o limite de mil caracteres!`)
      }
      if (this.entidadeForm.get('resultados_viagem').value?.length > 1000) {
        toastr.error('O campo "Resultados da viagem" excedeu o limite de mil caracteres!');
        throw new Error(`O campo "Resultados da viagem" excedeu o limite de mil caracteres!`)
      }
      if (!this.podeAlterarAudesp(this.entidadeForm.get('data_adiantamento').value) && !this.prestarContas && this.entidade.situacao === this.entidadeForm.get('situacao').value) {
        throw new Error(`O mês do registro já foi armazenado no TCE. Não é possível salvar!`)
      }
      if (!this.prazoAlteracao(this.entidadeForm.get('data_adiantamento').value) && !this.prestarContas) {
        throw new Error(`Alterações bloqueadas por estar fora do prazo!`);
      }
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  protected afterSubmit(ent: Adiantamento) {
    window.scrollTo(0, 0);
    this.listProrrogacao = ent?.prorrogacao_adiantamento?.split(";");
    setTimeout(() => this.inputField.nativeElement.focus());
    if (this.currentActionRoute === 'novo') {
      this.router.navigate(['/adiantamentos', 'novo', ent.id]);
    } else {
      this.router.navigate(['/adiantamentos', ent.id, 'editar']);
    }
    this.bloquearAdiantamento();
  }

  public preencherDocumento(event?: any) {
    // documentos do adiantamento
    this.documentoService.obterPorAdiantamento(this.entidade.id).pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (data: any) => {
          this.listaDocumentos = data ? data.content : new Array<AdiantamentoDocumento>();
        }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error })
      );
    this.buscaCidades();
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  private bloquearAdiantamento() {
    if (!this.funcaoService.podeAlterarAudesp(this.entidade.data_adiantamento, this.login)) {
      this.entidadeForm.disable();
      if (!this.entidade?.data_termino && !this.entidade?.data_prestacao) {
        this.entidadeForm.get('destino').enable();
        this.entidadeForm.get('data_partida').enable();
        this.entidadeForm.get('data_chegada').enable();
        this.entidadeForm.get('data_liberacao').enable();
        this.entidadeForm.get('data_vencimento').enable();
        this.entidadeForm.get('data_aplicacao').enable();
        this.entidadeForm.get('data_prazo_prestacao').enable();
      }

      this.entidadeForm.get('data_prestacao').enable();
      this.entidadeForm.get('data_termino').enable();
      this.entidadeForm.get('parecer').enable();
      this.entidadeForm.get('descricao_parecer').enable();

      // if (!this.entidade.data_prestacao && !this.entidade.data_termino) {
      //   this.entidadeForm.get('destino').enable()
      //   this.entidadeForm.get('data_partida').enable()
      //   this.entidadeForm.get('data_chegada').enable()
      // }
    }
    if (!this.entidade?.data_prestacao && this.prazoAlteracao(this.entidade.data_adiantamento)) {
      this.entidadeForm.get('situacao').enable();
    } else {
      this.entidadeForm.get('situacao').disable();
    }
    // if (this.parametro?.puxar_data_automatica) {
    //   this.entidadeForm.get('data_liberacao').disable();
    // }
    this.entidade?.empenho?.liquidacoes?.forEach(item => {
      if (item?.pagamentos?.length > 0) {
        this.entidadeForm.get('data_vencimento').disable();
      }
    });
  }

  public carregarMascara() {
    new GlobalService().calendarMascara();
  }

  private antesInserir() {
    if (this.currentActionRoute === 'novo') {
      this.obterUltimoAdiantamento();
      this.entidadeForm.get('data_adiantamento').setValue(new Date());
      this.entidadeForm.get('situacao').setValue('A');
    }
  }

  public dataVencimento() {
    if (this.entidadeForm.get('data_liberacao').value) {
      const dataLiberacao = new Date(this.entidadeForm.get('data_liberacao').value.getTime());
      dataLiberacao.setDate(dataLiberacao.getDate() + 30);
      this.entidadeForm.get('data_vencimento').setValue(new DateFormatPipe().transform(dataLiberacao, []))
    }
  }

  public async preencherFavorecido(event: any) {
    if (await this.validaEmpenhoAdiantamento()) return

    this.entidadeForm.get('beneficiario').setValue(event.favorecido.nome);
    this.entidadeForm.get('cargo_beneficiario').setValue(event.favorecido.funcao_cargo);
    this.entidadeForm.get('valor_adiantado').setValue(event.valor_empenho);//Não considera a anulação, trocar para o valor atual.
    this.entidadeForm.get('motivo').setValue(event.historico);
    this.entidadeForm.get('data_adiantamento').setValue(new DateFormatPipe().transform(event.data_empenho, []));

    if (event.subelemento.vpd?.nome) {
      this.entidadeForm.get('vpd').setValue(event.subelemento.vpd.nome)
    } else {
      this.entidadeForm.get('vpd').setValue('NENHUM')
    }
    if (event.favorecido.contas) {
      let contaPrincipal = event.favorecido.contas.find((m) => m.principal === true);
      if (contaPrincipal) {
        this.entidadeForm.get('banco').setValue(contaPrincipal.banco.nome);
        this.entidadeForm.get('agencia').setValue(contaPrincipal.agencia);
        this.entidadeForm.get('numero_conta').setValue(contaPrincipal.numero_conta);
      } else {
        let contaAtiva = event.favorecido.contas.find((m) => m.ativo === true);
        if (contaAtiva) {
          this.entidadeForm.get('banco').setValue(contaAtiva.banco.nome);
          this.entidadeForm.get('agencia').setValue(contaAtiva.agencia);
          this.entidadeForm.get('numero_conta').setValue(contaAtiva.numero_conta);
        }
      }
    }
    if (!this.entidade.empenho) {
      this.entidade.empenho = event;
      this.entidade.exercicio = this.login.exercicio;
      this.entidade.orgao = this.login.orgao;
    }
    this.calcularSaldo();
    this.preencherDatas(event);
  }

  private preencherDatas(empenho: Empenho) {
    if (!empenho) {
      return;
    }
    if (this.parametro?.puxar_data_automatica) {
      this.entidadeForm.get('data_vencimento').setValue(new DateFormatPipe().transform(empenho.liquidacoes[0]?.data_vencimento, []));
      if (empenho.liquidacoes[0]?.pagamentos[0]?.data_pagamento) {
        const dataLiberacao = new Date(empenho.liquidacoes[0]?.pagamentos[0]?.data_pagamento);
        const dataAplicacao = new Date(dataLiberacao);
        dataAplicacao.setDate(dataLiberacao.getDate() + 30);
        const dataPrestacao = new Date(dataAplicacao);
        dataPrestacao.setDate(dataAplicacao.getDate() + 30);

        this.entidadeForm.get('data_liberacao').setValue(new DateFormatPipe().transform(dataLiberacao.getTime(), []));
        this.entidadeForm.get('data_aplicacao').setValue(new DateFormatPipe().transform(dataAplicacao.getTime(), []));
        this.entidadeForm.get('data_prazo_prestacao').setValue(new DateFormatPipe().transform(dataPrestacao.getTime(), []));
      }
    }
  }

  public async calcularSaldo() {
    if (this.entidadeForm.get('empenho')) {
      // this.vlEfetivado = await this.empenhoService.obterValorEmpenho(
      //   this.entidade.empenho.numero, this.entidade.exercicio.id, this.entidade.orgao.id
      // ).toPromise();
      this.vlEfetivado = +this.entidade.empenho.valor_empenho;
    } else {
      this.vlEfetivado = this.entidadeForm.get('valor_adiantado').value ?
        +this.entidadeForm.get('valor_adiantado').value : +this.entidade.valor_adiantado;
    }

    this.vlAnulado = await this.adiantamentoService.totalAnuladoPorAdiantamento(this.entidade.empenho.numero, this.login.exercicio.id, this.login.orgao.id).toPromise();
    this.saldo = this.vlEfetivado - this.vlAnulado;
  }

  private obterUltimoAdiantamento() {
    this.adiantamentoService.obterUltimoAdiantamento(this.login.orgao.id, this.login.exercicio.id).pipe(takeUntil(this.unsubscribe))
      .subscribe((numero) => {
        this.entidadeForm.get('numero').setValue(this.funcaoService.strZero(numero, 5));
      });
  }

  private carregarAutoCompletes() {
    // autocomplete para empenho
    this.empenhoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('empenho'), this.empenhoService,
      'id', ['numero', 'exercicio.ano'], {
        especie: 'EMO',
        exercicio_id: this.login.exercicio.id, orgao_id: this.login.orgao.id,
        relations: 'exercicio,orgao,liquidacoes.pagamentos,favorecido.contas.banco,subelemento,ficha,ficha.acao,subelemento.vpd', orderBy: 'numero'
      }, { number: ['numero'] }
    );
  }

  private async validaEmpenhoAdiantamento(): Promise<boolean> {
    const empenho: Empenho = this.entidadeForm.get('empenho').value
    if (empenho) {
      const adiantamentos: Adiantamento[] = (await this.adiantamentoService.filtrar(1, -1, { relations: 'empenho,exercicio,orgao', 'empenho.id': empenho.id, 'exercicio.id': this.login.exercicio.id, 'orgao.id': this.login.orgao.id }).toPromise()).content

      if (adiantamentos.length && (!this.entidade.id || !adiantamentos.find(ad => ad.id === this.entidade.id))) {
        toastr.warning(`O empenho ${empenho.numero} está sendo utilizado por outro adiantamento!`);
        return true;
      }
      return false;
    }
    return false;
  }

  public salvarPrestacao() {
    this.prestarContas = true;
    this.beforeSubmit();
    this.entidadeForm.get('data_adiantamento').enable();
    this.entidadeForm.get('valor_adiantado').enable();
    if ((new Date(this.entidadeForm.get('data_prestacao').value).getFullYear() > this.login.exercicio.ano) || (new Date(this.entidadeForm.get('data_termino').value).getFullYear() > this.login.exercicio.ano)) {
      this.messageService.add({ severity: 'warn', summary: 'Aviso', detail: 'O ano da data de prestação de contas informada é de exercício diferente do logado no sistema, neste caso será necessário fazer um variação no exercício posterior para contabilizar a prestação de contas!', });
      this.entidadeForm.get('naoEscriturar').setValue(true)
    }
    const entidade: Adiantamento = this.jsonDataToResourceFn(this.entidadeForm.value);

    if (entidade.data_termino) {
      entidade.situacao = 'I';
      this.entidadeForm.get('situacao').setValue('I');
    }
    this.adiantamentoService.salvarPrestacao(entidade).pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.prestarContas = false;
        this.messageService.add({ severity: 'success', summary: 'Informação', detail: 'Prestação de contas salva com sucesso' });
      },
        (error) => {
          this.messageService.add(
            { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
          );
          this.cancelarPrestacao();
        });
  }

  public cancelarPrestacao() {
    this.prestarContas = false;
    this.entidadeForm.get('data_prestacao').setValue(null);
    this.entidadeForm.get('data_termino').setValue(null);
    this.entidadeForm.get('descricao_parecer').setValue(null);
    this.entidadeForm.get('parecer').setValue(null);
    this.loadResource();
  }

  private async parametroContabil() {
    const parametro = await this.parametroContabilService.filtrar(1, -1, { relations: 'orgao', 'orgao_id': this.login.orgao.id, }).toPromise();

    this.parametro = parametro?.content?.length ?  parametro.content[0]: this.login?.parametro['contabil'];
  }

  public imprimirAdiantamento() {
    const parametros = {};

    let relations = '';
    relations += 'empenho.ficha.executora.unidade,empenho.ficha.despesa,empenho.ficha.funcao,empenho.ficha.subfuncao,';
    relations += 'empenho.ficha.programa,empenho.ficha.acao,empenho.ficha.aplicacao,';
    relations += 'empenho.licitacao,empenho.modalidade,empenho.subelemento,empenho.favorecido';

    parametros['relations'] = relations;
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['id'] = this.entidadeForm.get('id').value;
    // parametros['impresso'] = this.selectNaoImpresso;
    this.adiantamentoService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        const nota = new NotaAdiantamento(this.assinaturaService);
        nota.imprimir(lista.content, this.login);
      },
        (error) => this.messageService.add(
          { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
        )
      );
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
      case 'controle-interno':
        this.router.navigate(['/contabilidade']);
        break;
      default:
        this.sair();
        break;
    }
  }

  public buscaCidades(atualizar?: boolean) {
    if ((this.entidadeForm.get('destino').value != null && this.entidadeForm.get('destino').value != '') || this.entidade.destino) {
      let origem = encodeURI(this.login.cidade.nome + '-' + this.login.cidade.estado.nome);
      let destino = encodeURI(atualizar ? this.entidadeForm.get('destino').value : this.entidade.destino);
      this.coordenadaOrigem = '';
      this.coordenadaDestino = '';

      let pOrigem = new Promise<String>((resolve) => {
        this.hereService.buscarCidade(origem).subscribe(res => {
          this.coordenadaOrigem = res.Response.View[0].Result[0].Location.DisplayPosition.Latitude;
          this.coordenadaOrigem += ',' + res.Response.View[0].Result[0].Location.DisplayPosition.Longitude;
          resolve(this.coordenadaOrigem);
        });
      });

      let pDestino = new Promise<String>((resolve) => {
        this.hereService.buscarCidade(destino).subscribe(res => {
          this.coordenadaDestino = res.Response.View[0].Result[0].Location.DisplayPosition.Latitude;
          this.coordenadaDestino += ',' + res.Response.View[0].Result[0].Location.DisplayPosition.Longitude;
          this.ibgeCidade = this.hereService.removerAcentos(res.Response.View[0].Result[0].Location.Address.City).split(' ').join('-').toLowerCase();
          this.ibgeUF = this.hereService.removerAcentos(res.Response.View[0].Result[0].Location.Address.State).toLowerCase();
          resolve(this.coordenadaDestino);
        });
      });

      Promise.all([pOrigem, pDestino]).then((res) => {
        this.hereService.buscarRota(res[0], res[1]).subscribe(destino => {
          this.destinoKm = this.hereService.converterDestino(destino.routes[0].sections[0].summary.length, 'km', 2);
          this.destinoHr = this.hereService.converterDestino(destino.routes[0].sections[0].summary.duration, 'hr', 0);
          this.destinoShow = true;
        });
      });
    } else {
      this.destinoShow = false;
    }
  }

  public async imprimirParecer() {
    if (!this.assinatura?.almoxarife || !this.assinatura?.cargo_almoxarife) {
      toastr.warning('Cadastre Assinatura de Controle Interno para prosseguir!')
      return;
    }

    if (!this.parecer) {
      toastr.warning('É necessário cadastrar parecer interno para prosseguir!')
      return;
    }

    Relatorio.imprimirPersonalizado('', this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, this.montarConteudoParecer(this.entidade), 'portrait', this.tituloRelatorioParecer(), this.layoutRelatorioParecer(), false, false, 'pdf')
  }

  public montarConteudoParecer(adiantamento: Adiantamento) {
    const conteudo = [];

    conteudo.push([{ text: 'PARECER', fontSize: 16, bold: true, alignment: 'center', border: [false, false, false, false] }])
    conteudo.push([{ text: '', margin: [0, 10, 0, 10], border: [false, false, false, false] }])
    conteudo.push([{
      text: `A ${this.login.orgao.nome} de acordo com o comunicado Nº${this.funcaoService.mascarar('####/####', this.parecer.numero_comunicado)}, Lei Municipal Nº${this.funcaoService.mascarar('####/####', this.parecer.lei)}, do Regimento de Adiantamento do Numerário, através do Departamento de Controle Interno, vem dar parecer ${adiantamento.parecer === 'F' ? 'Favorável'.toUpperCase() : adiantamento.parecer === 'D' ? 'Desfavorável'.toUpperCase() : ''}, sobre a regularidade da prestação de contas do empenho de adiantamento n° ${adiantamento.empenho?.numero}/${adiantamento.empenho?.exercicio?.ano}, já elaboradas e em nosso poder.`,
      fontSize: 12, border: [false, false, false, false]
    }]);
    conteudo.push([{ text: '', margin: [0, 10, 0, 10], border: [false, false, false, false] }])
    conteudo.push([{ text: `${this.login.cidade.nome} - ${this.login.cidade.estado.uf}, ${this.funcaoService.formatarDataExtenso(new Date)}`, fontSize: 12, alignment: 'center', border: [false, false, false, false] }]);
    conteudo.push([{ text: '', fontSize: 12, margin: [0, 10, 0, 10], border: [false, false, false, false] }])
    conteudo.push([{ text: '_________________________________________________', fontSize: 12, alignment: 'center', border: [false, false, false, false] }])
    conteudo.push([{ text: `${this.assinatura.almoxarife}`, fontSize: 12, alignment: 'center', border: [false, false, false, false] }])
    conteudo.push([{ text: `${this.assinatura.cargo_almoxarife}`, fontSize: 12, alignment: 'center', border: [false, false, false, false] }])

    return [
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          fontSize: 12,
          headerRows: 0,
          widths: ['*'],
          body: conteudo
        }
      }
    ];
  }

  public layoutRelatorioParecer() {
    return {
      linhas: {
        hLineWidth() {
          return 1;
        },
        vLineWidth() {
          return 1;
        },
        hLineColor() {
          return 'black';
        },
        paddingLeft() {
          return 3;
        },
        paddingRight() {
          return 3;
        }
      }
    };
  }

  public tituloRelatorioParecer() {
    return 'PARECER'
  }

  public acrescentaParecer() {
    const parecer = this.entidadeForm.get('parecer').value
    if (parecer) {
      this.entidadeForm.get('descricao_parecer').setValue(`${parecer === 'F' ? 'FAVORÁVEL' : parecer === 'D' ? 'DESFAVORÁVEL' : ''}`)
    }
  }

  public podeAlterarAudesp(data: Date): boolean {
    if (!data) {
      return true;
    }
    if (this.login['ultimoAudesp'] && data) {
      data = new Date(data);
      const mes = +this.login['ultimoAudesp'].split('-')[1];
      const ano = +this.login['ultimoAudesp'].split('-')[0];
      if ((data.getFullYear() < ano || (data.getFullYear() === ano && (+data.getMonth() + 1) <= mes)) && mes < 12)
        return false;
    }
    return true;
  }

  public prazoAlteracao(data: Date): boolean {
    if (this.login["dias_bloquear_alteracoes"]) {
      return (this.funcaoService.diferencaEmDias(new Date(), new Date(data)) < this.login["dias_bloquear_alteracoes"]);
    } else {
      return true;
    }
  }

}
