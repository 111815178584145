import { Component, Injector, Input, OnInit } from '@angular/core';
import { Login } from '../../entidade/login/login';
import * as toastr from 'toastr';
import { FuncaoService } from '../../util/funcao.service';
import { ConfirmationService } from 'primeng/api';
import { PNCPService } from '../../util/pncp.service';
import { ProgressoService } from '../../components/progresso/service/progresso.service';
import { PlanoContratacaoAnualService } from 'compra-lib';
import { PlanoContratacaoAnual } from '../../entidade/compra/plano-contratacao-anual.model';

@Component({
  selector: 'app-pncp-plano',
  templateUrl: './pncp-plano.component.html'
})
export class PncpPlanoComponent implements OnInit {

  @Input() login: Login;
  lista: Array<any> = new Array<any>();
  tipoFiltro: number = 0;
  todos: boolean = false;
  numeroPlano: string;

  textoAvisoLimite: string = '';

  relationsPlano: string = 'orgao,unidade,exercicio,itens';

  plano: PlanoContratacaoAnual = null;

  resultados: Array<{ item: string, sucesso: boolean, mensagem: string }> = new Array<{ item: string, sucesso: boolean, mensagem: string }>();
  public colunaOrdenacao: string;
  public ascendente = true;
  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected planoService: PlanoContratacaoAnualService,
    protected confirmationService: ConfirmationService,
    protected pncpService: PNCPService,
    protected progressoService: ProgressoService,
    private funcaoService: FuncaoService) {
  }

  ngOnInit(): void {
    this.filtrarPlanos();
  }

  filtrarPlanos() {
    const parametros = {};
    this.plano = null;

    parametros['orgao.id'] = this.login.orgao.id;
    parametros['exercicio.id'] = this.login.exercicio.id;
    parametros['relations'] = this.relationsPlano;
    parametros['cadastrado_pncp'] = false;
    parametros['ignorar_pncp'] = false;
    parametros['excluido'] = false;
    if (this.tipoFiltro == 1) {
      parametros['sequencial_pncp$null'] = true;
    } else if (this.tipoFiltro == 2) {
      parametros['sequencial_pncp$not_null'] = true;
    } else if (this.tipoFiltro < 0) {
      parametros['sequencial_pncp$not_null'] = true;
      parametros['cadastrado_pncp'] = true;
    }

    this.planoService.filtrar(0, -1, parametros).subscribe((res) => {
      this.lista = res.content;
    }, () => {
      toastr.error('Erro ao filtrar contratos para o PNCP');
    });

  }

  public podeIncluir(url?: string) {
    if (this.login) {
      if (new FuncaoService().campoJsonToken(this.login.token, "administrador") == true) {
        return true;
      }

      return new FuncaoService().podeIncluir(this.login, url)
    }
    return false;
  }

  marcarTodos() {
    this.lista.forEach(i => {
      i.enviar = this.todos;
    });
  }

  voltarListagemPlanos() {
    this.resultados = [];
    this.filtrarPlanos();
  }

  cancelarEdicao() {
    this.plano = null;
    this.resultados = [];
  }

  buscarPlano(item: PlanoContratacaoAnual) {
    let p = new Promise<void>(async (resolve, reject) => {
      if (item) {
        this.plano = item;
        resolve();
      } else {
        if (!this.numeroPlano || this.numeroPlano == '') {
          this.filtrarPlanos();

          reject();
        }

        let planos = await this.planoService.filtrar(1, -1,
          {
            'orgao.id': this.login.orgao.id,
            relations: this.relationsPlano,
            cadastrado_pncp: true,
            id: this.numeroPlano
          }).toPromise();

        if (planos.content.length > 1) {
          this.lista = planos.content;
          toastr.info('Mais de uma PCA encontrado')
          reject();
        } else {
          const plano = planos.content[0];
          if (plano && plano.cadastrado_pncp) {
            this.buscarPlano(plano);
            reject();
          } else {
            toastr.info('PCA não encontrada ou não cadastrada no PNCP')
            reject();
          }
        }
      }
    });

    p.then(() => {

    });
  }

  confirmarEnvio() {
    const envios: Array<number> = new Array<number>();
    let texto: string = '';
    this.textoAvisoLimite = '';
    for (const i of this.lista) {
      if (i.enviar) {
        envios.push(i.id);
        texto += '\nPlano número: ' + i.id + `(${i.itens.length} itens cadastrados)`;
        if (i.itens.length > 1000) {
          this.textoAvisoLimite += `O plano número ${i.id} possui ${i.itens.length} itens, ${i.itens.length - 1000} itens ainda estão pendentes de envio manualmente pós envio inicial.<br/>`;
        }
      }
    }

    this.confirmationService.confirm({
      header: 'Confirmar Envio',
      message: `<pre>Confirma o envio dos Planos de Contratação Anual a seguir para o PNCP?`
        + `\nAlterações posteriores apenas via exclusão: \n${texto}`
        + `\n${this.textoAvisoLimite}</pre>.`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, confirmo envios.',
      rejectLabel: 'Não',
      accept: async () => {
        this.enviar(envios);
      }
    });
  }

  private enviar(lista: Array<number>) {
    this.pncpService.enviarPlanosContratacao(lista).subscribe((idTransacao) => {
      this.progressoService.show(idTransacao, async (retorno) => {
        this.resultados = [];
        this.resultados = retorno;
        if (this.textoAvisoLimite != '') {
          this.confirmationService.confirm({
            header: '',
            message: '<pre>Rotina finalizada, confira o retorno na listagem de retornos. '
              + (this.textoAvisoLimite == '' ? '' : 'Devido a regras do PNCP, o limite de itens por envio é 1.000, com essa informação:<br/>' + this.textoAvisoLimite) + '</pre>',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'OK.',
            rejectVisible: false
          });
        }
      });
    }, e => {
      this.funcaoService.acaoErro(e);
    });
  }

  public reordenar(coluna: string, toggle: boolean, lista: any) {
    if (!coluna) return;

    if (!toggle) this.ascendente = true;
    else this.ascendente = !this.ascendente;

    this.colunaOrdenacao = coluna;
    if (this.colunaOrdenacao === 'id') {
      lista.sort((a, b) => (this.ascendente ? a.id - b.id : b.id - a.id))
    }
    if (this.colunaOrdenacao === 'exercicio.ano') {
      lista.sort((a, b) => (this.ascendente ? a.exercicio.ano - b.exercicio.ano : b.exercicio.ano - a.exercicio.ano))
    }
    if (this.colunaOrdenacao === 'unidade.nome') {
      lista.sort((a, b) => {
        if (this.ascendente) {
          return a.unidade.nome.localeCompare(b.unidade.nome);
        } else {
          return b.unidade.nome.localeCompare(a.unidade.nome);
        }
      });
    }
  }
}
