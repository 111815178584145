import { Component, Injector, Input, OnInit } from '@angular/core';
import { Login } from '../../entidade/login/login';
import * as toastr from 'toastr';
import { PncpContratoService } from '../service/contrato.service';
import { FuncaoService } from '../../util/funcao.service';
import { ConfirmationService } from 'primeng/api';
import { PNCPService } from '../../util/pncp.service';
import { ProgressoService } from '../../components/progresso/service/progresso.service';
import { Contrato } from '../../entidade/compra/contrato.model';
import { ContratoStorageService } from 'compra-lib';

@Component({
  selector: 'app-pncp-atas',
  templateUrl: './pncp-atas.component.html',
  styleUrls: ['./pncp-atas.component.css']
})
export class PncpAtasComponent implements OnInit {

  @Input() login: Login;
  lista: Array<any> = new Array<any>();
  tipoFiltro: number = 0;
  todos: boolean = false;
  numeroContrato: string;
  pendentes: boolean = false;
  historicoCompra: Array<any> = new Array<any>();
  relationsContrato = 'favorecido,tipo_contratacao,licitacao,licitacao.exercicio,orgao,licitacao.modalidade';

  contrato: Contrato = null;

  resultados: Array<{ item: string, sucesso: boolean, mensagem: string }> = new Array<{ item: string, sucesso: boolean, mensagem: string }>();
  public colunaOrdenacao: string;
  public ascendente = true;
  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected contratoService: PncpContratoService,
    protected storageService: ContratoStorageService,
    protected confirmationService: ConfirmationService,
    protected pncpService: PNCPService,
    protected progressoService: ProgressoService,
    private funcaoService: FuncaoService) {
  }

  ngOnInit(): void {
    this.filtrarContratos();
  }

  filtrarContratos() {
    const parametros = {};

    parametros['tipo'] = 'REGISTRO_PRECO';
    parametros['ignoreCondObrig'] = true;
    parametros['licitacao.sequencial_pncp$not_null'] = true;
    parametros['licitacao.excluido_pncp'] = false;
    parametros['orgao.id'] = this.login.orgao.id;
    parametros['relations'] = this.relationsContrato;
    parametros['cadastrado_pncp'] = false;
    parametros['excluido'] = false;
    if (this.tipoFiltro == 1) {
      parametros['sequencial_pncp$null'] = true;
    } else if (this.tipoFiltro == 2) {
      parametros['sequencial_pncp$not_null'] = true;
    } else if (this.tipoFiltro < 0) {
      parametros['sequencial_pncp$not_null'] = true;
      parametros['cadastrado_pncp'] = true;
    }

    this.contratoService.filtrar(0, -1, parametros).subscribe((res) => {
      this.lista = res.content;
    }, () => {
      toastr.error('Erro ao filtrar contratos para o PNCP');
    });

  }

  public podeIncluir(url?: string) {
    if (this.login) {
      if (new FuncaoService().campoJsonToken(this.login.token, "administrador") == true) {
        return true;
      }

      return new FuncaoService().podeIncluir(this.login, url)
    }
    return false;
  }

  marcarTodos() {
    this.lista.forEach(i => {
      i.enviar = this.todos;
    });
  }

  voltarListagemAtas() {
    this.resultados = [];
    this.filtrarContratos();
  }

  cancelarEdicao() {
    this.contrato = null;
    this.resultados = [];
  }

  buscarContrato(ata: Contrato) {
    let p = new Promise<void>(async (resolve, reject) => {
      if (ata) {
        this.contrato = ata;
        //Carregamento de arquivos e memorial retirado da base, por sobrecarga em cenários com +1000
        const arqs = await this.storageService.filtrar(0, -1, { id: ata.id }).toPromise();
        this.contrato.arquivos = [].concat(arqs.content);
        resolve();
      } else {
        if (!this.numeroContrato || this.numeroContrato == '') {
          if (!this.pendentes) {
            this.filtrarContratos();
          } else {
            this.filtrarPendentes();
          }

          reject();
        }

        let contratos = await this.contratoService.filtrar(1, -1,
          {
            'orgao.id': this.login.orgao.id,
            relations: this.relationsContrato,
            cadastrado_pncp: true,
            numero: this.numeroContrato
          }).toPromise();

        if (contratos.content.length > 1) {
          this.lista = contratos.content;
          toastr.info('Mais de uma ata encontrada')
          reject();
        } else {
          const contrato = contratos.content[0];
          if (contrato && contrato.cadastrado_pncp) {
            this.buscarContrato(contrato);
            reject();
          } else {
            toastr.info('Ata não encontrada ou não cadastrada no PNCP')
            reject();
          }
        }
      }
    });

    p.then(() => {
      const validos = this.contrato.arquivos.filter(a => a.tipo_documento);
      this.contrato.arquivos = validos;
    });
  }

  confirmarEnvio() {
    const envios: Array<number> = new Array<number>();
    let texto: string = '';
    for (const i of this.lista) {
      if (i.enviar) {
        envios.push(i.id);
        texto += '\n' + i.numero.replace(/(\d{4})(\d{4})/g, '\$1\/\$2') + ` (${i.sequencial_pncp ? 'Retificação' : 'Novo'})`;
      }
    }

    this.confirmationService.confirm({
      header: 'Confirmar Envio',
      message: `<pre>Confirma o envio das atas de registro de preço a seguir para o PNCP? \nAlterações posteriores apenas via retificação ou exclusão: \n${texto}</pre>.`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, confirmo envios.',
      rejectLabel: 'Não',
      accept: async () => {
        this.enviar(envios);
      }
    });
  }

  private enviar(lista: Array<number>) {
    if (!this.login.dados_pncp?.id) {
      toastr.error('Não autenticado no portal do PNCP');
      return;
    }
    this.pncpService.enviarAtaRegistroPreco(lista).subscribe((idTransacao) => {
      this.progressoService.show(idTransacao, async (retorno) => {
        this.resultados = retorno;
      });
    }, e => {
      console.log(e);
      this.funcaoService.acaoErro(e);
    });
  }

  filtrarPendentes() {
    if (!this.pendentes) {
      this.contratoService.buscarPendenciasPncp(this.login.orgao.id, this.login.exercicio.id, 'REGISTRO_PRECO').subscribe((res) => {
        this.lista = res.content;
      }, () => {
        toastr.error('Erro ao filtrar contratos para o PNCP');
      });
    } else {
      this.tipoFiltro = -1;
      this.filtrarContratos();
    }
  }

  public reordenar(coluna: string, toggle: boolean, lista: any) {
    if (!coluna) return;

    if (!toggle) this.ascendente = true;
    else this.ascendente = !this.ascendente;

    for (const item of lista) {
      if (!item.cadastrado_pncp && !item.sequencial_pncp) {
        item.situacao = 'PENDENTE'
      }

      if (item.cadastrado_pncp && item.sequencial_pncp) {
        item.situacao = 'ENVIADO'
      }

      if (!item.cadastrado_pncp && item.sequencial_pncp) {
        item.situacao = 'RETIFICAÇÃO'
      }
    }

    this.colunaOrdenacao = coluna;
    if (this.colunaOrdenacao === 'numero') {
      lista.sort((a, b) => (this.ascendente ? a.numero - b.numero : b.numero - a.numero))
    }
    if (this.colunaOrdenacao === 'favorecido.nome') {
      lista.sort((a, b) => {
        if (this.ascendente) {
          return a.favorecido.nome.localeCompare(b.favorecido.nome);
        } else {
          return b.favorecido.nome.localeCompare(a.favorecido.nome);
        }
      });
    }
    if (this.colunaOrdenacao === 'tipo_contratacao.nome') {
      lista.sort((a, b) => {
        if (this.ascendente) {
          return a.tipo_contratacao.nome.localeCompare(b.tipo_contratacao.nome);
        } else {
          return b.tipo_contratacao.nome.localeCompare(a.tipo_contratacao.nome);
        }
      });
    }
    if (this.colunaOrdenacao === 'situacao') {
      lista.sort((a, b) => {
        if (this.ascendente) {
          return a.situacao.localeCompare(b.situacao);
        } else {
          return b.situacao.localeCompare(a.situacao);
        }
      });
    }
  }

}
