import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FuncaoService, GlobalService, ExercicioService, Relatorio, OrgaoAssinaturaService, OrgaoAssinatura } from 'eddydata-lib';
import { DespesaService } from 'administrativo-lib';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GerenciamentoReceitasDespesas {
  private assinatura: OrgaoAssinatura;
  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected despesaService: DespesaService,
    protected exercicioService: ExercicioService,
    protected assinaturaService: OrgaoAssinaturaService) {
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
  }
  
  public async montarBalanceteDespesa(mes: number, exercicioId: number, login: any, dtInicio: string, dtFim: string) {
    const parametros: {} = {};
    if (mes) {
      parametros['mes'] = mes;
    } else {
      parametros['dtInicio'] = dtInicio;
      parametros['dtFim'] = dtFim;
    }
    if (exercicioId) {
      parametros['exercicio'] = exercicioId;
    }
    parametros['orgao'] = login.orgao.id;

    const ex = await this.exercicioService.obterId(exercicioId).toPromise();

    let titulo = '';
    if (mes) {
      titulo = this.globalService.obterDataBR().monthNames[mes - 1].toLocaleUpperCase() + '/' + ex.ano;
    } else {
      let dt = dtInicio.split("-");
      titulo = dt[2] + '/' + dt[1] + '/' + dt[0] + ' à ';
      dt = dtFim.split("-");
      titulo += dt[2] + '/' + dt[1] + '/' + dt[0];
    }

    this.despesaService.gerenciamentoReceitaDespesa(parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        Relatorio.imprimirPersonalizado(`PERÍODO: ${titulo}`, login.usuario.nome, login.usuario.sobrenome,
          login.orgao.nome, login.brasao,
          this.montarGerenciamento(dados)
            .concat(await this.montarAssinatura(login.orgao.id)),
          'portrait', 'RELATORIOS  AUXILIARES - DAGs',
          {
            linhas: {
              hLineWidth(i, node) {
                return 1;
              },
              vLineWidth(i) {
                return 1;
              },
              hLineColor(i) {
                return 'black';
              },
              paddingLeft(i) {
                return 3;
              },
              paddingRight(i, node) {
                return 3;
              }
            }
          });
      });
  }

  private montarGerenciamento(dados: any): {}[] {
    const conteudo: {}[] = [
      [{ text: 'RELATÓRIO DE GERENCIAMENTO DE RECEITAS E DESPESAS', fontSize: 12, alignment: 'center', colSpan: 4, margin: [0, 10, 0, 10], bold: true }, '', '', ''],
      [
        { text: 'COMPARATIVO DESPESA EMPENHADA', fontSize: 10, colSpan: 2, margin: [0, 6, 0, 6], border: [true, false, false, false] }, '',
        { text: 'COMPARATIVO DESPESA PROCESSADA', fontSize: 10, colSpan: 2, margin: [0, 6, 0, 6], border: [true, false, true, false] }, '',
      ]
    ];

    // Recursos Próprios
    conteudo.push([
      { text: 'RECURSO PRÓPRIOS', colSpan: 2, alignment: 'left', bold: true, border: [true, false, true, false] }, '',
      { text: 'RECURSO PRÓPRIOS', colSpan: 2, alignment: 'left', bold: true, border: [true, false, true, false] }, '',
    ]);
    conteudo.push([
      { text: 'Receitas de Recursos Próprios', alignment: 'left', border: [true, false, false, false] },
      { text: this.funcaoService.convertToBrNumber(+dados.receita_proprio, 2), alignment: 'right', border: [false, false, true, false] },
      { text: 'Receitas de Recursos Próprios', alignment: 'left', border: [true, false, false, false] },
      { text: this.funcaoService.convertToBrNumber(+dados.receita_proprio, 2), alignment: 'right', border: [false, false, true, false] },
    ]);
    conteudo.push([
      { text: 'Despesas de Recursos Próprios', alignment: 'left', border: [true, false, false, false] },
      { text: this.funcaoService.convertToBrNumber(+dados.empenhada_despesa_proprio, 2), alignment: 'right', border: [false, false, true, false] },
      { text: 'Despesas de Recursos Próprios', alignment: 'left', border: [true, false, false, false] },
      { text: this.funcaoService.convertToBrNumber(+dados.processada_despesa_proprio, 2), alignment: 'right', border: [false, false, true, false] },
    ]);
    conteudo.push([
      { text: 'Transferência Intra-Orçamentarias', alignment: 'left', border: [true, false, false, false] },
      { text: this.funcaoService.convertToBrNumber(+dados.transferencia_intra_orcamentaria, 2), alignment: 'right', border: [false, false, true, false] },
      { text: 'Transferência Intra-Orçamentarias', alignment: 'left', border: [true, false, false, false] },
      { text: this.funcaoService.convertToBrNumber(+dados.transferencia_intra_orcamentaria, 2), alignment: 'right', border: [false, false, true, false] },
    ]);
    conteudo.push([
      { text: 'SUPERAVIT / DEFICIT', alignment: 'left', bold: true, border: [true, false, false, false] },
      { text: this.funcaoService.convertToBrNumber(+dados.receita_proprio - (+dados.empenhada_despesa_proprio + +dados.transferencia_intra_orcamentaria), 2, true), alignment: 'right', bold: true, border: [false, false, true, false] },
      { text: 'SUPERAVIT / DEFICIT', alignment: 'left', bold: true, border: [true, false, false, false] },
      { text: this.funcaoService.convertToBrNumber(+dados.receita_proprio - (+dados.processada_despesa_proprio + +dados.transferencia_intra_orcamentaria), 2, true), alignment: 'right', bold: true, border: [false, false, true, false] },
    ]);

    // Recursos Vinculados
    conteudo.push([
      { text: 'RECURSOS VINCULADOS', colSpan: 2, alignment: 'left', bold: true, border: [true, false, true, false] }, '',
      { text: 'RECURSOS VINCULADOS', colSpan: 2, alignment: 'left', bold: true, border: [true, false, true, false] }, '',
    ]);
    conteudo.push([
      { text: 'Receitas de Recursos Vinculados', alignment: 'left', border: [true, false, false, false] },
      { text: this.funcaoService.convertToBrNumber(+dados.receita_vinculado, 2), alignment: 'right', border: [false, false, true, false] },
      { text: 'Receitas de Recursos Vinculados', alignment: 'left', border: [true, false, false, false] },
      { text: this.funcaoService.convertToBrNumber(+dados.receita_vinculado, 2), alignment: 'right', border: [false, false, true, false] },
    ]);
    conteudo.push([
      { text: 'Despesas de Recursos Vinculados', alignment: 'left', border: [true, false, false, false] },
      { text: this.funcaoService.convertToBrNumber(+dados.empenhada_despesa_vinculado, 2), alignment: 'right', border: [false, false, true, false] },
      { text: 'Despesas de Recursos Vinculados', alignment: 'left', border: [true, false, false, false] },
      { text: this.funcaoService.convertToBrNumber(+dados.processada_despesa_vinculado, 2), alignment: 'right', border: [false, false, true, false] },
    ]);
    conteudo.push([
      { text: 'SUPERAVIT / DEFICIT', alignment: 'left', bold: true, border: [true, false, false, false] },
      { text: this.funcaoService.convertToBrNumber(+dados.receita_vinculado - +dados.empenhada_despesa_vinculado, 2, true), alignment: 'right', bold: true, border: [false, false, true, false] },
      { text: 'SUPERAVIT / DEFICIT', alignment: 'left', bold: true, border: [true, false, false, false] },
      { text: this.funcaoService.convertToBrNumber(+dados.receita_vinculado - +dados.processada_despesa_vinculado, 2, true), alignment: 'right', bold: true, border: [false, false, true, false] },
    ]);

    const totalReceita = +dados.receita_proprio + +dados.receita_vinculado;
    const totalEmpenhadaDespesa = +dados.empenhada_despesa_proprio + +dados.empenhada_despesa_vinculado;
    const totalProcessadaDespesa = +dados.processada_despesa_proprio + +dados.processada_despesa_vinculado;
    const totalTransferencia = +dados.transferencia_intra_orcamentaria;
    
    // Totais
    conteudo.push([
      { text: 'RECURSO TOTAIS', colSpan: 2, alignment: 'left', bold: true, border: [true, false, true, false], margin: [0, 8, 0, 0] }, '',
      { text: 'RECURSO TOTAIS', colSpan: 2, alignment: 'left', bold: true, border: [true, false, true, false], margin: [0, 8, 0, 0] }, '',
    ]);
    conteudo.push([
      { text: 'Receitas de Recursos Totais', alignment: 'left', border: [true, false, false, false] },
      { text: this.funcaoService.convertToBrNumber(totalReceita, 2), alignment: 'right', border: [false, false, true, false] },
      { text: 'Receitas de Recursos Totais', alignment: 'left', border: [true, false, false, false] },
      { text: this.funcaoService.convertToBrNumber(totalReceita, 2), alignment: 'right', border: [false, false, true, false] },
    ]);
    conteudo.push([
      { text: 'Despesas de Recursos Totais', alignment: 'left', border: [true, false, false, false] },
      { text: this.funcaoService.convertToBrNumber(totalEmpenhadaDespesa, 2), alignment: 'right', border: [false, false, true, false] },
      { text: 'Despesas de Recursos Totais', alignment: 'left', border: [true, false, false, false] },
      { text: this.funcaoService.convertToBrNumber(totalProcessadaDespesa, 2), alignment: 'right', border: [false, false, true, false] },
    ]);
    conteudo.push([
      { text: 'Transferência Intra-Orçamentarias', alignment: 'left', border: [true, false, false, false] },
      { text: this.funcaoService.convertToBrNumber(totalTransferencia, 2), alignment: 'right', border: [false, false, true, false] },
      { text: 'Transferência Intra-Orçamentarias', alignment: 'left', border: [true, false, false, false] },
      { text: this.funcaoService.convertToBrNumber(totalTransferencia, 2), alignment: 'right', border: [false, false, true, false] },
    ]);
    conteudo.push([
      { text: 'SUPERAVIT / DEFICIT', alignment: 'left', margin: [0, 0, 0, 8], bold: true, border: [true, false, false, false] },
      { text: this.funcaoService.convertToBrNumber(totalReceita - (totalEmpenhadaDespesa + totalTransferencia), 2, true), alignment: 'right', margin: [0, 0, 0, 8], bold: true, border: [false, false, true, false] },
      { text: 'SUPERAVIT / DEFICIT', alignment: 'left', margin: [0, 0, 0, 8], bold: true, border: [true, false, false, false] },
      { text: this.funcaoService.convertToBrNumber(totalReceita - (totalProcessadaDespesa + totalTransferencia), 2, true), alignment: 'right', margin: [0, 0, 0, 8], bold: true, border: [false, false, true, false] },
    ]);

    return [{
      layout: 'linhas',
      table: {
        widths: ['auto', '*', 'auto', '*'],
        body: conteudo,
      }
    }];
  }

  private async montarAssinatura(orgao: number) {
    const conteudo = [];
    this.assinatura = await this.assinaturaService.obter({ orgao_id: orgao }).toPromise();

    conteudo.push([
      { text: '__________________________________________', alignment: 'center', margin: [0, 16, 0, 0], border: [true, true, false, false] },
      { text: '__________________________________________', alignment: 'center', margin: [0, 16, 0, 0], border: [false, true, true, false] }
    ]);
    conteudo.push([
      { text: this.assinatura.contador, bold: true, alignment: 'center', border: [true, false, false, false] },
      { text: this.assinatura.tesoureiro, bold: true, alignment: 'center', border: [false, false, true, false] },
    ]);
    conteudo.push([
      { text: this.assinatura.cargo_contador, bold: true, alignment: 'center', margin: [0, 0, 0, 8], border: [true, false, false, true] },
      { text: this.assinatura.cargo_tesoureiro, bold: true, alignment: 'center', margin: [0, 0, 0, 8], border: [false, false, true, true] },
    ]);

    return [{
      table: {
        dontBreakRows: true,
        widths: ['*', '*'],
        body: conteudo
      }
    }];
  }
}